import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IdAndLabel } from 'src/app/core/idAndLabel';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';

@Component({
  selector: 'app-assign-groups',
  templateUrl: './assign-groups.component.html',
  styleUrls: ['./assign-groups.component.scss']
})
export class AssignGroupsComponent implements OnInit {
  @Input() availableGroups: IdAndLabel[];
  @Input() title: string;
  @Input() message: string;
  @Input() singleSelection = false;
  @Output() result = new EventEmitter<IdAndLabel[]>();

  dataSource = new ClientFiltrableDataSource<IdAndLabel>();
  selectedGroupsHandler = new SelectedItemsHandler<IdAndLabel>();
  constructor(public modalRef: BsModalRef) {}

  ngOnInit() {
    this.dataSource.setItems(this.availableGroups);
  }

  closeModal() {
    this.result.emit(this.selectedGroupsHandler.items);
    this.modalRef.hide();
  }
}
