import { ShareType, UserRole } from "@app/shared/consts/remote-support/enums"
import {
  ArchivedMediaFileMetadata,
  AssetWrapper,
  MediaFile,
  MediaFileMetadata,
  PartialMediaFileMetadata
} from "./media"
import {
  ActiveMessageMetadata,
  ArchivedMessageMetadata,
  LoggedMessageMetadata,
  Message,
  PartialMessage
} from "./message"

export interface ShareContext<T> {
  shareType: ShareType
  data: T
}

export interface DrawingSegment {
  start: { x: number; y: number }
  end: { x: number; y: number }
}

export interface UIServiceStream {
  provider: UserRole
  stream: MediaStream
  recording?: {
    requester: UserRole;
    startTime: number;
    activeData?: {
      recorder: MediaRecorder;
      timeout: ReturnType<typeof setInterval>;
      data?: Blob;
      refTime?: number;
    };
  }
}

export interface SessionMetadataInterface<
  CustomData,
  AssetType extends MediaFileMetadata | string
> {
  date: Date
  duration?: number
  deviceID: string
  pin: string
  disconnectType?: SessionDisconnectType
  note?: SessionNote
  messages?: ArchivedMessageMetadata[] | ActiveMessageMetadata[]
  assets?: AssetWrapper<AssetType>[]
  customData: CustomData
}

export abstract class SessionMetadata<CustomData> {
  constructor(data: SessionMetadataInterface<CustomData, MediaFileMetadata>) {
    this.date = data.date
    this.duration = data.duration ? data.duration : 0
    this.deviceIdentifier = data.deviceID
    this.pin = data.pin
    this.disconnectType = data.disconnectType
      ? data.disconnectType
      : SessionDisconnectType.system
    this.note = data.note
      ? data.note
      : new SessionNote({ editDate: data.date, editor: "", text: "" })
    this.messages = data.messages ? data.messages : []
    this.assets = data.assets ? data.assets : []
    this.customData = data.customData
  }
  /**
   * The start time of the session
   */
  date: Date
  /**
   * The duration of this session, in ms
   */
  duration: number
  /**
   * A string used to uniquely identify the device associated with this session, such as a serial number
   */
  deviceIdentifier: string
  /**
   * The PIN used to join this session
   */
  pin: string
  /**
   * The cause of this session ending
   */
  disconnectType: SessionDisconnectType
  /**
   * Agent notes about this session
   */
  note: SessionNote
  /**
   * Messages sent during the session
   */
  messages: ArchivedMessageMetadata[] | ActiveMessageMetadata[]
  /**
   * Assets associated with this session.
   */
  assets: AssetWrapper<MediaFileMetadata>[]

  get media(): AssetWrapper<MediaFileMetadata>[] {
    return this.assets.filter(wrapper => wrapper.inMedia)
  }

  /**
   * Flexible storage for user-defined data used in the session,
   * such as agent identity.
   */
  customData: CustomData
}

// Trying implementation without this, because it's fundamentally no different from an ArchivedSession
// export class LoggedSessionMetadata<CustomData> extends SessionMetadata<
//   CustomData
// > {
//   constructor(
//     data: SessionMetadataInterface<CustomData, ArchivedMediaFileMetadata>
//   ) {
//     super(data)
//   }
//   // Note: update to >= ES2022 will require this gets a declare tag
//   assets: AssetWrapper<ArchivedMediaFileMetadata>[]
//   messages: ArchivedMessageMetadata[]

// }

export abstract class Session<CustomData> extends SessionMetadata<CustomData> {
  constructor(data: SessionMetadataInterface<CustomData, MediaFileMetadata>) {
    super(data)
    this.path = [
      this.deviceIdentifier,
      this.date.toISOString().replace(/:/g, "-")
    ]
  }
  // Note: update to >= ES2022 will require this gets a declare tag
  assets: AssetWrapper<MediaFileMetadata>[]
  path: string[]

  getDurationString(): string {
    let seconds = Math.floor(this.duration / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    hours = hours - days * 24
    minutes = minutes - days * 24 * 60 - hours * 60
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

    const hoursDisplay = hours.toString().padStart(2, "0")
    const minutesDisplay = minutes.toString().padStart(2, "0")
    const secondsDisplay = seconds.toString().padStart(2, "0")
    return `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`
  }

  getFolder(separator: string = "/"): string {
    let folder = ""
    this.path.forEach(level => (folder += `${level}${separator}`))
    return folder
  }

  toSessionLog() {
    const assets = this.assets.map(
      aw =>
        new AssetWrapper<string>(aw.asset.fileName, aw.inMessage, aw.inMedia)
    )
    let messages: Array<LoggedMessageMetadata>
    if (this.messages.length === 0) {
      messages = []
    } else {
      if (this.messages[0] instanceof ActiveMessageMetadata) {
        messages = ((this.messages as ActiveMessageMetadata[]).filter(
          m => !(m instanceof PartialMessage)
        ) as Message[]).map(
          m =>
            new LoggedMessageMetadata(
              m.sent,
              m.timestamp,
              m.message instanceof AssetWrapper
                ? m.message.asset.fileName
                : m.message,
              m.message instanceof AssetWrapper ? "media" : "text"
            )
        )
      } else {
        messages = (this.messages as ArchivedMessageMetadata[]).map(
          m =>
            new LoggedMessageMetadata(
              m.sent,
              m.timestamp,
              m.message instanceof AssetWrapper
                ? m.message.asset.fileName
                : m.message,
              m.message instanceof AssetWrapper ? "media" : "text"
            )
        )
      }
    }

    return new SessionLog<CustomData>({
      date: this.date,
      pin: this.pin,
      deviceIdentifier: this.deviceIdentifier,
      duration: this.duration,
      disconnectType: this.disconnectType,
      note: this.note,
      messages: messages,
      assets: assets,
      customData: this.customData
    })
  }
}

export class ActiveSession<CustomData> extends Session<CustomData> {
  constructor(
    data: SessionMetadataInterface<
      CustomData,
      PartialMediaFileMetadata | MediaFile
    >
  ) {
    super(data)
  }
  // Note: update to >= ES2022 will require this gets a declare tag
  assets: AssetWrapper<PartialMediaFileMetadata | MediaFile>[]
  messages: ActiveMessageMetadata[]
}

export class ArchivedSession<CustomData> extends Session<CustomData> {
  constructor(
    data: SessionMetadataInterface<
      CustomData,
      ArchivedMediaFileMetadata | MediaFile
    >
  ) {
    super(data)
  }
  // Note: update to >= ES2022 will require this gets a declare tag
  assets: AssetWrapper<ArchivedMediaFileMetadata | MediaFile>[]
  messages: ArchivedMessageMetadata[]
}

export interface SessionLogInterface<CustomData> {
  date: Date
  duration: number
  deviceIdentifier: string
  pin: string
  disconnectType?: SessionDisconnectType
  note?: SessionNote
  messages?: LoggedMessageMetadata[]
  assets?: AssetWrapper<string>[]
  customData: CustomData
}

export class SessionLog<CustomData> implements SessionLogInterface<CustomData> {
  constructor(source: SessionLogInterface<CustomData>) {
    this.date = source.date
    this.pin = source.pin
    this.deviceIdentifier = source.deviceIdentifier
    this.duration = source.duration
      ? source.duration
      : new Date().valueOf() - this.date.valueOf()
    this.disconnectType = source.disconnectType
      ? source.disconnectType
      : SessionDisconnectType.system
    this.note = source.note
      ? source.note
      : new SessionNote({ editDate: new Date(), editor: "", text: "" })
    this.messages = source.messages ? source.messages : []
    this.assets = source.assets ? source.assets : []
    this.customData = source.customData
  }

  date: Date
  duration: number
  deviceIdentifier: string
  pin: string
  disconnectType: SessionDisconnectType
  note: SessionNote
  messages: LoggedMessageMetadata[]
  assets: AssetWrapper<string>[]
  customData: CustomData

  setEnd(ts: Date = new Date()) {
    this.duration = ts.valueOf() - this.date.valueOf()
  }

  setDisconnect(type: SessionDisconnectType = SessionDisconnectType.system) {
    this.disconnectType = type
  }

  getPath(): string {
    return `${this.deviceIdentifier}~${this.date
      .toISOString()
      .replace(/:/g, "-")}~`
  }
}

export interface SessionNoteInterface {
  editDate: Date
  editor: string
  text: string
}

export class SessionNote implements SessionNoteInterface {
  editDate: Date
  editor: string
  text: string

  constructor(source: SessionNoteInterface) {
    this.editDate = source.editDate
    this.editor = source.editor
    this.text = source.text
  }

  setDate(date: Date) {
    this.editDate = date
  }

  setEditor(editor: string) {
    this.editor = editor
  }

  setNote(note: string) {
    this.text = note
  }
}

export interface SessionMediaDataInterface {
  Key: string
  Size: number
  LastModified: string
}

export interface SessionHistoryDataInterface {
  Key: string
  Size: number
}

export enum SessionDisconnectType {
  system = "SYSTEM",
  agent = "AGENT",
  tech = "TECH"
}
