import { registerLocaleData } from "@angular/common"
import localeDe from "@angular/common/locales/de"
import localeEs from "@angular/common/locales/es"
import localeFr from "@angular/common/locales/fr"
import localeIt from "@angular/common/locales/it"
import localeKo from "@angular/common/locales/ko"
import localeNl from "@angular/common/locales/nl"
import localeRu from "@angular/common/locales/ru"
import localeZhHans from "@angular/common/locales/zh-Hans"
import { TranslateService } from "@ngx-translate/core"
import { defineLocale } from "ngx-bootstrap/chronos"
import {
  deLocale,
  esLocale,
  frLocale,
  itLocale,
  koLocale,
  nlLocale,
  ruLocale,
  zhCnLocale
} from "ngx-bootstrap/locale"
import { AuthenticationService } from "./core/authentication.service"
import { PermissionService } from "./core/permission.service"

registerLocaleData(localeDe, "de-DE")
registerLocaleData(localeEs, "es-ES")
registerLocaleData(localeFr, "fr-FR")
registerLocaleData(localeRu, "ru-RU")
registerLocaleData(localeZhHans, "zh-CN")
registerLocaleData(localeIt, "it-IT")
registerLocaleData(localeNl, "nl-NL")
registerLocaleData(localeKo, "ko")
defineLocale("de", deLocale)
defineLocale("es", esLocale)
defineLocale("fr", frLocale)
defineLocale("ru", ruLocale)
defineLocale("zh-cn", zhCnLocale)
defineLocale("it", itLocale)
defineLocale("nl", nlLocale)
defineLocale("ko", koLocale)

function getUserLanguage(as: AuthenticationService) {
  const user = as.getUser()
  if (user && user.attributes && user.attributes.language) {
    return user.attributes.language.replace("_", "-")
  }
  return "en-US"
}

export function localeFactory(as: AuthenticationService): string {
  return getUserLanguage(as)
}

function translateInit(
  ts: TranslateService,
  as: AuthenticationService
): Promise<void> {
  const lang = getUserLanguage(as)

  const enPromise = import("../i18n/en-US.json").then(res => {
    ts.setTranslation("en-US", res)
    ts.setDefaultLang("en-US")
  })

  if (lang === "en-US") {
    return enPromise.then(() => {
      ts.use("en-US")
    })
  } else {
    return enPromise.then(() =>
      import(`../i18n/${lang}.json`).then(
        res => {
          ts.setTranslation(lang, res)
          ts.use(lang)
        },
        () => {
          ts.use("en-US")
        }
      )
    )
  }
}

export function appInit(
  ts: TranslateService,
  permissions: PermissionService,
  authService: AuthenticationService
): () => Promise<any> {
  return () => {
    if (authService.isAuthenticated()) {
      permissions.evaluate()
    }
    return authService
      .authServiceInit()
      .then(() => translateInit(ts, authService))
  }
}
