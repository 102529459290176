import { EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { BsModalRef } from "ngx-bootstrap/modal"
import { StageField, StageType, UnitType } from "src/app/api"
import {
  AuthenticationService,
  UserTempUnit
} from "src/app/core/authentication.service"
import { checkFormValidity } from "src/app/core/utils"
import { getRangeValueByTemperatureType, INumberRanges } from "./form-groups"

export class GenericEditStepModalComponent implements OnInit {
  @Input() formGroup: FormGroup
  @Input() isEditing: boolean
  @Input() unitType: UnitType
  @Output() save = new EventEmitter<FormGroup>()

  isDisabled = false

  userTempUnit: UserTempUnit
  stageNRanges: Record<StageField, INumberRanges>
  stageType: StageType = undefined as any // `as any` fixes typing error. this gets set by each implementor's ngOnInit.

  constructor(
    private modalRef: BsModalRef,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.userTempUnit = this.authService.getUserTemp()
    this.stageNRanges = getRangeValueByTemperatureType(
      this.unitType,
      this.stageType,
      this.userTempUnit
    )
  }

  getValueOfControl = (controlName: StageField): number => {
    let value: number = this.stageNRanges[controlName].default
    const control: FormControl = this.formGroup.controls[
      controlName
    ] as FormControl
    if (control !== undefined && control.value !== undefined) {
      value = +control.value
    }
    return value
  };

  submit() {
    if (!this.isDisabled) {
      this.isDisabled = true
      if (!checkFormValidity(this.formGroup)) {
        this.isDisabled = false
        return
      }
      this.save.emit(this.formGroup)
      this.modalRef.hide()
    }
  }

  cancel() {
    this.modalRef.hide()
  }
}
