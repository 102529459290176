import { Component, OnInit } from "@angular/core"
import { BsModalRef } from "ngx-bootstrap/modal"
import { StageType } from "src/app/api"
import { AuthenticationService } from "src/app/core/authentication.service"
import { GenericEditStepModalComponent } from "../generic-edit-step-modal-component"

@Component({
  selector: "app-edit-preheat-step",
  templateUrl: "./edit-preheat-step.component.html",
  styleUrls: ["./edit-preheat-step.component.scss"]
})
export class EditPreheatStepComponent extends GenericEditStepModalComponent
  implements OnInit {
  constructor(modalRef: BsModalRef, authService: AuthenticationService) {
    super(modalRef, authService)
  }

  ngOnInit() {
    this.stageType = StageType.preheat
    super.ngOnInit()
  }
}
