/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ResolutionType = 'minute' | 'hour' | 'day' | 'month';

export const ResolutionType = {
  Minute: 'minute' as ResolutionType,
  Hour: 'hour' as ResolutionType,
  Day: 'day' as ResolutionType,
  Month: 'month' as ResolutionType
};
