import { Pipe, PipeTransform } from '@angular/core';
import { Group, UserBase } from '../api';

@Pipe({
  name: 'adminsList'
})
export class AdminsListPipe implements PipeTransform {
  transform(value: UserBase[], args?: any): any {
    return value.length > 0
      ? value
          .map((user: UserBase) => user.firstName + ' ' + user.lastName)
          .join(', ')
      : 'N/A';
  }
}
