import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { EChartOption } from "echarts"
import { Observable } from "rxjs"
import { ChartConfiguration, ResolutionType } from "src/app/api"
import {
  ChartData,
  chartDataToDataset,
  createYAxisFromChartConfiguration,
  getDefaultChartOptions,
  getSeriesName,
  getYAxisIndexByVariableType,
  VariablesMap,
  YAxisDefinition
} from "src/app/core/chart-utils"
import { TemperaturePipe } from "../temperature/temperature.pipe"

@Component({
  selector: "app-generic-chart",
  templateUrl: "./generic-chart.component.html",
  styleUrls: ["./generic-chart.component.scss"],
  providers: [TemperaturePipe]
})
export class GenericChartComponent implements OnInit, OnChanges {
  @Input() chartType: "line" | "bar"

  @Input() configuration: ChartConfiguration

  @Input() resolution: ResolutionType

  @Input() chartData$: Observable<ChartData | undefined>

  @Input() dataZoom: boolean | undefined

  @Input() height: string | undefined

  @Input()
  set configurationIndex(val: number) {
    this._configurationIndex = val
    if (this.options) {
      this.setupChartConfiguration()
    }
  }

  get configurationIndex(): number {
    return this._configurationIndex
  }

  _configurationIndex = 0

  options: EChartOption
  mergeOptions: EChartOption

  yAxisDefinitions: Record<string, YAxisDefinition>

  constructor(
    private ts: TranslateService,
    private temperaturePipe: TemperaturePipe,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    this.yAxisDefinitions = {
      temperature: {
        name: this.ts.instant("UNIT.TEMPERATURE"),
        unit: this.temperaturePipe.tempUnit
      },
      percentage: {
        name: this.ts.instant("UNIT.PERCENTAGE"),
        unit: "%",
        max: 100,
        min: 0
      },
      RPM: {
        name: this.ts.instant("UNIT.RPM"),
        unit: "RPM",
        max: undefined
      },
      dB: {
        name: this.ts.instant("UNIT.DECIBEL"),
        unit: "dB",
        min: -120,
        max: 0
      }
    }
  }

  ngOnInit() {
    this.setUpChart()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["configuration"] &&
      changes["configuration"].currentValue !==
        changes["configuration"].previousValue
    ) {
      this.setUpChart()
    }
  }

  setUpChart() {
    this.setupChartConfiguration()

    this.chartData$.subscribe(data => {
      const source = data
        ? chartDataToDataset(
            data,
            this.configuration,
            this.configurationIndex,
            this.localeId,
            this.temperaturePipe.tempUnit,
            this.resolution
          )
        : []

      this.mergeOptions = {
        dataset: { source }
      }
    })
  }

  setupChartConfiguration() {
    const variableTypes = this.configuration.variableTypes as VariablesMap
    const yAxes = createYAxisFromChartConfiguration(
      this.configuration,
      this.yAxisDefinitions
    )

    const legendData: EChartOption.Legend.LegendDataObject[] = this.configuration.graph.map(
      item => {
        item = getSeriesName(
          item,
          variableTypes[item],
          this.temperaturePipe.tempUnit
        )
        return { name: item, icon: "rect" }
      }
    )

    // let seriesIndex = 0
    const series: EChartOption.Series[] = this.configuration.graph.map(item => {
      const yAxisIndex = getYAxisIndexByVariableType(
        variableTypes[item],
        yAxes
      )

      // if (
      //   this.configuration.colors &&
      //   seriesIndex < this.configuration.colors.length
      // ) {
      //   color = this.configuration.colors[seriesIndex]
      // }
      const res: EChartOption.Series = {
        ...this.getSeriesDefinition(),
        yAxisIndex,
        showSymbol: false,
        seriesLayoutBy: "row",
        animationEasing: "circularInOut"
        // itemStyle: {
        //   color
        // }
      }

      // seriesIndex++
      return res
    })

    const yAxesIndexes = yAxes.map((_, index) => index)

    this.options = {
      ...getDefaultChartOptions(),

      dataZoom:
        this.dataZoom !== undefined && this.dataZoom === false
          ? undefined
          : [
              {
                id: "dataZoomX",
                type: "slider",
                xAxisIndex: [0],
                filterMode: "empty"
              },
              {
                id: "dataZoomY",
                type: "slider",
                yAxisIndex: yAxesIndexes,
                filterMode: this.chartType === "line" ? "none" : "empty",
                // cast for fixing wrong type
                showDataShadow: (false as unknown) as string,
                showDetail: yAxesIndexes.length === 1
              }
            ],
      legend: {
        data: legendData,
        itemGap: 40,
        type: "scroll"
      },
      series,
      yAxis: yAxes.map(a => a.yAxis)
    }
  }

  private getSeriesDefinition(): EChartOption.Series {
    switch (this.chartType) {
      case "line": {
        return {
          type: "line"
        }
      }
      case "bar": {
        return {
          type: "bar"
        }
      }
    }
  }
}
