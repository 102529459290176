/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShelfTimer } from "src/app/shared/edit-recipe/recipe-steps-editor/multi-shelf-timer-modal/multi-shelf-timer-modal.component";
import { ImageBase64 } from "./imageBase64";
import { UnitType } from "./unitType";

export interface RecipeBody {
  libraryId: string;
  unitType: UnitType;
  coverImageId?: RecipeImage;
  /**
   * The json body of the recipe
   */
  content: RecipeContent;
}

export interface RecipeContent {
  image?: Array<RecipeImage>;
  sound?: string;
  name?: string;
  guid?: string;
  description?: string;
  menu?: Array<string>;
  runs?: number;
  steps?: Array<RecipeStep>;
  dID?: number;
  group?: Array<RecipeGroup>;
}

export interface RecipeStep {
  name: string;
  step: number;
  visual?: string | RecipeImage;
  mode: RecipeStepMode;
  description?: string;
  smoker?: boolean;
  latch?: boolean;
  probeSetpoint?: number;
  timeSetpoint?: number;
  cavitySetpoint?: number;
  fanSpeed?: number;
  humidity?: number;
  preheat?: number;
  powerLevel?: number;
  greaseCollection?: boolean;
  multiShelfTimer?: ShelfTimer[];
}

// interface that is multiplexed to represent both images that are retrieved
// from the cloud, and images that are pending upload to the cloud
// there are likely better ways to do this
export interface RecipeImage extends ImageBase64 {
  id?: string;
  isCover?: boolean; // whether this is the cover image for the recipe
  isPendingUpload?: boolean; // flag for whether it's downloaded or pending an upload
  presignedUrl?: string; // only present on downloaded versions
  sortOrder?: number; // what order to display the gallery images
}
export interface CompanyImage extends ImageBase64 {
  id?: string;
  isPendingUpload?: boolean; // flag for whether it's downloaded or pending an upload
  presignedUrl?: string; // only present on downloaded versions
  sortOrder?: number; // what order to display the gallery images
}

export interface RecipeGroup {
  name: string;
  position: number;
}

export interface RecipeStepMode {
  primary: ePrimaryState;
  secondary: eSecondaryState;
  tertiary: eTertiaryState;
}

export enum ePrimaryState {
  inactive = 0,
  prepare = 1,
  use = 2
}

export enum eSecondaryState {
  off = 0,
  error = 1,
  initialize = 2,
  regulate = 3,
  cooldown = 4,
  action = 5,
  ready = 6,
  enthalpic = 7,
  maintenance = 8,
  cleaning = 9
}

export enum eTertiaryState {
  mode_off = 0,
  haloheat = 1,
  convection = 2,
  steam = 3,
  retherm = 4,
  combi = 5,
  hold = 6,
  chill = 7,
  clean = 8,
  drip = 9,
  defrost = 10,
  preheat = 11,
  wait = 12,
  pause = 13,
  complete = 14,
  standby = 15,
  shutdown = 16
}
