import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageUnit'
})
export class PercentageUnitPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value + ' %';
  }
}
