import { ElementRef } from "@angular/core"
import { fromEvent } from "rxjs"
import { debounceTime, take, finalize, timeout } from "rxjs/operators"
import { _ } from "../core/translation-marker";

export default class FormUtils {
    static scrollToFirstInvalidControl(el: ElementRef) {
        const firstInvalidControl: HTMLElement = el.nativeElement.querySelector(
            "form .form-group .ng-invalid"
        );

        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: "smooth"
        })

        fromEvent(window, "scroll" )
            .pipe(
                debounceTime(100),
                take(1),
                timeout(500),
                finalize(() => firstInvalidControl.focus())
            )
            .subscribe(
                () => firstInvalidControl.focus(),
                _ => _, //do nothing if we timeout/error
            )
            
    }
    
    private static getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 116 //Header bar + label size
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset
    }
}