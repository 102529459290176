import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { TableColumn } from "@swimlane/ngx-datatable"
import { FiltrableDataSource } from "filtrable-data-source"
import { Location } from "src/app/api"
import { PermissionService } from "src/app/core/permission.service"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"
import { ShareAccessService } from "src/app/core/share-access.service"
import { getColorHexByLocation } from "../../core/utils"

@Component({
  selector: "app-admin-locations-table",
  templateUrl: "./admin-locations-table.component.html",
  styleUrls: ["./admin-locations-table.component.scss"]
})
export class AdminLocationsTableComponent implements OnInit {
  @Input() dataSource: FiltrableDataSource<any>
  @Input() selectionType?: string
  @Input() showAssignToGroup = false
  @Input() showRemoveFromGroup = false
  @Input() selectionHandler: SelectedItemsHandler<Location>
  @Input() inAdminArea: boolean

  @Output() assignToGroup = new EventEmitter<Location>()
  @Output() removeFromGroup = new EventEmitter<string>()
  @Output() deleteLocation = new EventEmitter<Location>()

  @ViewChild("locationNameTpl", { static: true }) locationNameTpl: TemplateRef<
    any
  >
  @ViewChild("actionMenuTpl", { static: true }) actionMenuTpl: TemplateRef<any>
  @ViewChild("locationStatusTpl", { static: true })
  locationStatusTpl: TemplateRef<any>

  columns: TableColumn[] = []
  getColorHex = getColorHexByLocation
  locationBasePath: string[] = []

  constructor(
    private ts: TranslateService,
    public auth: PermissionService,
    public shareAccessService: ShareAccessService
  ) {}

  async ngOnInit() {
    const locationNameString = await this.ts.get("LOCATION.NAME").toPromise()
    const locationStatusString = await this.ts
      .get("LOCATIONS.STATUS")
      .toPromise()
    const totalUnitsString = await this.ts
      .get("LOCATION.TOTAL_UNITS")
      .toPromise()
    
      // This datatable does not use ngx datatable to sort, but instead the filterable data table. This 
      // intercepts the event caught in FilterableDataSource in the onTableSort function and sorts it using
      // custom criteria. The else is simply there to duplicate the original functionality found in onTableSort
      this.dataSource.onTableSort = (event: any) => {
      if(event.sorts[0].prop === "locationName") {

        if(event.newValue === "asc") {
          this.dataSource.currentItems = this.dataSource.currentItems.sort(this.caseInsensitiveCompare)
        } else {
          this.dataSource.currentItems = this.dataSource.currentItems.sort(this.caseInsensitiveCompare).reverse()
        }
      } else {
        this.dataSource.setSort(event.sorts[0]);
      }
    }
    this.columns = [
      {
        prop: "locationName",
        name: locationNameString,
        cellTemplate: this.locationNameTpl,
        minWidth: 250
      },
      {
        prop: "totalUnits",
        name: totalUnitsString,
        minWidth: 100
      }
    ]
    if (this.inAdminArea) {
      this.locationBasePath = ["/", "admin", "locations"]
    } else {
      this.columns.push({
        cellTemplate: this.locationStatusTpl,
        sortable: false,
        name: locationStatusString,
        minWidth: 150
      })
    }
    this.columns.push({
      cellTemplate: this.actionMenuTpl,
      sortable: false,
      minWidth: 60,
      maxWidth: 60
    })
  }

  getEditLocationLinkSegments(locationId: string) {
    return [...this.locationBasePath].concat(locationId, "edit")
  }

  caseInsensitiveCompare(
    valueA: any,
    valueB: any
  ) {
    // Use toUpperCase() to ignore character casing
    const locA = valueA.locationName.toUpperCase();
    const locB = valueB.locationName.toUpperCase();
  
    let comparison = 0;
    if (locA > locB) {
      comparison = 1;
    } else if (locA < locB) {
      comparison = -1;
    }
    return comparison;
  }
}
