import { Component, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { saveAs } from "file-saver"
import { ODataFiltrableDataSource } from "filtrable-data-source"
import { BsModalService } from "ngx-bootstrap/modal"
import { debounceTime } from "rxjs/operators"
import { CookbookRecipe, RecipeBase, UnitsService } from "src/app/api"
import { PermissionService } from "src/app/core/permission.service"
import { getDateTime } from "src/app/core/utils"
import { Recipe } from "src/app/models/Recipe"
import { DialogModalService } from "../../core/dialog-modal.service"
import { IdAndLabel } from "../../core/idAndLabel"
import { SelectedItemsHandler } from "../../core/selected-items-handler"
import { _ } from "../../core/translation-marker"
import { CategoryAssignComponent } from "../../shared/category-assign/category-assign.component"
import {
  DuplicateRecipeModalComponent,
  RecipeDuplicateResult
} from "../../shared/duplicate-recipe-modal/duplicate-recipe-modal.component"
import { DeleteUnitRecipeService } from "../delete-unit-recipe.service"

@Component({
  selector: "app-unit-cookbook",
  templateUrl: "./unit-cookbook.component.html",
  styleUrls: ["./unit-cookbook.component.scss"]
})
export class UnitCookbookComponent implements OnInit {
  unitId: string
  dataSource = new ODataFiltrableDataSource<CookbookRecipe>()
  selectedCategories: IdAndLabel[] = []
  nameSearchFc = new FormControl(undefined)
  unitType: string

  // what is that?
  selectedHandler = new SelectedItemsHandler<RecipeBase>("recipeId")
  showAll = true
  readonly recipesPerPage = 12

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private unitsService: UnitsService,
    private modalService: BsModalService,
    private deleteService: DeleteUnitRecipeService,
    private dialogService: DialogModalService,
    private ts: TranslateService,
    public auth: PermissionService
  ) {}

  ngOnInit() {
    this.unitId = this.activatedRoute.parent!.snapshot.data.unit.unitId

    this.dataSource.limit = 12

    this.unitsService.getUnit(this.unitId).subscribe(unit => {
      this.unitType = unit.unitType
    })

    this.dataSource.fetchFunction = (top, skip, filter, orderby, count) => {
      let selectedCategoriesNames
      if (this.selectedCategories.length > 0) {
        selectedCategoriesNames = this.selectedCategories.map(c => c.label)
      }

      let nextTop
      let nextSkip
      if (!this.showAll) {
        // Load only the current page of recipes
        nextTop = top
        nextSkip = skip
      }

      return this.unitsService.getUnitCookbook(
        this.unitId,
        nextTop,
        nextSkip,
        count,
        this.nameSearchFc.value || undefined,
        selectedCategoriesNames
      )
    }
    this.dataSource.loadData()

    this.nameSearchFc.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.dataSource.loadData()
    })
  }

  onRecipeClicked(recipe: RecipeBase) {
    this.router.navigate(["../recipes", recipe.recipeId], {
      relativeTo: this.activatedRoute
    })
  }

  openCategoriesModal() {
    // type any because of ts bug
    // https://github.com/Microsoft/TypeScript/issues/28582
    const func: any = this.unitsService.getUnitCookbookCategories.bind(
      this.unitsService
    )

    const initialState: Partial<CategoryAssignComponent> = {
      id: this.unitId,
      selectedCategories: this.selectedCategories,
      queryFunction: func
    }
    const modalRef = this.modalService.show(CategoryAssignComponent, {
      initialState
    });
    (modalRef.content as CategoryAssignComponent).result.subscribe(
      (res: IdAndLabel[]) => {
        this.selectedCategories = this.selectedCategories.concat(res)
        this.dataSource.loadData()
      }
    )
  }

  onCategoriesRemoved(categories: IdAndLabel[]) {
    this.selectedCategories = categories
    this.dataSource.loadData()
  }

  deleteRecipes(recipes: RecipeBase[]) {
    this.deleteService
      .deleteRecipes(
        this.activatedRoute.parent!.snapshot.data.unit.connected,
        this.unitId,
        recipes.map(r => r.recipeId)
      )
      .subscribe(() => {
        this.selectedHandler.items = []
        this.dataSource.loadData()
      })
  }

  exportRecipe(recipe: RecipeBase) {
    this.unitsService
      .exportCookbookRecipes(this.unitId, [recipe.recipeId])
      .subscribe((res: Blob) => {
        saveAs(res, recipe.recipeName + ".tar.gz")
      })
  }

  exportRecipes() {
    const ids = this.selectedHandler.items.map(x => x.recipeId)
    this.unitsService
      .exportCookbookRecipes(this.unitId, ids)
      .subscribe((res: Blob) => {
        saveAs(res, "Recipes-" + getDateTime() + ".tar.gz")
      })
  }

  exportRecipesCTP() {
    const ids = this.selectedHandler.items.map(x => x.recipeId)
    this.unitsService
      .exportCookbookRecipesCTP(this.unitId, ids)
      .subscribe((res: Blob) => {
        saveAs(res, "recipe.zip")
      })
  }

  copyCookbookRecipesToCompanyLibrary() {
    const ids = this.selectedHandler.items.map(x => x.recipeId)
    this.unitsService
      .copyCookbookRecipesToCompanyLibrary(this.unitId, ids)
      .subscribe((recipes: Recipe[]) => {
        this.router.navigate([
          "/recipeLibrary",
          "libraries",
          `${recipes[0].libraryId}`
        ])
      })
  }

  duplicateRecipe(id: string) {
    const initialState: Partial<DuplicateRecipeModalComponent> = {
      recipeId: id,
      duplicateFunc: this.unitsService.duplicateCookbookRecipe.bind(
        this.unitsService,
        this.unitId,
        id
      )
    }
    const modalRef = this.modalService.show(DuplicateRecipeModalComponent, {
      initialState
    })
    const component = modalRef.content as DuplicateRecipeModalComponent
    component.recipeDuplicated.subscribe((result: RecipeDuplicateResult) => {
      if (result.outcome === "goToEdit") {
        this.router.navigate([
          "/units",
          this.unitId,
          "recipes",
          result.newId,
          "edit"
        ])
      } else {
        const msg = this.activatedRoute.parent!.snapshot.data.unit.connected
          ? _("RECIPE.DUPLICATE_SUCCESS")
          : _("RECIPE.DUPLICATE_OFFLINE")
        this.dialogService
          .success({
            icon: "recipe-white.svg",
            message: this.ts.instant(msg),
            detail: this.ts.instant("GLOBAL.OK_CLICK"),
            acceptButtonLabel: this.ts.instant("GLOBAL.CONTINUE")
          })
          .close$.subscribe(() => {
            this.dataSource.loadData()
          })
      }
    })
  }

  showAllChanged(newValue: boolean) {
    if (newValue !== undefined) {
      this.showAll = newValue
      if (!newValue) {
        this.dataSource.limit = this.recipesPerPage
      }
      this.dataSource.applyFilters()
    }
  }
}
