import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";

import { Observable } from "rxjs";

import { UserDetail } from "../model/userDetail";
import { UserList } from "../model/userList";

import { BASE_PATH } from "../variables";
import { Configuration } from "../configuration";
import { User } from "../model/user/user";
import { User as UserBase } from "../model/user";

@Injectable({
  providedIn: "root"
})
export class UsersService {
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Creates a user
   * This API can be called by an admin user to create users at his same level or levels below him
   * @param UserDetail
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createUser(
    UserDetail?: UserDetail,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public createUser(
    UserDetail?: UserDetail,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public createUser(
    UserDetail?: UserDetail,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public createUser(
    UserDetail?: UserDetail,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.configuration.basePath}/users`,
      UserDetail,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delets a user
   *
   * @param userId Id of the user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteUser(
    userId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteUser(
    userId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteUser(
    userId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteUser(
    userId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error(
        "Required parameter userId was null or undefined when calling deleteUser."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/users/${encodeURIComponent(
        String(userId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit a user
   *
   * @param userId Id of the user
   * @param UserDetail
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editUser(
    userId: string,
    UserDetail?: UserDetail,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public editUser(
    userId: string,
    UserDetail?: UserDetail,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public editUser(
    userId: string,
    UserDetail?: UserDetail,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public editUser(
    userId: string,
    UserDetail?: UserDetail,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error(
        "Required parameter userId was null or undefined when calling editUser."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<string>(
      `${this.configuration.basePath}/users/${encodeURIComponent(
        String(userId)
      )}`,
      UserDetail,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get user detail
   *
   * @param userId Id of the user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUser(
    userId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UserDetail>;
  public getUser(
    userId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UserDetail>>;
  public getUser(
    userId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UserDetail>>;
  public getUser(
    userId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error(
        "Required parameter userId was null or undefined when calling getUser."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UserDetail>(
      `${this.configuration.basePath}/users/${encodeURIComponent(
        String(userId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getCurrentUser(): Observable<User> {
    return this.httpClient.get<User>("/users/current");
  }

  public transferOwnership(
    owner: UserBase,
    newOwner: UserBase
  ): Observable<boolean> {
    return this.httpClient.post<boolean>("/users/transfer-ownership", {
      oldOwner: owner,
      newOwner: newOwner
    });
  }

  /**
   * Users list
   * Returns all the users visible to the user the makes the request (e.g. all company users for AccountAdmin, all group users for GroupAdmin, etc.)
   */
  public getUserList(): Observable<UserList> {
    return this.httpClient.get<UserList>("/users");
  }

  public getOutsideUsers(): Observable<Array<User>> {
    return this.httpClient.get<Array<User>>("/users/outsideUsers");
  }

  public addOutsideUser(user: UserDetail): Observable<User> {
    return this.httpClient.post<User>("/users/outsideUsers", user);
  }

  public deleteOutsideUser(userId: String): Observable<any> {
    return this.httpClient.delete<any>(`/users/outsideUsers/${userId}`);
  }
}
