/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { ChangeRecipeVisibilityBody } from "../model/changeRecipeVisibilityBody";
import { ImageBase64 } from "../model/imageBase64";
import { RecipeBody } from "../model/recipeBody";
import { RecipeLibrary } from "../model/recipeLibrary";
import { RecipeList } from "../model/recipeList";
import { RecipeSyncStatus } from "../model/recipeSyncStatus";
import { RecipeSyncStatusList } from "../model/recipeSyncStatusList";
import { RecipesAndUnitsList } from "../model/recipesAndUnitsList";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";
import { IRecipeEditService } from "../model/recipeEditService";

@Injectable({
  providedIn: "root"
})
export class RecipeService implements IRecipeEditService {
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Assign recipes to cookbooks
   *
   * @param RecipesAndUnitsList
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignRecipesToCookbooks(
    RecipesAndUnitsList?: RecipesAndUnitsList,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public assignRecipesToCookbooks(
    RecipesAndUnitsList?: RecipesAndUnitsList,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public assignRecipesToCookbooks(
    RecipesAndUnitsList?: RecipesAndUnitsList,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public assignRecipesToCookbooks(
    RecipesAndUnitsList?: RecipesAndUnitsList,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/recipe/assign`,
      RecipesAndUnitsList,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Move recipes from one library to another one
   *
   * @param ChangeRecipeVisibilityBody
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public changeRecipesVisibility(
    ChangeRecipeVisibilityBody?: ChangeRecipeVisibilityBody,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public changeRecipesVisibility(
    ChangeRecipeVisibilityBody?: ChangeRecipeVisibilityBody,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public changeRecipesVisibility(
    ChangeRecipeVisibilityBody?: ChangeRecipeVisibilityBody,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public changeRecipesVisibility(
    ChangeRecipeVisibilityBody?: ChangeRecipeVisibilityBody,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/recipe/libraries/${
        ChangeRecipeVisibilityBody!.libraryId
      }/recipes`,
      ChangeRecipeVisibilityBody!.recipeIds,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Creates an empty recipe and returns its id
   *
   * @param RecipeBody
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createRecipe(
    RecipeBody?: RecipeBody,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public createRecipe(
    RecipeBody?: RecipeBody,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public createRecipe(
    RecipeBody?: RecipeBody,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public createRecipe(
    RecipeBody?: RecipeBody,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.configuration.basePath}/recipe/recipes`,
      RecipeBody,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Removes an image from a recipe
   *
   * @param recipeId Id of the recipe
   * @param imageIds Array of image ids to delete
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteRecipeImage(
    recipeId: string,
    imageIds: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteRecipeImage(
    recipeId: string,
    imageIds: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteRecipeImage(
    recipeId: string,
    imageIds: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteRecipeImage(
    recipeId: string,
    imageId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling deleteRecipeImage."
      );
    }
    if (imageId === null || imageId === undefined) {
      throw new Error(
        "Required parameter imageIds was null or undefined when calling deleteRecipeImage."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/recipe/recipes/${encodeURIComponent(
        String(recipeId)
      )}/image/${encodeURIComponent(String(imageId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deletes some recipes
   *
   * @param recipeIds Ids of recipes to delete
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteRecipes(
    recipeIds: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteRecipes(
    recipeIds: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteRecipes(
    recipeIds: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteRecipes(
    recipeIds: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (recipeIds === null || recipeIds === undefined) {
      throw new Error(
        "Required parameter recipeIds was null or undefined when calling deleteRecipes."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (recipeIds) {
      recipeIds.forEach(element => {
        queryParameters = queryParameters.append("recipeIds", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/recipe/recipes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public duplicateRecipeToGlobalLibrary(
    libraryId: string,
    recipeId: string,
    feature: boolean = false
  ): Observable<void> {
    return this.httpClient.post<void>(
      `/recipe/libraries/${encodeURIComponent(
        libraryId
      )}/recipes/${encodeURIComponent(
        String(recipeId)
      )}/duplicate-to-global?isFeatured=${feature}`,
      {
        responseType: "json"
      }
    );
  }

  /**
   * Duplicate a recipe (creates a new recipe inside the library of the recipe with the same data and another name)
   *
   * @param recipeId Id of the recipe
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public duplicateRecipe(
    libraryId: string,
    recipeId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public duplicateRecipe(
    libraryId: string,
    recipeId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public duplicateRecipe(
    libraryId: string,
    recipeId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public duplicateRecipe(
    libraryId: string,
    recipeId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (recipeId === null || recipeId === undefined || libraryId == undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling duplicateRecipe."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/recipe/libraries/${encodeURIComponent(
        libraryId
      )}/recipes/${encodeURIComponent(String(recipeId))}/duplicate`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit a recipe
   *
   * @param recipeId Id of the recipe
   * @param RecipeBody
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editRecipe(
    recipeId: string,
    RecipeBody?: RecipeBody,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editRecipe(
    recipeId: string,
    RecipeBody?: RecipeBody,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editRecipe(
    recipeId: string,
    RecipeBody?: RecipeBody,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editRecipe(
    recipeId: string,
    RecipeBody?: RecipeBody,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling editRecipe."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/recipe/recipes/${encodeURIComponent(
        String(recipeId)
      )}`,
      RecipeBody,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Export some recipes from a library into a ZIP file
   *
   * @param request_body Array of recipe IDs
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportLibraryRecipes(
    request_body?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Blob>;
  public exportLibraryRecipes(
    request_body?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public exportLibraryRecipes(
    request_body?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public exportLibraryRecipes(
    request_body?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put(
      `${this.configuration.basePath}/recipe/recipes/export`,
      request_body,
      {
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
  /**
   * Export some recipes from a library, convert them into CTP format, and save them into a ZIP file
   *
   * @param request_body Array of recipe IDs
   */
  public exportLibraryRecipesCTP(
    request_body?: Array<string>
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put(
      `${this.configuration.basePath}/recipe/recipes/export/ctp`,
      request_body,
      {
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }

  /**
   * Get the content of a recipe
   *
   * @param recipeId Id of the recipe
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipe(
    recipeId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<RecipeBody>;
  public getRecipe(
    recipeId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<RecipeBody>>;
  public getRecipe(
    recipeId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<RecipeBody>>;
  public getRecipe(
    recipeId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling getRecipe."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RecipeBody>(
      `${this.configuration.basePath}/recipe/recipes/${encodeURIComponent(
        String(recipeId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get List of available categories
   * The available categories are extracted from the recipes
   * @param libraryId Id of the library
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipeCategories(
    libraryId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<string>>;
  public getRecipeCategories(
    libraryId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<string>>>;
  public getRecipeCategories(
    libraryId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<string>>>;
  public getRecipeCategories(
    libraryId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (libraryId === null || libraryId === undefined) {
      throw new Error(
        "Required parameter libraryId was null or undefined when calling getRecipeCategories."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (libraryId !== undefined && libraryId !== null) {
      queryParameters = queryParameters.set("libraryId", <any>libraryId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<string>>(
      `${this.configuration.basePath}/recipe/categories`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Returns the base URL for getting recipes images
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipeImagesBaseUrl(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public getRecipeImagesBaseUrl(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getRecipeImagesBaseUrl(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getRecipeImagesBaseUrl(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["text/plain"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<string>(
      `${this.configuration.basePath}/recipe/recipes/imageBaseurl`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get List of available Recipe Libraries for the user
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipeLibraries(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<RecipeLibrary>>;
  public getRecipeLibraries(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<RecipeLibrary>>>;
  public getRecipeLibraries(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<RecipeLibrary>>>;
  public getRecipeLibraries(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<RecipeLibrary>>(
      `${this.configuration.basePath}/recipe/libraries`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get a status for a Recipe Library
   *
   * @param libraryId Id of the library
   * @param statusId Id of the status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipeLibraryStatus(
    libraryId: string,
    statusId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<RecipeSyncStatus>;
  public getRecipeLibraryStatus(
    libraryId: string,
    statusId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<RecipeSyncStatus>>;
  public getRecipeLibraryStatus(
    libraryId: string,
    statusId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<RecipeSyncStatus>>;
  public getRecipeLibraryStatus(
    libraryId: string,
    statusId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (libraryId === null || libraryId === undefined) {
      throw new Error(
        "Required parameter libraryId was null or undefined when calling getRecipeLibraryStatus."
      );
    }
    if (statusId === null || statusId === undefined) {
      throw new Error(
        "Required parameter statusId was null or undefined when calling getRecipeLibraryStatus."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RecipeSyncStatus>(
      `${this.configuration.basePath}/recipe/libraries/${encodeURIComponent(
        String(libraryId)
      )}/status/${encodeURIComponent(String(statusId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of status for a Recipe Library
   * Get the list of sync status of a recipe library. The list is filtrable by actionType, statusId
   * @param libraryId Id of the library
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $filter Filter items by property values, see [OData Filtering](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionfilter)
   * @param $orderby Order items by property values, see [OData Sorting](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionorderby)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipeLibraryStatusList(
    libraryId: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<RecipeSyncStatusList>;
  public getRecipeLibraryStatusList(
    libraryId: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<RecipeSyncStatusList>>;
  public getRecipeLibraryStatusList(
    libraryId: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<RecipeSyncStatusList>>;
  public getRecipeLibraryStatusList(
    libraryId: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (libraryId === null || libraryId === undefined) {
      throw new Error(
        "Required parameter libraryId was null or undefined when calling getRecipeLibraryStatusList."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = queryParameters.set("$filter", <any>$filter);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append("$orderby", <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RecipeSyncStatusList>(
      `${this.configuration.basePath}/recipe/libraries/${encodeURIComponent(
        String(libraryId)
      )}/status`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get List of recipes
   * This API is paginated and filtrable with OData. Possibile filters are: libraryId, unit type, recipe name, recipe tags
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $filter Filter items by property values, see [OData Filtering](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionfilter)
   * @param $orderby Order items by property values, see [OData Sorting](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionorderby)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param recipeName Used to filter the list based on the recipe name
   * @param categories Used to filter the list based on categories
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipes(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    recipeName?: string,
    categories?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<RecipeList>;
  public getRecipes(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    recipeName?: string,
    categories?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<RecipeList>>;
  public getRecipes(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    recipeName?: string,
    categories?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<RecipeList>>;
  public getRecipes(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    recipeName?: string,
    categories?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = queryParameters.set("$filter", <any>$filter);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append("$orderby", <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }
    if (recipeName !== undefined && recipeName !== null) {
      queryParameters = queryParameters.set("recipeName", <any>recipeName);
    }
    if (categories) {
      categories.forEach(element => {
        queryParameters = queryParameters.append("categories", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RecipeList>(
      `${this.configuration.basePath}/recipe/recipes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Imports a recipe in the library from a recipe file
   *
   * @param libraryId Id of the library
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public importRecipe(
    libraryId: string,
    body?: Blob,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public importRecipe(
    libraryId: string,
    body?: Blob,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public importRecipe(
    libraryId: string,
    body?: Blob,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public importRecipe(
    libraryId: string,
    body?: Blob,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (libraryId === null || libraryId === undefined) {
      throw new Error(
        "Required parameter libraryId was null or undefined when calling importRecipe."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/octet-stream"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/recipe/libraries/${encodeURIComponent(
        String(libraryId)
      )}/import`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public importCTPRecipe(
    libraryId: string,
    recipe: Blob,
    images: Blob[] | undefined
  ): Observable<any> {
    const formData = new FormData();
    formData.append("recipe", recipe);
    images && images.forEach(image => formData.append("images", image));

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/recipe/libraries/${encodeURIComponent(
        String(libraryId)
      )}/import/ctp`,
      formData,
      {
        withCredentials: this.configuration.withCredentials
      }
    );
  }

  /**
   * Remove recipes from cookbooks
   *
   * @param RecipesAndUnitsList
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeRecipesFromCookbooks(
    RecipesAndUnitsList?: RecipesAndUnitsList,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public removeRecipesFromCookbooks(
    RecipesAndUnitsList?: RecipesAndUnitsList,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public removeRecipesFromCookbooks(
    RecipesAndUnitsList?: RecipesAndUnitsList,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public removeRecipesFromCookbooks(
    RecipesAndUnitsList?: RecipesAndUnitsList,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/recipe/remove`,
      RecipesAndUnitsList,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Creates a new image for a recipe
   *
   * @param recipeId Id of the recipe
   * @param ImageBase64
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public uploadRecipeImage(
    recipeId: string,
    ImageBase64?: ImageBase64,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public uploadRecipeImage(
    recipeId: string,
    ImageBase64?: ImageBase64,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public uploadRecipeImage(
    recipeId: string,
    ImageBase64?: ImageBase64,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public uploadRecipeImage(
    recipeId: string,
    ImageBase64?: ImageBase64,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling uploadRecipeImage."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/recipe/recipes/${encodeURIComponent(
        String(recipeId)
      )}/image`,
      ImageBase64,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
