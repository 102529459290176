import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { JwtHelperService } from "@auth0/angular-jwt"
import { TranslateService } from "@ngx-translate/core"
import { BsLocaleService } from "ngx-bootstrap/datepicker"
import { setTheme } from "ngx-bootstrap/utils"
import { ToastrService } from "ngx-toastr"
import { interval, Subscription } from "rxjs"
import { AppMainService } from "./app.main.service"
import { AuthenticationService } from "./core/authentication.service"
import { getTimeRemainingOnJwtToken } from "./core/utils"

const localeIdBootstrapLangMap: Record<string, string> = {
  "en-US": "en",
  "de-DE": "de",
  "es-ES": "es",
  "fr-FR": "fr",
  "ru-RU": "ru",
  "zh-CN": "zh-cn",
  "it-IT": "it",
  "nl-NL": "nl",
  ko: "ko"
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "altoshaam-dashboard"
  codesToBypass = [218]
  heartBeatSubscription: Subscription

  constructor(
    private router: Router,
    private mainService: AppMainService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private bsLocale: BsLocaleService,
    private authService: AuthenticationService,
    private jwtService: JwtHelperService,
    @Inject(LOCALE_ID) private localeId: string
  ) {}

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return
      }
      window.scrollTo(0, 0)
    })

    setTheme("bs4")

    // This is the global error handler
    this.mainService.subscribe((error: any) => {
      if (this.codesToBypass.indexOf(error.status) === -1) {
        if (error.status === 500) {
          // For 500 errors display a default error message
          this.translateService
            .get("ERRORS.SERVER_ERROR_TOAST_500")
            .subscribe(message => this.toastService.error(message, "Error"))
        } else if (error.error && error.error.message) {
          // `error.error.message` seems to be only the message the server sent.
          // Where as `error.message` seems to be a combination of the status code,
          // and the message the server sent
          this.toastService.error(error.error.message, "Error")
        } else if (error.message) {
          this.toastService.error(error.message, "Error")
        } else {
          this.translateService
            .get("ERRORS.SERVER_ERROR_TOAST")
            .subscribe(message => this.toastService.error(message, "Error"))
        }
      } else {
        return error
      }
    })

    Object.values(localeIdBootstrapLangMap).includes(this.localeId)
      ? this.bsLocale.use(this.localeId)
      : this.bsLocale.use(localeIdBootstrapLangMap[this.localeId])

    // Heartbeat to run every 30 seconds and check if the user's session is timed out
    const observable = interval(30000)
    this.heartBeatSubscription = observable.subscribe(() => {
      const token = this.authService.getIdToken()
      if (token != null && token.length > 0) {
        const jwtToken = this.jwtService.decodeToken(token)
        const timeRemaining = getTimeRemainingOnJwtToken(jwtToken)
        if (timeRemaining < 0) {
          // Session has ended time the user out
          this.authService.signOutAndNavigate(true)
        } else if (timeRemaining < 120) {
          // TODO Notify the user with 2 minute before expiration
        }
      }
    })
  }
}
