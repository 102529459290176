import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { PermissionService } from "src/app/core/permission.service"
import { GroupBase, LocationDetail } from "../../api"
import { FormWrapperPage } from "../../core/form-wrapper-page"
import {
  GroupConverter,
  IdAndLabel,
  keyValueToIdAndLabelArray
} from "../../core/idAndLabel"
import { _ } from "../../core/translation-marker"
import { checkFormValidity } from "../../core/utils"
import { createAddressFormGroup } from "../address/address.component"
import { htmlValidator } from "../directives/form-validation/html-input.directive"
import { GroupAssignComponent } from "../group-assign/group-assign.component"

@Component({
  selector: "app-location-form",
  templateUrl: "./location-form.component.html",
  styleUrls: ["./location-form.component.scss"]
})
export class LocationFormComponent implements OnInit {
  _location: LocationDetail
  containsHtml = {
    containsHtml: _("VALIDATION.HTML")
  }

  get location(): LocationDetail {
    return this._location
  }

  @Input()
  set location(location: LocationDetail) {
    if (location) {
      this._location = location
      this.updateForm()
    }
  }

  @Input() afterResponseCallback: Function

  @Input() assignToGroup = false

  @Input() groupsFromService: GroupBase[]

  @ViewChild(GroupAssignComponent, { static: true })
  modalGroupAssign: GroupAssignComponent

  locationForm: FormGroup

  groupConverter = GroupConverter.fromList

  groupsSelected: IdAndLabel[] = []

  groupSelected: string

  constructor(public auth: PermissionService) {}

  ngOnInit() {
    this.groupsSelected = []

    this.updateForm()
  }

  updateForm() {
    this.locationForm = new FormGroup({
      locationName: new FormControl("", [
        Validators.required,
        Validators.maxLength(50),
        htmlValidator()
      ]),
      address: createAddressFormGroup(),
      email: new FormControl("", [Validators.required, Validators.email]),
      phone: new FormControl("", Validators.required)
    })
    if (this.location) {
      this.locationForm.patchValue(this.location)

      if (this.location.groups) {
        this.location.groups.forEach(group => {
          if (!this.groupsSelected.includes(GroupConverter.fromGroup(group))) {
            this.groupsSelected.push(GroupConverter.fromGroup(group))
          }
        })
      }
    }
  }

  getFilteredGroups(): IdAndLabel[] {
    const groups: IdAndLabel[] = []
    if (this.groupsFromService) {
      this.groupsFromService.forEach((group: GroupBase) => {
        if (
          this.groupsSelected.filter(
            groupSelected => group.groupId === groupSelected.id
          ).length === 0
        ) {
          groups.push(GroupConverter.from(group))
        }
      })
    }
    this.groupsSelected = this.groupsSelected.filter(groupSelected =>
      this.groupsFromService
        .map(group => group.groupId)
        .includes(groupSelected.id)
    )
    return groups
  }

  onSubmit(formWrapper?: FormWrapperPage) {
    if (!checkFormValidity(this.locationForm)) {
      return
    }
    this.location = this.locationForm.value
    if (this.auth.permissions.locations_view) {
      this.location.groups = []

      this.groupsSelected.forEach(selected => {
        this.groupsFromService.forEach(group => {
          if (selected.id === group.groupId) {
            this.location.groups.push(group)
          }
        })
      })
    } else {
      this.location.groups = []
    }
    return this.location
  }

  openModal() {
    this.modalGroupAssign.location().subscribe(groupSelected => {
      this.groupsSelected = groupSelected
    })
  }

  groupsSelectedChange(items: IdAndLabel[]) {
    this.groupsSelected = items
  }

  addGroup(item: IdAndLabel) {
    this.groupSelected = ""
    if (
      this.groupsSelected.filter(selected => selected.id === item.id).length ===
      0
    ) {
      this.groupsSelected.push(item)
    }
  }
}
