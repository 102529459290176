import { Component, Input, OnInit } from '@angular/core';
import { FiltrableDataSource } from 'filtrable-data-source';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() dataSource: FiltrableDataSource<any>;
  @Input() isLoading = false;

  constructor() {}

  history = history;

  ngOnInit() {}
}
