import { Group } from '../api';
import { GroupBase } from '../api/model/groupBase';
import { Location } from '../api/model/location';

export interface IdAndLabel {
  id: string;
  label: string;
}

export function keyValueToIdAndLabelArray(val: any): IdAndLabel[] {
  return Object.keys(val).map(key => {
    return {
      id: key,
      label: val[key]
    };
  });
}

export class GroupConverter {
  static from(group: GroupBase): IdAndLabel {
    return { id: group.groupId, label: group.groupName };
  }

  static fromList(groups: GroupBase[]): IdAndLabel[] {
    if (groups) {
      return groups.map(group => GroupConverter.from(group));
    }
    return [];
  }

  static fromGroup(group: Group): IdAndLabel {
    return { id: group.groupId, label: group.groupName }
  }
}

export class LocationConverter {
  static from(location: Location): IdAndLabel {
    return { id: location.locationId, label: location.locationName };
  }

  static fromList(locations: Location[]): IdAndLabel[] {
    if (locations) {
      return locations.map(location => LocationConverter.from(location));
    }
    return [];
  }
}
