import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss']
})
export class CardFooterComponent implements OnInit {
  @HostBinding('class') hostClass = 'card-footer';

  constructor() {}

  ngOnInit() {}
}
