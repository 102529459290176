import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { FiltrableDataSource } from 'filtrable-data-source';
import { Unit } from 'src/app/api/index';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import { getColorHexByUnit } from 'src/app/core/utils';
import { UnitTypePipe } from '../unit-type.pipe';

@Component({
  selector: 'app-units-table',
  templateUrl: './units-table.component.html',
  styleUrls: ['./units-table.component.scss'],
  providers: [UnitTypePipe]
})
export class UnitsTableComponent implements OnInit {
  @Input() dataSource: FiltrableDataSource<any>;
  @Input() selectedHandler: SelectedItemsHandler<Unit>;
  @Input() menuTpl: TemplateRef<any>;
  @Output() unitLinkClicked = new EventEmitter<string>();

  @ViewChild('unitNameTpl', { static: true }) unitNameTpl: TemplateRef<any>;
  @ViewChild('unitStatusTpl', { static: true }) unitStatusTpl: TemplateRef<any>;
  @ViewChild('unitActionTpl', { static: true }) unitActionTpl: TemplateRef<any>;

  columns: TableColumn[] = [];
  getColorHex = getColorHexByUnit;

  constructor(private ts: TranslateService, private typePipe: UnitTypePipe) {}

  async ngOnInit() {
    this.dataSource.currentItems.forEach((element: any) => {
      element.chambers = [];
      if (element.currentAction && element.recipeName) {
        const currentActions = JSON.parse(element.currentAction!);
        const currentRecipes = JSON.parse(element.recipeName!);
        for (let i = 0; i < currentActions.length; i++) {
          const str =
            (currentActions.length > 1 ? i + 1 + '. ' : '') +
            currentActions[i] +
            ' ' +
            (currentRecipes[i] !== '' ? '"' + currentRecipes[i] + '"' : '');
          element.chambers.push(str);
        }
      }
    });

    const unitNameString = await this.ts.get('UNIT.NAME').toPromise();
    const unitTypeString = await this.ts.get('UNIT.UNIT_TYPE').toPromise();
    const unitStatusString = await this.ts.get('UNIT.UNIT_STATUS').toPromise();
    const unitActionString = await this.ts
      .get('UNIT.CURRENT_ACTION')
      .toPromise();

    this.columns = [
      {
        prop: 'unitName',
        name: unitNameString,
        cellTemplate: this.unitNameTpl,
        minWidth: 250
      },
      {
        prop: 'unitType',
        name: unitTypeString,
        pipe: this.typePipe,
        minWidth: 100
      },
      {
        prop: 'connected',
        cellTemplate: this.unitStatusTpl,
        name: unitStatusString,
        minWidth: 100
      },
      {
        sortable: false,
        cellTemplate: this.unitActionTpl,
        name: unitActionString,
        minWidth: 150
      },
      {
        cellTemplate: this.menuTpl,
        sortable: false,
        minWidth: 60,
        maxWidth: 60
      }
    ];
  }
}
