import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { UsersService } from "../api"

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.css"]
})
export class NotFoundComponent implements OnInit {
  history = history

  constructor(private router: Router, private userService: UsersService) {}

  ngOnInit() {}

  goToDashboard() {}
}
