import { Pipe, PipeTransform } from "@angular/core"
import { fromSecondsToTimeString } from "./utils"

@Pipe({
  name: "timeRange"
})
export class TimeRangePipe implements PipeTransform {
  transform(value: string): any {
    return fromSecondsToTimeString(value)
  }
}
