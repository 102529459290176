import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { UnitType } from "../model/unitType";
import { UnitCategory } from "../model/unitCategory";

@Injectable({
  providedIn: "root"
})
export class UnitService {
  constructor(protected httpClient: HttpClient) {}

  /**
   * Retrieves the unit types available to the current user
   */
  public getTypes(): Observable<UnitType[]> {
    return this.httpClient.get<UnitType[]>("/units/types", {
      responseType: "json"
    });
  }

  public getCategories(): Observable<UnitCategory[]> {
    return this.httpClient.get<UnitCategory[]>("/units/categories", {
      responseType: "json"
    });
  }
}
