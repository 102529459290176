import { DateTime } from 'luxon';

export function getToday(): [Date, Date] {
  const localDateTime = DateTime.local();
  const startDateTime = localDateTime.minus({minutes: localDateTime.minute, milliseconds: localDateTime.millisecond, hours: localDateTime.hour, seconds: localDateTime.second});
  return [startDateTime.toJSDate(), localDateTime.toJSDate()];
}

export function getOneDayAgo(): [Date, Date] {
  const localDateTime = DateTime.local();
  const startDateTime = localDateTime.minus({ days: 1, minutes: localDateTime.minute, milliseconds: localDateTime.millisecond, hours: localDateTime.hour, seconds: localDateTime.second });
  return [startDateTime.toJSDate(), localDateTime.toJSDate()];
}

export function getLastWeek(): [Date, Date] {
  const localDateTime = DateTime.local();
  const endDateTime = localDateTime.minus({days: 1, minutes: localDateTime.minute, milliseconds: localDateTime.millisecond, hours: localDateTime.hour, seconds: localDateTime.second}).plus({hours: 23, minutes: 59});
  const startDateTime = localDateTime.minus({days: 7, minutes: localDateTime.minute, milliseconds: localDateTime.millisecond, hours: localDateTime.hour, seconds: localDateTime.second});
  return [startDateTime.toJSDate(), endDateTime.toJSDate()];
}

export function getThisMonth(): [Date, Date] {
  const localDateTime = DateTime.local();
  const startDateTime = localDateTime.minus({days: localDateTime.day - 1, minutes: localDateTime.minute, milliseconds: localDateTime.millisecond, hours: localDateTime.hour, seconds: localDateTime.second});
  return [startDateTime.toJSDate(), localDateTime.toJSDate()];
}

export function getPrevMonth(): [Date, Date] {
  const prevMonth = DateTime.local().minus({ months: 1 });
  return [
    prevMonth.startOf('month').toJSDate(),
    prevMonth.endOf('month').toJSDate()
  ];
}

export function adjustDateRange(range: [Date, Date]): [Date, Date] {
  const from = DateTime.fromJSDate(range[0])
    .startOf('day')
    .toJSDate();
  const to = DateTime.fromJSDate(range[1])
    .endOf('day')
    .toJSDate();

  const now = new Date();
  return [from, to > now ? now : to];
}
