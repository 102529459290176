import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy
} from "@angular/common"

import { AgmCoreModule } from "@agm/core"
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { TreeModule } from "angular-tree-component"
import { AccordionModule } from "ngx-bootstrap/accordion"
import { BsDatepickerModule } from "ngx-bootstrap/datepicker"
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { ModalModule } from "ngx-bootstrap/modal"
import { PaginationModule } from "ngx-bootstrap/pagination"
import { TabsModule } from "ngx-bootstrap/tabs"
import { TypeaheadModule } from "ngx-bootstrap/typeahead"
import { NgProgressModule } from "ngx-progressbar"
import { NgProgressHttpModule } from "ngx-progressbar/http"
import { ToastrModule } from "ngx-toastr"
import { environment } from "src/environments/environment"
import { ApiModule, BASE_PATH } from "./api"
import { ApiRequestInterceptor } from "./api/api/api-request-interceptor"
import { appInit, localeFactory } from "./app-init"
import { AppComponent } from "./app.component"
import { HttpErrorEmitter } from "./app.httperroremitter.service"
import { AppMainService } from "./app.main.service"
import { googleApiKey } from "./constants"
import { AuthenticationService } from "./core/authentication.service"
import { CoreModule } from "./core/core.module"
import { PermissionService } from "./core/permission.service"
import { LayoutComponent } from "./layout/layout-component/layout.component"
import { OnboardingStepComponent } from "./layout/signup-layout-component/onboarding-step/onboarding-step.component"
import { SignupLayoutComponentComponent } from "./layout/signup-layout-component/signup-layout-component.component"
import { NotFoundComponent } from "./not-found/not-found.component"
import { OnboardingGuard } from "./onboarding/onboarding.guard"
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component"
import { RoutingModule } from "./routing.module"
import { SharedModule } from "./shared/shared.module"
import { SignInComponent } from "./sign-in/sign-in/sign-in.component"
import { SupportComponent } from "./support/support.component"
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component"

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SignupLayoutComponentComponent,
    OnboardingStepComponent,
    NotFoundComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    SupportComponent,
    SignInComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-center"
    }),
    RoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: googleApiKey,
      libraries: ["places"],
      language: "en"
    }),
    TranslateModule.forRoot({}),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    ApiModule,
    NgProgressModule.withConfig({
      color: "#4a90e2",
      spinner: false
    }),
    NgProgressHttpModule,
    TypeaheadModule.forRoot(),
    TreeModule.forRoot(),
    FormsModule
  ],
  providers: [
    AppMainService,
    {
      provide: APP_INITIALIZER,
      useValue: "/",
      useFactory: appInit,
      multi: true,
      deps: [TranslateService, PermissionService, AuthenticationService]
    },
    {
      provide: BASE_PATH,
      useValue: environment.apiBasePath
    },
    {
      provide: LOCALE_ID,
      useFactory: localeFactory,
      deps: [AuthenticationService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorEmitter,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestInterceptor,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    OnboardingGuard,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
