/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { Location } from "../model/location";
import { LocationDataResult } from "../model/locationDataResult";
import { LocationDetail } from "../model/locationDetail";
import { LocationsTreeItem } from "../model/locationsTreeItem";
import { RecipeBaseList } from "../model/recipeBaseList";
import { ResolutionType } from "../model/resolutionType";
import { UnitType } from "../model/unitType";
import { UnassignedLocationsCount } from "../model/unassignedLocationsCount";

import { BASE_PATH } from "../variables";
import { Configuration } from "../configuration";
import { Unit } from "../model/unit";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LocationsService {
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * Create a new location
   *
   * @param LocationDetail
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createLocation(
    LocationDetail?: LocationDetail,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Location>;
  public createLocation(
    LocationDetail?: LocationDetail,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Location>>;
  public createLocation(
    LocationDetail?: LocationDetail,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Location>>;
  public createLocation(
    LocationDetail?: LocationDetail,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.configuration.basePath}/locations`,
      LocationDetail,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete an existing location
   * An empty location cannot be deleted. Any recipe library associated with the location will be deleted as well
   * @param locationId Id of the location
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteLocation(
    locationId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteLocation(
    locationId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteLocation(
    locationId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteLocation(
    locationId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (locationId === null || locationId === undefined) {
      throw new Error(
        "Required parameter locationId was null or undefined when calling deleteLocation."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/locations/${encodeURIComponent(
        String(locationId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit location
   *
   * @param locationId Id of the location
   * @param LocationDetail
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editLocation(
    locationId: string,
    LocationDetail?: LocationDetail,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editLocation(
    locationId: string,
    LocationDetail?: LocationDetail,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editLocation(
    locationId: string,
    LocationDetail?: LocationDetail,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editLocation(
    locationId: string,
    LocationDetail?: LocationDetail,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (locationId === null || locationId === undefined) {
      throw new Error(
        "Required parameter locationId was null or undefined when calling editLocation."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    // const body = {
    //   ,
    //   locationName: LocationDetail?.locationName,
    //   address: LocationDetail?.address,
    //   totalUnits: LocationDetail?.totalUnits,
    //   connected: LocationDetail?.connected,
    //   connectedUnits: LocationDetail?.connectedUnits,
    //   unitsWithAlerts: LocationDetail?.unitsWithAlerts,
    //   groups: LocationDetail?.groups ? Object.values(LocationDetail.groups) : null,
    //   created: LocationDetail?.created,
    //   updated: LocationDetail?.updated,
    //   phone: LocationDetail?.phone,
    //   email: LocationDetail?.email,
    //   admins: LocationDetail?.admins,
    //   users: LocationDetail?.users,
    //   groupIds: LocationDetail?.groupIds
    // }

    const body = {
      locationId: "LocationDetail?.locationId"
    };

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/locations/${encodeURIComponent(
        String(locationId)
      )}`,
      LocationDetail,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Export data for a location
   *
   * @param locationId Id of the location
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param unitType Unit type
   * @param timezone User timezone
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportLocationData(
    locationId: string,
    from: string,
    to: string,
    unitType: UnitType,
    timezone: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Blob>;
  public exportLocationData(
    locationId: string,
    from: string,
    to: string,
    unitType: UnitType,
    timezone: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public exportLocationData(
    locationId: string,
    from: string,
    to: string,
    unitType: UnitType,
    timezone: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public exportLocationData(
    locationId: string,
    from: string,
    to: string,
    unitType: UnitType,
    timezone: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (locationId === null || locationId === undefined) {
      throw new Error(
        "Required parameter locationId was null or undefined when calling exportLocationData."
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        "Required parameter from was null or undefined when calling exportLocationData."
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        "Required parameter to was null or undefined when calling exportLocationData."
      );
    }
    if (unitType === null || unitType === undefined) {
      throw new Error(
        "Required parameter unitType was null or undefined when calling exportLocationData."
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        "Required parameter timezone was null or undefined when calling exportLocationData."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set("from", <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set("to", <any>to);
    }
    if (unitType !== undefined && unitType !== null) {
      queryParameters = queryParameters.set("unitType", <any>unitType);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set("timezone", <any>timezone);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(
      `${this.configuration.basePath}/locations/${encodeURIComponent(
        String(locationId)
      )}/data/export`,
      {
        params: queryParameters,
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get basic information for a location
   *
   * @param locationId Id of the location
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLocation(
    locationId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<LocationDetail>;
  public getLocation(
    locationId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<LocationDetail>>;
  public getLocation(
    locationId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<LocationDetail>>;
  public getLocation(
    locationId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (locationId === null || locationId === undefined) {
      throw new Error(
        "Required parameter locationId was null or undefined when calling getLocation."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LocationDetail>(
      `${this.configuration.basePath}/locations/${encodeURIComponent(
        String(locationId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get charts data for a location
   *
   * @param locationId Id of the location
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param resolution Resolution of the data displayed in the chart
   * @param timezone User timezone
   * @param unitType Unit type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLocationDataChart(
    locationId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    unitType?: UnitType,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<any>>;
  public getLocationDataChart(
    locationId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    unitType?: UnitType,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<any>>>;
  public getLocationDataChart(
    locationId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    unitType?: UnitType,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<any>>>;
  public getLocationDataChart(
    locationId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    unitType?: UnitType,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (locationId === null || locationId === undefined) {
      throw new Error(
        "Required parameter locationId was null or undefined when calling getLocationDataChart."
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        "Required parameter from was null or undefined when calling getLocationDataChart."
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        "Required parameter to was null or undefined when calling getLocationDataChart."
      );
    }
    if (resolution === null || resolution === undefined) {
      throw new Error(
        "Required parameter resolution was null or undefined when calling getLocationDataChart."
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        "Required parameter timezone was null or undefined when calling getLocationDataChart."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set("from", <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set("to", <any>to);
    }
    if (unitType !== undefined && unitType !== null) {
      queryParameters = queryParameters.set("unitType", <any>unitType);
    }
    if (resolution !== undefined && resolution !== null) {
      queryParameters = queryParameters.set("resolution", <any>resolution);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set("timezone", <any>timezone);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Array<any>>>(
      `${this.configuration.basePath}/locations/${encodeURIComponent(
        String(locationId)
      )}/data/chart`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get table data for a location
   *
   * @param locationId Id of the location
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param resolution Resolution of the data displayed in the chart
   * @param timezone User timezone
   * @param unitType Unit type
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $filter Filter items by property values, see [OData Filtering](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionfilter)
   * @param $orderby Order items by property values, see [OData Sorting](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionorderby)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLocationDataTable(
    locationId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    unitType?: UnitType,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<LocationDataResult>;
  public getLocationDataTable(
    locationId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    unitType?: UnitType,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<LocationDataResult>>;
  public getLocationDataTable(
    locationId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    unitType?: UnitType,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<LocationDataResult>>;
  public getLocationDataTable(
    locationId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    unitType?: UnitType,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (locationId === null || locationId === undefined) {
      throw new Error(
        "Required parameter locationId was null or undefined when calling getLocationDataTable."
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        "Required parameter from was null or undefined when calling getLocationDataTable."
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        "Required parameter to was null or undefined when calling getLocationDataTable."
      );
    }
    if (resolution === null || resolution === undefined) {
      throw new Error(
        "Required parameter resolution was null or undefined when calling getLocationDataTable."
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        "Required parameter timezone was null or undefined when calling getLocationDataTable."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set("from", <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set("to", <any>to);
    }
    if (unitType !== undefined && unitType !== null) {
      queryParameters = queryParameters.set("unitType", <any>unitType);
    }
    if (resolution !== undefined && resolution !== null) {
      queryParameters = queryParameters.set("resolution", <any>resolution);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set("timezone", <any>timezone);
    }
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = queryParameters.set("$filter", <any>$filter);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append("$orderby", <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LocationDataResult>(
      `${this.configuration.basePath}/locations/${encodeURIComponent(
        String(locationId)
      )}/data/table`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get recipes that are in at least one cookbook of the units of the location, filtrable and paginable. Recipes with the same ids should returned once.
   *
   * @param locationId Id of the location
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param recipeName Used to filter the list based on the recipe name
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLocationRecipes(
    locationId: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    recipeName?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<RecipeBaseList>;
  public getLocationRecipes(
    locationId: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    recipeName?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<RecipeBaseList>>;
  public getLocationRecipes(
    locationId: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    recipeName?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<RecipeBaseList>>;
  public getLocationRecipes(
    locationId: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    recipeName?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (locationId === null || locationId === undefined) {
      throw new Error(
        "Required parameter locationId was null or undefined when calling getLocationRecipes."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }
    if (recipeName !== undefined && recipeName !== null) {
      queryParameters = queryParameters.set("recipeName", <any>recipeName);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RecipeBaseList>(
      `${this.configuration.basePath}/locations/${encodeURIComponent(
        String(locationId)
      )}/recipes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get all locations visible to the user (or locations within a group if groupId parameter is specified)
   *
   * @param groupId * If this parameter is not present all locations visibile to the user will be returned. * If the parameter is present and has a value, all the locations within the passed group are returned * If the parameter is present and it value is \&quot;null\&quot;, all the locations that are not assigned to a group will be returned
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLocations(
    groupId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Location>>;
  public getLocations(
    groupId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Location>>>;
  public getLocations(
    groupId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Location>>>;
  public getLocations(
    groupId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (groupId !== undefined && groupId !== null) {
      queryParameters = queryParameters.set("groupId", <any>groupId);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<Location>>(`${this.configuration.basePath}/locations`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      })
      .pipe(
        map(result => {
          var retval = (result as any) as Location[];
          return retval.sort(this.compare);
        })
      );
  }

  /**
   * Get all locations visible to the user (or locations within a group if groupId parameter is specified)
   *
   * @param groupId * If this parameter is not present all locations visibile to the user will be returned. * If the parameter is present and has a value, all the locations within the passed group are returned * If the parameter is present and it value is \&quot;null\&quot;, all the locations that are not assigned to a group will be returned
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnassignedLocations(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<Location>>;
  public getUnassignedLocations(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Location>>>;
  public getUnassignedLocations(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Location>>>;
  public getUnassignedLocations(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Location>>(
      `${this.configuration.basePath}/locations/unassigned`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the locations tree
   *
   * @param recipeIds Array of recipeIds, to filter the tree to show only units that have that recipe deployed
   * @param groupId Id of the group
   * @param unitType Type of the unit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLocationsTree(
    recipeIds?: Array<string>,
    groupId?: string,
    unitType?: UnitType,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<LocationsTreeItem>>;
  public getLocationsTree(
    recipeIds?: Array<string>,
    groupId?: string,
    unitType?: UnitType,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<LocationsTreeItem>>>;
  public getLocationsTree(
    recipeIds?: Array<string>,
    groupId?: string,
    unitType?: UnitType,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<LocationsTreeItem>>>;
  public getLocationsTree(
    recipeIds?: Array<string>,
    groupId?: string,
    unitType?: UnitType,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (recipeIds) {
      recipeIds.forEach(element => {
        queryParameters = queryParameters.append("recipeIds", <any>element);
      });
    }
    if (groupId !== undefined && groupId !== null) {
      queryParameters = queryParameters.set("groupId", <any>groupId);
    }
    if (unitType !== undefined && unitType !== null) {
      queryParameters = queryParameters.set("unitType", <any>unitType);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<LocationsTreeItem>>(
      `${this.configuration.basePath}/locations/tree`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get number of locations that are not assigned to a group
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnassignedLocationsCount(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnassignedLocationsCount>;
  public getUnassignedLocationsCount(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UnassignedLocationsCount>>;
  public getUnassignedLocationsCount(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UnassignedLocationsCount>>;
  public getUnassignedLocationsCount(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnassignedLocationsCount>(
      `${this.configuration.basePath}/locations/unassigned/count`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get number of locations that are not assigned to a group
   */
  public getUnits(locationId: string, unitTypes?: string[]): Observable<any> {
    return this.httpClient.get<any>(
      `/locations/${encodeURIComponent(String(locationId))}/units`,
      {
        params: unitTypes ? { unitTypes: unitTypes.join(",") } : undefined,
        responseType: "json"
      }
    );
  }

  public getMobileVisionLocations(): Observable<any> {
    return this.httpClient.get<any>("/mobile-vision/locations", {
      responseType: "json"
    });
  }

  public getLocationsWithUnits(): Observable<any> {
    return this.httpClient.get<any>("/locations/locations-with-units");
  }

  compare(a: any, b: any) {
    // Use toUpperCase() to ignore character casing
    const locA = a.locationName.toUpperCase();
    const locB = b.locationName.toUpperCase();

    let comparison = 0;
    if (locA > locB) {
      comparison = 1;
    } else if (locA < locB) {
      comparison = -1;
    }
    return comparison;
  }
}
