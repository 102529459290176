import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { RecipeService } from '../api';
import { DialogModalService } from '../core/dialog-modal.service';
import { ModalCloseEvent } from '../shared/generic-modal/generic-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DeleteRecipesService {
  constructor(
    private dialogService: DialogModalService,
    private recipeService: RecipeService,
    private ts: TranslateService
  ) {}

  deleteRecipes(recipeIds: string[]): Observable<ModalCloseEvent> {
    return this.openDeleteConfirmModal(recipeIds).pipe(
      mergeMap(() => this.openSuccessModal(recipeIds))
    );
  }

  private openDeleteConfirmModal(recipeIds: string[]): Observable<any> {
    const delete$ = this.recipeService.deleteRecipes(recipeIds);
    const detailMsg = `<p>${this.ts.instant(
      'RECIPE_LIBRARY.DELETE_RECIPES.DESC1'
    )}</p><p>${this.ts.instant('RECIPE_LIBRARY.DELETE_RECIPES.DESC2')}</p>`;

    return this.dialogService.dangerConfirm({
      title: this.ts.instant('RECIPE_LIBRARY.DELETE_RECIPES.TITLE'),
      detail: detailMsg,
      acceptButtonLabel: this.ts.instant('GLOBAL.DELETE'),
      beforeAccept$: delete$
    }).accept$;
  }

  private openSuccessModal(recipeIds: string[]): Observable<ModalCloseEvent> {
    return this.dialogService.success({
      icon: 'recipe-white.svg',
      message: this.ts.instant('RECIPE_LIBRARY.DELETE_RECIPES.CONFIRM', {
        num: recipeIds.length
      }),
      detail: this.ts.instant('GLOBAL.OK_CLICK'),
      acceptButtonLabel: this.ts.instant('GLOBAL.CONTINUE')
    }).close$;
  }
}
