import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, Resolve } from "@angular/router"
import { EMPTY, Observable } from "rxjs"
import { catchError, map, take } from "rxjs/operators"
import { UnitSettingsService, UnitSettingsTemplate } from "../api"

@Injectable({
  providedIn: "root"
})
export class UnitSettingsResolverService
  implements Resolve<UnitSettingsTemplate> {
  constructor(private unitSettingService: UnitSettingsService) {}
  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<UnitSettingsTemplate> | Observable<never> {
    const settingsId = route.params["settingsId"]
    return this.unitSettingService.getUnitSettingsItem(settingsId).pipe(
      map(setting => combineSettingsAndCleanVisibility(setting)),
      catchError(() => {
        return EMPTY
      })
    )
  }
}

// Unit Settings and Clean Visibility Data are combined in the UI but treated separately in
// the backend, recombine the backend response here
export function combineSettingsAndCleanVisibility(data: UnitSettingsTemplate) {
  if (data.visibleCleanings && data.settings) {
    // TODO this is a lot of nesting
    (data.settings as any).visibleCleanings = {
      visibleCleanings: data.visibleCleanings
    }
  }
  return data
}
