import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { ClientFiltrableDataSource } from "filtrable-data-source"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { Observable } from "rxjs"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"
import { objectListDifference } from "src/app/core/utils"
import { IdAndLabel } from "../../core/idAndLabel"
import { CreateCategoryComponent } from "./create-category/create-category.component"

@Component({
  selector: "app-category-assign",
  templateUrl: "./category-assign.component.html",
  styleUrls: ["./category-assign.component.scss"]
})
export class CategoryAssignComponent implements OnInit {
  /**
   * The id of the library or the unit where the categories should be searched
   */
  @Input() id: string

  /**
   * Array of categories already selected by the user outside this modal
   */
  @Input() selectedCategories: IdAndLabel[]

  /**
   * If true, the modal is used only for filtering in recipe list
   */
  @Input() onlyFiltering = true

  /**
   * Function to call the correct api (library or unit)
   */
  @Input() queryFunction: (id: string) => Observable<string[]>

  @Input() isGlobal = false

  /**
   * Array of categories selected by the user
   */
  @Output() result = new EventEmitter<IdAndLabel[]>()

  isLoading = false
  dataSource = new ClientFiltrableDataSource<IdAndLabel>()
  selectedCategoriesHandler = new SelectedItemsHandler<IdAndLabel>()
  modalHidden = false

  constructor(
    public modalRef: BsModalRef,
    public modalService: BsModalService
  ) {}

  ngOnInit() {
    this.isLoading = true
    this.dataSource.setItems([])

    if (!this.id && !this.isGlobal) {
      return
    }

    this.queryFunction(this.id).subscribe(res => {
      const allCategories: IdAndLabel[] = res.map(r => ({ id: r, label: r }))
      const availableCategories = objectListDifference(
        allCategories,
        this.selectedCategories
      )
      this.dataSource.setItems(availableCategories)
      this.isLoading = false
    })
  }

  closeModal() {
    this.result.emit(this.selectedCategoriesHandler.items)
    this.modalRef.hide()
  }

  openCreateModal() {
    this.modalHidden = true
    const newModalRef = this.modalService.show(CreateCategoryComponent)
    const createComponent = newModalRef.content as CreateCategoryComponent
    createComponent.result.subscribe((newTag?: string) => {
      if (newTag && this.dataSource.items != null) {
        if (
          !this.dataSource.items.find(i => i.id === newTag) &&
          !this.selectedCategories.find(i => i.id === newTag)
        ) {
          const newItems = this.dataSource.items.concat({
            id: newTag,
            label: newTag
          })
          this.dataSource.setItems(newItems)
        }
      }
      this.modalHidden = false
    })
  }
}
