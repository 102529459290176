import { Component, Input, OnInit } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { Company, RecipeImage } from "../../api/index"
import { createAddressFormGroup } from "../address/address.component"

@Component({
  selector: "app-edit-company-form",
  templateUrl: "./edit-company-form.component.html",
  styleUrls: ["./edit-company-form.component.scss"]
})
export class EditCompanyFormComponent implements OnInit {
  @Input()
  set company(val: Company) {
    this._company = val
    this.companyForm.patchValue(val)
  }

  _company?: Company

  submitted: boolean

  companyForm = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.maxLength(255)]),
    companyType: new FormControl("", Validators.required),
    address: createAddressFormGroup(),
    phone: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    contactFirstName: new FormControl("", Validators.required),
    contactLastName: new FormControl("", Validators.required),
    logo: new FormControl(),
    image: new FormControl()
  })

  constructor() {}

  ngOnInit() {}

  get imageFormControl() {
    return this.companyForm.controls["image"] as FormControl
  }
}
