import { Component, Input, OnInit } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"

export function createAddressFormGroup(): FormGroup {
  return new FormGroup({
    street: new FormControl("", Validators.required),
    city: new FormControl("", Validators.required),
    state: new FormControl("", Validators.required),
    // TODO I believe postal code is the only field that google maps sometimes fails to fill in,
    //      it's possible that other fields will need to be marked as non-required though
    postalCode: new FormControl(""),
    country: new FormControl("", Validators.required),
    latitude: new FormControl("", Validators.required),
    longitude: new FormControl("", Validators.required)
  })
}

@Component({
  selector: "app-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"]
})
export class AddressComponent implements OnInit {
  @Input() addressForm: FormGroup

  constructor() {}

  ngOnInit() {}
}
