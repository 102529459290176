import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SharesService } from '../api';
import { LocationShareModalComponent } from '../location/location-share-modal/location-share-modal.component';
import { DialogModalService } from './dialog-modal.service';

@Injectable({
  providedIn: 'root'
})
export class ShareAccessService {
  constructor(
    private modalService: BsModalService,
    private dialogService: DialogModalService,
    private ts: TranslateService,
    private sharesService: SharesService
  ) {}

  openShareAccesModal(locationId: string) {
    const initialState: Partial<LocationShareModalComponent> = {
      locationId: locationId
    };

    const modalRef = this.modalService.show(LocationShareModalComponent, {
      initialState
    });
    const component = modalRef.content as LocationShareModalComponent;
  }

  resendShareLink(singleShareLog: any) {
    this.dialogService
      .successConfirm({
        title: this.ts.instant('LOCATION.SHARE_ACCESS.RESEND_LINK_TITLE'),
        detail: this.ts.instant('LOCATION.SHARE_ACCESS.RESEND_LINK_DESC', {
          email: singleShareLog.emails.join(', '),
          location: singleShareLog.location.locationName
        }),
        acceptButtonLabel: this.ts.instant('GLOBAL.CONFIRM')
      })
      .accept$.subscribe(() => {
        this.sharesService
          .resendShareLink(singleShareLog.shareId)
          .subscribe(() => {
            this.dialogService.success({
              icon: 'check-white.svg',
              message: this.ts.instant('LOCATION.SHARE_ACCESS.LINK_SENT'),
              detail: this.ts.instant('LOCATION.SHARE_ACCESS.LINK_SENT_DESC', {
                location: singleShareLog.location.locationName,
                email: singleShareLog.emails.join(', ')
              })
            });
          });
      });
  }

  expireShareLink(singleShareLog: any) {
    this.dialogService
      .dangerConfirm({
        title: this.ts.instant('LOCATION.SHARE_ACCESS.EXPIRE_LINK_TITLE'),
        detail: this.ts.instant('LOCATION.SHARE_ACCESS.EXPIRE_LINK_DESC', {
          location: singleShareLog.location.locationName
        }),
        acceptButtonLabel: this.ts.instant('GLOBAL.EXPIRE')
      })
      .accept$.subscribe(() => {
        this.sharesService.expireShare(singleShareLog.shareId).subscribe(() => {
          this.dialogService
            .success({
              icon: 'locations.svg',
              message: this.ts.instant('LOCATION.SHARE_ACCESS.LINK_EXPIRED', {
                location: singleShareLog.location.locationName
              }),
              detail: this.ts.instant('LOCATION.SHARE_ACCESS.LINK_EXPIRED_DESC')
            })
            .accept$.subscribe(() => {
              window.location.reload();
            });
        });
      });
  }
}
