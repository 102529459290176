import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  Input
} from "@angular/core"
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl
} from "@angular/forms"
import IMask from "imask"
import { fromSecondsToTimeString, fromTimeStringToSeconds } from "./utils"

@Component({
  selector: "app-time-setpoint",
  templateUrl: "./time-setpoint.component.html",
  styleUrls: ["./time-setpoint.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSetpointComponent),
      multi: true
    }
  ]
})
export class TimeSetpointComponent
  implements ControlValueAccessor, AfterViewInit {
  hostControl?: FormControl

  /** This form control is internal and used only for masked value */
  maskedFormControl = new FormControl(0)
  mask = {
    mask: "hh{:}mm{:}mm",
    lazy: true,
    overwrite: false,
    autofix: true,
    placeholderChar: "0",
    blocks: {
      hh: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 99
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59
      }
    }
  }

  onChange?: (p?: any) => any
  onTouched: (p?: any) => any

  constructor(private injector: Injector) {
    this.maskedFormControl.valueChanges.subscribe((v: string) => {
      const modelValue = fromTimeStringToSeconds(v)
      if (this.onChange) {
        this.onChange(+modelValue!)
      }
    })
  }

  ngAfterViewInit(): void {
    this.hostControl = this.injector.get(NgControl, null)
  }

  writeValue(seconds: number | string): void {
    if (seconds !== -1 && seconds !== -2) {
      this.maskedFormControl.setValue(fromSecondsToTimeString(String(seconds)))
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.maskedFormControl.disable()
    } else {
      this.maskedFormControl.enable()
    }
  }
}
