import { Pipe, PipeTransform } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { _ } from "src/app/core/translation-marker"
import { UnitType } from "../api"

const unitTypeTranslationMap: { [key in UnitType]: string } = {
  cookAndHold: _("UNIT_TYPE.COOK_AND_HOLD_SMOKER"),
  cookAndHoldNoSmoker: _("UNIT_TYPE.COOK_AND_HOLD"),
  vector: _("UNIT_TYPE.VECTOR_H"),
  vectorF: _("UNIT_TYPE.VECTOR_F"),
  vectorFG: _("UNIT_TYPE.VECTOR_FG"),
  converge: _("UNIT_TYPE.CONVERGE"),
  prodigiPro: _("UNIT_TYPE.PRODIGI_PRO")
}

@Pipe({
  name: "unitType"
})
export class UnitTypePipe implements PipeTransform {
  constructor(private ts: TranslateService) {}

  transform(value: UnitType): string {
    const translationKey = unitTypeTranslationMap[value]
    return translationKey ? this.ts.instant(translationKey) : ""
  }
}
