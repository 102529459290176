import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Location, LocationsService } from '../api';

@Injectable({
  providedIn: 'root'
})
export class LocationsListService implements Resolve<Location[]> {
  locations: Location[] = [];

  constructor(private locationService: LocationsService) {
    this.resolve();
  }

  resolve(): Promise<Location[]> {
    return this.locationService
      .getLocations()
      .toPromise()
      .then(res => {
        this.locations = res;
        return res;
      });
  }
}
