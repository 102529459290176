/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type UnitSettingsActionType = 'assign' | 'restore';

export const UnitSettingsActionType = {
  Assign: 'assign' as UnitSettingsActionType,
  Restore: 'restore' as UnitSettingsActionType
};
