// TODO none of this is localized in V1

import { UnitType } from "../unitType";

const VECTOR = {
  doorOpen: "Door",
  door0Open: "Door 1",
  door1Open: "Door 2",
  highLimit0: "High Limit 1",
  highLimit1: "High Limit 2",
  targetFan: "Target Fan (%)",
  cbTemp: "CB Temp",
  coolingFanStatus: "Cooling Fan",
  chamberCount: "4",
  heaterStatus: "Heater Status",
  actualFanRPM: "Actual Fan (RPM)",
  targetTemp: "Target Temp",
  inputVoltage: "Input Voltage",
  wss: "WiFi Signal Strength",
  actualTemp: "Actual Temp",
  ibTemp: "IB Temp",
  ipv4: "IPV4 Address",
  coolingFanHighLimit: "Cooling Fan High Limit"
};

const VECTOR_F = {
  ...VECTOR,
  // only appears in chambers 0 and 2
  probeTargetTemp0: "Target Probe",
  probeTemp0: "Actual Probe",
  probeTargetTemp2: "Target Probe",
  probeTemp2: "Actual Probe"
};

const VECTOR_FG = {
  ...VECTOR_F,
  gasValveStatus: "Gas Valve Status",
  gasBlowerRPM: "Gas Blower RPM"
};

const COOK_AND_HOLD_NO_SMOKER = {
  ...VECTOR,
  // 6 probes, but only the first has a taret temp
  probeTargetTemp0: "Probe 1 Target Temp",
  probeTemp0: "Probe 1 Actual Temp",
  probeTemp1: "Probe 2 Actual Temp",
  probeTemp2: "Probe 3 Actual Temp",
  probeTemp3: "Probe 4 Actual Temp",
  probeTemp4: "Probe 5 Actual Temp",
  probeTemp5: "Probe 6 Actual Temp"
};

const COOK_AND_HOLD = {
  ...COOK_AND_HOLD_NO_SMOKER, // the name schemes feel backwards
  smokerStatus: "Smoker Status"
};

const CONVERGE = {
  ...VECTOR,
  // new top-level variables
  highLimit3: "High Limit 3",
  y2Solenoid: "Y2 Tank Solenoid",
  y4Pump: "Y4 Cleaning Pump",
  catalyticConverter: "Catalytic Converter Status",
  drainLineTemp: "Drain Line Temp",
  waterPressureSwitch: "Water Pressure Switch",

  // Per chamber
  probeTargetTemp: "Target Probe",
  probeTemp: "Actual Probe",
  steamLevel: "Steam Level",
  ventValveStatus: "Vent Valve Status",
  y1Status: "Y1 Status",
  steamHeater: "Steam Heater"
};

const PRODIGI_PRO = {
  // TODO-PRODIGI: fix to match actual Prodigi telemetry
  ...VECTOR,
  // new top-level variables
  smokerStatus: "Smoker Status",
  pressureSwitchStatus: "Pressure Switch Status",
  greaseCollection: "Grease Collection",
  ventValveStatus: "Vent Valve Status",
  drainLineTemp: "B3",
  bypassSteamTemp: "B5",
  probeTemp: "B10",
  heaterStatus2: "K41 Status",
  powerLevelStatus1: "K44 Status",
  powerLevelStatus2: "K45 Status",
  hoodFanStatus: "Ventless Hood Status",
  actualTemp2: "N6",
  highLimit2: "N7",
  steamValveStatus: "Y1 Status",
  tankSolenoid: "Y2 Status",
  cleaningRinse: "Y3 Status",
  rinseWater: "Y4 Status",
  vfdFirmware: "VFD 1&2 Firmware",
  vfdFanSpeed: "VFD 1&2 Fan Speed",
  gasValveStatus: "LSI Status",
  gasSpeed1: "Gas Speed 1",
  gasSpeed2: "Gas Speed 2",

  probeTargetTemp0: "Target Probe",
  probeTemp0: "Actual Probe"
};

export const TELEMETRY_METADATA_KEYS: Record<UnitType, any> = {
  vector: VECTOR,
  vectorF: VECTOR_F,
  vectorFG: VECTOR_FG,
  cookAndHoldNoSmoker: COOK_AND_HOLD_NO_SMOKER,
  cookAndHold: COOK_AND_HOLD,
  converge: CONVERGE,
  prodigiPro: PRODIGI_PRO
};

export const DoorOpenKeys = {
  0: "UNIT.SERVICE_MONITOR.CLOSED",
  1: "UNIT.SERVICE_MONITOR.OPEN"
};

export const HighLimitKeys = {
  0: "UNIT.SERVICE_MONITOR.TRIPPED",
  1: "UNIT.SERVICE_MONITOR.OK"
};
