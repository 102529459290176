import { Pipe, PipeTransform } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { _ } from "src/app/core/translation-marker"
import { UnitType, UserRole } from "../api"

const userRoleTranslationMap: { [key in UserRole]: string } = {
  system_admin: _("USER_ROLE.SYSTEM_ADMIN"),
  company_admin: _("USER_ROLE.COMPANY_ADMIN"),
  culinary_admin: _("USER_ROLE.CULINARY_ADMIN"),
  company_user: _("USER_ROLE.COMPANY_USER"),
  group_admin: _("USER_ROLE.GROUP_ADMIN"),
  group_user: _("USER_ROLE.GROUP_USER"),
  location_admin: _("USER_ROLE.LOCATION_ADMIN"),
  location_user: _("USER_ROLE.LOCATION_USER")
}

@Pipe({
  name: "role"
})
export class UserRolePipe implements PipeTransform {
  constructor(private ts: TranslateService) {}

  transform(value: UserRole): string {
    return this.ts.instant(userRoleTranslationMap[value] || "Unknown Role")
  }
}
