import { Pipe, PipeTransform } from "@angular/core"
import { Address } from "../api"

@Pipe({
  name: "addressShort"
})
export class AddressShortPipe implements PipeTransform {
  transform(value: Address, args?: any): any {
    if (value) {
      return `${value.street}. ${value.postalCode} ${value.city}, ${
        value.state
      } - ${value.country}`
    }
    return ""
  }
}
