import { Component, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"
import { guid } from "src/app/core/utils"

@Component({
  selector: "app-data-source-select-all",
  templateUrl: "./data-source-select-all.component.html",
  styleUrls: ["./data-source-select-all.component.scss"]
})
export class DataSourceSelectAllComponent implements OnInit {
  @Input()
  set currentItems(val: any[] | undefined) {
    if (val) {
      this._currentItems = val
      if (this.selectedHandler) {
        this.setCheckboxStatus()
      }
    }
  }

  @Input() selectedHandler: SelectedItemsHandler<any>

  formControl: FormControl
  checkboxId = guid()
  indeterminate = false

  private _currentItems: any[] = []

  constructor() {
    this.formControl = new FormControl(false)
    this.formControl.valueChanges.subscribe((val: boolean) => {
      // this.selectedHandler.toggleAllItemsSelected(val)
      for (const item of this._currentItems) {
        /*  Tofix  */
        if (
          !item.default &&
          item.synced !== "assign_pending" &&
          item.synced !== "remove_pending"
        ) {
          this.selectedHandler.toggleItemSelected(item, val)
        }
      }
    })
  }

  ngOnInit() {
    this.setCheckboxStatus()
    this.selectedHandler.itemsChanged$.subscribe(() => {
      this.setCheckboxStatus()
    })
  }

  setCheckboxStatus() {
    const selectedItems = this._currentItems.filter(i =>
      this.selectedHandler.isSelected(i)
    )
    const checkboxValue = selectedItems.length > 0
    this.indeterminate =
      checkboxValue && selectedItems.length < this._currentItems.length
    this.formControl.setValue(checkboxValue, { emitEvent: false })
  }
}
