import { Injectable } from "@angular/core"
import { CanActivate, CanActivateChild, Router } from "@angular/router"
import { AuthenticationService } from "./authentication.service"

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(
    private authentication: AuthenticationService,
    private router: Router
  ) {}

  canActivateChild(): boolean {
    return this.canActivate()
  }

  canActivate(): boolean {
    if (!this.authentication.isAuthenticated()) {
      // Check if token exists, if it does that means the user's session expired display timeout message
      const expired = this.authentication.getIdToken() ? true : false
      // Sign out user (also clears local storage) and navigate to auth screen
      this.authentication.signOutAndNavigate(expired)
      return false
    }
    return true
  }
}
