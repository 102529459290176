import { Component, Input, OnInit } from '@angular/core';
import { IdAndLabel } from 'src/app/core/idAndLabel';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';

@Component({
  selector: 'app-groups-select-dropdown',
  templateUrl: './groups-select-dropdown.component.html',
  styleUrls: ['./groups-select-dropdown.component.scss']
})
export class GroupsSelectDropdownComponent implements OnInit {
  @Input() groups: IdAndLabel[];
  @Input() selectedGroups: SelectedItemsHandler<IdAndLabel>;

  constructor() {}

  ngOnInit() {}
}
