/// <reference types="@types/googlemaps" />

export interface LocationAddress {
  street: string
  streetNumber: string
  city: string
  state: string
  country: string
  postalCode: string
}

type addressComponentType =
  | "street_number"
  | "route"
  | "locality"
  | "country"
  | "postal_code"
  | "administrative_area_level_1"
  | "administrative_area_level_2"
  | "sublocality_level_1"

const addressComponentsMap: {
  [key in addressComponentType]: "short_name" | "long_name"
} = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  country: "long_name",
  postal_code: "short_name",
  administrative_area_level_1: "long_name",
  administrative_area_level_2: "long_name",
  sublocality_level_1: "long_name"
}

export function parseAddressFromAddressComponents(
  components: google.maps.GeocoderAddressComponent[]
): LocationAddress {
  const addressComponents: { [key in addressComponentType]?: string } = {}
  for (const comp of components) {
    const type = comp.types[0] as addressComponentType
    if (type in addressComponentsMap) {
      const keyToUse = addressComponentsMap[type]
      addressComponents[type] = (comp as any)[keyToUse]
    }
  }

  return {
    street: addressComponents.route || "",
    streetNumber: addressComponents.street_number || "",
    postalCode: addressComponents.postal_code || "",
    city:
      addressComponents.locality ||
      addressComponents.administrative_area_level_2 ||
      addressComponents.sublocality_level_1 ||
      "",
    state: addressComponents.administrative_area_level_1 || "",
    country: addressComponents.country || ""
  }
}
