import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RecipeLibrary } from 'src/app/api';
import { LibrariesService } from '../../core/libraries.service';

@Component({
  selector: 'app-create-library',
  templateUrl: './create-library.component.html',
  styleUrls: ['./create-library.component.scss']
})
export class CreateLibraryComponent implements OnInit {
  @Output() librarySelected = new EventEmitter<RecipeLibrary>();

  dataSource = new ClientFiltrableDataSource<RecipeLibrary>();
  selectedLibrary?: RecipeLibrary;

  constructor(
    private librariesService: LibrariesService,
    private modalRef: BsModalRef
  ) {}

  ngOnInit() {
    this.onGroupsTabSelected();
  }

  onGroupsTabSelected() {
    this.selectedLibrary = undefined;
    this.dataSource.setItems(this.librariesService.emptyGroupLibraries);
  }

  onLocationsTabSelected() {
    this.selectedLibrary = undefined;
    this.dataSource.setItems(this.librariesService.emptyLocationLibraries);
  }

  dismiss() {
    this.modalRef.hide();
  }

  create() {
    this.librarySelected.emit(this.selectedLibrary);
    this.modalRef.hide();
  }
}
