import { AfterViewInit, Component, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { DomSanitizer, SafeUrl } from "@angular/platform-browser"
import { TranslateService } from "@ngx-translate/core"
import { ImageBase64 } from "src/app/api"
import {
  fromFileContentToBase64Image,
  readFileContent
} from "src/app/core/file-utils"

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.scss"]
})
export class ImageUploaderComponent implements OnInit, AfterViewInit {
  @Input()
  set imageUrl(val: string | undefined) {
    this.imageSrc = val
  }
  @Input()
  imageControl: FormControl

  imageSrc?: SafeUrl

  constructor(private ts: TranslateService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const initialValue: ImageBase64 = {
      mimeType: undefined,
      data: undefined,
      name: undefined
    }
    this.imageControl.setValue(this.imageSrc ? initialValue : null)
  }

  ngAfterViewInit() {}

  onFileSelected(file: File) {
    readFileContent(file).then(content => {
      this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(content)
      this.imageControl.setValue(
        fromFileContentToBase64Image(file.type, content, file.name)
      )
      this.imageControl.markAsDirty()
    })
  }

  deleteImage() {
    this.imageSrc = undefined
    this.imageControl.setValue(null)
    this.imageControl.markAsDirty()
  }
}
