import { TelemetryChamber } from "./telemetryChamber";

export class HighLimits {
  0: number;
  1: number;
  coolingFan: number;
}

export class TelemetryPayload {
  door0Open: number;
  door1Open: number;
  inputVoltage: number;
  cbTemp: number;
  ibTemp: number;
  wss: number;
  ipv4: number;
  coolingFanStatus: number;
  highLimits: HighLimits;
  chambers: TelemetryChamber[];

  // catch all for keys that are specific to a single oven type
  [key: string]: any;
}
