/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { SettingsIdAndUnitsList } from "../model/settingsIdAndUnitsList";
import { UnitSettingsList } from "../model/unitSettingsList";
import { UnitSettingsStatusList } from "../model/unitSettingsStatusList";
import { UnitSettingsSyncStatus } from "../model/unitSettingsSyncStatus";
import { UnitSettingsTemplate } from "../model/unitSettingsTemplate";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";
import { UnitSettingsActionType } from "../model/unitSettingsActionType";

@Injectable({
  providedIn: "root"
})
export class UnitSettingsService {
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Assign Unit settings to Units
   *
   * @param SettingsIdAndUnitsList
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignUnitSettingsToUnits(
    SettingsIdAndUnitsList?: SettingsIdAndUnitsList,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public assignUnitSettingsToUnits(
    SettingsIdAndUnitsList?: SettingsIdAndUnitsList,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public assignUnitSettingsToUnits(
    SettingsIdAndUnitsList?: SettingsIdAndUnitsList,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public assignUnitSettingsToUnits(
    SettingsIdAndUnitsList?: SettingsIdAndUnitsList,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/settings/assign`,
      SettingsIdAndUnitsList,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create a new Unit Settings file
   *
   * @param UnitSettingsTemplate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createUnitSettings(
    UnitSettingsTemplate?: UnitSettingsTemplate,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public createUnitSettings(
    UnitSettingsTemplate?: UnitSettingsTemplate,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public createUnitSettings(
    UnitSettingsTemplate?: UnitSettingsTemplate,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public createUnitSettings(
    UnitSettingsTemplate?: UnitSettingsTemplate,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.configuration.basePath}/settings`,
      UnitSettingsTemplate,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deletes some Unit Settings files
   *
   * @param settingIds
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteUnitSettings(
    settingIds: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteUnitSettings(
    settingIds: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteUnitSettings(
    settingIds: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteUnitSettings(
    settingIds: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (settingIds === null || settingIds === undefined) {
      throw new Error(
        "Required parameter settingIds was null or undefined when calling deleteUnitSettings."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (settingIds) {
      settingIds.forEach(element => {
        queryParameters = queryParameters.append("settingIds", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/settings`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Duplicate a single unit settings
   *
   * @param settingsId Id of the unit settings
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public duplicateUnitSettingsItem(
    settingsId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public duplicateUnitSettingsItem(
    settingsId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public duplicateUnitSettingsItem(
    settingsId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public duplicateUnitSettingsItem(
    settingsId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (settingsId === null || settingsId === undefined) {
      throw new Error(
        "Required parameter settingsId was null or undefined when calling duplicateUnitSettingsItem."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/settings/${encodeURIComponent(
        String(settingsId)
      )}/duplicate`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit a single unit settings item
   *
   * @param settingsId Id of the unit settings
   * @param UnitSettingsTemplate
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editUnitSettingsItem(
    settingsId: string,
    UnitSettingsTemplate?: UnitSettingsTemplate,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editUnitSettingsItem(
    settingsId: string,
    UnitSettingsTemplate?: UnitSettingsTemplate,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editUnitSettingsItem(
    settingsId: string,
    UnitSettingsTemplate?: UnitSettingsTemplate,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editUnitSettingsItem(
    settingsId: string,
    UnitSettingsTemplate?: UnitSettingsTemplate,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (settingsId === null || settingsId === undefined) {
      throw new Error(
        "Required parameter settingsId was null or undefined when calling editUnitSettingsItem."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/settings/${encodeURIComponent(
        String(settingsId)
      )}`,
      UnitSettingsTemplate,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get IANA Timezones
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getIANATimezones(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<string>>;
  public getIANATimezones(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<string>>>;
  public getIANATimezones(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<string>>>;
  public getIANATimezones(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<string>>(
      `${this.configuration.basePath}/settings/iana`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the available clean types for a given unit
   *
   */
  public getCleanTypesForUnitType(unitType: string): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(
      `${this.configuration.basePath}/settings/clean-types?unitType=${unitType}`
    );
  }

  /**
   * Get a single unit settings item
   *
   * @param settingsId Id of the unit settings
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitSettingsItem(
    settingsId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnitSettingsTemplate>;
  public getUnitSettingsItem(
    settingsId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UnitSettingsTemplate>>;
  public getUnitSettingsItem(
    settingsId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UnitSettingsTemplate>>;
  public getUnitSettingsItem(
    settingsId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (settingsId === null || settingsId === undefined) {
      throw new Error(
        "Required parameter settingsId was null or undefined when calling getUnitSettingsItem."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnitSettingsTemplate>(
      `${this.configuration.basePath}/settings/${encodeURIComponent(
        String(settingsId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get List of all available Unit Settings templates (filtrable by unit type and name)
   *
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $filter Filter items by property values, see [OData Filtering](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionfilter)
   * @param $orderby Order items by property values, see [OData Sorting](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionorderby)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitSettingsList(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnitSettingsList>;
  public getUnitSettingsList(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UnitSettingsList>>;
  public getUnitSettingsList(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UnitSettingsList>>;
  public getUnitSettingsList(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = queryParameters.set("$filter", <any>$filter);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append("$orderby", <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnitSettingsList>(
      `${this.configuration.basePath}/settings`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get status for Unit Settings
   * Get the list of sync status of a recipe library. The list is filtrable by actionType, statusId
   * @param statusId Id of the status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitSettingsStatus(
    statusId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnitSettingsSyncStatus>;
  public getUnitSettingsStatus(
    statusId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UnitSettingsSyncStatus>>;
  public getUnitSettingsStatus(
    statusId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UnitSettingsSyncStatus>>;
  public getUnitSettingsStatus(
    statusId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (statusId === null || statusId === undefined) {
      throw new Error(
        "Required parameter statusId was null or undefined when calling getUnitSettingsStatus."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnitSettingsSyncStatus>(
      `${this.configuration.basePath}/settings/status/${encodeURIComponent(
        String(statusId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of status for Unit Settings
   * Get the list of sync status of a recipe library. The list is filtrable by actionType, statusId
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $filter Filter items by property values, see [OData Filtering](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionfilter)
   * @param $orderby Order items by property values, see [OData Sorting](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionorderby)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitSettingsStatusList(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnitSettingsStatusList>;
  public getUnitSettingsStatusList(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UnitSettingsStatusList>>;
  public getUnitSettingsStatusList(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UnitSettingsStatusList>>;
  public getUnitSettingsStatusList(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = queryParameters.set("$filter", <any>$filter);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append("$orderby", <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnitSettingsStatusList>(
      `${this.configuration.basePath}/settings/status`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Reset unit settings of some units
   *
   * @param request_body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public restoreUnitSettings(
    request_body?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public restoreUnitSettings(
    request_body?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public restoreUnitSettings(
    request_body?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public restoreUnitSettings(
    request_body?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/settings/restore`,
      request_body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getSettingsActionLog(
    top: number = 10,
    skip: number = 0,
    orderby?: string[],
    actionType?: UnitSettingsActionType
  ): Observable<UnitSettingsStatusList> {
    let queryParameters = new HttpParams()
      .set("$top", "" + top)
      .set("$skip", "" + skip);
    if (orderby && orderby.length) {
      queryParameters = queryParameters.set("$orderby", orderby[0]);
    }
    if (actionType) {
      queryParameters = queryParameters.set("actionType", actionType);
    }

    return this.httpClient.get<UnitSettingsStatusList>("/settings/status", {
      params: queryParameters,
      responseType: "json"
    });
  }

  public importSettings(settings: UnitSettingsTemplate) {
    return this.httpClient.post<any>(
      `${this.configuration.basePath}/settings/import`,
      settings
    );
  }
}
