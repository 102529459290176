import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

@Component({
  selector: "app-submit-button",
  templateUrl: "./submit-button.component.html",
  styleUrls: ["./submit-button.component.scss"]
})
export class SubmitButtonComponent implements OnInit {
  constructor() {}

  @Input() loading: boolean
  @Input() label: string
  @Input() formId: string
  @Input() buttonClass = "btn btn-success"
  @Input() disabled = false
  @Output() customClick = new EventEmitter()

  ngOnInit() {}

  clickEvent(event: any) {
    if (!this.disabled) {
      this.customClick.emit(event)
    }
  }
}
