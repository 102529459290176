import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from "@angular/core"
import { FormControl } from "@angular/forms"
import { NavigationEnd, Router } from "@angular/router"
import { RemoteSupportService } from "@app/shared/services"
import { RemoteSupportUIService } from "@app/shared/services/remote-support-ui.service"
import { CompaniesService, ProfileService, UsersService } from "src/app/api"
import { User, UserWithAttributes } from "src/app/api/model/user/user"
import { AuthenticationService } from "src/app/core/authentication.service"
import { KeycloakImpersonateService } from "src/app/core/keycloak-impersonate.service"
import { LayoutService } from "src/app/core/layout.service"
import { MessageService } from "src/app/core/message.service"
import { PermissionService } from "src/app/core/permission.service"
import { checkForCookie } from "src/app/core/utils"
import { LocalStorageKey } from "src/app/models/enums/local-storage"

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
  providers: [RemoteSupportUIService, RemoteSupportService]
})
export class LayoutComponent implements OnInit, OnDestroy {
  public mobileMq = window.matchMedia("(max-width: 768px)")
  public path: string[]
  public avatarURL: string | undefined
  public companies: any[]
  public companySelectionForm: FormControl
  public isImpersonating = false
  public selectedCompanyName: string
  public technicianHasName = false
  public canImpersonate = false
  private defaultLibraryName = "Company"

  @ViewChild("companyMenuBtn", { static: true }) companyMenuBtn: ElementRef

  public get sidebarCollapsed() {
    return this._sidebarCollapsed
  }

  public set sidebarCollapsed(value) {
    this._sidebarCollapsed = value
    setTimeout(() => {
      this.layoutService.sidebarToggled.next()
    }, 500)
  }

  private _sidebarCollapsed = true

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private profileService: ProfileService,
    private layoutService: LayoutService,
    private message: MessageService,
    private rsUIService: RemoteSupportUIService<User>,
    public auth: PermissionService,
    public companiesService: CompaniesService,
    public keycloakImpersonate: KeycloakImpersonateService
  ) {
    // Check window size and cookie, then navigate to mobile vision if small
    if (
      window.innerWidth < 900 &&
      checkForCookie("mobileFullVersion") !== "true"
    ) {
      this.router.navigateByUrl("mobile-vision")
    }
    this.companies = []
    this.companiesService.getCompanies().subscribe(data => {
      // Fetch user company form storage
      const userCompany = localStorage.getItem(LocalStorageKey.userCompany)
      // Filter companies to NOT include the user's company
      this.companies = data.filter(
        company => company.companyId !== userCompany
      )
      // If there is 1 or more companies in the list allow user to impersonate, and check active company id
      if (this.companies.length > 0) {
        this.canImpersonate = true
        // Fetch user's active company id from storage
        const activeCompanyId = localStorage.getItem(
          LocalStorageKey.activeCompany
        )

        // If the id is NOT undefined, null, or empty then find the company in the list and set impersonating view
        if (activeCompanyId) {
          const activeCompany = this.companies.find(
            company => company.companyId === activeCompanyId
          )
          if (activeCompany) {
            this.isImpersonating = true
            this.selectedCompanyName = activeCompany.name
          } else {
            // If active company id doesn't exist in the authorized list
            // then clear the users active company attribute in cognito
            this.authService.changeActiveCompany("")
            this.selectedCompanyName = this.defaultLibraryName
          }
        }
      } else {
        const company = data.find(c => c.companyId === userCompany)
        this.selectedCompanyName =
          company === undefined ? this.defaultLibraryName : company.name
      }
    })
  }

  ngOnInit() {
    this.mobileMq.addListener(this.handleMqChange)
    this.handleMqChange()
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.sidebarCollapsed = true
      }
    })
    this.profileService
      .getUserWithAttributes()
      .subscribe((user: UserWithAttributes) => {
        this.avatarURL = user.attributes.avatarUrl || ""
      })
    // this.userService
    //   .getUser()
    //   .subscribe((user: MyKeycloakProfile | undefined) => {
    //     if (this.userService.getRoles()!.technician) {
    //       this.technicianHasName = user!.firstName !== ""
    //     }
    //     const roles = this.userService.getRoles()
    //     if (
    //       user &&
    //       user.attributes.location_ids &&
    //       this.auth.permissions.group_view
    //     ) {
    //       this.path = ["/locations", user.attributes.location_ids[0]]
    //     } else {
    //     }
    //   })
    this.path = ["/locations"]
  }

  ngOnDestroy() {
    this.mobileMq.removeListener(this.handleMqChange)
  }

  handleMqChange = () => {
    if (this.mobileMq.matches) {
      this.sidebarCollapsed = true
    }
  };

  toggleCollapse() {
    this.sidebarCollapsed = !this.sidebarCollapsed
  }

  startImpersonation(company: any) {
    // Close the dropdown
    this.companyMenuBtn.nativeElement.click()
    this.selectedCompanyName = ""
    if (company && company !== "") {
      this.selectedCompanyName = company.name
      this.authService.changeActiveCompany(company.companyId).then(() => {
        this.isImpersonating = true
        this.refresh()
      })
    }
  }

  stopImpersonation() {
    this.authService.changeActiveCompany("").then(() => {
      this.selectedCompanyName = this.defaultLibraryName
      this.isImpersonating = false
      this.refresh()
    })
  }

  refresh() {
    this.router.onSameUrlNavigation = "reload"
    this.router.navigate([""])
    if (this.router.url === "/overview") {
      window.location.reload()
    }
  }

  logout() {
    this.authService.signOutAndNavigate()
  }

  openUserSettings() {
    this.router.navigate(["/userProfile", "settings"])
  }
}
