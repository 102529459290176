import { CanvasPoint, CanvasRect } from "../models/canvas/canvas-elements"

export namespace CanvasUtil {
  export const DEFAULT_PEN_WIDTH = 5
  export const DEFAULT_PEN_COLOR = "red"
  export const DEFAULT_ERASER_WIDTH = 25
  // export const DEFAULT_ERASER_COLOR = "black"
  export const DEFAULT_ERASER_COLOR = "rgb(3, 160, 98)"
  export const DEFAULT_LINE_CAP = "round"

  export const DESTINATION_OUT = "destination-out"
  export const SOURCE_OVER = "source-over"

  export function drawLine(
    begin: CanvasPoint,
    end: CanvasPoint,
    context: CanvasRenderingContext2D
  ) {
    // incase the context is not set
    if (!context) {
      return
    }

    // we're drawing lines so we need a previous position
    if (begin) {
      // start our drawing path
      context.beginPath()

      // sets the start point
      context.moveTo(begin.x, begin.y) // from

      // draws a line from the start pos until the current position
      context.lineTo(end.x, end.y)

      // strokes the current path with the styles we set earlier
      context.stroke()

      // console.log(
      //   "drawOnCanvas: " +
      //     begin.x +
      //     "," +
      //     begin.y +
      //     " -> " +
      //     end.x +
      //     "," +
      //     end.y
      // )
    }
  }

  export function clearCanvas(
    rect: CanvasRect,
    context: CanvasRenderingContext2D
  ) {
    if (!context || !rect) {
      return
    }

    context.clearRect(rect.left, rect.top, rect.width, rect.height)
  }
}
