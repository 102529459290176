import { DomSanitizer, SafeUrl } from "@angular/platform-browser"
import { FileUtil } from "@app/shared/utils"

export enum MediaFileCategory {
  unknown = "Unknown",
  sent = "Sent",
  received = "Received",
  capture = "Captured"
}

export interface AssetWrapperInterface<AssetType> {
  /**
   * The asset associated with this metadata
   */
  asset: AssetType
  /**
   * Flag indicating an asset's use in one or more messages
   */
  inMessage: boolean
  /**
   * Flag indicating an asset's presence in the media view
   */
  inMedia: boolean
}

/**
 * A representation of a media asset associated with a session,
 * containing flags for its presence in media or messages
 */
export class AssetWrapper<T extends MediaFileMetadata | string>
  implements AssetWrapperInterface<T> {
  constructor(asset: T, inMessage: boolean = false, inMedia: boolean = false) {
    this.asset = asset
    this.inMessage = inMessage
    this.inMedia = inMedia

    const name =
      asset instanceof MediaFileMetadata ? asset.fileName : (asset as string)
    this.id = `${inMessage ? "Mes.-" : ""}${name}${inMedia ? "-Med." : ""}`
  }
  /**
   * The asset associated with this, being some variant of {@link MediaFileMetadata}
   */
  asset: T
  inMessage: boolean
  inMedia: boolean
  /**
   * A unique identifier for this asset, used for things like selection handlers
   */
  id: string

  /**
   * Replaces the object being referenced in the asset.
   * Cleans up any {@link MediaFile}s being dereferenced.
   * @param asset The new object referenced by the asset
   */
  replaceAsset(asset: T) {
    if (
      asset instanceof MediaFileMetadata &&
      this.asset instanceof MediaFileMetadata
    ) {
      if (this.asset instanceof MediaFile) {
        this.asset.cleanup()
      }
      if (this.asset.timestamp.valueOf() !== asset.timestamp.valueOf()) {
        console.warn(
          `replaceAsset: timestamps do not match: ${this.asset.timestamp.valueOf()} -> ${asset.timestamp.valueOf()}`
        )
      }
      this.asset = asset
      this.id = `${this.inMessage ? "Mes.-" : ""}${asset.fileName}${
        this.inMedia ? "-Med." : ""
      }`
    } else {
      this.asset = asset
      this.id = `${this.inMessage ? "Mes.-" : ""}${asset}${
        this.inMedia ? "-Med." : ""
      }`
    }
  }

  /**
   * Ensures that the file referenced does not retain blob references that could cause memory leaks.
   */
  cleanupAsset() {
    if (this.asset instanceof MediaFile) {
      this.asset.cleanup()
    }
  }
}

export enum MediaFileDisplayStyle {
  // Full and stable display styles
  fullImage = 0,
  fullVideo = 1,
  fullUnknown = 2,
  movCompatibility = 3,
  basic = 4,
  // Incomplete display styles
  unarchived = 10,
  loading = 11,
  loadingProgress = 12, // Reserved for if partial message events fire upon all chunk receipts
  // Error display styles
  fetchError = 20,
  supportedError = 21,
  parseError = 22
}

/**
 * A representation of a media file involved in a session,
 * including those in the process of being received or downloaded.
 * Child classes include {@link PartialMediaFileMetadata}, {@link ArchivedMediaFileMetadata}, and {@link MediaFile}
 */
export abstract class MediaFileMetadata {
  constructor(
    type: string,
    category: MediaFileCategory,
    ts: Date,
    edited: boolean
  ) {
    this.type = type
    this.category = category
    this.timestamp = ts
    this.edited = edited
  }

  type: string
  category: MediaFileCategory
  timestamp: Date
  edited: boolean

  /* Static methods */
  /**
   * Strips a multi-layer path to a file down to the file name
   * @param path A path with separators of "/" or "~" to strip
   * @returns A string containing the final layer of the path (expected to be a file name and extension)
   */
  static pathToFile(path: string): string {
    return path.split(/\/|~/).reverse()[0]
  }

  /**
   * Generates core information about a {@link MediaFileMetadata} that can be determined from its file name
   * @param fileName A string representation of a suitably-formatted file name
   * @returns type - String representation of the MIME type of the file (if an extension is included) or a blank string
   *
   * category - {@link MediaFileCategory} indicating the category of the file
   *
   * timestamp - {@link Date} indicating when the message was created
   *
   * edited - Flag indicating if the file has been edited since its creation
   */
  static parseFileName(
    fileName: string
  ): {
    type: string;
    category: MediaFileCategory;
    timestamp: Date;
    edited: boolean;
  } {
    const type = fileName.includes(".") ? FileUtil.getMimeType(fileName) : ""
    const ts = this.fileNameToDate(fileName)
    const edited = fileName.includes("-E.")

    let category: MediaFileCategory
    switch (fileName.split("-")[0]) {
      case MediaFileCategory.sent.toLowerCase().replace(/ /g, "_"):
        category = MediaFileCategory.sent
        break
      case MediaFileCategory.received.toLowerCase().replace(/ /g, "_"):
        category = MediaFileCategory.received
        break
      case MediaFileCategory.capture.toLowerCase().replace(/ /g, "_"):
      case "Recording":
      case "Screenshot":
        // Legacy compatibility included
        category = MediaFileCategory.capture
        break
      default:
        category = MediaFileCategory.unknown
        break
    }

    return { type: type, category: category, timestamp: ts, edited: edited }
  }

  /**
   * Extracts date information from a suitably-formatted {@link MediaFileMetadata} file name
   * @param fileName The file name to process
   * @returns The date associated with the file name
   */
  static fileNameToDate(fileName: string): Date {
    let cleaned = fileName
      .substring(
        fileName.search(
          /\d{4}-[01]\d-[0-3]\dT[0-2]\d-[0-5]\d-[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/
        )
      )
      .substring(0, 24)

    const dateSections = cleaned.split("-")
    if (dateSections.length === 5) {
      cleaned = `${dateSections[0]}-${dateSections[1]}-${dateSections[2]}:${
        dateSections[3]
      }:${dateSections[4]}`
    } else {
      console.warn(
        `MediaFileMetadata: Invalid date format, got sections ${dateSections}`
      )
    }
    return new Date(cleaned)
  }

  /* Instance methods */

  getDisplayStyle(): MediaFileDisplayStyle {
    if (this instanceof MediaFile) {
      switch (this.renderIssue) {
        case "error":
          return MediaFileDisplayStyle.parseError
        case "mov":
          return MediaFileDisplayStyle.movCompatibility
        case "unsupported":
          return MediaFileDisplayStyle.supportedError
        case false:
          switch (this.type.split("/")[0]) {
            case "image":
              return MediaFileDisplayStyle.fullImage
            case "video":
              return MediaFileDisplayStyle.fullVideo
            default:
              return MediaFileDisplayStyle.fullUnknown
          }
      }
    } else if (this instanceof ArchivedMediaFileMetadata) {
      switch (this.archived) {
        case false:
          return MediaFileDisplayStyle.unarchived
        case "unfetched":
        case "fetching":
          return MediaFileDisplayStyle.loading
        case "fetchError":
          return MediaFileDisplayStyle.fetchError
      }
    } else if (this instanceof PartialMediaFileMetadata) {
      return MediaFileDisplayStyle.loading // This may become loadingProgress if onPartialMessage implementation changes
    }
    return MediaFileDisplayStyle.basic
  }

  // Basic file name generator, not including extension to ensure partial message support
  get fileName(): string {
    const prefix = this.category.toLowerCase().replace(/ /g, "_")
    return `${prefix}-${this.timestamp.toISOString().replace(/:/g, "-")}${
      this.edited ? "-E" : ""
    }`
  }

  getDisplayName(): string {
    // At some point this will need to support translations
    if (this.category === MediaFileCategory.unknown) {
      return "Unknown File"
    }
    const prefix =
      this.category + (this.type.startsWith("image") ? " image" : " video")
    return `${prefix}: ${this.timestamp.toLocaleString()}`
  }
}

export class PartialMediaFileMetadata extends MediaFileMetadata {
  constructor(
    type: string,
    category: MediaFileCategory,
    ts: Date,
    id: number,
    receivedChunks: number = 0,
    totalChunks: number = 1,
    edited: boolean = false
  ) {
    super(type, category, ts, edited)
    this.receivedChunks = receivedChunks
    this.totalChunks = totalChunks
    this.id = id
  }
  receivedChunks: number
  totalChunks: number
  /**
   * As PMFM objects are only generated by onPartialMessage events and need to be updated if multiple messages are received,
   * the msgid of the source notification is included to allow matching to future events.
   */
  id: number

  update(received: number) {
    this.receivedChunks = received
  }
}

export class ArchivedMediaFileMetadata extends MediaFileMetadata {
  constructor(
    fileName: string,
    archived: false | "unfetched" | "fetching" | "fetchError" = "fetching"
  ) {
    const metadata = MediaFileMetadata.parseFileName(fileName)
    super(
      metadata.type,
      metadata.category,
      metadata.timestamp,
      metadata.edited
    )

    this.archived = archived
  }

  archived: false | "unfetched" | "fetching" | "fetchError"

  get fileName(): string {
    return `${super.fileName}${FileUtil.getExtFromMimeType(this.type)}`
  }

  toMediaFile(
    sanitizer: DomSanitizer,
    data: ArrayBuffer | Uint8Array
  ): MediaFile {
    return new MediaFile(
      sanitizer,
      this.type,
      data,
      this.category,
      this.timestamp,
      this.edited
    )
  }
}

export class MediaFile extends MediaFileMetadata {
  constructor(
    sanitizer: DomSanitizer,
    type: string,
    data: ArrayBuffer | Uint8Array,
    category: MediaFileCategory,
    ts: Date,
    edited: boolean = false,
    renderIssue: false | "error" | "unsupported" | "mov" = false
  ) {
    super(type, category, ts, edited)
    this.data = data
    const blob = new Blob([data], {
      type: type
    })
    this.url = URL.createObjectURL(blob)
    this.safeUrl = sanitizer.bypassSecurityTrustUrl(this.url)

    this.renderIssue = renderIssue
  }

  data: ArrayBuffer | Uint8Array
  url: string
  safeUrl: SafeUrl
  renderIssue: false | "error" | "unsupported" | "mov"

  /* Static methods */

  static generateFile(
    url: string,
    data: Uint8Array,
    sanitizer: DomSanitizer
  ): MediaFile {
    const levels = url.split(/\/|~/)
    const fileName = levels[2]
    const metadata = super.parseFileName(fileName)

    return new MediaFile(
      sanitizer,
      metadata.type,
      data,
      metadata.category,
      metadata.timestamp,
      metadata.edited
    )
  }

  /* Instance methods */

  get fileName(): string {
    return `${super.fileName}${FileUtil.getExtFromMimeType(this.type)}`
  }

  cloneFile(
    sanitizer: DomSanitizer,
    changes?: {
      type?: string;
      data?: ArrayBuffer | Uint8Array;
      category?: MediaFileCategory;
      timestamp?: Date;
      edited?: boolean;
      renderIssue?: false | "error" | "unsupported" | "mov";
    }
  ) {
    if (changes) {
      const type = changes.type ? changes.type : this.type
      const data = changes.data ? changes.data : this.data
      const category = changes.category ? changes.category : this.category
      const timestamp = changes.timestamp ? changes.timestamp : this.timestamp
      const edited =
        changes.edited !== undefined ? changes.edited : this.edited
      const renderIssue =
        changes.renderIssue !== undefined
          ? changes.renderIssue
          : this.renderIssue
      return new MediaFile(
        sanitizer,
        type,
        data,
        category,
        timestamp,
        edited,
        renderIssue
      )
    } else {
      return new MediaFile(
        sanitizer,
        this.type,
        this.data,
        this.category,
        this.timestamp,
        this.edited,
        this.renderIssue
      )
    }
  }

  update(
    sanitizer: DomSanitizer,
    changes?: {
      type?: string;
      data?: ArrayBuffer | Uint8Array;
      category?: MediaFileCategory;
      timestamp?: Date;
      edited?: boolean;
      renderIssue?: false | "error" | "unsupported" | "mov";
    }
  ) {
    if (changes) {
      if (changes.type) {
        this.type = changes.type
      }
      if (changes.data) {
        this.data = changes.data
        this.cleanup()
        const blob = new Blob([this.data], {
          type: this.type
        })
        this.url = URL.createObjectURL(blob)
        this.safeUrl = sanitizer.bypassSecurityTrustUrl(this.url)
      }
      if (changes.category) {
        this.category = changes.category
      }
      if (changes.timestamp) {
        this.timestamp = changes.timestamp
      }
      if (changes.edited !== undefined) {
        this.edited = changes.edited
      }
      if (changes.renderIssue !== undefined) {
        this.renderIssue = changes.renderIssue
      }
    }
  }

  cleanup() {
    URL.revokeObjectURL(this.url)
  }
}

export interface HistoryElement {
  type: "Draw" | "Clear"
  elements: SVGGeometryElement | Array<SVGGeometryElement>
}

export class HistoryList {
  index: number // Points to the next index to be returned on undo, redo will return index + 1
  history: Array<HistoryElement>

  constructor() {
    this.index = -1
    this.history = new Array<HistoryElement>()
  }

  undo(): HistoryElement | undefined {
    console.log(
      `HistoryList: undo called.  Index is ${this.index}, history length is ${
        this.history.length
      }`
    )
    if (this.index < 0) {
      return undefined
    }
    this.index--
    return this.history[this.index + 1]
  }

  redo(): HistoryElement | undefined {
    console.log(
      `HistoryList: redo called.  Index is ${this.index}, history length is ${
        this.history.length
      }`
    )
    if (this.index + 1 >= this.history.length) {
      return undefined
    }
    this.index++
    return this.history[this.index]
  }

  add(histElement: HistoryElement) {
    console.log("HistoryList: element added")
    // If history is not at the end, chop out old values
    if (this.index < this.history.length - 1) {
      this.history = this.history.slice(undefined, this.index + 1)
    }
    this.index++
    this.history.push(histElement)
    console.log(
      `HistoryList: index is ${this.index}, new element has type ${
        histElement.type
      } and element(s) of type ${typeof histElement.elements}`
    )
  }

  canUndo(): boolean {
    return this.index >= 0
  }

  canRedo(): boolean {
    return this.history.length > 0 && this.index < this.history.length - 1
  }
}

export type HTMLAndSVGElement = HTMLElement & SVGElement
