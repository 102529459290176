import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormControl } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import { DateTime } from "luxon"
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker"
import { getLastWeek, getPrevMonth, getThisMonth, getToday } from "./utils"

interface DatePickerRange {
  label: string
  value: Date[]
}

@Component({
  selector: "app-custom-date-picker",
  templateUrl: "./custom-date-picker.component.html",
  styleUrls: ["./custom-date-picker.component.scss"]
})
export class CustomDatePickerComponent implements OnInit {
  @Input() rangeFormControl: FormControl
  @Input() showRanges = true
  @Input() placement = "bottom right"
  @Input() maxDeltaDays = -1 // maximum number of days after the start date that can be chosen
  @Input() singleDateSelect = false
  @Output() dateRangeSelected: EventEmitter<Date[]> = new EventEmitter<
    Date[]
  >()

  maxDate: Date = new Date()
  datePicker: any
  date: any
  datePickerConfig: Partial<BsDatepickerConfig>

  constructor(
    private ts: TranslateService,
    private localeService: BsLocaleService
  ) {}

  ngOnInit() {
    const ranges: DatePickerRange[] = this.showRanges
      ? [
          {
            label: this.ts.instant("GLOBAL.TODAY"),
            value: getToday()
          },
          {
            label: this.ts.instant("GLOBAL.LAST_7_DAYS"),
            value: getLastWeek()
          },
          {
            label: this.ts.instant("GLOBAL.THIS_MONTH"),
            value: getThisMonth()
          },
          {
            label: this.ts.instant("GLOBAL.PREVIOUS_MONTH"),
            value: getPrevMonth()
          }
        ]
      : []

    this.datePickerConfig = {
      containerClass: "theme-blue",
      showWeekNumbers: false,
      ranges,
      returnFocusToInput: false
    }

    this.rangeFormControl.valueChanges.subscribe(() => {
      // Unfortunately there doesn't seem to be a way to limit the end date
      // based on the value of the start date, so this will have to do.
      if (this.singleDateSelect) {
        this.dateRangeSelected.emit([
          this.rangeFormControl.value,
          this.rangeFormControl.value
        ])
        return
      }

      const maxEnd = this.getMaxEndDate(this.rangeFormControl.value[0])

      if (maxEnd) {
        if (maxEnd > new Date()) {
          // If the calculated max end date based on the start date + delta
          // is more than the allowed overall maximum end date, change the
          // selected end date to the max end date
          this.rangeFormControl.value[1] = new Date()
          this.rangeFormControl.setValue(this.rangeFormControl.value)
          return
        } else if (maxEnd < this.rangeFormControl.value[1]) {
          // Limit the date range to the start date + delta
          this.rangeFormControl.value[1] = maxEnd
          this.rangeFormControl.setValue(this.rangeFormControl.value)
          // Changing the form control value causes `valueChanges` to be raised
          // again with the new value, so we'll just short-circuit here
          return
        }
      }

      // Notify the parent component that a date range was selected
      this.dateRangeSelected.emit(this.rangeFormControl.value)
    })
  }

  private getMaxEndDate(startDate: Date): Date | undefined {
    if (this.maxDeltaDays > 0) {
      const start: DateTime = DateTime.fromJSDate(startDate)
      return start.plus({ days: this.maxDeltaDays }).toJSDate()
    }
  }
}
