import { Component, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { BsModalRef } from "ngx-bootstrap/modal"
import { StageType } from "src/app/api"
import { AuthenticationService } from "src/app/core/authentication.service"
import { GenericEditStepModalComponent } from "../generic-edit-step-modal-component"

const numberRangeToArray = (
  min: number,
  max: number,
  step: number
): Array<number> => {
  const length = Math.ceil((max - min) / step) + 1
  const values: Array<number> = new Array(length)
    .fill(0)
    .map((x, i) => i * step + min)
  return values
}

@Component({
  selector: "app-edit-steam-modal",
  templateUrl: "./edit-steam-modal.component.html",
  styleUrls: ["./edit-steam-modal.component.scss"]
})
export class EditSteamModalComponent extends GenericEditStepModalComponent
  implements OnInit {
  hasProbeCooking = true

  cookingModeFormControl = new FormControl()

  public fanSpeedOptions: Array<number>

  get timeSetpointControl(): FormControl {
    return this.formGroup.controls["timeSetpoint"] as FormControl
  }

  get probeSetpointControl(): FormControl {
    return this.formGroup.controls["probeSetpoint"] as FormControl
  }

  // Steam (aka humidity) is set to 100% permanently for the steam option, and the user cannot change it.
  get humidityControl(): FormControl {
    return this.formGroup.controls["humidity"] as FormControl
  }

  constructor(modalRef: BsModalRef, authService: AuthenticationService) {
    super(modalRef, authService)
    this.cookingModeFormControl.valueChanges.subscribe(
      (val: "probe" | "time") => {
        if (val === "time") {
          this.probeSetpointControl.setValue(-1)

          if (this.timeSetpointControl.value === -1) {
            this.timeSetpointControl.setValue(
              this.stageNRanges.timeSetpoint.default
            )
          }
        } else {
          setTimeout(() => {
            this.timeSetpointControl.setValue(-1)
          }, 0)

          if (this.probeSetpointControl.value === -1) {
            this.probeSetpointControl.setValue(
              this.stageNRanges.probeSetpoint.default
            )
          }
        }
      }
    )
  }

  ngOnInit() {
    this.stageType = StageType.steam
    super.ngOnInit()
    if (this.timeSetpointControl.value === -1) {
      this.cookingModeFormControl.setValue("probe")
    } else {
      this.cookingModeFormControl.setValue("time")
    }

    this.fanSpeedOptions = numberRangeToArray(
      this.stageNRanges.fanSpeed.min,
      this.stageNRanges.fanSpeed.max,
      this.stageNRanges.fanSpeed.step
    )
  }
}
