export * from './alerts.service';
import { AlertsService } from './alerts.service';
export * from './companies.service';
import { CompaniesService } from './companies.service';
export * from './groups.service';
import { GroupsService } from './groups.service';
export * from './locations.service';
import { LocationsService } from './locations.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './recipe.service';
import { RecipeService } from './recipe.service';
export * from './reports.service';
import { ReportsService } from './reports.service';
export * from './shares.service';
import { SharesService } from './shares.service';
export * from './unitSettings.service';
import { UnitSettingsService } from './unitSettings.service';
export * from './units.service';
import { UnitsService } from './units.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [
  AlertsService,
  CompaniesService,
  GroupsService,
  LocationsService,
  ProfileService,
  RecipeService,
  ReportsService,
  SharesService,
  UnitSettingsService,
  UnitsService,
  UsersService
];
