/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 0 = °C, 1 = °F
 */
export type UnitSettingsTempUnitEnum = 0 | 1;

export const UnitSettingsTempUnitEnum = {
  NUMBER_0: 0 as UnitSettingsTempUnitEnum,
  NUMBER_1: 1 as UnitSettingsTempUnitEnum
};
