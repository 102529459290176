import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuardService } from "./core/auth-guard.service"
import { _ } from "./core/translation-marker"
import { DashboardGuard } from "./dashboard-guard.guard"
import { GlobalLibraryGuard } from "./global-library/global-library.guard"
import { LayoutComponent } from "./layout/layout-component/layout.component"
import { SignupLayoutComponentComponent } from "./layout/signup-layout-component/signup-layout-component.component"
import { NotFoundComponent } from "./not-found/not-found.component"
import { OnboardingGuard } from "./onboarding/onboarding.guard"
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component"
import { SignInComponent } from "./sign-in/sign-in/sign-in.component"
import { SupportComponent } from "./support/support.component"
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component"

export const routes: Routes = [
  {
    path: "signup",
    component: SignupLayoutComponentComponent,
    data: {
      title: "Register Page"
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./onboarding/onboarding.module").then(m => m.OnboardingModule)
      }
    ]
  },
  {
    path: "mobile-vision",
    loadChildren: () =>
      import("./mobile-vision/mobile-vision.module").then(
        m => m.MobileVisionModule
      ),
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService]
  },
  {
    path: "auth",
    component: SignInComponent
  },
  {
    path: "cygnus-client",
    loadChildren: () =>
      import("./remote-support-client/remote-support-client.module").then(
        m => m.RemoteSupportClientModule
      )
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [OnboardingGuard, AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: "",
        canActivate: [DashboardGuard],
        children: []
      },
      {
        path: "admin",
        loadChildren: () =>
          import("./admin/admin.module").then(m => m.AdminModule),
        data: {
          canAccess: ["company_admin", "group_admin", "location_admin"],
          title: _("ADMINISTRATION.TITLE")
        }
      },
      {
        path: "overview",
        loadChildren: () =>
          import("./overview/overview.module").then(m => m.OverviewModule),
        data: {
          title: _("OVERVIEW.TITLE")
        }
      },
      {
        path: "locations",
        loadChildren: () =>
          import("./location/location.module").then(m => m.LocationModule),
        data: {
          title: _("LOCATIONS.TITLE")
        }
      },
      {
        path: "unitSettings",
        loadChildren: () =>
          import("./unit-settings/unit-settings.module").then(
            m => m.UnitSettingsModule
          ),
        data: {
          title: _("UNIT_SETTINGS.TITLE")
        }
      },
      {
        path: "units",
        loadChildren: () => import("./unit/unit.module").then(m => m.UnitModule)
      },
      {
        path: "alerts",
        loadChildren: () =>
          import("./alerts/alerts.module").then(m => m.AlertsModule)
      },
      {
        path: "report",
        data: {
          title: _("REPORT.TITLE")
        },
        loadChildren: () =>
          import("./report/report.module").then(m => m.ReportModule)
      },
      {
        path: "remote-support",
        data: {
          canAccess: ["company_admin", "group_admin", "location_admin"],
          title: _("REMOTE_SUPPORT.TITLE_GENERAL")
        },
        loadChildren: () =>
          import("./remote-support/remote-support.module").then(
            m => m.RemoteSupportModule
          )
      },
      {
        path: "recipeLibrary",
        data: {
          title: _("RECIPE_LIBRARY.TITLE")
        },
        loadChildren: () =>
          import("./recipe-library/recipe-library.module").then(
            m => m.RecipeLibraryModule
          )
      },
      {
        path: "userProfile",
        data: {
          title: _("PROFILE.TITLE")
        },
        loadChildren: () =>
          import("./profile/profile.module").then(m => m.ProfileModule)
      },
      {
        path: "channel",
        loadChildren: () =>
          import("./channel/channel.module").then(m => m.ChannelModule)
      },
      {
        path: "technician-dashboard",
        loadChildren: () =>
          import("./technician-dashboard/technician-dashboard.module").then(
            m => m.TechnicianDashboardModule
          ),
        data: {
          title: _("TECHNICIAN_DASHBOARD.TITLE")
        }
      },
      {
        path: "global-library",
        canActivate: [GlobalLibraryGuard],
        loadChildren: () =>
          import("./global-library/global-library.module").then(
            m => m.GlobalLibraryModule
          ),
        data: {
          title: _("RECIPE_LIBRARY.GLOBAL_LIBRARY")
        }
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent
      },
      {
        path: "terms-of-use",
        component: TermsOfUseComponent
      },
      {
        path: "support",
        component: SupportComponent
      },
      {
        path: "**",
        component: NotFoundComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})
export class RoutingModule {}
