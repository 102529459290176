import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dbUnit'
})
export class DbUnitPipe implements PipeTransform {
  transform(value: string): any {
    return `${value} dB`;
  }
}
