import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { RecipeService } from 'src/app/api';

export interface RecipeDuplicateResult {
  outcome: 'goToEdit' | 'saveAsCopy';
  newId: string;
}

@Component({
  selector: 'app-duplicate-recipe-modal',
  templateUrl: './duplicate-recipe-modal.component.html',
  styleUrls: ['./duplicate-recipe-modal.component.scss']
})
export class DuplicateRecipeModalComponent implements OnInit {
  @Input() recipeId: string;
  @Input() duplicateFunc: Function;
  @Output() recipeDuplicated = new EventEmitter<RecipeDuplicateResult>();
  goToEditLoading = false;
  saveAsCopyLoading = false;

  constructor(
    private modalRef: BsModalRef,
    private recipeService: RecipeService
  ) {}

  ngOnInit() {}

  cancel() {
    this.modalRef.hide();
  }

  onGoToEdit() {
    this.goToEditLoading = true;
    this.duplicateRecipe().subscribe(
      newId => {
        this.modalRef.hide();
        this.recipeDuplicated.emit({ outcome: 'goToEdit', newId });
      },
      () => {
        this.goToEditLoading = false;
      }
    );
  }

  onSaveAsCopy() {
    this.saveAsCopyLoading = true;
    this.duplicateRecipe().subscribe(
      newId => {
        this.modalRef.hide();
        this.recipeDuplicated.emit({ outcome: 'saveAsCopy', newId });
      },
      () => {
        this.saveAsCopyLoading = false;
      }
    );
  }

  private duplicateRecipe(): Observable<string> {
    return this.duplicateFunc();
  }
}
