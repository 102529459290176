import { UserRole } from "../userRole";

export class User {
  userId: string;
  customerId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  role: UserRole;
  created: string;
  groupId: string;
  locationId: string;

  // A comparator that sorts with a hierarchy of:
  // lastName -> firstName -> userId
  static comparator(a: User, b: User): number {
    if (!a && !b) {
      return 0;
    }
    if (a && !b) {
      return 1;
    }
    if (!a && b) {
      return -1;
    }
    if (a === b) {
      return 0;
    }

    const lastCompare = a.lastName.localeCompare(b.lastName);
    if (lastCompare !== 0) {
      return lastCompare;
    }

    const firstCompare = a.firstName.localeCompare(b.firstName);
    if (firstCompare !== 0) {
      return firstCompare;
    }

    return a.userId.localeCompare(b.userId);
  }

  static sessionHistoryString(user: User | undefined): string {
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }
    return "Unknown";
  }
}

export class UserAttributes {
  avatarUrl: string | null;
  language: string;
  tempUnit: string;
  notifyAlertsByMail: boolean;
}

export class UserWithAttributes extends User {
  attributes: UserAttributes;
}
