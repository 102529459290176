import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms"
import { BsModalRef } from "ngx-bootstrap/modal"

@Component({
  selector: "app-multi-shelf-timer-modal",
  templateUrl: "./multi-shelf-timer-modal.component.html",
  styleUrls: ["./multi-shelf-timer-modal.component.scss"]
})
export class MultiShelfTimerModalComponent implements OnInit {
  existingMultiShelfTimers: ShelfTimer[]
  currentMultiShelfTimers: ShelfTimer[] = []
  multiShelfTimerFormGroup: FormGroup[] = []
  maxTimers = 10
  formValid = false
  @Output() multiShelfTimer = new EventEmitter<FormGroup[]>()

  constructor(private modalRef: BsModalRef) {}

  ngOnInit() {
    if (this.existingMultiShelfTimers.length > 0) {
      for (const shelfTimer of this.existingMultiShelfTimers) {
        this.addTimer(
          shelfTimer.mode,
          shelfTimer.sound,
          shelfTimer.targetValue,
          shelfTimer.text
        )
      }
    }
  }

  addTimer(mode?: number, sound?: string, targetValue?: number, text?: string) {
    if (this.currentMultiShelfTimers.length <= this.maxTimers) {
      this.formValid = true
    }
    if (
      this.currentMultiShelfTimers.length >= this.maxTimers ||
      this.multiShelfTimerFormGroup.length >= this.maxTimers
    ) {
      this.currentMultiShelfTimers.splice(
        this.maxTimers,
        this.currentMultiShelfTimers.length - this.maxTimers
      )
      this.multiShelfTimerFormGroup.splice(
        this.maxTimers,
        this.multiShelfTimerFormGroup.length - this.maxTimers
      )
      return
    }
    const newShelfTimer = {
      mode: mode ? mode : DefaultShelfTimer.mode,
      sound: sound ? sound : DefaultShelfTimer.sound,
      targetValue: targetValue ? targetValue : DefaultShelfTimer.targetValue,
      text: text ? text : DefaultShelfTimer.text
    }
    this.multiShelfTimerFormGroup.push(
      new FormGroup({
        mode: new FormControl(newShelfTimer.mode),
        sound: new FormControl(newShelfTimer.sound),
        targetValue: new FormControl(newShelfTimer.targetValue, [
          Validators.required,
          Validators.min(-1)
        ]),
        text: new FormControl(newShelfTimer.text, Validators.required)
      })
    )
    this.currentMultiShelfTimers.push(newShelfTimer)
  }

  deleteTimer(index: number) {
    this.formValid = true
    this.multiShelfTimerFormGroup.splice(index, 1)
    this.currentMultiShelfTimers.splice(index, 1)
    if (this.currentMultiShelfTimers.length === 0) {
      this.formValid = false
    }
  }

  save() {
    this.formValid = true
    if (
      this.currentMultiShelfTimers.length <= 0 ||
      this.currentMultiShelfTimers.length > this.maxTimers
    ) {
      this.formValid = false
    }
    for (const index in this.multiShelfTimerFormGroup) {
      if (index) {
        if (!this.multiShelfTimerFormGroup[index].valid) {
          this.multiShelfTimerFormGroup[index].markAllAsTouched()
          this.formValid = false
          continue
        }
      }
    }
    if (!this.formValid) {
      return
    }
    this.modalRef.hide()
    this.multiShelfTimer.emit(this.multiShelfTimerFormGroup)
  }

  cancel() {
    this.modalRef.hide()
  }
}

export interface ShelfTimer {
  mode: number
  sound: string
  targetValue: number
  text: string
}

export const DefaultShelfTimer: ShelfTimer = {
  mode: 0,
  sound: "",
  targetValue: 300,
  text: ""
}

export function buildMultiShelfTimerFormArray(
  multiShelfTimer: FormArray,
  shelfTimer?: ShelfTimer
) {
  multiShelfTimer.push(
    new FormGroup({
      mode: new FormControl(
        shelfTimer ? shelfTimer.mode : DefaultShelfTimer.mode
      ),
      sound: new FormControl(
        shelfTimer ? shelfTimer.sound : DefaultShelfTimer.sound
      ),
      targetValue: new FormControl(
        shelfTimer ? shelfTimer.targetValue : DefaultShelfTimer.targetValue,
        [Validators.required, Validators.min(-1)]
      ),
      text: new FormControl(
        shelfTimer ? shelfTimer.text : DefaultShelfTimer.text,
        Validators.required
      )
    })
  )
}
