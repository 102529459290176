import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormControl } from "@angular/forms"
import { ODataFiltrableDataSource } from "filtrable-data-source"
import { tap } from "rxjs/operators"
import { UnitService } from "src/app/api/api/unit.service"
import { AdminUnitsService } from "src/app/core/admin-units.service"
import { PermissionService } from "src/app/core/permission.service"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"
import { dataSourceEqualFilterToggle } from "src/app/core/utils"
import { Unit, UnitsService, UnitType } from "../../api"

@Component({
  selector: "app-units-list",
  templateUrl: "./units-list.component.html",
  styleUrls: ["./units-list.component.scss"]
})
export class UnitsListComponent implements OnInit {
  @Input()
  set locationId(id: string) {
    this.selectedHandler.items = []
    dataSourceEqualFilterToggle(this.dataSource, "locationId", id)
  }
  @Input()
  set unitType(type: UnitType) {
    this.selectedHandler.items = []
    dataSourceEqualFilterToggle(this.dataSource, "unitType", type)
  }
  @Input() admin: boolean

  @Output() unitLinkClicked = new EventEmitter<string>()

  dataSource = new ODataFiltrableDataSource<Unit>()
  fields = ["unitName", "unitId"]
  selectedHandler = new SelectedItemsHandler<Unit>("unitId")
  gridToggle = true
  unitTypeFc: FormControl = new FormControl(undefined)
  unitStatusFc: FormControl = new FormControl(undefined)
  lastUpdate = new Date()
  unitTypes = Object.keys(UnitType)

  status = {
    "": "GLOBAL.ALL",
    true: "UNIT.CONNECTED",
    false: "UNIT.OFFLINE",
    alert: "UNIT.ALERT"
  }

  constructor(
    private unitsService: UnitsService,
    private adminUnitsService: AdminUnitsService,
    public auth: PermissionService
  ) {
    this.dataSource.limit = 12

    this.fetchData()

    this.unitStatusFc.valueChanges.subscribe(status => {
      if (status !== "alert") {
        this.fetchData(status)
      } else {
        this.fetchData(undefined, true)
      }
    })
    this.unitTypeFc.valueChanges.subscribe(type => {
      dataSourceEqualFilterToggle(this.dataSource, "unitType", type)
    })
  }

  fetchData(connected?: boolean, isInAlarm?: boolean) {
    this.dataSource.fetchFunction = (top, skip, filter, orderBy, count) => {
      return this.unitsService
        .getUnits(top, skip, filter, ["unitName"], count, connected, isInAlarm)
        .pipe(
          tap(() => {
            this.lastUpdate = new Date()
          })
        )
    }

    this.dataSource.loadData()
  }
  ngOnInit() {}

  deleteUnits(units: Unit[]) {
    const ids = units.map(u => u.unitId)
    this.adminUnitsService.deleteUnits(ids).subscribe(() => {
      this.refresh()
    })
  }

  refresh() {
    this.selectedHandler.items = []
    this.dataSource.setPage(0)
    this.dataSource.loadData()
  }

  setStatusValue(value: String) {
    this.unitStatusFc.setValue(value)
  }
}
