import { Component, DoCheck, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { GroupBase, GroupsService } from "src/app/api"
import { GroupsListService } from "src/app/core/groups-list.service"

@Component({
  selector: "app-groups-filter",
  templateUrl: "./groups-filter.component.html",
  styleUrls: ["./groups-filter.component.scss"]
})
export class GroupsFilterComponent implements OnInit, DoCheck {
  @Input() groupFormControl: FormControl
  selectedGroup: GroupBase | undefined | null = null

  groups: GroupBase[]

  sorted = false // Used to not resort groups in ngDoCheck

  constructor(public groupService: GroupsService) {}

  ngOnInit() {
    this.groupService.getGroupList().subscribe(res => {
      this.groups = res
    })
  }

  ngDoCheck() {}

  setValue(groupId: String) {
    this.groupFormControl.setValue(groupId)
    if (groupId === undefined) {
      this.selectedGroup = undefined
    } else {
      this.selectedGroup = this.groups.find(group => group.groupId === groupId)
    }
  }
}
