import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ITab } from './ITab';

@Component({
  selector: 'app-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.scss']
})
export class TabLayoutComponent implements OnInit, OnDestroy {
  @Input()
  tabs: ITab[] = [];

  activePath: string;

  subscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.subscription = router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(e => {
        this.updateActiveTab();
      });
  }

  ngOnInit() {
    this.updateActiveTab();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onTabSelect(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  updateActiveTab() {
    const currentUrlParts = this.router.routerState.snapshot.url.split('/');
    this.activePath = currentUrlParts[currentUrlParts.length - 1];
  }
}
