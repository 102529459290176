import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecipeSyncActionType } from '../api';

@Pipe({
  name: 'actionType'
})
export class ActionTypePipe implements PipeTransform {
  constructor(private ts: TranslateService) {}

  transform(value: RecipeSyncActionType): string {
    switch (value) {
      case 'assign':
        return this.ts.instant('GLOBAL.ASSIGN');
      case 'remove':
        return this.ts.instant('GLOBAL.REMOVE');
    }
    return '';
  }
}
