import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export type modalCancelFromType = 'header' | 'footer';

export type modalType =
  | 'SUCCESS'
  | 'DANGER'
  | 'SUCCESS_CONFIRM'
  | 'DANGER_CONFIRM'
  | 'BLOCK';

export interface ModalCloseEvent {
  type: 'accept' | 'cancel';
  cancelFrom?: modalCancelFromType;
}

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {
  @Input() icon?: string;
  @Input() acceptButtonLabel?: string;
  @Input() cancelButtonLabel?: string;
  @Input() beforeAccept$?: Observable<any>;
  @Input() title: string;
  @Input() type: modalType;
  @Output() close = new EventEmitter<ModalCloseEvent>();

  isLoading = false;

  constructor(private ts: TranslateService) {}

  ngOnInit() {
    if (!this.acceptButtonLabel) {
      this.acceptButtonLabel = this.ts.instant('GLOBAL.CONTINUE');
    }
    if (!this.cancelButtonLabel) {
      this.cancelButtonLabel = this.ts.instant('GLOBAL.CANCEL');
    }
  }

  accept() {
    if (this.beforeAccept$) {
      this.isLoading = true;
      this.beforeAccept$.subscribe(() => {
        this.isLoading = false;
        this.close.emit({ type: 'accept' });
      });
    } else {
      this.close.emit({ type: 'accept' });
    }
  }

  cancel(from: modalCancelFromType) {
    this.close.emit({ type: 'cancel', cancelFrom: from });
  }
}
