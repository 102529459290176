import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";
import { Observable } from "rxjs";
import { RecipeBody } from "../model/recipeBody";
import { ImageBase64 } from "../model/imageBase64";
import { CustomHttpUrlEncodingCodec } from "../encoder";
import { RecipeList } from "../model/recipeList";
import { IRecipeEditService } from "../model/recipeEditService";
import { RecipeBase } from "../model/recipeBase";

@Injectable({
  providedIn: "root"
})
export class GlobalLibraryService implements IRecipeEditService {
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  public getRecipe(recipeId: string): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling getGlobalRecipe."
      );
    }

    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = ["application/json"];

    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);

    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    return this.httpClient.get<RecipeBody>(
      `${
        this.configuration.basePath
      }/global-recipe/recipes/${encodeURIComponent(recipeId)}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: "body"
      }
    );
  }

  public editRecipe(
    recipeId: string,
    recipeBody?: RecipeBody
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${
        this.configuration.basePath
      }/global-recipe/recipes/${encodeURIComponent(String(recipeId))}`,
      recipeBody,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: "body"
      }
    );
  }

  uploadRecipeImage(recipeId: string, image?: ImageBase64): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling uploadGlobalRecipeImage."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${
        this.configuration.basePath
      }/global-recipe/recipes/${encodeURIComponent(String(recipeId))}/image`,
      image,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: "body"
      }
    );
  }

  public getRecipes(
    featuredOnly: boolean,
    popularOnly: boolean,
    $count: boolean,
    $top?: number,
    $skip?: number,
    unitTypes?: string[],
    recipeName?: string,
    categories?: Array<string>,
    $orderby?: Array<string>
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });

    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append("$orderby", <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }
    if (recipeName !== undefined && recipeName !== null) {
      queryParameters = queryParameters.set("recipeName", <any>recipeName);
    }
    if (categories) {
      categories.forEach(element => {
        queryParameters = queryParameters.append("categories", <any>element);
      });
    }
    if (unitTypes) {
      unitTypes.forEach(element => {
        queryParameters = queryParameters.append("unitTypes", <any>element);
      });
    }
    if (featuredOnly !== undefined && featuredOnly !== null) {
      queryParameters = queryParameters.set("featuredOnly", <any>featuredOnly);
    }

    if (popularOnly !== undefined && popularOnly !== null) {
      queryParameters = queryParameters.set("popularOnly", <any>popularOnly);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RecipeList>(
      `${this.configuration.basePath}/global-recipe/recipes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: "body"
      }
    );
  }

  public deleteRecipes(recipeIds: string[]): Observable<any> {
    if (recipeIds === null || recipeIds === undefined) {
      throw new Error(
        "Required parameter recipeIds was null or undefined when calling deleteGlobalRecipes."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (recipeIds) {
      recipeIds.forEach(element => {
        queryParameters = queryParameters.append("globalRecipeIds", <any>(
          element
        ));
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/global-recipe/recipes`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: "body"
      }
    );
  }

  public getRecipeCategories(libraryId: string): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });

    if (libraryId !== undefined && libraryId !== null) {
      queryParameters = queryParameters.set("libraryId", <any>libraryId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<string>>(
      `${this.configuration.basePath}/global-recipe/categories`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: "body"
      }
    );
  }

  public deleteRecipeImage(recipeId: string, imageId: string): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling deleteGlobalRecipeImage."
      );
    }
    if (imageId === null || imageId === undefined) {
      throw new Error(
        "Required parameter imageIds was null or undefined when calling deleteGlobalRecipeImage."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${
        this.configuration.basePath
      }/global-recipe/recipes/${encodeURIComponent(
        String(recipeId)
      )}/image/${encodeURIComponent(String(imageId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: "body"
      }
    );
  }

  public copyToCompanyLibrary(recipeIds: string[]): Observable<any> {
    return this.httpClient.post<void>(
      `${
        this.configuration.basePath
      }/global-recipe/recipes/copy-to-company-library`,
      recipeIds,
      {
        responseType: "json"
      }
    );
  }

  get selectedRecipes(): RecipeBase[] {
    if (localStorage.getItem("globalRecipes") !== null)
      return JSON.parse(localStorage.getItem("globalRecipes")!);
    else {
      return [];
    }
  }

  set selectedRecipes(recipes: RecipeBase[]) {
    localStorage.setItem("globalRecipes", JSON.stringify(recipes));
  }
}
