import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { ToastrService } from "ngx-toastr"
import { AuthenticationService } from "src/app/core/authentication.service"
import { version } from "../../../../package.json"

enum SignInPageState {
  SignIn,
  ForgotPassword,
  SetNewPassword,
  ResetPassword
}

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"]
})
export class SignInComponent implements OnInit {
  email = ""
  password = ""
  resetCode = ""
  user = undefined

  error = undefined
  loading = false
  appVersion = version

  PageState = SignInPageState
  pageState = SignInPageState.SignIn

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.navigateToHome()
    }
  }

  signInUser() {
    if (!this.password) {
      this.displayError("Password cannot be empty")
      return
    }
    if (!this.email) {
      this.displayError("Username cannot be empty")
      return
    }
    this.loading = true
    this.authService
      .login(this.email, this.password)
      .then(user => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          // Set the user, this is needed for the new password workflow
          this.user = user
          this.setPageState(SignInPageState.SetNewPassword)
        } else {
          // Navigate after successful sign in
          this.navigateToHome()
        }
      })
      .catch(err => this.displayError(err))
      .finally(() => (this.loading = false))
  }

  forgotPassword() {
    this.loading = true
    this.authService
      .forgotPassword(this.email)
      .then(() => this.setPageState(SignInPageState.ResetPassword))
      .catch(err => this.displayError(err))
      .finally(() => (this.loading = false))
  }

  resetPassword() {
    this.loading = true
    this.authService
      .forgotPasswordSubmit(
        this.email,
        this.resetCode.toString(),
        this.password
      )
      .then(() => {
        this.toastService.success("Password reset successfully")
        this.setPageState(SignInPageState.SignIn)
      })
      .catch(err => this.displayError(err))
      .finally(() => (this.loading = false))
  }

  setNewPassword() {
    this.loading = true
    this.authService
      .completeNewPassword(this.user, this.password)
      .then(() => {
        this.toastService.success("Password updated successfully")
        this.setPageState(SignInPageState.SignIn)
      })
      .catch(err => this.displayError(err))
      .finally(() => (this.loading = false))
  }

  private displayError(err: any) {
    this.error = err.message || err
  }

  private setPageState(state: SignInPageState) {
    // Always clear user whenever we go back to sign in page
    if (state === SignInPageState.SignIn) {
      this.user = undefined
    }
    // Always clear password and error whenever changing state
    this.password = ""
    this.error = undefined
    this.pageState = state
  }

  private navigateToHome() {
    this.router.navigateByUrl("/", { replaceUrl: true })
  }
}
