import { DefaultCategory } from "@cygnus-reach/core"

export enum ConnectionState {
  connecting = "connecting",
  reconnecting = "reconnecting",
  hasPin = "hasPin",
  connected = "connected",
  closed = "closed"
}

export enum FabAnimationState {
  inactive = "inactive",
  active = "active"
}

export enum BluetoothState {
  disconnected = 0,
  connecting,
  connected
}

export enum UserRole {
  Agent = 1,
  Tech = 2
}

export enum ShareType {
  Video = 0,
  Screen = 1
}

export enum MimeType {
  jpeg = "image/jpeg",
  png = "image/png",
  gif = "image/gif",
  mp4 = "video/mp4",
  webm = "video/webm",
  mov = "video/quicktime",
  json = "application/json",
  octet_stream = "application/octet-stream",
  text = "text/plain"
}

export enum Tabs {
  device = "Device Info",
  video = "Video Share",
  screen = "Screen Share",
  media = "Media"
}

export enum NotificationType {
  // Default
  Chat = DefaultCategory.Chat as number,
  Bytes = DefaultCategory.Bytes as number,
  Log = DefaultCategory.Log as number,
  Object = DefaultCategory.Object as number,
  // BLE (some may be non-notifications, unused)
  BluetoothStatus = 100,
  DeviceData = 101,
  Parameters = 102,
  BleInfo = 103,
  BleHeartbeat = 104,
  // System
  LogDownload = 110,
  // Sharing
  Drawing = 120,
  // Messaging
  Image = 130,
  Video = 131,
  RequestedScreenshot = 132,
  RequestedRecording = 133
}

export enum CommandType {
  // BLE (may be incomplete, unused)
  ConnectToDevice = 100,
  DisconnectFromDevice = 101,
  BleWrite = 102,
  CLICommand = 103,
  // Sharing
  StartShare = 110,
  EndShare = 111,
  SwapTool = 112,
  UndoPath = 113,
  // Capturing
  RecordVideo = 120,
  RecordVideoEnd = 121,
  CaptureScreenshot = 122
}

export enum QueryType {
  RequestDevices = 100,
  BleRead = 101
}

export enum SessionHistoryEvent {
  // Incoming messages
  incomingTextMessage = 100,
  incomingMediaMessage = 101,
  incomingScreenshot = 102,
  incomingVideoRecording = 103,

  // Outgoing messages
  outgoingTextMessage = 110,
  outgoingMediaMessage = 111,

  // Media tab actions
  saveMedia = 120,
  sendMedia = 121,
  deleteMedia = 122,

  // Notes action(s)
  saveNote = 130
}
