import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public updateUnitSubj = new Subject();
  public reloadPageSubject = new Subject<any>();
  constructor() {}

  public updateUnit() {
    this.updateUnitSubj.next();
  }

  public reloadPage() {
    this.reloadPageSubject.next();
  }
}
