import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router"
import { Observable } from "rxjs"
import { PermissionService } from "../core/permission.service"

@Injectable({
  providedIn: "root"
})
export class GlobalLibraryGuard implements CanActivate {
  constructor(private permission: PermissionService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(async (resolve, reject) => {
      const roles = this.permission.getRoles()
      if (roles) {
        if (roles.company_admin || roles.culinary_admin || roles.system_admin) {
          resolve(true)
        } else {
          this.router.navigate(["/overview"])
          resolve(false)
        }
      }
    })
  }
}
