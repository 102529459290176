import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IdAndLabel } from '../../../core/idAndLabel';

@Component({
  selector: 'app-removable-item',
  templateUrl: './removable-item.component.html',
  styleUrls: ['./removable-item.component.scss']
})
export class RemovableItemComponent implements OnInit {
  @Input() item: IdAndLabel;
  @Output() remove = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  onRemoveClick() {
    this.remove.emit(this.item.id);
  }
}
