import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RecipeLibrary } from 'src/app/api';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import { CreateLibraryComponent } from 'src/app/shared/create-library/create-library.component';
import { LibrariesService } from '../../../core/libraries.service';

@Component({
  selector: 'app-edit-visibility-modal',
  templateUrl: './edit-visibility-modal.component.html',
  styleUrls: ['./edit-visibility-modal.component.scss']
})
export class EditVisibilityModalComponent implements OnInit {
  @Output() librarySelected = new EventEmitter<RecipeLibrary>();

  dataSource = new ClientFiltrableDataSource<RecipeLibrary>();
  selectedLibraryHandler = new SelectedItemsHandler<RecipeLibrary>('libraryId');

  constructor(
    private librariesService: LibrariesService,
    private modalRef: BsModalRef,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.dataSource.setItems(this.librariesService.usedLibraries);
  }

  openCreateModal() {
    const createModalRef = this.modalService.show(CreateLibraryComponent);
    const createComponent = createModalRef.content as CreateLibraryComponent;
    createComponent.librarySelected.subscribe((l: RecipeLibrary) => {
      const newItems = this.dataSource.items!.concat(l);
      this.dataSource.setItems(newItems);
      this.selectedLibraryHandler.selectSingleItem(l);
    });
  }

  dismiss() {
    this.modalRef.hide();
  }

  close() {
    this.modalRef.hide();
    const selectedLibrary = this.selectedLibraryHandler.items[0];
    this.librarySelected.emit(selectedLibrary);
  }
}
