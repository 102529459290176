import { Location } from "@angular/common"
import { Component, OnInit } from "@angular/core"
import { FormArray } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { ToastrService } from "ngx-toastr"
import { RecipeBase, RecipeBody, RecipeImage, UnitType } from "src/app/api"
import { GlobalLibraryService } from "src/app/api/api/global-library.service"
import { UnitService } from "src/app/api/api/unit.service"
import { AuthenticationService } from "src/app/core/authentication.service"
import { PermissionService } from "src/app/core/permission.service"
import { parseRecipeRouteData } from "src/app/core/utils"
import { DeleteRecipesService } from "../../recipe-library/delete-recipes.service"
import { DeleteUnitRecipeService } from "../../unit/delete-unit-recipe.service"
import { fillStepsFormArrayFromRecipe } from "../edit-recipe/recipe-steps-editor/form-groups"
import { TemperatureTypePipe } from "../temperature-type.pipe"

function calculateTotalCookingTime(recipe: RecipeBody): number {
  if (
    recipe &&
    recipe.content &&
    recipe.content.steps &&
    Array.isArray(recipe.content.steps)
  ) {
    return recipe.content.steps.reduce(
      (total: number, current: any) =>
        current.timeSetpoint && current.timeSetpoint >= 0
          ? total + +current.timeSetpoint
          : total,
      0
    )
  }
  return 0
}

@Component({
  selector: "app-view-recipe",
  templateUrl: "./view-recipe.component.html",
  styleUrls: ["./view-recipe.component.scss"]
})
export class ViewRecipeComponent implements OnInit {
  recipeId: string
  recipeBody: RecipeBody
  stepsFormArray = new FormArray([])
  imageIds: RecipeImage[] = []
  totalSeconds = 0
  isUnitRecipe: boolean
  isGlobalRecipe: boolean
  unitId: string
  unitType: string
  unitTypes: UnitType[]
  invalidUnitType: boolean

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deleteRecipeService: DeleteRecipesService,
    private deleteUnitRecipeService: DeleteUnitRecipeService,
    private location: Location,
    private ts: TranslateService,
    private toastService: ToastrService,
    private globalRecipeService: GlobalLibraryService,
    public auth: PermissionService,
    private authService: AuthenticationService,
    private temperatureTypePipe: TemperatureTypePipe,
    private unitService: UnitService
  ) {
    route.params.subscribe(params => {
      this.recipeId = params["recipeId"]
      this.unitId = params["unitId"] // this can be undefined in case of library recipe
    })
    route.data.subscribe(data => {
      const recipeData = parseRecipeRouteData(data)
      this.recipeBody = recipeData.recipeBody
      this.isUnitRecipe = recipeData.isUnitRecipe
      this.isGlobalRecipe = recipeData.isGlobalRecipe
      this.unitType = recipeData.unitType

      fillStepsFormArrayFromRecipe(
        this.recipeBody,
        this.stepsFormArray,
        this.authService.getUserTemp(),
        this.temperatureTypePipe
      )

      this.totalSeconds = calculateTotalCookingTime(this.recipeBody)
      if (
        this.recipeBody &&
        this.recipeBody.content &&
        this.recipeBody.content.image &&
        Array.isArray(this.recipeBody.content.image)
      ) {
        this.imageIds = this.recipeBody.content.image
      }

      this.unitService.getTypes().subscribe(response => {
        this.unitTypes = response
        this.invalidUnitType =
          this.unitTypes.find(x => x === this.unitType) === undefined
      })
    })
  }

  ngOnInit() {}

  isGloballySelected() {
    return this.globalRecipeService.selectedRecipes.some(
      r => r.recipeId === this.recipeId
    )
  }

  shareToCustomerLibrary() {
    this.globalRecipeService
      .copyToCompanyLibrary([this.recipeId])
      .subscribe(() => {
        this.toastService.success(
          this.ts.instant("RECIPE_LIBRARY.GLOBAL.ADD_TO_CUSTOMER_SUCCESS")
        )
        if (this.isGloballySelected()) {
          this.globalRecipeService.selectedRecipes = this.globalRecipeService.selectedRecipes.filter(
            x => x.recipeId !== this.recipeId
          )
        }
      })
  }
}
