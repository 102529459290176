import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core"
import {
  DoorOpenKeys,
  HighLimitKeys
} from "src/app/api/model/telemetry/telemetryKeys"
import { TelemetryPayload } from "src/app/api/model/telemetry/telemetryPayload"

@Component({
  selector: "app-cook-and-hold-monitor",
  templateUrl: "./cook-and-hold-monitor.component.html",
  styleUrls: ["./cook-and-hold-monitor.component.scss"]
})
export class CookAndHoldMonitorComponent implements OnInit, OnChanges {
  @Input() metadata: any
  @Input() values: TelemetryPayload
  @Input() startupValues: any
  @Input() changedValues: any

  DoorOpenKeys = DoorOpenKeys
  HighLimitKeys = HighLimitKeys

  constructor() {}

  ngOnInit() {}

  // Waits for metadata to load in and then sets chamberRange
  ngOnChanges(changes: SimpleChanges) {}
}
