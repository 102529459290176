/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";

import { Observable } from "rxjs";

import { Company } from "../model/company";
import { CompanyOnboarding } from "../model/companyOnboarding";
import { CompanyWithAdmins } from "../model/companyWithAdmins";

import { BASE_PATH } from "../variables";
import { Configuration } from "../configuration";
import { ImageBase64 } from "../model/imageBase64";

@Injectable({
  providedIn: "root"
})
export class CompaniesService {
  private company: Company;
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Edit the specified company (for company_admin of the specified company)
   *
   * @param companyId Id of the company
   * @param Company Company detail
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editCompany(
    companyId: string,
    Company: Company,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editCompany(
    companyId: string,
    Company: Company,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editCompany(
    companyId: string,
    Company: Company,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editCompany(
    companyId: string,
    Company: Company,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling editCompany."
      );
    }
    if (Company === null || Company === undefined) {
      throw new Error(
        "Required parameter Company was null or undefined when calling editCompany."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/companies/${encodeURIComponent(
        String(companyId)
      )}`,
      Company,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of all available and active companies (for Alto-Shaam Account Administrator)
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompanies(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<CompanyWithAdmins>>;
  public getCompanies(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<CompanyWithAdmins>>>;
  public getCompanies(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<CompanyWithAdmins>>>;
  public getCompanies(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<CompanyWithAdmins>>(
      `${this.configuration.basePath}/companies`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get detail of the specified company
   * This API is accessible to all the users (company_admin, group_admin, group_user, location_admin, location_user) of the company
   * @param companyId Id of the company
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCompany(
    companyId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Company>;
  public getCompany(
    companyId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Company>>;
  public getCompany(
    companyId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Company>>;
  public getCompany(
    companyId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling getCompany."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Company>(
      `${this.configuration.basePath}/companies/${encodeURIComponent(
        String(companyId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the step that the user has completed in the onboarding process.
   * When the company is created is equal to 0. When the user has finished the onboarding process it will be null
   * @param companyId Id of the company
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOnboardingStep(
    companyId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<number | null>;
  public getOnboardingStep(
    companyId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<number | null>>;
  public getOnboardingStep(
    companyId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<number | null>>;
  public getOnboardingStep(
    companyId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling getOnboardingStep."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<number | null>(
      `${this.configuration.basePath}/companies/${encodeURIComponent(
        String(companyId)
      )}/onboardingStep`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sets the step that the user has completed in the onboarding process.
   * When the user has finished the onboarding process it will be null
   * @param companyId Id of the company
   * @param CompanyOnboarding
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setOnboardingStep(
    companyId: string,
    CompanyOnboarding?: CompanyOnboarding,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public setOnboardingStep(
    companyId: string,
    CompanyOnboarding?: CompanyOnboarding,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public setOnboardingStep(
    companyId: string,
    CompanyOnboarding?: CompanyOnboarding,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public setOnboardingStep(
    companyId: string,
    CompanyOnboarding?: CompanyOnboarding,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (companyId === null || companyId === undefined) {
      throw new Error(
        "Required parameter companyId was null or undefined when calling setOnboardingStep."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/companies/${encodeURIComponent(
        String(companyId)
      )}/onboardingStep`,
      CompanyOnboarding,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Creates a new image for a recipe
   *
   * @param imageId Id of the recipe
   * @param ImageBase64
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public uploadImage(
    imageId: string,
    ImageBase64?: ImageBase64,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public uploadImage(
    imageId: string,
    ImageBase64?: ImageBase64,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public uploadImage(
    imageId: string,
    ImageBase64?: ImageBase64,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public uploadImage(
    imageId: string,
    ImageBase64?: ImageBase64,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (imageId === null || imageId === undefined) {
      throw new Error(
        "Required parameter imageId was null or undefined when calling uploadRecipeImage."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/companies/${encodeURIComponent(
        String(imageId)
      )}/image`,
      ImageBase64,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
