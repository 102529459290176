import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-filters-reset',
  templateUrl: './filters-reset.component.html',
  styleUrls: ['./filters-reset.component.scss']
})
export class FiltersResetComponent implements OnInit {
  @Input() control: AbstractControl;

  constructor() {}

  ngOnInit() {}

  reset() {
    this.control.reset();
  }
}
