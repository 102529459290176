import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { GroupBase } from '../../api';
import { GroupConverter, IdAndLabel } from '../../core/idAndLabel';

@Component({
  selector: 'app-group-assign',
  templateUrl: './group-assign.component.html',
  styleUrls: ['./group-assign.component.scss']
})
export class GroupAssignComponent implements OnInit {
  @ViewChild('locationTemplate', { static: true })
  locationTemplate: TemplateRef<any>;

  @ViewChild('userTemplate', { static: true })
  userTemplate: TemplateRef<any>;

  @Input()
  groups: GroupBase[];

  result: Subject<IdAndLabel[]>;
  groupsSelected: IdAndLabel[] = [];
  groupConverter = GroupConverter;
  modalRef: BsModalRef;
  selectedAll: boolean;

  constructor(private modalService: BsModalService) {
    this.result = new Subject();
  }

  ngOnInit() {}

  location(): Subject<IdAndLabel[]> {
    this.modalRef = this.modalService.show(this.locationTemplate);
    return this.result;
  }

  user() {
    this.modalRef = this.modalService.show(this.userTemplate);
    return this.result;
  }

  assignGroups() {
    this.result.next(this.groupsSelected);
    this.modalRef.hide();
  }

  selectAll() {
    this.selectedAll = !this.selectedAll;
    if (this.selectedAll) {
      this.groupsSelected = GroupConverter.fromList(this.groups);
    } else {
      this.groupsSelected = [];
    }
  }

  isChecked(group: GroupBase): boolean {
    return (
      this.groupsSelected.filter(selected => selected.id === group.groupId)
        .length > 0
    );
  }

  selectGroup(event: any) {
    if (event.target.checked) {
      this.groupsSelected.push(
        GroupConverter.from(
          this.groups.filter(group => group.groupId === event.target.value)[0]
        )
      );
    } else {
      this.groupsSelected = this.groupsSelected.filter(
        group => group.id !== event.target.value
      );
    }
  }
}
