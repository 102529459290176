import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { MqttService } from "ngx-mqtt"
import { Subscription } from "rxjs"
import { UnitDetail, UnitsService, UnitType } from "src/app/api"
import { TELEMETRY_METADATA_KEYS } from "src/app/api/model/telemetry/telemetryKeys"
import { TelemetryPayload } from "src/app/api/model/telemetry/telemetryPayload"
import { environment } from "src/environments/environment"

interface ServiceMonitorData {
  timestamp?: number
  data: { key: string; value: any }[]
}

interface ServiceMonitorParsedData {
  timestamp?: number
  data?: TelemetryPayload
  startupData?: { key: string; value: any }[]
}

function parseData(data: { key: string; value: any }[]): Record<string, any> {
  const newData: Record<string, any> = {}
  for (const item of data) {
    newData[item.key] = item.value
  }
  return newData
}

@Component({
  selector: "app-service-monitor",
  templateUrl: "./service-monitor.component.html",
  styleUrls: ["./service-monitor.component.scss"]
})
export class ServiceMonitorComponent implements OnInit, OnDestroy {
  unit: UnitDetail
  serviceMonitorConfig: Record<string, string>

  serviceMonitorData: ServiceMonitorParsedData = {
    timestamp: undefined,
    data: undefined,
    startupData: undefined
  }
  changedVariables: Record<string, boolean> = {}
  interval: any

  constructor(
    private unitsService: UnitsService,
    private activatedRoute: ActivatedRoute,
    private mqttService: MqttService
  ) {}
  ngOnInit() {
    this.activatedRoute.parent!.data.subscribe(data => {
      this.unit = data.unit
      this.serviceMonitorConfig = TELEMETRY_METADATA_KEYS[this.unit.unitType]
    })
    this.pollLatestTelemetry()

    this.interval = setInterval(() => {
      this.pollLatestTelemetry()
    }, 60 * 1000)
  }

  pollLatestTelemetry() {
    this.unitsService.getUnitServiceMonitor(this.unit.unitId).subscribe(res => {
      this.serviceMonitorConfig = TELEMETRY_METADATA_KEYS[this.unit.unitType]
      this.serviceMonitorData = {
        timestamp: res.timestamp,
        data: res.payload,
        startupData: res.startupReport
      }
    })
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }

  calculateChangedVariables(
    oldValues: Record<string, any>,
    newValues: Record<string, any>
  ) {
    const changedVariables: Record<string, boolean> = {
      ...this.changedVariables
    }
    for (const key of Object.keys(newValues)) {
      if (oldValues[key] == null || oldValues[key] !== newValues[key]) {
        this.changedVariables[key] = false
        changedVariables[key] = true
      }
    }
    setTimeout(() => {
      this.changedVariables = changedVariables
    }, 0)
  }
}
