import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from "@angular/forms"
import { evaluateModifiers, RecipeModifiersType, StageType } from "src/app/api"
import { TemperatureTypePipe } from "../../temperature-type.pipe"

const RinseCleanType = "RINSE"

const validatorBypassModifiers = [
  RecipeModifiersType.coldSmoke,
  RecipeModifiersType.ovenRest
]

export function recipeModifierOrOtherValidator(
  otherFormValues: FormGroup,
  temperaturePipe: TemperatureTypePipe,
  otherValidator: ValidatorFn
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const modifiers = evaluateModifiers(
      otherFormValues.value,
      StageType.cook,
      temperaturePipe
    )
    // If any modifiers are detected, assume the stage is in a valid configuration

    if (
      validatorBypassModifiers.some(modifier => modifiers.includes(modifier))
    ) {
      return null
    }
    return otherValidator(control)
  }
}

/**
 * A validator that consider -1 a valid value, or use another validator to validate the value
 * @param validator The original validator
 */
export function minusOneOrOtherValidator(validator: ValidatorFn): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (+control.value === -1) {
      return null
    }
    return validator(control)
  }
}

/**
 * A validator that consider -1 and -2 as valid values, or use another validator to validate the value.
 * Used for Recipe timeSetpoint form controls on ovens with infinite cook capabilities, i.e. Prodigi
 * @param validator The original validator
 */
export function minusOneTwoOrOtherValidator(
  validator: ValidatorFn
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (+control.value === -1 || +control.value === -2) {
      return null
    }
    return validator(control)
  }
}

/**
 * Ensure that at least one non-rinse clean type is present in the list of clean types
 *
 * TODO: it might make sense to drive this logic from the DB instead, but frontend validation is
 *       more straightforward
 */
export function atLeastOneNonRinseCleanTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const hasNonRinseClean = (control.value || []).some(
      (cleanType: any) => cleanType !== RinseCleanType
    )
    return hasNonRinseClean ? null : { visibleCleans: true }
  }
}
