import { AfterViewInit, Component, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { UnitType } from "src/app/api"
import { UnitService } from "src/app/api/api/unit.service"

@Component({
  selector: "app-unit-type-filter",
  templateUrl: "./unit-type-filter.component.html",
  styleUrls: ["./unit-type-filter.component.scss"]
})
export class UnitTypeFilterComponent implements AfterViewInit {
  @Input() unitTypeControl: FormControl
  @Input() unitTypes: UnitType[] | undefined

  constructor(private unitService: UnitService) {}

  ngAfterViewInit(): void {
    if (!this.unitTypes) {
      this.unitService.getTypes().subscribe(unitTypes => {
        this.unitTypes = unitTypes
      })
    }
  }

  setValue(value: String) {
    this.unitTypeControl.setValue(value)
  }
}
