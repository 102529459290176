import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import * as moment from "moment"
import { BsModalRef } from "ngx-bootstrap/modal"
import { Alert } from "src/app/api"

export type ViewAlertActionType = "goToUnit" | "shareAccess"

@Component({
  selector: "app-view-alert-modal",
  templateUrl: "./view-alert-modal.component.html",
  styleUrls: ["./view-alert-modal.component.scss"],
  providers: []
})
export class ViewAlertModalComponent implements OnInit {
  @Input() alert: Alert
  @Input() hideGoToUnitBtn: boolean
  @Output() action = new EventEmitter<ViewAlertActionType>()

  displayTimestamp = ""
  constructor(private modalRef: BsModalRef) {}

  ngOnInit() {
    // There's a wacky bug that causes the user's offset to be reported incorrectly. For example, the browser KNOWS a user timezone of CDT
    // is -05:00, and will report it correctly when explicitly asked for the offset, but when localizing the time both MomentJS and the Angular
    // DatePipe use an offset that is off by 1 hour. This hack allows us to get the correct current offset for the user and offset the UTC time
    // correctly.
    this.displayTimestamp = moment
      .utc(this.alert.timestamp)
      .add(moment().utcOffset(), "minutes")
      .format("YYYY-MM-DD h:mm A")
  }

  close() {
    this.modalRef.hide()
  }

  shareAccess() {
    this.action.emit("shareAccess")
    this.close()
  }

  goToUnit() {
    this.action.emit("goToUnit")
    this.close()
  }
}
