import { Injectable } from "@angular/core"
import { Resolve } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { GroupsService, RecipeLibrary, RecipeService } from "../api"
import { IdAndLabel } from "./idAndLabel"

@Injectable({
  providedIn: "root"
})
export class LibrariesService implements Resolve<void> {
  usedLibraries: RecipeLibrary[] = []
  emptyGroupLibraries: RecipeLibrary[] = []
  emptyLocationLibraries: RecipeLibrary[] = []

  constructor(
    private recipeService: RecipeService,
    private groupsService: GroupsService,
    private ts: TranslateService
  ) {}

  refresh() {
    this.usedLibraries = []
    this.emptyGroupLibraries = []
    this.emptyLocationLibraries = []
    return this.recipeService
      .getRecipeLibraries()
      .toPromise()
      .then(res => {
        this.usedLibraries = res.filter(
          (l, idx) => idx === 0 || l.recipeCount > 0
        )
        this.emptyGroupLibraries = res.filter(
          l => l.recipeCount === 0 && l.groupId != null
        )
        this.emptyLocationLibraries = res.filter(
          l => l.recipeCount === 0 && l.locationId != null
        )
        this.renameUsedLibraries()
      })
  }

  getCompanyLibrary(): RecipeLibrary | undefined {
    return this.usedLibraries.find(l => l.companyId != null)
  }

  resolve(): Promise<void> {
    return this.refresh()
  }

  isCompanyLibrary(libraryId: string): boolean {
    const companyLibrary = this.getCompanyLibrary()
    return companyLibrary != null && companyLibrary.libraryId === libraryId
  }

  getAllGroupsIfIsCompanyLibrary(libraryId: string): Promise<IdAndLabel[]> {
    if (this.isCompanyLibrary(libraryId)) {
      return this.groupsService
        .getGroupList()
        .toPromise()
        .then(res => res.map(g => ({ id: g.groupId, label: g.groupName })))
    }
    return Promise.resolve([])
  }

  getLibraryById(id: string): RecipeLibrary | undefined {
    return (
      this.usedLibraries.find(l => l.libraryId === id) ||
      this.emptyGroupLibraries.find(l => l.libraryId === id) ||
      this.emptyLocationLibraries.find(l => l.libraryId === id)
    )
  }

  private renameUsedLibraries() {
    this.usedLibraries.forEach(l => {
      // the company library for a customer is now explicitly designated
      // no need to override a name
      (l as any).name = this.ts.instant("RECIPE_LIBRARY.LIBRARY_NAME", {
        itemName: l.name
      })
    })
  }
}
