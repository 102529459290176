import { Component, Input, OnInit } from "@angular/core"
import { FormArray, FormControl } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { RecipeImage, StageType } from "src/app/api"
import { AuthenticationService } from "src/app/core/authentication.service"
import { ImagesUploadModalComponent } from "../../images-upload-modal/images-upload-modal.component"
import { GenericEditStepModalComponent } from "../generic-edit-step-modal-component"

const UNLIMITED_TIME_SETPOINT = -1
@Component({
  selector: "app-edit-action-modal",
  templateUrl: "./edit-action-modal.component.html",
  styleUrls: ["./edit-action-modal.component.scss"]
})
export class EditActionModalComponent extends GenericEditStepModalComponent
  implements OnInit {
  previousSetpoint = 60

  timeModeFormControl: FormControl = new FormControl("untimed")

  constructor(modalRef: BsModalRef, authService: AuthenticationService) {
    super(modalRef, authService)
  }

  ngOnInit() {
    this.stageType = StageType.action
    super.ngOnInit()
    if (
      this.formGroup.value &&
      this.formGroup.value.timeSetpoint === UNLIMITED_TIME_SETPOINT
    ) {
      this.timeModeFormControl.setValue("untimed")
    } else {
      this.timeModeFormControl.setValue("timed")
    }
    this.timeModeFormControl.valueChanges.subscribe(val =>
      this.toggleTimeSetpoint(val)
    )
  }

  toggleTimeSetpoint(val: string) {
    if (val === "timed") {
      this.formGroup.controls["timeSetpoint"].setValue(this.previousSetpoint)
    } else {
      this.previousSetpoint = this.formGroup.value["timeSetpoint"]
      this.formGroup.controls["timeSetpoint"].setValue(UNLIMITED_TIME_SETPOINT)
    }
  }
}
