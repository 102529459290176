import { Pipe, PipeTransform } from "@angular/core"
import {
  AuthenticationService,
  UserTempUnit
} from "src/app/core/authentication.service"
import { toUserTemp } from "./temp-utils"

@Pipe({
  name: "temperature"
})
export class TemperaturePipe implements PipeTransform {
  tempUnit: UserTempUnit

  constructor(private authService: AuthenticationService) {
    this.tempUnit = this.authService.getUserTemp()
  }

  transform(value: number | null, noConversion?: boolean): string {
    return value || value === 0
      ? `${noConversion ? value : toUserTemp(value, this.tempUnit)} ${
          this.tempUnit
        }`
      : ""
  }
}
