import { Address } from './address';

export class Location {
  locationId: string;
  locationName: string;
  address: Address;
  totalUnits: number;
  /**
   * True if all units are connected, false otherwhise
   */
  connected: boolean;
  /**
   * Number of connected units
   */
  connectedUnits: number;
  /**
   * Number of units with alerts
   */
  unitsWithAlerts: number;
  /**
   * Dictionary containing the groups that contains the location (key is groupId and values is groupName)
   */
  groups: { [key: string]: string };
}
