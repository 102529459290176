import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"

export function htmlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const htmlRegex = new RegExp("<\\/?[a-z\\s\\S]*>")
    const unicodeRegex = new RegExp("&#?[\\w]*;")

    const containsHtml = htmlRegex.test(control.value)
    const containsUnicode = unicodeRegex.test(control.value)

    return containsHtml || containsUnicode
      ? { containsHtml: { value: "test" } }
      : null
  }
}
