import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ShareLocation, SharesService } from 'src/app/api';
import { DialogModalService } from 'src/app/core/dialog-modal.service';
import { checkFormValidity } from 'src/app/core/utils';

@Component({
  selector: 'app-location-share-modal',
  templateUrl: './location-share-modal.component.html',
  styleUrls: ['./location-share-modal.component.scss']
})
export class LocationShareModalComponent implements OnInit {
  locationId: string;
  placeholder: string;
  singleEmail = new FormControl('', [Validators.required, Validators.email]);
  invitationFormGroup = new FormGroup({
    emails: new FormArray([], Validators.required),
    duration: new FormControl(24, [
      Validators.required,
      Validators.max(24),
      Validators.min(1)
    ]),
    locationId: new FormControl(this.locationId)
  });

  get emailsFormArray() {
    return this.invitationFormGroup.controls['emails'] as FormArray;
  }

  constructor(
    private modalRef: BsModalRef,
    private ts: TranslateService,
    private router: Router,
    private formBuilder: FormBuilder,
    private sharesService: SharesService,
    private dialogService: DialogModalService
  ) {}

  ngOnInit() {
    this.placeholder = this.ts.instant('LOCATION.SHARE_ACCESS.INSERT_EMAIL');
  }

  close() {
    this.modalRef.hide();
  }

  sendInvitation() {
    if (checkFormValidity(this.invitationFormGroup)) {
      const endAt = new Date(
        new Date().valueOf() +
          this.hToMilliSecondsOnly(this.invitationFormGroup.value.duration)
      );
      const shareLocationData: ShareLocation = {
        emails: this.invitationFormGroup.value.emails,
        locationId: this.locationId,
        endAt: endAt.toISOString()
      };
      this.sharesService.createLocationShareLink(shareLocationData).subscribe(
        data => {
          this.modalRef.hide();
          this.dialogService
            .successConfirm({
              message: this.ts.instant('LOCATION.SHARE_ACCESS.LINK_SENT'),
              detail: this.ts.instant('LOCATION.SHARE_ACCESS.LINK_SENT_DESC2'),
              acceptButtonLabel: this.ts.instant(
                'ADMINISTRATION.LOCATIONS.GO_TO_LOCATION'
              ),
              cancelButtonLabel: this.ts.instant('GLOBAL.CONTINUE'),
              icon: 'check-white.svg'
            })
            .accept$.subscribe(() => {
              this.router.navigate(['locations', this.locationId]);
            });
        },
        error => {
          this.modalRef.hide();
          if (error.status === 406) {
            this.dialogService.danger({
              message: this.ts.instant('LOCATION.SHARE_ACCESS.LINK_NOT_SENT'),
              detail: this.ts.instant(
                'LOCATION.SHARE_ACCESS.LINK_NOT_SENT_DESC'
              )
            });
          }
        }
      );
    }
  }

  changedEmails(id: string) {
    const idx = this.emailsFormArray.value.findIndex((x: any) => x.name === id);
    this.emailsFormArray.removeAt(idx);
  }

  addEmail() {
    if (this.singleEmail.valid) {
      this.emailsFormArray.push(
        this.formBuilder.control(this.singleEmail.value)
      );
    }
  }

  hToMilliSecondsOnly(h: number) {
    return h * 1000 * 60 * 60;
  }
}
