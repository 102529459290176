import { Pipe, PipeTransform } from '@angular/core';
import { IdAndLabel } from 'src/app/core/idAndLabel';
import { LibrariesService } from '../../core/libraries.service';

@Pipe({
  name: 'libraryToIdAndLabel'
})
export class LibraryToIdAndLabelPipe implements PipeTransform {
  constructor(private librariesService: LibrariesService) {}

  transform(libraryId: string): IdAndLabel | undefined {
    const library = this.librariesService.getLibraryById(libraryId);
    return library ? { id: library.libraryId, label: library.name } : undefined;
  }
}
