import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material"
import { MediaFile } from "@app/shared/models/remote-support/media"

@Component({
  selector: "app-media-preview",
  templateUrl: "./media-preview.component.html",
  styleUrls: ["./media-preview.component.scss"]
})
export class MediaPreviewComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MediaFile,
    private dialogRef: MatDialogRef<MediaPreviewComponent>
  ) {}

  ngOnInit() {}

  onClose(): void {
    this.dialogRef.close()
  }
}
