import { Injectable, Injector } from "@angular/core"
import { Router } from "@angular/router"
import { JwtHelperService } from "@auth0/angular-jwt"
import { CognitoUser } from "@aws-amplify/auth"
import { Auth } from "aws-amplify"
import { ProfileService, UsersService } from "../api"
import { GlobalLibraryService } from "../api/api/global-library.service"
import { User, UserWithAttributes } from "../api/model/user/user"
import { CognitoAttributes } from "../models/enums/cognito-attributes.model"
import { LocalStorageKey } from "../models/enums/local-storage"
import { DialogModalService } from "./dialog-modal.service"
import { PermissionService } from "./permission.service"

export type UserTempUnit = "°C" | "°F"

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  private currentUser: UserWithAttributes

  // Have to lazy load router and dialog service since the app initializer doesn't like them being a dependency
  // Might cause problems
  private get router() {
    return this.injector.get<Router>(Router)
  }
  private get dialogService() {
    return this.injector.get<DialogModalService>(DialogModalService)
  }

  constructor(
    private jwtService: JwtHelperService,
    private userService: UsersService,
    private profileService: ProfileService,
    private permissions: PermissionService,
    private injector: Injector,
    private globalLibraryService: GlobalLibraryService
  ) {}

  isAuthenticated(): boolean {
    const token = this.getIdToken()
    if (!token) {
      return false
    }
    return !this.jwtService.isTokenExpired(token)
  }

  getIdToken(): string | null {
    return localStorage.getItem(LocalStorageKey.idJWT)
  }

  private getRefreshToken(): string | null {
    return localStorage.getItem(LocalStorageKey.refreshJWT)
  }

  getUserTemp(): UserTempUnit {
    return this.currentUser.attributes.tempUnit === "F" ? "°F" : "°C"
  }

  signOutAndNavigate(expired: Boolean = false) {
    this.signOut().then(() => {
      // If the expired flag is true then display the session expired modal
      if (expired) {
        this.dialogService.danger({
          title: "Session Expired",
          message:
            "Your session has expired due to inactivity. Please sign in again to continue.",
          acceptButtonLabel: "OK"
        })
      }
      this.router.navigateByUrl("/auth")
    })
  }

  async refreshSession(): Promise<void> {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = cognitoUser.getSignInUserSession()
    if (currentSession != null) {
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err, session) => {
          if (!err) {
            this.setUserTokens(session)
          }
        }
      )
    }
  }

  async login(username: string, password: string): Promise<CognitoUser | any> {
    const user = await Auth.signIn(username, password)
    if (user.challengeName !== "NEW_PASSWORD_REQUIRED") {
      await this.updateUserAndPermissions(user)
      await this.authServiceInit()
    }
    return Promise.resolve(user)
  }

  async signOut(): Promise<void> {
    await Auth.signOut()
    const selectedGlobal = this.globalLibraryService.selectedRecipes
    localStorage.clear()
    this.globalLibraryService.selectedRecipes = selectedGlobal
  }

  async completeNewPassword(
    user: CognitoUser | any,
    newPassword: string
  ): Promise<void> {
    await Auth.completeNewPassword(user, newPassword)
  }

  async forgotPassword(username: string): Promise<void> {
    return Auth.forgotPassword(username)
  }

  async forgotPasswordSubmit(
    username: string,
    code: string,
    newPassword: string
  ): Promise<string> {
    return Auth.forgotPasswordSubmit(username, code, newPassword)
  }

  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<string> {
    const user = await Auth.currentAuthenticatedUser()
    return Auth.changePassword(user, oldPassword, newPassword)
  }

  async changeActiveCompany(companyId: string): Promise<void> {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(user, {
      [CognitoAttributes.activeCompany]: companyId
    })
    const newSession = await Auth.currentAuthenticatedUser({
      bypassCache: true
    })
    await this.updateUserAndPermissions(newSession)
  }

  async updateUserAndPermissions(user: CognitoUser | any) {
    this.setUserProperties(user)

    // Get detailed user info
    const userInfo = await this.userService.getCurrentUser().toPromise()
    // Check if active company is null. If so then set it to be user's main company
    if (!user.attributes[CognitoAttributes.activeCompany]) {
      localStorage.setItem(LocalStorageKey.activeCompany, userInfo.customerId)
    }
    this.setUserProfile(userInfo)
    this.permissions.evaluate()
  }

  private setUserTokens(userSession: any) {
    localStorage.setItem(LocalStorageKey.idJWT, userSession.idToken.jwtToken)
    localStorage.setItem(
      LocalStorageKey.refreshJWT,
      userSession.refreshToken.token
    )
  }

  private setUserProperties(user: CognitoUser | any) {
    this.setUserTokens(user.signInUserSession)
    localStorage.setItem(
      LocalStorageKey.userInfo,
      JSON.stringify(user.attributes)
    )
    if (user.attributes[CognitoAttributes.activeCompany]) {
      localStorage.setItem(
        LocalStorageKey.activeCompany,
        user.attributes[CognitoAttributes.activeCompany]
      )
    } else {
      localStorage.removeItem(LocalStorageKey.activeCompany)
    }
    localStorage.setItem(LocalStorageKey.userId, user.attributes.sub)
  }

  private setUserProfile(user: User) {
    localStorage.setItem(LocalStorageKey.email, user.email)
    localStorage.setItem(LocalStorageKey.userCompany, user.customerId)
    localStorage.setItem(LocalStorageKey.userRole, user.role)
    if (user.groupId) {
      localStorage.setItem(LocalStorageKey.groupId, user.groupId)
    } else {
      localStorage.removeItem(LocalStorageKey.groupId)
    }
    if (user.locationId) {
      localStorage.setItem(LocalStorageKey.locationId, user.locationId)
    } else {
      localStorage.removeItem(LocalStorageKey.locationId)
    }
  }

  async authServiceInit(): Promise<void | UserWithAttributes> {
    return this.isAuthenticated()
      ? this.profileService
          .getUserWithAttributes()
          .toPromise()
          .then(user => {
            this.currentUser = user
            this.getUser()
          })
      : Promise.resolve()
  }

  getUser(): UserWithAttributes {
    // Can continue building out fetching local storage data as needed
    const user =
      this.currentUser !== undefined
        ? this.currentUser
        : new UserWithAttributes()
    user.customerId = localStorage.getItem(LocalStorageKey.userCompany)!!
    user.userId = localStorage.getItem(LocalStorageKey.userId)!!
    user.email = localStorage.getItem(LocalStorageKey.email) || ""
    return user
  }

  getActiveCompany(): string {
    return (
      localStorage.getItem(LocalStorageKey.activeCompany) ||
      this.getUser().customerId
    )
  }
}
