import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  adjustDateRange,
  getOneDayAgo
} from "src/app/shared/custom-date-picker/utils"

@Component({
  selector: 'app-unit-alerts',
  templateUrl: './unit-alerts.component.html',
  styleUrls: ['./unit-alerts.component.scss']
})
export class UnitAlertsComponent implements OnInit {
  currUnitId: string;

  formArray: FormArray;
  rangeFormControl = new FormControl();

  constructor(private router: Router, private route: ActivatedRoute) {
    this.rangeFormControl = new FormControl(getOneDayAgo());
    this.currUnitId = this.route.snapshot.parent!.params.unitId;

    this.formArray = new FormArray([this.rangeFormControl]);
  }
  ngOnInit() {}
}
