import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-body',
  templateUrl: './card-body.component.html',
  styleUrls: ['./card-body.component.scss']
})
export class CardBodyComponent implements OnInit {
  @HostBinding('class') hostClass = 'card-body';

  constructor() {}

  ngOnInit() {}
}
