import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding-step',
  templateUrl: './onboarding-step.component.html',
  styleUrls: ['./onboarding-step.component.scss']
})
export class OnboardingStepComponent implements OnInit {
  @Input() id: number;
  @Input() stepSelected: number;
  @Input() last: boolean;

  constructor() {}

  ngOnInit() {}
}
