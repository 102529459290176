import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { checkFormValidity } from 'src/app/core/utils';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {
  @Output() result = new EventEmitter<string | undefined>();

  formGroup = new FormGroup({
    name: new FormControl(undefined, Validators.required)
  });

  constructor(public modalRef: BsModalRef) {}

  ngOnInit() {}

  dismiss() {
    this.modalRef.hide();
    this.result.emit(undefined);
  }

  create() {
    if (!checkFormValidity(this.formGroup)) {
      return;
    }

    this.modalRef.hide();
    const newTagValue = this.formGroup.controls['name'].value as string;
    this.result.emit(newTagValue);
  }
}
