import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"

/**
 * This validator checks if an input matches the correct time format.
 *
 * This is needed because the control's value is converted to total seconds, so there is not a number of characters to track.
 *
 * @returns Any form errors due to invalid format or lack of characters.
 */
export function timeFormatValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value ? null : { timeFormat: true }
  }
}
