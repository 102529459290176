import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core"
import {
  DoorOpenKeys,
  HighLimitKeys
} from "src/app/api/model/telemetry/telemetryKeys"
import { TelemetryPayload } from "src/app/api/model/telemetry/telemetryPayload"

@Component({
  selector: "app-vector-monitor",
  templateUrl: "./vector-monitor.component.html",
  styleUrls: ["./vector-monitor.component.scss"]
})
export class VectorMonitorComponent implements OnInit, OnChanges {
  @Input() metadata: any
  @Input() values?: TelemetryPayload
  @Input() startupValues: any
  @Input() changedValues: any
  @Input() type: string

  DoorOpenKeys = DoorOpenKeys
  HighLimitKeys = HighLimitKeys

  constructor() {}

  ngOnInit() {}

  // Waits for metadata to load in and then sets chamberRange
  ngOnChanges(changes: SimpleChanges) {}
}
