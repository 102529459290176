import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { RecipeImage } from "src/app/api"

@Component({
  selector: "app-action-step",
  templateUrl: "./action-step.component.html",
  styleUrls: ["./action-step.component.scss"]
})
export class ActionStepComponent implements OnInit {
  @Input() formGroup: FormGroup
  @Input() index: number
  @Input() images: RecipeImage[]
  @Input() editingEnabled = true
  @Output() edit = new EventEmitter<void>()
  @Output() delete = new EventEmitter<void>()

  get hasVisual() {
    return this.formGroup.value.visual && this.formGroup.value.visual.id
  }

  get hasTimeSetpoint() {
    return this.formGroup.value.timeSetpoint !== 0
  }

  constructor() {}

  ngOnInit() {}
}
