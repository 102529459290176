/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";

import { Observable } from "rxjs";

import { Group } from "../model/group";
import { GroupBase } from "../model/groupBase";

import { BASE_PATH } from "../variables";
import { Configuration } from "../configuration";

@Injectable({
  providedIn: "root"
})
export class GroupsService {
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Creates a new group
   *
   * @param Group
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createGroup(
    Group?: Group,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Group>;
  public createGroup(
    Group?: Group,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Group>>;
  public createGroup(
    Group?: Group,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Group>>;
  public createGroup(
    Group?: Group,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<Group>(
      `${this.configuration.basePath}/groups`,
      Group,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deletes a group
   * If there is a recipe library linked to this group it will be deleted, and so all the recipes insides it.
   * @param groupId Id of the group
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteGroup(
    groupId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteGroup(
    groupId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteGroup(
    groupId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteGroup(
    groupId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (groupId === null || groupId === undefined) {
      throw new Error(
        "Required parameter groupId was null or undefined when calling deleteGroup."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/groups/${encodeURIComponent(
        String(groupId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit group information
   *
   * @param groupId Id of the group
   * @param Group
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editGroup(
    groupId: string,
    Group?: Group,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editGroup(
    groupId: string,
    Group?: Group,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editGroup(
    groupId: string,
    Group?: Group,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editGroup(
    groupId: string,
    Group?: Group,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (groupId === null || groupId === undefined) {
      throw new Error(
        "Required parameter groupId was null or undefined when calling editGroup."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/groups/${encodeURIComponent(
        String(groupId)
      )}`,
      Group,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get group base data
   *
   * @param groupId Id of the group
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGroup(
    groupId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Group>;
  public getGroup(
    groupId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Group>>;
  public getGroup(
    groupId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Group>>;
  public getGroup(
    groupId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (groupId === null || groupId === undefined) {
      throw new Error(
        "Required parameter groupId was null or undefined when calling getGroup."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Group>(
      `${this.configuration.basePath}/groups/${encodeURIComponent(
        String(groupId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get List of available groups, for account admin
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGroupList(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<GroupBase>>;
  public getGroupList(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<GroupBase>>>;
  public getGroupList(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<GroupBase>>>;
  public getGroupList(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<GroupBase>>(
      `${this.configuration.basePath}/groups`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Assign locations to a group (accessible to the company admin)
   *
   * @param groupId The ID of the group to assign the location to.
   * @param locationId The ID of the location being assigned.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignLocationToGroup(
    groupId: string,
    locationId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public assignLocationToGroup(
    groupId: string,
    locationId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public assignLocationToGroup(
    groupId: string,
    locationId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public assignLocationToGroup(
    groupId: string,
    locationId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (groupId === null || groupId === undefined) {
      throw new Error(
        "Required parameter groupId was null or undefined when calling assignLocationsToGroup."
      );
    }
    if (locationId === null || locationId === undefined) {
      throw new Error(
        "Required parameter locationId was null or undefined when calling assignLocationsToGroup."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/groups/${encodeURIComponent(
        String(groupId)
      )}/locations/${encodeURIComponent(String(locationId))}`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Removes locations from a group (accessible to the company admin)
   *
   * @param GroupAndLocations
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeLocationFromGroup(
    groupId?: string,
    locationId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public removeLocationFromGroup(
    groupId?: string,
    locationId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public removeLocationFromGroup(
    groupId?: string,
    locationId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public removeLocationFromGroup(
    groupId?: string,
    locationId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/groups/${encodeURIComponent(
        String(groupId)
      )}/locations/${encodeURIComponent(String(locationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
