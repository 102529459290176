import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltrableDataSource } from 'filtrable-data-source';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-data-source-pagination',
  templateUrl: './data-source-pagination.component.html',
  styleUrls: ['./data-source-pagination.component.scss']
})
export class DataSourcePaginationComponent implements OnInit {
  @Input()
  dataSource: FiltrableDataSource<any>;
  @Output()
  pageChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  handlePageChange(event: PageChangedEvent) {
    const page = event.page - 1;
    this.dataSource.setPage(page);
    this.pageChange.emit(page);
  }

  isLastPage() {
    const lastPage =
      Math.ceil(this.dataSource.totalItems / this.dataSource.limit) - 1;
    return this.dataSource.page === lastPage;
  }
}
