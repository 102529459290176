/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type UnitSettingsLanguageEnum =
  | 'fr_FR'
  | 'de_DE'
  | 'en_US'
  | 'ru_RU'
  | 'zh_CN';

export const UnitSettingsLanguageEnum = {
  FrFR: 'fr_FR' as UnitSettingsLanguageEnum,
  DeDE: 'de_DE' as UnitSettingsLanguageEnum,
  EnUS: 'en_US' as UnitSettingsLanguageEnum,
  RuRU: 'ru_RU' as UnitSettingsLanguageEnum,
  ZhCN: 'zh_CN' as UnitSettingsLanguageEnum
};
