import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AlertsService } from './api/alerts.service';
import { CompaniesService } from './api/companies.service';
import { GroupsService } from './api/groups.service';
import { LocationsService } from './api/locations.service';
import { ProfileService } from './api/profile.service';
import { RecipeService } from './api/recipe.service';
import { ReportsService } from './api/reports.service';
import { ReportService } from './api/report.service';
import { SharesService } from './api/shares.service';
import { UnitSettingsService } from './api/unitSettings.service';
import { UnitsService } from './api/units.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AlertsService,
    CompaniesService,
    GroupsService,
    LocationsService,
    ProfileService,
    RecipeService,
    ReportsService,
    ReportService,
    SharesService,
    UnitSettingsService,
    UnitsService,
    UsersService
  ]
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
