import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Interval } from 'luxon';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { _ } from '../../core/translation-marker';
import { adjustDateRange, getLastWeek } from '../custom-date-picker/utils';

function maxDaysValidator(days: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const range: [Date, Date] = control.value;
    if (
      range &&
      Interval.fromDateTimes(range[0], range[1]).length('days') > days
    ) {
      return {
        maxDays: {
          days
        }
      };
    }

    return null;
  };
}

@Component({
  selector: 'app-export-data-modal',
  templateUrl: './export-data-modal.component.html',
  styleUrls: ['./export-data-modal.component.scss']
})
export class ExportDataModalComponent implements OnInit {
  @Output() export = new EventEmitter<[Date, Date]>();
  @Input() maxLength = 7;
  rangeDateControl: FormControl;
  customErrorMessages = { maxDays: _('UNIT.EXPORT_LIMIT_ERROR') };

  constructor(private modalRef: BsModalRef) {}

  ngOnInit() {
    this.rangeDateControl = new FormControl(getLastWeek(), [
      Validators.required,
      maxDaysValidator(this.maxLength)
    ]);
  }

  close() {
    this.modalRef.hide();
  }

  exportData() {
    const adjusted = adjustDateRange(this.rangeDateControl.value);
    this.export.emit(adjusted);
  }
}
