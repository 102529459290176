export class AlertSearchParameters {
  locationId: String;
  unitId: String;
  unitType: String;
  groupId: String;
  companyId: String;
  unread: boolean;
  start: String;
  end: String;
  offset: number;
  count: number;
}
