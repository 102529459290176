/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { Alert } from "../model/alert";
import { AlertList } from "../model/alertList";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";
import { AlertSearchParameters } from "../model/AlertSearchParameters";

@Injectable({
  providedIn: "root"
})
export class AlertsService {
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Detail of an alert
   *
   * @param alertId Id of the alert
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAlertDetail(
    alertId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Alert>;
  public getAlertDetail(
    alertId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Alert>>;
  public getAlertDetail(
    alertId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Alert>>;
  public getAlertDetail(
    alertId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (alertId === null || alertId === undefined) {
      throw new Error(
        "Required parameter alertId was null or undefined when calling getAlertDetail."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Alert>(
      `${this.configuration.basePath}/alerts/${encodeURIComponent(
        String(alertId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Mark an alert as read
   *
   * @param alertId Id of the alert
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setAlertReadStatus(
    alertId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public setAlertReadStatus(
    alertId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public setAlertReadStatus(
    alertId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public setAlertReadStatus(
    alertId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (alertId === null || alertId === undefined) {
      throw new Error(
        "Required parameter alertId was null or undefined when calling setAlertReadStatus."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/alerts/${encodeURIComponent(
        String(alertId)
      )}/read`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getAlerts(searchParams: AlertSearchParameters) {
    return this.httpClient.post<Array<Alert>>(
      `${this.configuration.basePath}/alerts`,
      searchParams,
      { responseType: "json" }
    );
  }

  public markAlertsAsRead(alertIds: string[]): Observable<void> {
    return this.httpClient.post<void>(
      `${this.configuration.basePath}/alerts/read`,
      alertIds,
      { responseType: "json" }
    );
  }
}
