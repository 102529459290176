import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ShareAccessService } from 'src/app/core/share-access.service';

@Component({
  selector: 'app-location-share-detail-modal',
  templateUrl: './location-share-detail-modal.component.html',
  styleUrls: ['./location-share-detail-modal.component.scss']
})
export class LocationShareDetailModalComponent implements OnInit {
  @Input() shareDetail: any;
  constructor(
    public modalRef: BsModalRef,
    public shareAccessService: ShareAccessService
  ) {}

  ngOnInit() {}

  close() {
    this.modalRef.hide();
  }
}
