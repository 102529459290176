import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors
} from "@angular/forms"
import { Data } from "@angular/router"
import {
  and,
  contains,
  equal,
  FiltrableDataSource,
  greaterThan,
  greaterThanOrEqual,
  lessThanOrEqual
} from "filtrable-data-source"
import { differenceWith, isEqual } from "lodash-es"
import { DateTime, Interval } from "luxon"
import { Location, RecipeBody, ResolutionType, Unit, UnitType } from "../api"
import { adjustDateRange } from "../shared/custom-date-picker/utils"
import { fillRecipeActionImagesFromRecipe } from "../shared/edit-recipe/recipe-steps-editor/form-groups"

export function markFormGroupTouched(formGroup: FormGroup) {
  Object.values(formGroup.controls).forEach((control: any) => {
    control.markAsTouched()

    if (control.controls) {
      markFormGroupTouched(control)
    }
  })
}

export function checkFormValidity(form: FormGroup): boolean {
  if (form.invalid) {
    markFormGroupTouched(form)
    setTimeout(() => {
      const firstInvalidEl = document.querySelector(
        "input.ng-invalid.ng-touched"
      ) as HTMLElement | null
      if (firstInvalidEl) {
        firstInvalidEl.scrollIntoView()
        window.scrollBy(0, -100)
        firstInvalidEl.focus()
      }
    })
    return false
  } else {
    return true
  }
}

function parseControlError(
  key: string,
  control: AbstractControl
): Record<string, ValidationErrors> {
  const res: Record<string, ValidationErrors> = {}

  if (control instanceof FormGroup || control instanceof FormArray) {
    const errors = getAllFormErrors(control, key)
    if (Object.keys(errors).length > 0) {
      Object.assign(res, errors)
    }
  } else if (control.errors) {
    res[key] = control.errors
  }

  return res
}

export function getAllFormErrors(
  form: FormGroup | FormArray,
  path = ""
): Record<string, ValidationErrors> {
  const res: Record<string, ValidationErrors> = {}

  if (form instanceof FormGroup) {
    for (const key of Object.keys(form.controls)) {
      Object.assign(
        res,
        parseControlError(
          `${path ? `${path}.${key}` : key}`,
          form.controls[key]
        )
      )
    }
  }

  if (form instanceof FormArray) {
    for (let i = 0; i < form.controls.length; i++) {
      Object.assign(
        res,
        parseControlError(`${path ? `${path}.${i}` : i}`, form.controls[i])
      )
    }
  }

  return res
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  )
}

export function PasswordValidator(confirmPasswordInput: string) {
  let confirmPasswordControl: FormControl
  let passwordControl: FormControl

  return (control: FormControl) => {
    if (!control.parent) {
      return null
    }

    if (!confirmPasswordControl) {
      confirmPasswordControl = control
      passwordControl = control.parent.get(confirmPasswordInput) as FormControl
      passwordControl.valueChanges.subscribe(() => {
        confirmPasswordControl.updateValueAndValidity()
      })
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      return {
        notMatch: true
      }
    }
    return null
  }
}

export function dataSourceEqualFilterToggle(
  dataSource: FiltrableDataSource<any>,
  field: string,
  value: any
) {
  if (value) {
    dataSource.setFilter(field, equal(value))
  } else {
    dataSource.removeFilter(field)
  }
  dataSource.applyFilters()
}

export function dataSourceGreaterThanFilterToggle(
  dataSource: FiltrableDataSource<any>,
  field: string,
  value: any
) {
  if (value != null) {
    dataSource.setFilter(field, greaterThan(value))
  } else {
    dataSource.removeFilter(field)
  }
  dataSource.applyFilters()
}

export function dataSourceContainsFilterToggle(
  dataSource: FiltrableDataSource<any>,
  field: string,
  value: any
) {
  if (value != null) {
    dataSource.setFilter(field, contains(value))
  } else {
    dataSource.removeFilter(field)
  }
  dataSource.applyFilters()
}

export type dateRangeType = [Date, Date] | undefined

export function dataSourceDateRangeFilterToggle(
  dataSource: FiltrableDataSource<any>,
  field: string,
  value: dateRangeType
) {
  if (value) {
    const adjusted = adjustDateRange(value)
    dataSource.setFilter(
      field,
      and(greaterThanOrEqual(adjusted[0]), lessThanOrEqual(adjusted[1]))
    )
  } else {
    dataSource.removeFilter(field)
  }
  dataSource.applyFilters()
}

export function getColorHexByLocation(location: Location): string {
  if (location.connected && location.unitsWithAlerts === 0) {
    return "#8fc92a" // connected
  } else if (location.connected && location.unitsWithAlerts > 0) {
    return "#d54022" // alert
  }
  return "#cfd2d3" // offline
}

export function getColorHexByUnit(unit: Unit) {
  return unit.connected ? "#8fc92a" : "#cfd2d3"
}

export function getBasicStatusColor(location: Location): string {
  if (location.connected && location.unitsWithAlerts === 0) {
    return "green"
  } else if (location.connected && location.unitsWithAlerts > 0) {
    return "red"
  }
  return "grey"
}

export function objectListDifference<T>(l1: T[], l2: T[]): T[] {
  return differenceWith(l1, l2, isEqual)
}

export function getDateTime(): string {
  return DateTime.fromJSDate(new Date()).toFormat("yyyyMMdd_HHmmss")
}

export function findResolutionLevel(start: Date, end: Date): ResolutionType {
  const weeks = Interval.fromDateTimes(start, end).length("weeks")

  if (weeks <= 1) {
    return "minute"
  } else if (weeks > 1 && weeks <= 12) {
    return "hour"
  } else {
    return "day"
  }
}

export function findHistogramResolutionLevel(
  start: Date,
  end: Date
): ResolutionType {
  if (Interval.fromDateTimes(start, end).length("day") <= 1) {
    return "hour"
  }

  if (Interval.fromDateTimes(start, end).length("month") <= 1) {
    return "day"
  }

  return "month"
}

export function getLocalTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function getTimeRemainingOnJwtToken(decodedJwtToken: any): number {
  // Subtract the expiration time in seconds from the current time in seconds
  return decodedJwtToken.exp - Math.floor(Date.now() / 1000)
}

export interface RecipeRouteData {
  recipeBody: RecipeBody
  isUnitRecipe: boolean
  isGlobalRecipe: boolean
  unitType: UnitType
}

export function parseRecipeRouteData(
  routeData: Data,
  routeState?: any
): RecipeRouteData {
  if (routeData && routeData.recipe && routeData.recipe.content !== undefined) {
    // TODO it is not ideal to put this here, but convert from the oven-side recipe image ID schema
    // to the frontend-side recipe image Form schema before passing to any component
    const recipeData = { ...(routeData.recipe as RecipeBody), ...routeState }
    fillRecipeActionImagesFromRecipe(recipeData)
    return {
      recipeBody: recipeData,
      isUnitRecipe: false,
      isGlobalRecipe: recipeData.popular !== undefined, // todo this is a bit hacky, only global recipes have the `popular` flag
      unitType: recipeData.unitType
    }
  }

  const unitType =
    routeData == null || routeData.unit == null
      ? null
      : routeData.unit.unitType

  const res: Partial<RecipeBody> = {
    content: routeData.recipe,
    unitType: unitType
  }
  fillRecipeActionImagesFromRecipe(res as RecipeBody)
  return {
    recipeBody: res as RecipeBody,
    unitType: unitType,
    isUnitRecipe: true,
    isGlobalRecipe: false
  }
}

// Returns cookie value if cookie name exists, undefined otherwise
export function checkForCookie(cookieName: string): string | undefined {
  const cookieArray = document.cookie.split(";")
  for (let i = 0; i < cookieArray.length; i++) {
    const keyValue = cookieArray[i].trim().split("=")
    if (keyValue.length > 1 && keyValue[0] === cookieName) {
      return keyValue[1]
    }
  }
  return undefined
}

/**
 * Sets the full-version cookie so Mobile Vision is not activated for smaller screens
 */
export function optOutOfMobileVision() {
  const date = new Date()
  date.setTime(date.getTime() + 100 * 24 * 60 * 60 * 1000)
  const expiration = date.toUTCString()
  document.cookie = `mobileFullVersion=true; expires=${expiration}; path=/`
}

/**
 * Clears the full-version cookie so Mobile Vision is activated for smaller screens
 */
export function optIntoMobileVision() {
  document.cookie = `mobileFullVersion=false; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
}

export function numberRangeToArray(
  min: number,
  max: number,
  step: number
): Array<number> {
  const length = Math.ceil((max - min) / step) + 1
  const values: Array<number> = new Array(length)
    .fill(0)
    .map((x, i) => i * step + min)
  return values
}
