import { UnitType } from "./unitType";

export class Unit {
  unitId: string;
  unitName: string;
  unitType: UnitType;
  locationId: string;
  serialNumber?: string;
  readonly connected: boolean;
  readonly currentAction?: string;
  readonly recipeName?: string;
  readonly alertsCount: number;
}
