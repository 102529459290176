import { Pipe, PipeTransform } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { _ } from "src/app/core/translation-marker"
import { UnitCategory } from "../api/model/unitCategory"

const unitCategoryTranslationMap: { [key in UnitCategory]: string } = {
  cookAndHold: _("UNIT_TYPE.COOK_AND_HOLD"),
  vector: _("UNIT_TYPE.VECTOR"),
  converge: _("UNIT_TYPE.CONVERGE"),
  prodigi: _("UNIT_TYPE.PRODIGI_PRO")
}

@Pipe({
  name: "unitCategory"
})
export class UnitCategoryPipe implements PipeTransform {
  constructor(private ts: TranslateService) {}

  transform(value: UnitCategory): string {
    const translationKey = unitCategoryTranslationMap[value]
    return translationKey ? this.ts.instant(translationKey) : ""
  }
}
