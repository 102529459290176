import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent implements OnInit {
  @Input()
  set value(val: number) {
    this.currentValue = val / 100;
    this.calculateDashLength();
  }

  @Input() size: number;
  @Input() backgroundColor?: string;
  @Input() color: string;
  @Input() showText = true;

  strokeWidth: number;
  radius: number;
  viewBox: string;
  dashArray: number;
  dashOffset: number;
  currentValue = 0;

  constructor() {}

  ngOnInit() {
    this.strokeWidth = this.size / 10;
    this.radius = this.size / 2 - this.strokeWidth / 2;
    this.viewBox = `0 0 ${this.size} ${this.size}`;
    this.dashArray = 2 * Math.PI * this.radius;
    this.calculateDashLength();
  }

  calculateDashLength() {
    if (this.dashArray) {
      this.dashOffset = this.dashArray * (1 - this.currentValue);
    }
  }
}
