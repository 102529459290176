import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IdAndLabel } from '../../core/idAndLabel';

@Component({
  selector: 'app-removable-items',
  templateUrl: './removable-items.component.html',
  styleUrls: ['./removable-items.component.scss']
})
export class RemovableItemsComponent {
  @Input() items: IdAndLabel[] = [];
  @Output() itemsChange = new EventEmitter<IdAndLabel[]>();

  constructor() {}

  removeItem(id: string) {
    this.items = this.items.filter(item => item.id !== id);
    this.itemsChange.emit(this.items);
  }
}
