import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core"
import { FormControl } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import * as moment from "moment"
import { Alert, AlertsService } from "src/app/api"
import { PagingParams } from "src/app/api/model/paging/PagingParams"
import { GlobalAlertService } from "src/app/core/global-alert.service"
import { AlertSearchParameters } from "../../api/model/AlertSearchParameters"
import { UnitTypePipe } from "../unit-type.pipe"

@Component({
  selector: "app-alerts-table",
  templateUrl: "./alerts-table.component.html",
  styleUrls: ["./alerts-table.component.scss"],
  providers: [UnitTypePipe]
})
export class AlertsTableComponent implements OnInit {
  @Input() dateRangeFormControl: FormControl
  @Input() unitTypeFormControl?: FormControl
  @Input() groupFormControl?: FormControl
  @Input() locationFormControl?: FormControl

  @Input() hideGoToUnitBtn: boolean
  @Input() hideLocationColumn: boolean
  @Input() hideUnitColumn: boolean
  @Input() locationIdToPass: string
  @Input() unitIdToPass: string

  rows: Array<Alert> = []
  alertSearchParams = new AlertSearchParameters()
  columns: Object[]
  pagingParams: PagingParams = new PagingParams(0, 10)

  @ViewChild("iconTpl", { static: true }) iconTpl: TemplateRef<any>
  @ViewChild("dateTpl", { static: true }) dateTpl: TemplateRef<any>
  @ViewChild("statusTpl", { static: true }) statusTpl: TemplateRef<any>
  @ViewChild("menuTpl", { static: true }) menuTpl: TemplateRef<any>
  @ViewChild("unitTypeTpl", { static: true }) unitTypeTpl: TemplateRef<any>
  @ViewChild("titleTpl", { static: true }) titleTpl: TemplateRef<any>

  constructor(
    private alertsService: AlertsService,
    private ts: TranslateService,
    public globalAlertService: GlobalAlertService
  ) {}

  ngOnInit() {
    this.columns = [
      {
        field: "title",
        name: this.ts.instant("ALERTS.ALERT"),
        cellTemplate: this.titleTpl
      }
    ]

    if (!this.hideUnitColumn) {
      this.columns.push(
        {
          field: "unitType",
          name: this.ts.instant("UNIT.UNIT_TYPE"),
          cellTemplate: this.unitTypeTpl
        },
        {
          field: "unitName",
          name: this.ts.instant("UNIT.NAME")
        }
      )
    }

    if (!this.hideLocationColumn) {
      this.columns.push({
        field: "locationName",
        name: this.ts.instant("LOCATION.NAME")
      })
    }

    this.columns.push(
      {
        field: "timestamp",
        name: this.ts.instant("GLOBAL.DATE_TIME"),
        cellTemplate: this.dateTpl
      },
      {
        field: "read",
        name: this.ts.instant("GLOBAL.STATUS"),
        cellTemplate: this.statusTpl
      },
      {
        cellTemplate: this.menuTpl,
        sort: false,
        maxWidth: 50
      }
    )

    if (this.groupFormControl) {
      this.groupFormControl.valueChanges.subscribe(val => {
        this.alertSearchParams.groupId = val || null
        this.loadData()
      })
    }

    if (this.locationFormControl) {
      this.locationFormControl.valueChanges.subscribe(val => {
        this.alertSearchParams.locationId = val || null
        this.loadData()
      })
    }

    if (this.unitTypeFormControl) {
      this.unitTypeFormControl.valueChanges.subscribe(val => {
        this.alertSearchParams.unitType = val || null
        this.loadData()
      })
    }

    this.dateRangeFormControl.valueChanges.subscribe(val => {
      this.alertSearchParams.start =
        (val && val[0] && val[0].toISOString()) || null
      this.alertSearchParams.end =
        (val && val[1] && val[1].toISOString()) || null
      this.loadData()
    })

    if (this.locationIdToPass) {
      this.alertSearchParams.locationId = this.locationIdToPass
    }

    if (this.unitIdToPass) {
      this.alertSearchParams.unitId = this.unitIdToPass
    }
    this.setResolvedFilter(false)
  }

  markAsRead(alertId: string) {
    this.globalAlertService.markAsRead(alertId).subscribe(() => {
      this.loadData()
    })
  }

  setResolvedFilter(resolved: boolean) {
    this.alertSearchParams.unread = !resolved
    this.loadData()
  }

  loadData() {
    this.alertsService.getAlerts(this.alertSearchParams).subscribe(data => {
      this.rows = data
    })
  }

  toLocalTimezone(timestamp: string): string {
    return moment
      .utc(timestamp)
      .add(moment().utcOffset(), "minutes")
      .format("YYYY-MM-DD h:mm A")
  }
}
