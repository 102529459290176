import { Pipe, PipeTransform } from "@angular/core"
import { RecipeLibrary } from "../api"

interface LibraryIconPipeArgs {
  color: "red" | "primary-dark" | "white"
}

@Pipe({
  name: "libraryIcon"
})
export class LibraryIconPipe implements PipeTransform {
  transform(
    value: RecipeLibrary,
    args: LibraryIconPipeArgs = { color: "red" }
  ): string {
    let iconName = "group"
    if (value && value.locationId != null) {
      iconName = "locations"
    }
    return `/assets/static/images/icons/${iconName}-${args.color}.svg`
  }
}
