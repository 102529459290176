import { Component, OnInit, ViewChild } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import {
  UnitSettingsService,
  UnitSettingsTemplate,
  UnitType
} from "src/app/api"
import { UnitService } from "src/app/api/api/unit.service"
import { DialogModalService } from "src/app/core/dialog-modal.service"
import { checkFormValidity } from "src/app/core/utils"
import { celsiusToFahrenheit } from "src/app/shared/temperature/temp-utils"
import { UnitSettingsFormComponent } from "src/app/shared/unit-settings-form/unit-settings-form.component"

@Component({
  selector: "app-create-unit-settings",
  templateUrl: "./create-unit-settings.component.html",
  styleUrls: ["./create-unit-settings.component.scss"]
})
export class CreateUnitSettingsComponent implements OnInit {
  @ViewChild(UnitSettingsFormComponent, { static: false })
  unitSettingsForm: UnitSettingsFormComponent

  settingsId: string
  completeUnitSettingsForm: FormGroup
  unitSetting: UnitSettingsTemplate
  formControl = new FormControl(undefined, Validators.required)
  unitTypes = Object.values(UnitType)
  unitType = UnitType
  isLoading = false
  loading = false
  typeSelected = false
  completeUnitSettingsFormValue: any

  constructor(
    private router: Router,
    private unitService: UnitService,
    private unitSettingsService: UnitSettingsService,
    private dialogService: DialogModalService,
    private ts: TranslateService,
    private route: ActivatedRoute
  ) {
    let unitSettingValues: UnitSettingsTemplate

    route.params.subscribe(params => {
      this.settingsId = params["settingsId"]
    })
    route.data.subscribe(data => {
      unitSettingValues = data.unitSetting as UnitSettingsTemplate
      this.unitSetting = unitSettingValues
      if (unitSettingValues) {
        this.formControl.patchValue(unitSettingValues.unitType)
        this.selectUnitType()
        this.completeUnitSettingsForm.patchValue(unitSettingValues)
      } else {
        this.unitService.getTypes().subscribe(types => {
          this.unitTypes = types
        })
      }
    })

    const currNav = this.router.getCurrentNavigation()
    if (currNav != null) {
      const state = currNav.extras.state
      if (state) {
        this.formControl.setValue(state["unit"])
      }
    }
  }

  ngOnInit() {}

  back() {
    history.back()
  }

  backToUnitTypeSelection() {
    if (this.settingsId === undefined) {
      this.typeSelected = false
    } else {
      this.back()
    }
  }

  selectUnitType() {
    this.isLoading = true
    if (this.formControl.value) {
      this.unitType = this.formControl.value
      this.typeSelected = true
      this.completeUnitSettingsForm = this.getCompleteUnitSettingsForm()
    }
    this.isLoading = false
  }

  getCompleteUnitSettingsForm() {
    return new FormGroup({
      name: new FormControl("", [
        Validators.required,
        Validators.maxLength(255)
      ]),
      unitType: new FormControl(this.formControl.value, [Validators.required]),
      description: new FormControl("", [Validators.maxLength(255)])
    })
  }

  save() {
    // this.completeUnitSettingsFormValue = this.completeUnitSettingsForm.getRawValue();

    this.completeUnitSettingsForm.addControl(
      "settings",
      this.unitSettingsForm.unitSettingsForm
    )
    this.completeUnitSettingsFormValue = this.completeUnitSettingsForm.getRawValue() as UnitSettingsTemplate
    unitSettingsTransformations(this.completeUnitSettingsFormValue)

    if (checkFormValidity(this.completeUnitSettingsForm)) {
      this.loading = true

      if (this.settingsId) {
        this.unitSettingsService
          .editUnitSettingsItem(
            this.settingsId,
            this.completeUnitSettingsFormValue
          )
          .subscribe(() => {
            this.loading = false
            this.completeUnitSettingsForm.markAsPristine()
            this.router.navigate(["/", "unitSettings"])
          })
      } else {
        this.unitSettingsService
          .createUnitSettings(this.completeUnitSettingsFormValue)
          .subscribe(() => {
            this.loading = false
            this.completeUnitSettingsForm.markAsPristine()

            this.dialogService
              .success({
                icon: "settings.svg",
                message: this.ts.instant("UNIT_SETTINGS.SAVE_MESSAGE", {
                  unitSettingsName: this.completeUnitSettingsFormValue.name
                }),
                detail: this.ts.instant("UNIT_SETTINGS.SAVE_DETAIL"),
                acceptButtonLabel: this.ts.instant("GLOBAL.CONTINUE")
              })
              .close$.subscribe(() => {
                this.router.navigate(["/", "unitSettings"])
              })
          })
      }
    }
  }
}

export function unitSettingsTransformations(
  unitSettingsTemplate: UnitSettingsTemplate
) {
  const unitSettingsRaw = unitSettingsTemplate.settings as any
  // Added screen brightness field. Currently Vector/Cook and Hold use screen.brightness and
  // converge uses settings.screenBrightness. Set both just in case, and the other will be ignored
  unitSettingsRaw.screen.brightness = unitSettingsRaw.settings.screenBrightness

  // Convert lock timeout from minutes to milliseconds
  const timeout = unitSettingsRaw.settings.autoScreenLockTimeout
  unitSettingsRaw.settings.autoScreenLockTimeout = timeout
    ? timeout * 60 * 1000
    : timeout

  // Convert string time 00:00 to minutes
  const stringTime = unitSettingsRaw.cleaning.cleanFreqTime
  if (typeof stringTime === "string" || stringTime instanceof String) {
    const timeArray = stringTime.split(":")
    unitSettingsRaw.cleaning.cleanFreqTime =
      parseInt(timeArray[0], 10) * 60 + parseInt(timeArray[1], 10)
  }

  // Convert temperature to °F to send to oven if user is working with °C
  if (
    unitSettingsRaw.localization.tempUnit !== null &&
    !unitSettingsRaw.localization.tempUnit
  ) {
    for (const chamber in unitSettingsRaw.temperature) {
      if (unitSettingsRaw.temperature[chamber]) {
        unitSettingsRaw.temperature[chamber] = celsiusToFahrenheit(
          unitSettingsRaw.temperature[chamber]
        )
      }
    }
  }
  // Convert temperature settings toggle from boolean to int
  unitSettingsRaw.localization.tempUnit = Number(
    unitSettingsRaw.localization.tempUnit
  )

  // visible cleans are a separate data structure from settings but are edited in the same form.
  // Move visible cleans out of the raw settings and into the top level object
  if (unitSettingsRaw.visibleCleanings) {
    unitSettingsTemplate.visibleCleanings =
      unitSettingsRaw.visibleCleanings.visibleCleanings
    delete unitSettingsRaw.visibleCleanings
  }
}
