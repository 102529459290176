import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { contains, FiltrableDataSource } from 'filtrable-data-source';
import { debounceTime } from 'rxjs/operators';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';

@Component({
  selector: 'app-data-source-search-field',
  templateUrl: './data-source-search-field.component.html',
  styleUrls: ['./data-source-search-field.component.scss']
})
export class DataSourceSearchFieldComponent implements OnInit {
  @Input() dataSource: FiltrableDataSource<any>;
  @Input() selectedHandler?: SelectedItemsHandler<any>;
  @Input() field: string;
  @Input() placeholder: string;

  formControl = new FormControl('');

  constructor() {}

  ngOnInit() {
    this.formControl.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      if (val) {
        this.dataSource.setFilter(this.field, contains(val));
      } else {
        this.dataSource.removeFilter(this.field);
      }
      this.dataSource.applyFilters();
      if (this.selectedHandler) {
        this.selectedHandler.items = [];
      }
    });
  }
}
