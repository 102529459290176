import { Component, Input, OnInit } from '@angular/core';
import { Location } from 'src/app/api';

@Component({
  selector: 'app-location-summary',
  templateUrl: './location-summary.component.html',
  styleUrls: ['./location-summary.component.scss']
})
export class LocationSummaryComponent implements OnInit {
  @Input() location: Location;
  @Input() basePath: string[] = [];

  constructor() {}

  ngOnInit() {}

  getLink() {
    return this.basePath.concat(this.location.locationId);
  }
}
