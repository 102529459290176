import { Pipe, PipeTransform } from "@angular/core"

/**
 * Sorts an array in ascending order. Accepts either an array of objects or an array of primitives.
 * If provided an array of primitives, the array is sorted in natural order.
 * If provided an array of objects and a property name, the array is sorted by the given property.
 * If the given property's value is a string, it is lowercased before sorting.
 * If no target property is provided, it is assumed that the array is an array of primitives.
 *
 * Usage:
 *
 * <*ngfor="dataArray | sort: 'propertyName'">
 *
 * where `propertyName` should be omitted if `dataArray` is an array of primitives.
 */
@Pipe({
  name: "sort"
})
export class SortPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return array
    }
    if (field) {
      array.sort((a: any, b: any) => {
        let valueA = a[field]
        if (typeof valueA === "string" || valueA instanceof String) {
          valueA = valueA.toLowerCase()
        }
        let valueB = b[field]
        if (typeof valueB === "string" || valueB instanceof String) {
          valueB = valueB.toLowerCase()
        }
        if (valueA < valueB) {
          return -1
        } else if (valueA > valueB) {
          return 1
        } else {
          return 0
        }
      })
    } else {
      // This is an array of primitives, so we'll sort in natural order
      array.sort()
    }
    return array
  }
}
