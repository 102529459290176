import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { StageType } from "src/app/api/model/models"
import { modeToStageType } from "../form-groups"

@Component({
  selector: "app-converge-step",
  templateUrl: "./converge-step.component.html",
  styleUrls: ["./converge-step.component.scss"]
})
export class ConvergeStepComponent implements OnInit {
  @Input() formGroup: FormGroup
  @Input() unitType: string
  @Input() index: number
  @Input() editingEnabled = true
  @Output() edit = new EventEmitter<void>()
  @Output() delete = new EventEmitter<void>()

  private stageType: StageType
  private modeMap = {} as {
    [key: string]: any;
  }

  constructor() {
    this.modeMap[`${StageType.combi}`] = {
      title: "RECIPE_LIBRARY.EDITOR.CONVECTION",
      image: "cooking-primary-dark.svg"
    }
    this.modeMap[`${StageType.stage}`] = {
      title: "RECIPE_LIBRARY.EDITOR.CONVECTION",
      image: "cooking-primary-dark.svg"
    }
    this.modeMap[`${StageType.combi}`] = {
      title: "RECIPE_LIBRARY.EDITOR.COMBI",
      image: "combi.png"
    }
    this.modeMap[`${StageType.steam}`] = {
      title: "RECIPE_LIBRARY.EDITOR.STEAM",
      image: "steam.png"
    }
  }

  ngOnInit(): void {
    this.stageType = modeToStageType(this.formGroup.value.mode)
  }

  getStepTitle(): string {
    return this.modeMap[this.stageType.toString()].title
  }

  getStepImageName(): string {
    return this.modeMap[this.stageType.toString()].image
  }
}
