import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { TableColumn } from "@swimlane/ngx-datatable"
import { Unit, UnitLastStatus, UnitsService } from "src/app/api"
import { MessageService } from "src/app/core/message.service"
import { TemperatureTypePipe } from "src/app/shared/temperature-type.pipe"

@Component({
  selector: "app-unit-overview",
  templateUrl: "./unit-overview.component.html",
  styleUrls: ["./unit-overview.component.scss"],
  providers: [TemperatureTypePipe]
})
export class UnitOverviewComponent implements OnInit {
  unit: Unit
  unitOverviewData: UnitLastStatus
  chambers: Array<any> = []
  columns: TableColumn[]

  @ViewChild("chamberNumberTpl", { static: true })
  chamberNumberTpl: TemplateRef<any>
  @ViewChild("chamberRecipeTpl", { static: true })
  chamberRecipeTpl: TemplateRef<any>
  @ViewChild("chamberTemperatureTpl", { static: true })
  chamberTemperatureTpl: TemplateRef<any>

  constructor(
    private unitsService: UnitsService,
    private route: ActivatedRoute,
    private ts: TranslateService,
    private message: MessageService,
    public temperaturePipe: TemperatureTypePipe
  ) {}

  ngOnInit() {
    if (this.route.parent!.snapshot.data) {
      this.unitOverviewData = this.route.parent!.snapshot.data.unitOverviewData
      this.unit = this.route.parent!.snapshot.data.unit

      this.manageUnitDate()
      this.manageChambers()
    }

    this.columns = [
      {
        prop: "chamberNumber",
        name: this.ts.instant("UNIT.CHAMBER"),
        cellTemplate: this.chamberNumberTpl,
        minWidth: 200
      },
      {
        prop: "recipe",
        name:
          this.ts.instant("GLOBAL.RECIPE") +
          " / " +
          this.ts.instant("UNIT.STEP"),
        cellTemplate: this.chamberRecipeTpl,
        minWidth: 400
      },
      {
        prop: "cookingMode",
        name: this.ts.instant("UNIT.COOKING_MODE"),
        minWidth: 200
      },
      {
        prop: "chamberTemperatures",
        name: this.ts.instant("UNIT.ACTUAL_TARGET_TEMP"),
        cellTemplate: this.chamberTemperatureTpl,
        minWidth: 500
      }
    ]
  }

  refresh() {
    if (this.route.parent!.snapshot.data) {
      this.unitOverviewData = this.route.parent!.snapshot.data.unitOverviewData
      this.unit = this.route.parent!.snapshot.data.unit

      this.manageUnitDate()
      this.manageChambers()
      this.message.updateUnit()
    }
  }

  manageUnitDate() {
    if (this.unitOverviewData) {
      const currDate = new Date(this.unitOverviewData.timestamp)

      this.unitOverviewData.timestamp =
        currDate.valueOf() === NaN
          ? this.unitOverviewData.timestamp
          : new Date().toISOString()
    }
  }

  manageChambers() {
    this.chambers = []

    if (
      this.unitOverviewData &&
      this.unitOverviewData.status &&
      this.unitOverviewData.status.chamberTemperatures &&
      this.unitOverviewData.status.recipeName &&
      this.unitOverviewData.status.chamberTemperatures.length ===
        this.unitOverviewData.status.recipeName.length
    ) {
      for (
        let i = 0;
        i < this.unitOverviewData.status.chamberTemperatures.length;
        i++
      ) {
        // According to V1: SOMETIMES we get "recipe_not_found" instead of a recipe name
        // V1 implementation skipped these cases
        if (this.unitOverviewData.status.recipeName[i] === "recipe_not_found") {
          continue
        }
        this.chambers.push({
          chamberNumber: i + 1,
          recipe: this.unitOverviewData.status.recipeName[i],
          recipeStep: this.unitOverviewData.status.recipeStep[i] + 1,
          cookingMode: this.unitOverviewData.status.cookingMode
            ? this.unitOverviewData.status.cookingMode[i]
            : "none",
          chamberTemperatures: this.unitOverviewData.status.chamberTemperatures[
            i
          ],
          targetTemperatures: this.unitOverviewData.status.targetTemperatures[i]
        })
      }
    }
  }
}
