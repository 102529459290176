import { ReturnStatement } from "@angular/compiler"
import { MimeType } from "../consts/remote-support/enums"

export namespace FileUtil {
  export const PATH_SEPARATOR = "/"

  export const EXT_JPEG = ".jpeg"
  export const EXT_JPG = ".jpg"
  export const EXT_PNG = ".png"
  export const EXT_GIF = ".gif"
  export const EXT_MP4 = ".mp4"
  export const EXT_WEBM = ".webm"
  export const EXT_MOV = ".mov"
  export const EXT_JSON = ".json"
  export const EXT_TXT = ".txt"
  export const EXT_BIN = ".bin"

  // Different options for the encoding to send to the device
  export const enum ENCODING_OPTIONS {
    HEX_ENCODING = "hex",
    UTF8_ENCODING = "utf-8"
  }

  export function decodeUint8Array(data: Uint8Array): string {
    return new TextDecoder(ENCODING_OPTIONS.UTF8_ENCODING).decode(data)
  }

  export function decodeUint8ArrayToJson(data: Uint8Array): any {
    return JSON.parse(
      new TextDecoder(ENCODING_OPTIONS.UTF8_ENCODING).decode(data)
    )
  }

  export function getMimeType(fileName: string): MimeType {
    const ext = getExtension(fileName)
    switch (ext) {
      case EXT_JPEG:
      case EXT_JPG:
        return MimeType.jpeg
      case EXT_PNG:
        return MimeType.png
      case EXT_GIF:
        return MimeType.gif
      case EXT_MP4:
        return MimeType.mp4
      case EXT_WEBM:
        return MimeType.webm
      case EXT_MOV:
        return MimeType.mov
      case EXT_JSON:
        return MimeType.json
      case EXT_TXT:
        return MimeType.text
      case EXT_BIN:
        return MimeType.octet_stream
      default:
        return MimeType.text
    }
  }

  export function getExtFromMimeType(mimeType: string): string {
    mimeType = mimeType.split(";")[0] // Strip off any provided codecs
    switch (mimeType) {
      case MimeType.jpeg:
        return EXT_JPG
      case MimeType.png:
        return EXT_PNG
      case MimeType.gif:
        return EXT_GIF
      case MimeType.mp4:
        return EXT_MP4
      case MimeType.webm:
        return EXT_WEBM
      case MimeType.mov:
        return EXT_MOV
      case MimeType.json:
        return EXT_JSON
      case MimeType.octet_stream:
        return EXT_BIN
      case MimeType.text:
      default:
        return EXT_TXT
    }
  }

  export function getExtension(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf("."))
  }

  export function saveBytesToFile(data: Uint8Array, mimeType: MimeType): File {
    return new Blob([data], { type: mimeType }) as File
  }

  export async function getBytesFromFile(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer
        const bytes = new Uint8Array(arrayBuffer)
        resolve(bytes)
      }
      reader.onerror = () => {
        reject(new Error("Failed to read file."))
      }
      reader.readAsArrayBuffer(file)
    })
  }
}
