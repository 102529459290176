import { Location } from "@angular/common"
import { Component, ElementRef, HostListener, OnInit } from "@angular/core"
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { BsModalService } from "ngx-bootstrap/modal"
import { ToastrService } from "ngx-toastr"
import { forkJoin, Observable, of } from "rxjs"
import { finalize, map, mergeMap } from "rxjs/operators"
import {
  eSecondaryState,
  RecipeBody,
  RecipeImage,
  RecipeLibrary,
  RecipeService,
  UnitsService,
  UnitType
} from "src/app/api"
import { RecipeStep } from "src/app/api"
import { GlobalLibraryService } from "src/app/api/api/global-library.service"
import { IRecipeEditService } from "src/app/api/model/recipeEditService"
import {
  AuthenticationService,
  UserTempUnit
} from "src/app/core/authentication.service"
import { DialogModalService } from "src/app/core/dialog-modal.service"
import { IdAndLabel } from "src/app/core/idAndLabel"
import { LibrariesService } from "src/app/core/libraries.service"
import { PermissionService } from "src/app/core/permission.service"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"
import { getAllFormErrors, parseRecipeRouteData } from "src/app/core/utils"
import { CategoryAssignComponent } from "src/app/shared/category-assign/category-assign.component"
import { _ } from "../../core/translation-marker"
import { htmlValidator } from "../directives/form-validation/html-input.directive"
import { NoWhitespaceValidator } from "../directives/form-validation/no-whitespace.directive"
import FormUtils from "../form-util"
import { TemperatureTypePipe } from "../temperature-type.pipe"
import { celsiusToFahrenheit } from "../temperature/temp-utils"
import { EditVisibilityModalComponent } from "./edit-visibility-modal/edit-visibility-modal.component"
import { ImagesUploadModalComponent } from "./images-upload-modal/images-upload-modal.component"
import {
  convertStepValues,
  fillGroupFormArrayRecipe,
  fillImagesFormArrayFromRecipe,
  fillRecipeActionImagesFromRecipe,
  fillStepsFormArrayFromRecipe,
  getRecipeDidByUnitType,
  isActionStep,
  isCookStep
} from "./recipe-steps-editor/form-groups"

@Component({
  selector: "app-edit-recipe",
  templateUrl: "./edit-recipe.component.html",
  styleUrls: ["./edit-recipe.component.scss"]
})
export class EditRecipeComponent implements OnInit {
  recipeId: string
  unitId: string
  unitType: UnitType
  isUnitRecipe: boolean
  isGlobalRecipe: boolean
  isLoading = false
  categories: IdAndLabel[] = []
  recipeForm: FormGroup
  userTemp: UserTempUnit
  imagesToDelete: Set<string>
  // Recipe creation is done across two forms, this stores the info entered in the
  // previous form
  creationDetails?: any
  private originalLibraryId: string
  private recipeEditService: IRecipeEditService

  errorMsgs = {
    containsHtml: _("VALIDATION.HTML"),
    whitespace: _("VALIDATION.WHITESPACE")
  }

  @HostListener("window:beforeunload", ["$event"])
  onBeforeUnload(event: BeforeUnloadEvent) {
    if (this.hasUnsavedData()) {
      event.preventDefault()
      event.returnValue = ""
    }
  }

  get contentFormGroup() {
    return this.recipeForm.controls["content"] as FormGroup
  }

  get stepsFormArray() {
    return this.contentFormGroup.controls["steps"] as FormArray
  }

  get imageFormArray() {
    return this.contentFormGroup.controls["image"] as FormArray
  }

  get groupFormArray() {
    return this.contentFormGroup.controls["group"] as FormArray
  }

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private recipeService: RecipeService,
    private globalRecipeService: GlobalLibraryService,
    private dialogService: DialogModalService,
    public auth: PermissionService,
    private ts: TranslateService,
    private router: Router,
    private modalService: BsModalService,
    private unitsService: UnitsService,
    private formBuilder: FormBuilder,
    private librariesService: LibrariesService,
    private authService: AuthenticationService,
    private toastService: ToastrService,
    private el: ElementRef,
    private temperatureTypePipe: TemperatureTypePipe
  ) {
    this.userTemp = this.authService.getUserTemp()
    this.creationDetails = route.snapshot.params["unitType"]
      ? { ...route.snapshot.params }
      : undefined
    route.params.subscribe(params => {
      this.recipeId = params["recipeId"]
      this.unitId = params["unitId"] // this can be undefined in case of library recipe
    })

    let recipeBody: RecipeBody
    route.data.subscribe(data => {
      const recipeData = parseRecipeRouteData(data, this.creationDetails)
      recipeBody = recipeData.recipeBody
      this.isUnitRecipe = recipeData.isUnitRecipe
      this.isGlobalRecipe = recipeData.isGlobalRecipe
      this.unitType = recipeData.unitType
      this.imagesToDelete = new Set<string>()
      this.recipeEditService = recipeData.isGlobalRecipe
        ? this.globalRecipeService
        : this.recipeService

      if (recipeBody) {
        // building form conditionally
        this.recipeForm = new FormGroup({
          unitType: new FormControl(
            undefined,
            !this.isUnitRecipe ? Validators.required : null
          ),
          libraryId: new FormControl(
            undefined,
            !(this.isUnitRecipe || this.isGlobalRecipe)
              ? Validators.required
              : null
          ),
          content: new FormGroup({
            name: new FormControl("", [
              Validators.required,
              htmlValidator(),
              NoWhitespaceValidator()
            ]),
            description: new FormControl(""),
            group: new FormArray([]),
            guid: new FormControl(""),
            image: new FormArray([]), // start with an empty form in the cover image
            steps: new FormArray([]),
            dID: new FormControl(getRecipeDidByUnitType(this.unitType)),
            menu: new FormArray([]),
            runs: new FormControl(0),
            sound: new FormControl("default")
          }),
          showGlobally: new FormControl(false),
          featureGlobally: new FormControl(false)
        })
        this.setRecipeValue(recipeBody)
        this.originalLibraryId = recipeBody.libraryId
      }
    })
  }

  ngOnInit() {}

  hasUnsavedData(): boolean {
    return this.recipeForm.dirty
  }

  back() {
    if (this.isUnitRecipe) {
      this.location.back()
    } else if (this.isGlobalRecipe) {
      this.router.navigate(["/global-library"])
    } else {
      // This recipe was edited from the library recipe list page, so need to go back twice to get to original library recipe list page.
      const libraryId =
        this.originalLibraryId ||
        (this.creationDetails || {}).libraryId ||
        this.recipeForm.controls["libraryId"].value
      this.router.navigate(["/recipeLibrary", "libraries", libraryId])
    }
  }

  removeGroup(id: string) {
    const idx = this.groupFormArray.value.findIndex((x: any) => x.name === id)
    this.groupFormArray.removeAt(idx)
  }

  openCategoriesModal() {
    // type any because of ts bug
    // https://github.com/Microsoft/TypeScript/issues/28582
    this.categories = this.groupFormArray.value.map((data: any) => {
      const cat = {
        id: data.name,
        label: data.name
      }
      return cat
    })

    const func: any = !this.isUnitRecipe
      ? this.recipeEditService.getRecipeCategories.bind(this.recipeEditService)
      : this.unitsService.getUnitCookbookCategories.bind(
          this.unitsService,
          this.unitId
        )

    const initialState: Partial<CategoryAssignComponent> = {
      id: !this.isUnitRecipe
        ? this.recipeForm.controls["libraryId"].value
        : undefined,
      selectedCategories: this.categories,
      onlyFiltering: false,
      queryFunction: func,
      isGlobal: this.isGlobalRecipe
    }
    const modalRef = this.modalService.show(CategoryAssignComponent, {
      initialState
    });
    (modalRef.content as CategoryAssignComponent).result.subscribe(
      (res: IdAndLabel[]) => {
        res.map(c => {
          this.groupFormArray.push(
            this.formBuilder.group({
              name: c.id,
              position: 0
            })
          )
        })
        this.recipeForm.markAsDirty()
      }
    )
  }

  openVisibilityModal() {
    const modalRef = this.modalService.show(EditVisibilityModalComponent)
    const component = modalRef.content as EditVisibilityModalComponent
    component.librarySelected.subscribe((l: RecipeLibrary) => {
      this.librariesService.refresh().then(() => {
        this.recipeForm.controls["libraryId"].setValue(l.libraryId)
      })
    })
  }

  deleteCoverPhoto(toDelete: RecipeImage) {
    if (this.canDeleteImage(toDelete)) {
      if (!toDelete.isPendingUpload) {
        this.imagesToDelete.add(toDelete.id!)
      }
    } else {
      // if the image is being used as an action step, move it into the gallery images
      this.imageFormArray.push(new FormControl(toDelete))
      this.toastService.info(
        this.ts.instant("RECIPE_LIBRARY.EDITOR.DELETE_ACTION_COVER_IMAGE")
      )
    }
    this.imageFormArray.setControl(0, new FormControl({}))
    this.imageFormArray.markAsDirty()
  }

  // use anonymous function to avoid scope binding issues
  canDeleteImage = (image: RecipeImage) => {
    // Do not allow the deletion of any images that are being used as
    return !(this.recipeForm.value as RecipeBody).content.steps!.some(
      step => step.visual && (step.visual as RecipeImage).id === image.id
    )
  };

  deleteSelectedImages(toDelete: RecipeImage[]) {
    toDelete.forEach(image => {
      if (!image.isPendingUpload) {
        // if an image was already synced with the cloud, mark it for deletion when
        // the form is submitted
        this.imagesToDelete.add(image.id!)
      }
    })
  }

  // Mixing promises and observables is probably not the intended
  // use case
  deleteStaleImages() {
    if (this.imagesToDelete.size === 0) {
      return of([])
    }
    return forkJoin(
      [...this.imagesToDelete].map(imgId =>
        this.recipeEditService.deleteRecipeImage(this.recipeId, imgId)
      )
    ).pipe(o => {
      this.imagesToDelete.clear()
      return o
    })
  }

  uploadAndUpdateImages() {
    const toUpload: RecipeImage[] = this.imageFormArray.value.filter(
      (image: RecipeImage) => {
        return image.id !== null && image.id !== undefined
      }
    )
    if (toUpload.length === 0) {
      return of([])
    }
    return forkJoin(
      toUpload.map((v: RecipeImage) =>
        this.recipeEditService.uploadRecipeImage(this.recipeId, {
          existingImageId: v.id,
          isCover: v.isCover,
          mimeType: v.mimeType!,
          name: v.name,
          data: v.data!,
          sortOrder: v.sortOrder
        })
      )
    )
  }

  adminCopyToGlobalLibrary(): Observable<void> {
    const { showGlobally, featureGlobally } = this.recipeForm.value
    if (this.auth.permissions.culinary_edit_view && showGlobally) {
      return this.recipeService.duplicateRecipeToGlobalLibrary(
        this.originalLibraryId,
        this.recipeId,
        featureGlobally
      )
    } else {
      return of(undefined)
    }
  }

  editRecipeOrCookbook(recipeBody: RecipeBody) {
    if (this.isUnitRecipe) {
      return this.unitsService.editCookbookRecipe(
        this.unitId,
        this.recipeId,
        recipeBody.content
      )
    } else if (
      this.creationDetails &&
      !this.recipeId &&
      this.recipeEditService.createRecipe
    ) {
      return this.recipeEditService
        .createRecipe(recipeBody)
        .pipe(map(rId => (this.recipeId = rId)))
    } else {
      return this.recipeEditService.editRecipe(this.recipeId, recipeBody)
    }
  }

  updateRecipeFormImageValues(recipeBody: RecipeBody) {
    // Grab the first image in the array to use as the cover
    if (
      this.imageFormArray.value.length > 0 &&
      (this.imageFormArray.value[0] as RecipeImage).id != null
    ) {
      recipeBody.coverImageId = this.imageFormArray.value[0]
      this.imageFormArray.value[0].isCover = true
    } else {
      // If the cover image was removed and we're promoting a new image to cover
      this.imageFormArray.value.every((element: RecipeImage) => {
        if (element.id != null) {
          recipeBody.coverImageId = element
          element.isCover = true
          return false
        }
        return true
      })
    }
    // Set ordering of images
    let sort = 0
    this.imageFormArray.value.forEach((image: RecipeImage) => {
      if (image.id) {
        image.sortOrder = sort
        sort += 10
      }
    })
    // replace the full image form values inside of the action visuals with
    // just their ids for serialization to the database
    recipeBody.content.steps!.forEach(step => {
      // TODO something has gone wrong if we need to explicitly check a variable type
      if (step.visual && !(typeof step.visual === "string")) {
        step.visual = (step.visual as RecipeImage).id || ""
      }
    })
  }

  save() {
    if (!this.recipeForm.valid) {
      this.recipeForm.markAllAsTouched()
      FormUtils.scrollToFirstInvalidControl(this.el)
      return
    }

    if (this.userTemp === "°C") {
      this.stepsFormArray.value.forEach((step: RecipeStep) => {
        if (!isActionStep(step, this.userTemp)) {
          convertStepValues(step, celsiusToFahrenheit, true)
        }

        if (
          isCookStep(step, this.userTemp) &&
          step.smoker === true &&
          step.cavitySetpoint === 59
        ) {
          step.cavitySetpoint = 60
        }
      })
    }

    const recipeBody = this.recipeForm.value as RecipeBody
    recipeBody.content.guid = this.recipeId

    // Recipe name must be filled in
    // TODO: Why does this need to be checked if the field is required? Does this mean the object assignment above is failing?
    if (
      recipeBody.content.name === null ||
      recipeBody.content.name === undefined ||
      recipeBody.content.name === ""
    ) {
      console.error("ERROR: Invalid recipe. Must specify a recipe name.")
      this.dialogService.danger({
        title: this.ts.instant("GLOBAL.ERROR"),
        message: this.ts.instant(
          "RECIPE_LIBRARY.EDITOR.CANNOT_SAVE_INVALID_RECIPE"
        )
      })
      return null
    } else {
      recipeBody.content.name = recipeBody.content.name.trim()
    }

    // The recipe must contain at least one stage step (ie not only action steps and > 0 steps).
    if (
      recipeBody.content.steps === null ||
      recipeBody.content.steps === undefined ||
      recipeBody.content.steps.length < 1 ||
      // Filter steps down to only the stages. This is not well defined, so we'll do this by filtering out the actions.
      recipeBody.content.steps.filter(
        step => step.mode.secondary !== eSecondaryState.action
      ).length < 1
    ) {
      this.dialogService.danger({
        title: this.ts.instant("GLOBAL.ERROR"),
        message: this.ts.instant("RECIPE_LIBRARY.RECIPE_NEEDS_STAGE")
      })
      return null
    }

    this.updateRecipeFormImageValues(recipeBody)

    this.isLoading = true

    const saveMsg = this.isUnitRecipe
      ? this.ts.instant("RECIPE_LIBRARY.EDITOR.SAVE_DETAIL_UNIT")
      : this.ts.instant("RECIPE_LIBRARY.EDITOR.SAVE_DETAIL")

    this.editRecipeOrCookbook(recipeBody)
      .pipe(mergeMap(() => this.deleteStaleImages()))
      .pipe(mergeMap(() => this.uploadAndUpdateImages()))
      .pipe(mergeMap(() => this.adminCopyToGlobalLibrary()))
      .pipe(
        finalize(() => {
          this.isLoading = false
        })
      )
      .subscribe(() => {
        this.recipeForm.markAsPristine()
        this.librariesService.refresh().then(() => {
          this.dialogService
            .success({
              icon: "recipe-white.svg",
              message: this.ts.instant("RECIPE_LIBRARY.EDITOR.SAVE_MESSAGE", {
                recipeName: recipeBody.content.name
              }),
              detail: saveMsg,
              acceptButtonLabel: this.ts.instant("GLOBAL.CONTINUE")
            })
            .close$.subscribe(() => {
              this.back()
            })
        })
      })
  }

  private setRecipeValue(value: RecipeBody) {
    this.recipeForm.patchValue(value)
    fillStepsFormArrayFromRecipe(
      value,
      this.stepsFormArray,
      this.userTemp,
      this.temperatureTypePipe
    )
    fillImagesFormArrayFromRecipe(value, this.imageFormArray)
    fillGroupFormArrayRecipe(value, this.groupFormArray)
  }
}
