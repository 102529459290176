import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-stage-step',
  templateUrl: './stage-step.component.html',
  styleUrls: ['./stage-step.component.scss']
})
export class StageStepComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() unitType: string;
  @Input() index: number;
  @Input() editingEnabled = true;
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
