/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type UnitDoorStatus = 'OPEN' | 'CLOSE';

export const UnitDoorStatus = {
  OPEN: 'OPEN' as UnitDoorStatus,
  CLOSE: 'CLOSE' as UnitDoorStatus
};
