import { HttpEvent, HttpEventType } from "@angular/common/http"
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { BsModalRef } from "ngx-bootstrap/modal"
import { Observable, Subscription } from "rxjs"
import { ImageBase64, RecipeImage, RecipeService } from "src/app/api"
import {
  convertSizeToMb,
  fromFileContentToBase64Image,
  readFileContent
} from "src/app/core/file-utils"
import { v4 as uuidv4 } from "uuid"

interface ImageUploadStatus {
  fileName: string
  fileSize: string
  percent: number
  imageId?: RecipeImage
  error: boolean
  subscription?: Subscription
}

@Component({
  selector: "app-images-upload-modal",
  templateUrl: "./images-upload-modal.component.html",
  styleUrls: ["./images-upload-modal.component.scss"]
})
export class ImagesUploadModalComponent implements OnInit {
  @Input() title: string
  @Input() message: string
  @Input() maxFiles?: number
  @Input() recipeId: string
  @Input() isCover: boolean
  @Input() sortOrder: number
  @Input() layeredModal = false
  @Output() uploadedIds = new EventEmitter<RecipeImage[]>()

  uploading = false
  uploadsStatus: ImageUploadStatus[] = []

  constructor(
    private modalRef: BsModalRef,
    private recipeService: RecipeService
  ) {}

  ngOnInit() {}

  dismiss() {
    this.modalRef.hide()
  }

  close() {
    this.modalRef.hide()
    this.uploadedIds.emit(
      this.uploadsStatus.filter(s => s.imageId).map(s => s.imageId!)
    )
  }

  cancelUpload(index: number) {
    const status = this.uploadsStatus[index]
    if (status) {
      if (status.subscription) {
        status.subscription.unsubscribe()
      }
      this.uploadsStatus.splice(index, 1)
      this.uploading = false
    }
  }

  deleteImage(index: number) {
    const status = this.uploadsStatus[index]
    if (status && status.imageId) {
      this.uploadsStatus.splice(index, 1)
    }
  }

  onFileSelected(file: File) {
    this.uploading = true
    let arrayIndex = 0

    readFileContent(file).then(content => {
      // remove the .png/.jpg extension from the file name
      const fileName = file.name.replace(/\.[^.]*$/, "")

      const base64image = fromFileContentToBase64Image(
        file.type,
        content,
        fileName,
        this.isCover
      )
      arrayIndex = this.uploadsStatus.length
      this.uploadsStatus.push({
        fileName: file.name,
        fileSize: convertSizeToMb(file.size),
        percent: 100,
        error: false,
        imageId: {
          id: uuidv4(), // random UUID, needed for some ui features
          data: base64image.data!,
          name: base64image.name,
          mimeType: file.type,
          isCover: this.isCover,
          isPendingUpload: true
        }
      })
      this.uploading = false
    })
  }
}
