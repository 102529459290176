import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core"
import { ColumnMode, SortDirection, SortType } from "@swimlane/ngx-datatable"

export class GenericDataTableColumn {
  name: string
  field: string
  sortable = true
  cellTemplate?: TemplateRef<any>
  minWidth?: number
  maxWidth?: number
  comparator?: GenericDataTableComparator
}

export type GenericDataTableComparator = (
  valueA: any,
  valueB: any,
  rowA: any,
  rowB: any,
  sortDirection: SortDirection
) => number

// Datatable doesn't know how to sort dates with multi-row elements, so we have to provide a custom comparator.
// Assumes valueA and valueB contain iso (string) and index (number) fields.
// iso field should be in a format that can be resolved by the Date() constructor.
export function dateTimeComparator(
  valueA: any,
  valueB: any,
  rowA: any,
  rowB: any,
  sortDirection: SortDirection
): number {
  if (!valueA && !valueB) {
    return 0
  }
  if (valueA && !valueB) {
    return 1
  }
  if (!valueA && valueB) {
    return -1
  }

  const timeDiff =
    new Date(valueA.iso).getTime() - new Date(valueB.iso).getTime()
  if (!timeDiff) {
    if (sortDirection === SortDirection.asc) {
      return valueA.index - valueB.index
    }
    return valueB.index - valueA.index
  }
  return timeDiff
}

@Component({
  selector: "app-generic-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"]
})
export class GenericDataTableComponent implements OnInit, OnChanges {
  @ViewChild("table", { static: false }) table: any
  /**
   * Type of selection to use. This value should be one of the values in the SelectionType enum,
   * but specifying that here causes difficulties for consuming components.
   */
  @Input() selectionType?: string = undefined

  // Expected to be a set of objects of the form { name: "header name", field: "object field name",
  // sortable?: boolean (defaults to true), cellTemplate?: template ref, minWidth?: column minimum width, maxWidth?: column maximumwidth }
  @Input() columns: GenericDataTableColumn[] = []
  @Input() rows: Object[] = []
  @Input() count = 0
  @Input() pageSize = 10
  @Input() sortColumn: string
  @Input() hasDetailRow = false
  @Input() flex = false
  /**
   * Callback function invoked when a row is clicked or checked
   */
  @Output() rowSelected: EventEmitter<any> = new EventEmitter()
  @Output() pageChanged: EventEmitter<number> = new EventEmitter()
  @Output() tableSorted: EventEmitter<any> = new EventEmitter()

  page = 0
  ColumnMode = ColumnMode
  SortType = SortType
  sorts: Array<Object>
  selectedRows: any[] = []

  ngOnChanges(changes: SimpleChanges) {
    this.page = 0
  }

  constructor() {}

  ngOnInit() {
    if (this.sortColumn != null && this.sortColumn !== undefined) {
      this.sorts = [{ prop: this.sortColumn, dir: "desc" }]
    } else {
      this.sorts = []
    }
    this.setPage(0)
  }

  setPage(page: number) {
    this.pageChanged.emit(page)
    this.page = page
  }

  onPageChange(event: any): void {
    this.setPage(event)
  }

  isSelectable(row: any): boolean {
    return "single" !== this.selectionType
  }

  onRowSelect(event: any): void {
    this.selectedRows = []
    if (event.type === "click") {
      this.rowSelected.emit(event.row)
    }
  }

  onSort(event: any): void {
    this.setPage(0)
    this.tableSorted.emit(event)
  }

  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row)
  }
}
