import { Component, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"

interface Day {
  id: number
  name: string
  selected: boolean
}

@Component({
  selector: "app-week-day-selector",
  templateUrl: "./week-day-selector.component.html",
  styleUrls: ["./week-day-selector.component.scss"]
})
export class WeekDaySelectorComponent implements OnInit {
  @Input() weekDayFormController: FormControl

  weekDayList = [
    { id: 1, name: "Mon", selected: false },
    { id: 2, name: "Tue", selected: false },
    { id: 3, name: "Wed", selected: false },
    { id: 4, name: "Thu", selected: false },
    { id: 5, name: "Fri", selected: false },
    { id: 6, name: "Sat", selected: false },
    { id: 7, name: "Sun", selected: false }
  ]

  constructor() {}

  ngOnInit() {
    this.weekDayFormController.value.forEach((dayId: number) => {
      this.weekDayList.find(day => day.id === dayId)!!.selected = true
    })
  }

  onDayClicked(day: Day) {
    day.selected = !day.selected
    this.weekDayFormController.markAsTouched()
    const currentList: number[] = this.weekDayFormController.value
    // Remove the day or add it accordingly
    if (currentList.includes(day.id)) {
      this.weekDayFormController.setValue(
        currentList.filter(dayId => day.id !== dayId).sort()
      )
    } else {
      currentList.push(day.id)
      this.weekDayFormController.setValue(currentList.sort())
    }
  }
}
