import { IdAndName } from './IdAndName';

export class LocationAndUnit {
  location: IdAndName;
  unit: IdAndName;

  constructor(location: IdAndName, unit: IdAndName) {
    this.location = location;
    this.unit = unit;
  }
}
