/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from "./address";
import { ImageBase64 } from "./imageBase64";
import { CompanyImage } from "./recipeBody";

export interface Company {
  companyId: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  companyType: Company.CompanyTypeEnum;
  address: Address;
  email: string;
  phone: string;
  logo?: ImageBase64;
  /**
   * The URL of the logo image
   */
  logoUrl?: string;
  contactFirstName: string;
  contactLastName: string;
  image?: CompanyImage;
}

export namespace Company {
  export type CompanyTypeEnum = "singleSubsidiary" | "multipleSubsidiary";
  export const CompanyTypeEnum = {
    SingleSubsidiary: "singleSubsidiary" as CompanyTypeEnum,
    MultipleSubsidiary: "multipleSubsidiary" as CompanyTypeEnum
  };
}
