import { Component, Input, OnInit } from "@angular/core"
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import { getTimeZones, TimeZone } from "@vvo/tzdb"
import {
  StageField,
  StageType,
  UnitSettingsHomeEnum,
  UnitSettingsModel,
  UnitSettingsService,
  UnitType
} from "src/app/api"
import { atLeastOneNonRinseCleanTypeValidator } from "../edit-recipe/recipe-steps-editor/custom-validators"
import {
  getRangeValueByUnitType,
  INumberRanges
} from "../edit-recipe/recipe-steps-editor/form-groups"
import { TemperatureTypePipe } from "../temperature-type.pipe"
import {
  celsiusToFahrenheit,
  fahrenheitToCelsius
} from "../temperature/temp-utils"

enum CleaningReminderType {
  Hours = "Hours",
  DayAndTime = "Day/Time"
}

@Component({
  selector: "app-unit-settings-form",
  templateUrl: "./unit-settings-form.component.html",
  styleUrls: ["./unit-settings-form.component.scss"],
  providers: [TemperatureTypePipe]
})
export class UnitSettingsFormComponent implements OnInit {
  @Input() isReadOnlyMode: boolean
  /**
   * True if the form is used in the unit settings section
   */
  @Input() isGeneral = false
  @Input() unitSetting?: UnitSettingsModel
  @Input() unitType: UnitType
  unitSettingsForm: FormGroup
  unitStageNRanges: Record<StageField, INumberRanges>
  languages: Record<string, string>
  timezones: string[] = []
  homeViews = Object.values(UnitSettingsHomeEnum)
  visibleCleanings: string[] = []
  selectedCleaningReminder = CleaningReminderType.Hours
  CleaningReminderType = CleaningReminderType
  cleaningReminderTypes = [
    CleaningReminderType.Hours,
    CleaningReminderType.DayAndTime
  ]

  get securityFormGroup(): FormGroup {
    return this.unitSettingsForm.controls["security"] as FormGroup
  }

  get lockRecipeFormControl() {
    return this.securityFormGroup.controls["lockRecipeEnable"]
  }
  get lockCookingFormControl() {
    return this.securityFormGroup.controls["lockCookingEnable"]
  }
  get lockSettingsFormControl() {
    return this.securityFormGroup.controls["lockSettingsEnable"]
  }
  get lockServiceFormControl() {
    return this.securityFormGroup.controls["lockServiceEnable"]
  }
  get lockRecipePinFormControl() {
    return this.securityFormGroup.controls["lockRecipePin"]
  }
  get lockCookingPinFormControl() {
    return this.securityFormGroup.controls["lockCookingPin"]
  }
  get lockSettingsPinFormControl() {
    return this.securityFormGroup.controls["lockSettingsPin"]
  }

  get lightSettingsFormControl() {
    return this.unitSettingsForm.controls["light_settings"] as FormGroup
  }
  get temperatureFormControl() {
    return this.unitSettingsForm.controls["temperature"] as FormGroup
  }

  get localizationFormControl() {
    return this.unitSettingsForm.controls.localization as FormGroup
  }

  // Controls the 'settings' key in the generated JSON
  get settingsFormControl() {
    return this.unitSettingsForm.controls["settings"] as FormGroup
  }

  get soundSettingsFormControl() {
    return this.unitSettingsForm.controls["sound_settings"] as FormGroup
  }

  get autoScreenLockFormControl() {
    return this.settingsFormControl.controls["autoScreenLock"]
  }
  get autoScreenLockTimeoutFormControl() {
    return this.settingsFormControl.controls["autoScreenLockTimeout"]
  }

  get cleaningFormGroup() {
    return this.unitSettingsForm.controls["cleaning"] as FormGroup
  }

  get visibleCleaningsFormGroup() {
    return this.unitSettingsForm.controls["visibleCleanings"] as FormGroup
  }

  get cleaningFreqTimeFormControl() {
    return this.cleaningFormGroup.controls["cleanFreqTime"]
  }

  get cleaningFreqHoursFormControl() {
    return this.cleaningFormGroup.controls["cleanFreqHours"]
  }

  get cleaningFreqDaysFormControl() {
    return this.cleaningFormGroup.controls["cleanFreqDays"]
  }

  get cleanLockoutFormControl() {
    return this.cleaningFormGroup.controls["cleanLockout"]
  }

  constructor(
    private ts: TranslateService,
    private unitSettingsService: UnitSettingsService,
    private temperatureTypePipe: TemperatureTypePipe
  ) {
    this.languages = {
      en_US: this.ts.instant("GLOBAL.LANGUAGES.en_US"),
      fr_FR: this.ts.instant("GLOBAL.LANGUAGES.fr_FR"),
      de_DE: this.ts.instant("GLOBAL.LANGUAGES.de_DE"),
      es_ES: this.ts.instant("GLOBAL.LANGUAGES.es_ES"),
      ru_RU: this.ts.instant("GLOBAL.LANGUAGES.ru_RU"),
      zh_CN: this.ts.instant("GLOBAL.LANGUAGES.zh_CN"),
      it_IT: this.ts.instant("GLOBAL.LANGUAGES.it_IT"),
      ja_JP: this.ts.instant("GLOBAL.LANGUAGES.ja_JP"),
      ko: this.ts.instant("GLOBAL.LANGUAGES.ko"),
      nl_NL: this.ts.instant("GLOBAL.LANGUAGES.nl_NL")
    }
  }

  setPinFormStatus(lockValue: boolean, pinField: AbstractControl) {
    if (!this.isReadOnlyMode) {
      if (!lockValue) {
        pinField.patchValue("")
        pinField.disable()
      } else {
        pinField.enable()
        pinField.markAsUntouched()
      }
    }
  }

  lockEnabledValidator(type: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const val = control.value
      let validity = null
      const controlGroup = control.parent

      if (controlGroup) {
        if (controlGroup.get("lock" + type + "Enable")!.value) {
          if (!val || val === "") {
            validity = { required: true }
          } else {
            const regex = new RegExp("^[0-9]+$")
            validity =
              val.length >= 4 && val.length <= 8 && regex.test(val)
                ? null
                : { required: true }
          }
        }
      }
      return validity
    }
  }

  autoScreenLockValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlGroup = control.parent
      const screenLock = controlGroup && controlGroup.get("autoScreenLock")
      return screenLock && screenLock.value && !control.value
        ? { required: true }
        : null
    }
  }

  cleaningFreqDayTimeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlGroup = control.parent
      const cleanFreqHours = controlGroup && controlGroup.get("cleanFreqHours")
      return cleanFreqHours &&
        cleanFreqHours.value === -1 &&
        control.value.length < 1
        ? { required: true }
        : null
    }
  }

  get unitTypeHasCleaning() {
    return this.unitType === "prodigiPro" || this.unitType === "converge"
  }

  ngOnInit() {
    // For now don't use package, use fixed list provided by Alto Shaam
    // this.timezones = [
    //   ...new Set(
    //     getTimeZones({ includeUtc: true }).map(t => this.getUTCValue(t))
    //   )
    // ]
    this.timezones = this.getTimeZoneList()
    this.unitStageNRanges = getRangeValueByUnitType(
      this.unitType,
      StageType.preheat
    )

    this.unitSettingsForm = this.getUnitSettingsFormComposition()

    this.lockRecipeFormControl.valueChanges.subscribe((e: boolean) => {
      this.setPinFormStatus(e, this.lockRecipePinFormControl)
    })
    this.lockCookingFormControl.valueChanges.subscribe((e: boolean) => {
      this.setPinFormStatus(e, this.lockCookingPinFormControl)
    })
    this.lockSettingsFormControl.valueChanges.subscribe((e: boolean) => {
      this.setPinFormStatus(e, this.lockSettingsPinFormControl)
    })
    this.autoScreenLockFormControl.valueChanges.subscribe((e: boolean) => {
      this.setPinFormStatus(e, this.autoScreenLockTimeoutFormControl)
    })

    this.setPinFormStatus(false, this.lockRecipePinFormControl)
    this.setPinFormStatus(false, this.lockCookingPinFormControl)
    this.setPinFormStatus(false, this.lockSettingsPinFormControl)
    this.setPinFormStatus(false, this.autoScreenLockTimeoutFormControl)

    if (this.unitSetting) {
      // Check the cleaning setting and set the dropdown accordingly. Only applies to prodigi
      if (
        this.unitSetting.cleaning &&
        this.unitSetting.cleaning.cleanFreqHours !== undefined
      ) {
        if (this.unitSetting.cleaning.cleanFreqHours === -1) {
          this.setCleaningReminderType(CleaningReminderType.DayAndTime)
        } else {
          this.setCleaningReminderType(CleaningReminderType.Hours)
        }
      }

      this.unitSettingsForm.patchValue(this.unitSetting)
      this.autoScreenLockTimeoutFormControl.setValue(
        this.autoScreenLockTimeoutFormControl.value / 60000
      )

      if (!this.unitSetting.localization.tempUnit) {
        for (const chamber in this.temperatureFormControl.controls) {
          if (this.temperatureFormControl.controls.hasOwnProperty(chamber)) {
            this.temperatureFormControl.controls[chamber].setValue(
              fahrenheitToCelsius(
                this.temperatureFormControl.controls[chamber].value
              )
            )
          }
        }
      }

      // If the passed in unit settings have a freq time then convert it from minutes to hh:mm string
      if (
        this.unitSetting.cleaning &&
        this.unitSetting.cleaning.cleanFreqTime >= 0
      ) {
        this.cleaningFreqTimeFormControl.setValue(
          this.minutesToTimeString(this.unitSetting.cleaning.cleanFreqTime)
        )
      }
    }
    if (this.unitTypeHasCleaning) {
      this.unitSettingsService
        .getCleanTypesForUnitType(this.unitType)
        .subscribe(cleanTypes => {
          this.visibleCleanings = cleanTypes
          // for newly created forms, set all cleans to visible by default
          if (!this.unitSetting) {
            this.visibleCleaningsFormGroup.controls[
              "visibleCleanings"
            ].setValue(this.visibleCleanings)
          }
        })
    }
    this.localizationFormControl.controls.tempUnit.valueChanges.subscribe(
      units => {
        let chamberIndex = 0
        for (const chamber in this.temperatureFormControl.controls) {
          if (this.temperatureFormControl.controls.hasOwnProperty(chamber)) {
            const tempValue = this.temperatureFormControl.controls[chamber]
              .value
            // Uncomment this if we want to default fill value when changing temp units and the current value is empty
            // const tempValue = this.temperatureFormControl.controls[chamber].value !== null
            // ? this.temperatureFormControl.controls[chamber].value
            // : this.unitStageNRanges.preheat.default
            this.updateChamber(chamber, tempValue, units, [
              chamberIndex === 0 ||
              (chamberIndex === 1 &&
                this.unitType !== "cookAndHold" &&
                this.unitType !== "cookAndHoldNoSmoker")
                ? Validators.required
                : Validators.nullValidator,
              Validators.min(
                this.temperatureTypePipe.getValueForUnitSettings(
                  this.unitStageNRanges.preheat.min,
                  units
                )
              ),
              Validators.max(
                this.temperatureTypePipe.getValueForUnitSettings(
                  this.unitStageNRanges.preheat.max,
                  units
                )
              )
            ])
          }
          chamberIndex++; // tslint:disable-line
        }
      }
    )
  }

  getUnitSettingsFormComposition() {
    const group = new FormGroup({
      screen: new FormGroup({
        home: new FormControl(
          {
            value: 0,
            disabled: this.isReadOnlyMode
          },
          [Validators.required]
        ),
        homeGroup: new FormControl(
          {
            value: "Favorites",
            disabled: this.isReadOnlyMode
          },
          [Validators.required]
        )
      }),
      localization: new FormGroup({
        language: new FormControl(
          {
            value: "en_US",
            disabled: this.isReadOnlyMode
          },
          [Validators.required]
        ),
        timezone: new FormControl(
          {
            value: "",
            disabled: this.isReadOnlyMode
          },
          [Validators.required]
        ),
        tempUnit: new FormControl({
          value: this.unitSetting
            ? this.unitSetting["localization"]["tempUnit"] === 1
            : this.temperatureTypePipe.tempUnitPreferenceF,
          disabled: this.isReadOnlyMode
        })
      }),
      cleaning: new FormGroup(
        this.unitTypeHasCleaning
          ? {
              cleanFreqHours: new FormControl(
                {
                  value: 24,
                  disabled: this.isReadOnlyMode
                },
                [Validators.required, Validators.min(1), Validators.max(168)]
              ),
              cleanFreqDays: new FormControl(
                {
                  value: [],
                  disabled: this.isReadOnlyMode
                },
                [this.cleaningFreqDayTimeValidator()]
              ),
              cleanFreqTime: new FormControl(
                {
                  value: -1,
                  disabled: this.isReadOnlyMode
                },
                [Validators.required]
              ),
              cleanLockout: new FormControl(
                {
                  value: false,
                  disabled: this.isReadOnlyMode
                },
                [Validators.required]
              ),
              cleanLockoutTime: new FormControl(
                {
                  value: 1,
                  disabled: this.isReadOnlyMode
                },
                [Validators.required, Validators.min(1), Validators.max(100)]
              )
            }
          : {}
      ),
      visibleCleanings: new FormGroup(
        this.unitTypeHasCleaning
          ? {
              visibleCleanings: new FormControl(
                {
                  value: "",
                  disabled: this.isReadOnlyMode
                },
                [atLeastOneNonRinseCleanTypeValidator()]
              )
            }
          : {}
      ),
      settings: new FormGroup({
        autoPreheat: new FormControl({
          value: false,
          disabled: this.isReadOnlyMode
        }),
        replayRecipe: new FormControl({
          value: false,
          disabled: this.isReadOnlyMode
        }),
        screenBrightness: new FormControl(
          {
            value: 100,
            disabled: this.isReadOnlyMode
          },
          [Validators.required]
        ),
        autoScreenLock: new FormControl({
          value: false,
          disabled: this.isReadOnlyMode
        }),
        autoScreenLockTimeout: new FormControl(
          {
            value: 0,
            disabled: this.isReadOnlyMode
          },
          [this.autoScreenLockValidator()]
        )
      }),
      sound_settings: new FormGroup({
        volume: new FormControl({
          value: 100,
          disabled: this.isReadOnlyMode
        })
      }),
      light_settings: new FormGroup({
        brightness: new FormControl(
          {
            value: 100,
            disabled: this.isReadOnlyMode
          },
          [Validators.required]
        )
      }),
      handle: new FormGroup({
        enabled: new FormControl({
          value: true,
          disabled: this.isReadOnlyMode
        })
      }),
      temperature: new FormGroup({
        ch0: new FormControl(
          {
            value: this.temperatureTypePipe.getValueForUnitSettings(
              this.unitStageNRanges.preheat.default,
              this.unitSetting
                ? this.unitSetting["localization"]["tempUnit"] === 1
                : this.temperatureTypePipe.tempUnitPreferenceF
            ),
            disabled: this.isReadOnlyMode
          },
          [
            Validators.required,
            // Be considerate of F vs C
            Validators.max(
              this.temperatureTypePipe.getValueForUnitSettings(
                this.unitStageNRanges.preheat.max,
                this.unitSetting
                  ? this.unitSetting["localization"]["tempUnit"] === 1
                  : this.temperatureTypePipe.tempUnitPreferenceF
              )
            ),
            Validators.min(
              this.temperatureTypePipe.getValueForUnitSettings(
                this.unitStageNRanges.preheat.min,
                this.unitSetting
                  ? this.unitSetting["localization"]["tempUnit"] === 1
                  : this.temperatureTypePipe.tempUnitPreferenceF
              )
            )
          ]
        ),
        ch1: new FormControl(
          {
            value: this.temperatureTypePipe.getValueForUnitSettings(
              this.unitStageNRanges.preheat.default,
              this.unitSetting
                ? this.unitSetting["localization"]["tempUnit"] === 1
                : this.temperatureTypePipe.tempUnitPreferenceF
            ),
            disabled: this.isReadOnlyMode
          },
          [
            this.unitType !== "cookAndHold" &&
            this.unitType !== "cookAndHoldNoSmoker"
              ? Validators.required
              : Validators.nullValidator,
            Validators.max(
              this.temperatureTypePipe.getValueForUnitSettings(
                this.unitStageNRanges.preheat.max,
                this.unitSetting
                  ? this.unitSetting["localization"]["tempUnit"] === 1
                  : this.temperatureTypePipe.tempUnitPreferenceF
              )
            ),
            Validators.min(
              this.temperatureTypePipe.getValueForUnitSettings(
                this.unitStageNRanges.preheat.min,
                this.unitSetting
                  ? this.unitSetting["localization"]["tempUnit"] === 1
                  : this.temperatureTypePipe.tempUnitPreferenceF
              )
            )
          ]
        ),
        ch2: new FormControl(
          {
            value: this.temperatureTypePipe.getValueForUnitSettings(
              this.unitStageNRanges.preheat.default,
              this.unitSetting
                ? this.unitSetting["localization"]["tempUnit"] === 1
                : this.temperatureTypePipe.tempUnitPreferenceF
            ),
            disabled: this.isReadOnlyMode
          },
          [
            Validators.max(
              this.temperatureTypePipe.getValueForUnitSettings(
                this.unitStageNRanges.preheat.max,
                this.unitSetting
                  ? this.unitSetting["localization"]["tempUnit"] === 1
                  : this.temperatureTypePipe.tempUnitPreferenceF
              )
            ),
            Validators.min(
              this.temperatureTypePipe.getValueForUnitSettings(
                this.unitStageNRanges.preheat.min,
                this.unitSetting
                  ? this.unitSetting["localization"]["tempUnit"] === 1
                  : this.temperatureTypePipe.tempUnitPreferenceF
              )
            )
          ]
        ),
        ch3: new FormControl(
          {
            value: this.temperatureTypePipe.getValueForUnitSettings(
              this.unitStageNRanges.preheat.default,
              this.unitSetting
                ? this.unitSetting["localization"]["tempUnit"] === 1
                : this.temperatureTypePipe.tempUnitPreferenceF
            ),
            disabled: this.isReadOnlyMode
          },
          [
            Validators.max(
              this.temperatureTypePipe.getValueForUnitSettings(
                this.unitStageNRanges.preheat.max,
                this.unitSetting
                  ? this.unitSetting["localization"]["tempUnit"] === 1
                  : this.temperatureTypePipe.tempUnitPreferenceF
              )
            ),
            Validators.min(
              this.temperatureTypePipe.getValueForUnitSettings(
                this.unitStageNRanges.preheat.min,
                this.unitSetting
                  ? this.unitSetting["localization"]["tempUnit"] === 1
                  : this.temperatureTypePipe.tempUnitPreferenceF
              )
            )
          ]
        )
      }),
      security: new FormGroup({
        lockRecipeEnable: new FormControl(
          {
            value: false,
            disabled: this.isReadOnlyMode
          },
          [Validators.required]
        ),
        lockRecipePin: new FormControl(
          {
            value: "",
            disabled: this.isReadOnlyMode
          },
          [this.lockEnabledValidator("Recipe")]
        ),
        lockCookingEnable: new FormControl(
          {
            value: false,
            disabled: this.isReadOnlyMode
          },
          [Validators.required]
        ),
        lockCookingPin: new FormControl(
          {
            value: "",
            disabled: this.isReadOnlyMode
          },
          [this.lockEnabledValidator("Cooking")]
        ),
        lockSettingsEnable: new FormControl(
          {
            value: false,
            disabled: this.isReadOnlyMode
          },
          [Validators.required]
        ),
        lockSettingsPin: new FormControl(
          {
            value: "",
            disabled: this.isReadOnlyMode
          },
          [this.lockEnabledValidator("Settings")]
        )
      })
    })

    this.formGroupManagement(group)

    return group
  }

  // This will appropriately handle keeping or removing inconsistent form controls.
  // We fully remove the control so the submitted form doesn't have a key:value pair for the control at all.
  formGroupManagement(group: FormGroup): void {
    if (this.isReadOnlyMode) {
      group.enable()
    }
    // C&H ovens only have two chambers so only Chambers and 2 are displayed in the form.
    // C&H ovens no longer have light settings either.
    // Must remove the controls for temperature chambers 3 and 4 (ch2 and ch3) as well as light_settings.
    // Simply clearing the validators still includes these values in the form submitted to the back end.
    if (
      this.unitType === "cookAndHold" ||
      this.unitType === "cookAndHoldNoSmoker"
    ) {
      this.removeChamber(group, "ch2")
      this.removeChamber(group, "ch3")
      if (group.contains("light_settings")) {
        group.removeControl("light_settings")
      }
    }
    if (this.unitType === "prodigiPro") {
      this.removeChamber(group, "ch1")
      this.removeChamber(group, "ch2")
      this.removeChamber(group, "ch3")
    }
    if (this.isReadOnlyMode) {
      group.disable()
    }
  }

  updateChamber(
    chamber: string,
    value: number,
    units: boolean,
    validators: ValidatorFn[]
  ) {
    if (
      units &&
      value ===
        this.temperatureTypePipe.getValueForUnitSettings(
          this.unitStageNRanges.preheat.min,
          !units
        )
    ) {
      value = this.unitStageNRanges.preheat.min
    } else if (
      units &&
      value ===
        this.temperatureTypePipe.getValueForUnitSettings(
          this.unitStageNRanges.preheat.max,
          !units
        )
    ) {
      value = this.unitStageNRanges.preheat.max
    } else if (
      units &&
      value !== null &&
      value !== this.unitStageNRanges.preheat.default
    ) {
      value = units ? celsiusToFahrenheit(value) : value
    }

    if (value !== null) {
      this.temperatureFormControl.controls[chamber].patchValue(
        this.temperatureTypePipe.getValueForUnitSettings(value, units)
      )
    }

    this.temperatureFormControl.controls[chamber].setValidators(validators)
    this.temperatureFormControl.controls[chamber].markAsDirty()
    this.temperatureFormControl.controls[chamber].updateValueAndValidity()
  }

  removeChamber(group: FormGroup, chamberName: string) {
    if (group.contains("temperature")) {
      if ((group.controls.temperature as FormGroup).contains(chamberName)) {
        (group.controls.temperature as FormGroup).removeControl(chamberName)
      }
    }
  }

  setCleaningReminderType(type: CleaningReminderType) {
    // Update selected cleaning reminder
    this.selectedCleaningReminder = type

    // Handle clearing and resetting fields along with enabling/disabling fields
    // based on which reminder type gets selected
    if (type === CleaningReminderType.Hours) {
      // Clear days and set time to -1 to mark as not used
      this.cleaningFreqTimeFormControl.setValue(-1)
      this.cleaningFreqDaysFormControl.setValue([])

      // Reset hours form
      this.cleaningFreqHoursFormControl.setValue(24)

      // Disable day/time and enable hours ONLY IF not in read only mode
      if (!this.isReadOnlyMode) {
        this.cleaningFreqTimeFormControl.disable()
        this.cleaningFreqDaysFormControl.disable()
        this.cleaningFreqHoursFormControl.enable()
      }
    } else {
      // Set hours to -1 to mark as not used
      this.cleaningFreqHoursFormControl.setValue(-1)

      // Reset time form
      this.cleaningFreqTimeFormControl.setValue("00:00")

      // Disable hours and enable day/time ONLY IF not in read only mode
      if (!this.isReadOnlyMode) {
        this.cleaningFreqTimeFormControl.enable()
        this.cleaningFreqDaysFormControl.enable()
        this.cleaningFreqHoursFormControl.disable()
      }
    }
  }

  /**
   * Formats minutes to string in the form of hh:mm with 0 padding
   */
  minutesToTimeString(minutes: number): string {
    return (
      Math.floor(minutes / 60)
        .toString()
        .padStart(2, "0") +
      ":" +
      (minutes % 60).toString().padStart(2, "0")
    )
  }

  /**
   * Custom timezone list provided by Alto Shaam
   */
  getTimeZoneList(): Array<any> {
    return [
      { UTC: "UTC-11:00", location: "Alofi" },
      { UTC: "UTC-10:00", location: "Hawaiian Islands, French Polynesia" },
      { UTC: "UTC-09:00", location: "Anchorage" },
      { UTC: "UTC-08:00", location: "Vancouver, Los Angeles" },
      { UTC: "UTC-07:00", location: "Edmonton, Denver, Easter Island" },
      { UTC: "UTC-06:00", location: "Winnipeg, Chicago, Mexico City" },
      { UTC: "UTC-05:00", location: "Toronto, New York, Bogota" },
      { UTC: "UTC-04:00", location: "Caracas, Santiago, Bolivia" },
      { UTC: "UTC-03:00", location: "Greenland, Sao Paulo" },
      { UTC: "UTC-02:00", location: "Grytviken" },
      { UTC: "UTC-01:00", location: "Scoresbysund" },
      { UTC: "UTC+00:00", location: "Iceland, England, Portugal" },
      { UTC: "UTC+01:00", location: "Sweden, Paris, Lagos" },
      { UTC: "UTC+02:00", location: "Finland, Ukraine, Cairo" },
      { UTC: "UTC+03:00", location: "Moscow, Riyadh, Madagascar" },
      { UTC: "UTC+04:00", location: "Muscat, Dubai" },
      { UTC: "UTC+05:00", location: "Uzbekistan, Pakistan" },
      { UTC: "UTC+05:30", location: "India" },
      { UTC: "UTC+06:00", location: "Omsk, Chagos" },
      { UTC: "UTC+07:00", location: "Bangkok, Ho Chi Minh City" },
      { UTC: "UTC+08:00", location: "Beijing, Manilla, Perth" },
      { UTC: "UTC+09:00", location: "Seoul, Tok" },
      { UTC: "UTC+10:00", location: "Papua New Guinea, Melbourne" },
      { UTC: "UTC+11:00", location: "Arawa" },
      { UTC: "UTC+12:00", location: "Auckland, Fiji," },
      { UTC: "UTC+13:00", location: "Kanton" },
      { UTC: "UTC+14:00", location: "Kiritimati" }
    ]
  }

  getTimeZoneString(timezone: TimeZone): string {
    return (
      "UTC" +
      timezone.currentTimeFormat
        .split(",")
        .slice(0, 2)
        .join()
    )
  }

  getUTCValue(timezone: TimeZone): string {
    return this.getTimeZoneString(timezone).split(" ")[0]
  }
}
