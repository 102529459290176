import {
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from "@angular/core"
import { FormControl } from "@angular/forms"
import { FiltrableDataSource } from "filtrable-data-source"
import { LocationsListService } from "src/app/core/locations-list.service"
import { dataSourceEqualFilterToggle } from "src/app/core/utils"

@Component({
  selector: "app-locations-filter",
  templateUrl: "./locations-filter.component.html",
  styleUrls: ["./locations-filter.component.scss"]
})
export class LocationsFilterComponent implements OnInit {
  @Input() locationFormControl: FormControl = new FormControl()
  @Input() dataSource?: FiltrableDataSource<any>
  @ViewChild("locationDropdown", { static: false })
  locationDropdown: ElementRef

  locationFilter: string
  sorted = false // Used to not resort groups in ngDoCheck

  constructor(public locationsListService: LocationsListService) {}

  ngOnInit() {
    if (this.dataSource) {
      this.locationFormControl.valueChanges.subscribe(val => {
        dataSourceEqualFilterToggle(this.dataSource!, "locationId", val)
      })
    }
  }

  setLocationValue(value: string) {
    this.locationFormControl.setValue(value)
    this.locationDropdown.nativeElement.click()
  }

  getLocationById(id: string) {
    return this.locationsListService.locations.find(
      loc => loc.locationId === id
    )
  }
}
