import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { BsModalService } from "ngx-bootstrap/modal"
import { ToastrService } from "ngx-toastr"
import { throwError } from "rxjs"
import { catchError } from "rxjs/operators"
import { MessageService } from "src/app/core/message.service"
import { PermissionService } from "src/app/core/permission.service"
import { UnitRemoteControlModalComponent } from "src/app/shared/unit-remote-control-modal/unit-remote-control-modal.component"
import { UnitSettingsModalComponent } from "src/app/shared/unit-settings-modal/unit-settings-modal.component"
import { combineSettingsAndCleanVisibility } from "src/app/unit-settings/unit-settings-resolver.service"
import {
  UnitDetail,
  UnitLastStatus,
  UnitSettingsTemplate,
  UnitsService,
  UnitType
} from "../../api"
import { getColorHexByUnit } from "../../core/utils"

@Component({
  selector: "app-unit-dashboard",
  templateUrl: "./unit-dashboard.component.html",
  styleUrls: ["./unit-dashboard.component.scss"]
})
export class UnitDashboardComponent implements OnInit {
  unit: UnitDetail
  unitStatus: UnitLastStatus
  getColorHex = getColorHexByUnit
  tabList: any

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    public auth: PermissionService,
    private unitService: UnitsService,
    private message: MessageService,
    private ts: TranslateService,
    private toastService: ToastrService
  ) {
    this.message.updateUnitSubj.subscribe(() => {
      this.unitService.getUnit(this.unit.unitId).subscribe(data => {
        this.unit = data
        this.manageChamberList()
      })
    })
  }

  ngOnInit() {
    if (
      this.auth &&
      this.auth.permissions &&
      this.auth.permissions.technician_view
    ) {
      this.tabList = [
        { label: this.ts.instant("UNITS.TAB.OVERVIEW"), path: "overview" },
        { label: this.ts.instant("UNITS.TAB.DATA"), path: "data" },
        { label: this.ts.instant("UNITS.TAB.ALERTS"), path: "alerts" }
      ]
    } else {
      this.tabList = [
        { label: this.ts.instant("UNITS.TAB.OVERVIEW"), path: "overview" },
        { label: this.ts.instant("UNITS.TAB.DATA"), path: "data" },
        { label: this.ts.instant("UNITS.TAB.COOKBOOK"), path: "cookbook" },
        { label: this.ts.instant("UNITS.TAB.ALERTS"), path: "alerts" }
      ]
    }
    this.activatedRoute.parent!.data.subscribe(data => {
      this.unit = data.unit
      this.unitStatus = data.unitOverviewData
    })
  }

  openRemoteControlModal() {
    const currentActions = JSON.parse(this.unit.currentAction!)

    const status = this.getOvenStatus(currentActions)

    const initialState: Partial<UnitRemoteControlModalComponent> = {
      unitId: this.unit.unitId,
      currentStatus: status
    }

    const modalRef = this.modalService.show(UnitRemoteControlModalComponent, {
      initialState,
      class: "modal-lg"
    })

    const component = modalRef.content as UnitRemoteControlModalComponent
    component.unitReload.subscribe(() => {
      this.refreshUnitData()
    })
  }

  getOvenStatus(arr: Array<string>) {
    let status = ""
    const checkIdle = arr.every((v: string) => v === "IDLE")

    if (checkIdle) {
      status = "IDLE"
    } else {
      if (arr.indexOf("COOLINGDOWN") !== -1) {
        status = "COOLINGDOWN"
      } else {
        if (arr.indexOf("COOKING") !== -1) {
          status = "COOKING"
        } else {
          if (arr.indexOf("PREHEATING") !== -1) {
            status = "PREHEATING"
          }
        }
      }
    }

    return status
  }

  manageChamberList() {
    if (this.unit) {
      this.unitService.getUnitStatus(this.unit.unitId).subscribe(res => {
        this.unitStatus = res
      })
    }
  }

  refreshUnitData() {
    const oldActions = this.unit.currentAction
    const interval = setInterval(() => {
      this.unitService.getUnit(this.unit.unitId).subscribe(data => {
        if (oldActions !== data.currentAction) {
          this.unit = data
          this.manageChamberList()
          clearInterval(interval)
        }
      })
    }, 5000)
  }

  openServiceMonitor(unit: UnitDetail) {
    this.router.navigate(["/", "units", unit.unitId, "service-monitor"])
  }

  openUnitSettings(unit: UnitDetail) {
    this.unitService
      .getUnitSettings(this.unit.unitId)
      .pipe(
        catchError(error => {
          if (error.status === 404) {
            return throwError(
              new Error(this.ts.instant("UNITS.TAB.NO_ACTIVE_SETTINGS"))
            )
          }
          return throwError(error)
        })
      )
      .subscribe(
        data => {
          const currUnitSettings = combineSettingsAndCleanVisibility(data)
            .settings
          const isPresent = Object.keys(data).length !== 0

          const initialState: Partial<UnitSettingsModalComponent> = {
            unit,
            currUnitSettings,
            isPresent
          }
          const modalRef = this.modalService.show(UnitSettingsModalComponent, {
            initialState: initialState,
            class: "modal-lg"
          })
          const component = modalRef.content as UnitSettingsModalComponent
        },
        error => {
          this.toastService.error(error.message)
        }
      )
  }

  startSupportSession(unit: UnitDetail) {
    this.router.navigateByUrl(
      `/remote-support?unitSerial=${unit.serialNumber}`
    )
  }
}
