import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RecipeBase, RecipeLibrary, RecipeService } from 'src/app/api';
import { LibrariesService } from 'src/app/core/libraries.service';

export type changeVisibilityModalResultType = 'prev' | 'next' | 'cancel';

@Component({
  selector: 'app-change-visibility-modal',
  templateUrl: './change-visibility-modal.component.html',
  styleUrls: ['./change-visibility-modal.component.scss']
})
export class ChangeVisibilityModalComponent implements OnInit {
  @Input() title: string;
  @Input() recipeLibrary: RecipeLibrary;
  @Output() result = new EventEmitter<changeVisibilityModalResultType>();

  recipes: RecipeBase[];
  selectedLibraryFg = new FormControl(undefined);
  loading = false;

  constructor(
    private recipeService: RecipeService,
    private librariesService: LibrariesService
  ) {}

  ngOnInit() {
    this.recipeService
      .getRecipes(
        undefined,
        undefined,
        `libraryId eq '${this.recipeLibrary.libraryId}'`
      )
      .subscribe(res => {
        this.recipes = res.result;
      });
  }

  cancel() {
    this.result.emit('cancel');
  }

  goBack() {
    this.result.emit('prev');
  }

  changeVisibility() {
    this.loading = true;
    const newLibrary = this.selectedLibraryFg.value as RecipeLibrary;
    this.recipeService
      .changeRecipesVisibility({
        libraryId: newLibrary.libraryId,
        recipeIds: this.recipes.map(r => r.recipeId)
      })
      .subscribe(
        () => {
          this.librariesService.refresh().then(() => {
            this.result.emit('next');
          });
        },
        () => {
          this.loading = false;
        }
      );
  }
}
