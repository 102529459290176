import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { BsModalRef } from "ngx-bootstrap/modal"
import { UnitRemoteControlCommand, UnitsService } from "src/app/api"

@Component({
  selector: "app-unit-remote-control-modal",
  templateUrl: "./unit-remote-control-modal.component.html",
  styleUrls: ["./unit-remote-control-modal.component.scss"]
})
export class UnitRemoteControlModalComponent implements OnInit {
  unitId: string
  currentStatus: string
  unitStatus: any = {} // Partial<UnitStatus>;
  status = "waitingInstruction"
  commandList = Object.values(UnitRemoteControlCommand)
  @Output() unitReload = new EventEmitter()

  constructor(
    private modalRef: BsModalRef,
    private unitsService: UnitsService
  ) {}

  ngOnInit() {}

  close() {
    this.modalRef.hide()
  }

  startPreheat() {
    this.sendCommand(this.commandList[0], "PREHEATING")
  }

  stopPreheating() {
    this.sendCommand(this.commandList[1], "IDLE")
  }

  stopCooking() {
    this.sendCommand(this.commandList[2], "IDLE")
  }

  sendCommand(command: UnitRemoteControlCommand, endStatus: string) {
    this.status = "syncing"
    this.unitsService.sendRemoteControlCommand(this.unitId, command).subscribe(
      () => {
        this.currentStatus = endStatus
        this.status = "synced"
        this.unitReload.emit()
      },
      data => {
        if (data.status === 218) {
          this.status = "offline"
        }
      }
    )
  }
}
