import { AfterViewInit, Component, Input } from "@angular/core"
import { FormControl } from "@angular/forms"
import { UnitService } from "src/app/api/api/unit.service"
import { UnitCategory } from "src/app/api/model/unitCategory"

@Component({
  selector: "app-unit-category-filter",
  templateUrl: "./unit-category-filter.component.html",
  styleUrls: ["./unit-category-filter.component.scss"]
})
export class UnitCategoryFilterComponent implements AfterViewInit {
  @Input() unitCategoryFormControl: FormControl
  @Input() unitCategories: UnitCategory[] | undefined
  @Input() includeAll = true
  selectedCategory: string | undefined | null = null

  constructor(private unitService: UnitService) {}

  ngAfterViewInit(): void {
    if (!this.unitCategories) {
      this.unitService.getCategories().subscribe(unitCategory => {
        this.unitCategories = unitCategory
      })
    }
  }

  setValue(unitCategory: UnitCategory) {
    this.unitCategoryFormControl.setValue(unitCategory)
    if (unitCategory === undefined) {
      this.selectedCategory = undefined
    } else {
      this.selectedCategory = unitCategory
    }
  }
}
