import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { TableColumn } from "@swimlane/ngx-datatable"
import { FiltrableDataSource } from "filtrable-data-source"
import { UnitSettingsTemplateBase } from "src/app/api"
import { PermissionService } from "src/app/core/permission.service"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"

@Component({
  selector: "app-unit-settings-table",
  templateUrl: "./unit-settings-table.component.html",
  styleUrls: ["./unit-settings-table.component.scss"]
})
export class UnitSettingsTableComponent implements OnInit {
  @Input() dataSource: FiltrableDataSource<any>
  @Input() selectedHandler: SelectedItemsHandler<UnitSettingsTemplateBase>
  @Output() duplicateUnitSettings = new EventEmitter<any>()
  @Output() deleteUnitSettings = new EventEmitter<any>()
  @Output() assignToUnit = new EventEmitter<any>()
  @Output() unitLinkClicked = new EventEmitter<any>()

  columns: TableColumn[] = []
  selected: any[] = []

  @ViewChild("unitNameTpl", { static: true }) unitNameTpl: TemplateRef<any>
  @ViewChild("unitCreatorTp1", { static: true }) unitCreatorTp1: TemplateRef<
    any
  >
  @ViewChild("actionMenuTpl", { static: true }) actionMenuTpl: TemplateRef<any>

  constructor(public auth: PermissionService, private ts: TranslateService) {}

  ngOnInit() {
    this.columns = [
      {
        prop: "name",
        name: this.ts.instant("UNIT_SETTINGS.UNIT_SETTINGS_NAME"),
        cellTemplate: this.unitNameTpl,
        minWidth: 250
      },
      {
        prop: "creatorName",
        name: this.ts.instant("GLOBAL.AUTHOR_CREATOR"),
        cellTemplate: this.unitCreatorTp1,
        minWidth: 100
      },
      {
        cellTemplate: this.actionMenuTpl,
        sortable: false,
        minWidth: 60,
        maxWidth: 60
      }
    ]
  }
}
