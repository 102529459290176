export const gMapStyles = [
  {
    featureType: 'all',

    elementType: 'all',

    stylers: [
      {
        visibility: 'on'
      }
    ]
  },

  {
    featureType: 'all',

    elementType: 'geometry.fill',

    stylers: [
      {
        weight: '2.00'
      }
    ]
  },

  {
    featureType: 'all',

    elementType: 'geometry.stroke',

    stylers: [
      {
        color: '#9c9c9c'
      }
    ]
  },

  {
    featureType: 'all',

    elementType: 'labels.text',

    stylers: [
      {
        visibility: 'on'
      }
    ]
  },

  {
    featureType: 'administrative',

    elementType: 'all',

    stylers: [
      {
        visibility: 'on'
      }
    ]
  },

  {
    featureType: 'administrative',

    elementType: 'labels.icon',

    stylers: [
      {
        visibility: 'on'
      }
    ]
  },

  {
    featureType: 'landscape',

    elementType: 'all',

    stylers: [
      {
        color: '#f2f2f2'
      },

      {
        visibility: 'on'
      }
    ]
  },

  {
    featureType: 'landscape',

    elementType: 'geometry.fill',

    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },

  {
    featureType: 'landscape',

    elementType: 'labels.icon',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'landscape.man_made',

    elementType: 'geometry.fill',

    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },

  {
    featureType: 'poi',

    elementType: 'all',

    stylers: [
      {
        visibility: 'on'
      },

      {
        saturation: '-19'
      },

      {
        lightness: '40'
      }
    ]
  },

  {
    featureType: 'poi',

    elementType: 'labels.icon',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'poi.attraction',

    elementType: 'labels.text',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'poi.government',

    elementType: 'labels.icon',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'poi.medical',

    elementType: 'labels.icon',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'poi.park',

    elementType: 'labels.icon',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'road',

    elementType: 'all',

    stylers: [
      {
        saturation: -100
      },

      {
        lightness: 45
      },

      {
        visibility: 'on'
      }
    ]
  },

  {
    featureType: 'road',

    elementType: 'geometry.fill',

    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },

  {
    featureType: 'road',

    elementType: 'labels.text.fill',

    stylers: [
      {
        color: '#7b7b7b'
      }
    ]
  },

  {
    featureType: 'road',

    elementType: 'labels.text.stroke',

    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },

  {
    featureType: 'road',

    elementType: 'labels.icon',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'road.highway',

    elementType: 'all',

    stylers: [
      {
        visibility: 'on'
      }
    ]
  },

  {
    featureType: 'road.highway',

    elementType: 'labels.text',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'road.highway.controlled_access',

    elementType: 'labels',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'road.highway.controlled_access',

    elementType: 'labels.icon',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'road.arterial',

    elementType: 'all',

    stylers: [
      {
        visibility: 'on'
      }
    ]
  },

  {
    featureType: 'road.arterial',

    elementType: 'labels.icon',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'transit',

    elementType: 'all',

    stylers: [
      {
        visibility: 'on'
      }
    ]
  },

  {
    featureType: 'transit',

    elementType: 'geometry.fill',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'transit',

    elementType: 'geometry.stroke',

    stylers: [
      {
        visibility: 'off'
      }
    ]
  },

  {
    featureType: 'transit.station',

    elementType: 'all',

    stylers: [
      {
        visibility: 'simplified'
      }
    ]
  },

  {
    featureType: 'water',

    elementType: 'all',

    stylers: [
      {
        visibility: 'on'
      },

      {
        lightness: '30'
      },

      {
        saturation: '-53'
      },

      {
        color: '#7fc3ee'
      }
    ]
  },

  {
    featureType: 'water',

    elementType: 'geometry.fill',

    stylers: [
      {
        color: '#a2c9d5'
      }
    ]
  },

  {
    featureType: 'water',

    elementType: 'labels.text.fill',

    stylers: [
      {
        color: '#070707'
      },

      {
        weight: '0.01'
      },

      {
        lightness: '35'
      },

      {
        saturation: '-21'
      }
    ]
  },

  {
    featureType: 'water',

    elementType: 'labels.text.stroke',

    stylers: [
      {
        color: '#ffffff'
      }
    ]
  }
];
