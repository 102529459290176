import { Component, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { UnitType } from "src/app/api"

@Component({
  selector: "app-unit-type-select",
  templateUrl: "./unit-type-select.component.html",
  styleUrls: ["./unit-type-select.component.scss"]
})
export class UnitTypeSelectComponent {
  @Input() unitTypeControl: FormControl
  @Input() selectClass?: string

  unitTypes = Object.values(UnitType)

  constructor() {}
}
