import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ITreeOptions,
  ITreeState,
  TreeComponent as AngularTreeComponent,
  TreeModel,
  TreeNode
} from 'angular-tree-component';
import { Observable } from 'rxjs';

export interface ITreeEvent {
  eventName: 'activate' | 'select' | 'deselect';
  node: TreeNode;
  treeModel: TreeModel;
}

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreeComponent implements OnInit {
  @Input() nodes: any;
  @Input() options: ITreeOptions;
  @Input() state: ITreeState;
  @Input() multiSelect = true;

  @Input() nameFilter$?: Observable<string>;

  @Output() event = new EventEmitter<ITreeEvent>();
  @Output() stateChange = new EventEmitter<ITreeState>();

  @ViewChild(AngularTreeComponent, { static: true }) tree: AngularTreeComponent;

  constructor() {}

  ngOnInit() {
    if (this.nameFilter$) {
      this.nameFilter$.subscribe(val => {
        this.tree.treeModel.filterNodes(val, true);
      });
    }
  }
}
