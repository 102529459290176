import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"

/**
 * This validator checks if an input is blank or contains only whitespace characters.
 * @returns Any form errors due to whitespace.
 */
export function NoWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || "").trim().length === 0
    return !isWhitespace ? null : { whitespace: true }
  }
}
