/// <reference types="@types/googlemaps" />
import { AgmMap, MapsAPILoader } from "@agm/core"
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild
} from "@angular/core"
import { FormGroup } from "@angular/forms"
import { gMapStyles } from "src/app/gmap-styles"
import { Address } from "../../api"
import { parseAddressFromAddressComponents } from "./location-address"

@Component({
  selector: "app-coordinate-widget",
  styleUrls: ["./coordinate-widget.component.scss"],
  templateUrl: "./coordinate-widget.component.html"
})
export class CoordinateWidgetComponent implements OnInit {
  private _address: FormGroup

  get address(): FormGroup {
    return this._address
  }

  public zoom = 5
  private max_zoom = 17

  @Input("addressForm")
  set address(address: FormGroup) {
    if (!address) {
      return
    }

    this._address = address

    if (
      address.controls["longitude"] &&
      address.controls["longitude"].value &&
      address.controls["latitude"] &&
      address.controls["latitude"].value
    ) {
      this.zoom = this.max_zoom
    }
  }

  @ViewChild("map", { static: true }) map: AgmMap

  @ViewChild("search", { read: ElementRef, static: true })
  public searchElementRef: ElementRef

  public geocoder: any
  public mapStyles = gMapStyles

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder()
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ["address"]
        }
      )

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace()
          if (place.geometry === undefined || place.geometry === null) {
            return
          }
          this.zoom = this.max_zoom
          const locationAddress = parseAddressFromAddressComponents(
            place.address_components
          )
          const newAddress: Address = {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            street: (
              locationAddress.streetNumber +
              " " +
              locationAddress.street
            ).trim(),
            city: locationAddress.city,
            state: locationAddress.state,
            country: locationAddress.country,
            postalCode: locationAddress.postalCode
          }
          this._address.patchValue(newAddress)
        })
      })
    })
  }

  mapClick($event: any) {
    const lng = $event.coords.lng
    const lat = $event.coords.lat

    const address: Partial<Address> = {
      longitude: lng,
      latitude: lat
    }
    this._address.patchValue(address)
  }
}
