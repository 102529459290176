import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms"
import {
  ePrimaryState,
  eSecondaryState,
  eTertiaryState,
  RecipeBody,
  RecipeStep,
  RecipeStepMode,
  StageField,
  StageType,
  UnitType
} from "src/app/api"
import { UserTempUnit } from "src/app/core/mykeycloak.service"
import {
  maxTimeValidator,
  minTimeValidator
} from "src/app/shared/time-setpoint/utils"
import { timeFormatValidator } from "../../directives/form-validation/time-format.directive"
import { TemperatureTypePipe } from "../../temperature-type.pipe"
import {
  fahrenheitToCelsius,
  rangeToCelsius
} from "../../temperature/temp-utils"
import {
  minusOneOrOtherValidator,
  minusOneTwoOrOtherValidator,
  recipeModifierOrOtherValidator
} from "./custom-validators"
import { buildMultiShelfTimerFormArray } from "./multi-shelf-timer-modal/multi-shelf-timer-modal.component"

export function isActionStep(
  step: RecipeStep,
  userTempUnit: UserTempUnit
): boolean {
  return (
    step.mode.primary === ePrimaryState.use &&
    step.mode.secondary === eSecondaryState.action &&
    step.mode.tertiary === eTertiaryState.wait
  )
}

export function createActionFormGroup(
  existing?: {
    [key: string]: any;
  },
  unitType?: UnitType
): FormGroup {
  const mode: RecipeStepMode = {
    primary: ePrimaryState.use,
    secondary: eSecondaryState.action,
    tertiary: eTertiaryState.wait
  }

  const prodigiProRangeValues = getRangeValueByUnitType(
    UnitType.ProdigiPro,
    StageType.action
  )

  const res = new FormGroup({
    name: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl({}),
    humidity: new FormControl(0),
    cavitySetpoint: new FormControl(0),
    timeSetpoint:
      unitType === UnitType.ProdigiPro
        ? new FormControl(-1, [
            Validators.required,
            timeFormatValidator(),
            minusOneOrOtherValidator(
              minTimeValidator(prodigiProRangeValues.timeSetpoint.min)
            ),
            minusOneOrOtherValidator(
              maxTimeValidator(prodigiProRangeValues.timeSetpoint.max)
            )
          ])
        : new FormControl(0),
    probeSetpoint: new FormControl(-1),
    fanSpeed: new FormControl(0),
    description: new FormControl("", Validators.required),
    mode: new FormControl(mode)
  })
  if (existing) {
    res.patchValue(existing)
  }

  return res
}

export interface INumberRanges {
  min: number
  max: number
  step: number
  default: number
}

function override<T>(obj: any, over: any): T {
  return { ...obj, ...over }
}

class DefaultOvenParameters {
  private empty = { min: -1, max: -1, step: -1, default: -1 }
  private defaultFanSpeed = { min: 10, max: 100, step: 5, default: 10 }
  // Times are in seconds
  private defaultTimeSetpoint = { min: 10, max: 86399, step: 1, default: 60 }
  private defaultProbeSetpoint = { min: 65, max: 210, step: 1, default: 85 }
  private defaultPreheat = { min: 85, max: 325, step: 1, default: 85 }
  private defaultCavitySetpoint = { min: 85, max: 525, step: 1, default: 375 }
  private defaultHumidity = { min: 0, max: 100, step: 1, default: 100 }
  private defaultPowerLevel = { min: 0, max: 2, step: 1, default: 1 }

  constructor() {}

  public getParameters(
    unitType: UnitType,
    stageType: StageType
  ): Record<StageField, INumberRanges> {
    const params = {
      cavitySetpoint: { ...this.defaultCavitySetpoint },
      timeSetpoint: { ...this.defaultTimeSetpoint },
      probeSetpoint: { ...this.defaultProbeSetpoint },
      fanSpeed: { ...this.defaultFanSpeed },
      preheat: { ...this.defaultPreheat },
      humidity: { ...this.defaultHumidity },
      powerLevel: { ...this.defaultPowerLevel }
    }

    if (unitType === UnitType.Vector) {
      params.preheat = override(params.preheat, { max: 525 })
    }

    if (unitType === UnitType.Converge) {
      params.preheat = override(params.preheat, { max: 525 })
      params.fanSpeed = override(params.fanSpeed, {
        default: 100
      })
      if (stageType === StageType.steam) {
        params.cavitySetpoint = override(params.cavitySetpoint, {
          max: 250,
          default: 212
        })
      } else if (stageType === StageType.cook) {
        params.humidity = override(params.humidity, { default: 0 })
      }
    }

    if (unitType === UnitType.ProdigiPro) {
      params.fanSpeed = override(params.fanSpeed, {
        min: 20,
        step: 20,
        default: 60
      })
      params.probeSetpoint = override(params.probeSetpoint, {
        min: 32
      })
      params.cavitySetpoint = override(params.cavitySetpoint, {
        max: 575
      })
      params.preheat = override(params.preheat, {
        max: 575
      })
      if (stageType === StageType.steam) {
        params.cavitySetpoint = override(params.cavitySetpoint, {
          max: 250,
          default: 212
        })
      } else if (
        stageType === StageType.cook ||
        stageType === StageType.stage
      ) {
        params.humidity = override(params.humidity, { default: 0 })
      }
    }

    if (
      unitType === UnitType.CookAndHold ||
      unitType === UnitType.CookAndHoldNoSmoker
    ) {
      if (stageType === StageType.hold) {
        params.cavitySetpoint = override(params.cavitySetpoint, {
          default: 175,
          max: 205
        })
      } else {
        params.cavitySetpoint = override(params.cavitySetpoint, {
          default: 275,
          max: 325
        })
      }
      params.probeSetpoint = override(params.probeSetpoint, { max: 205 })
    }
    return params
  }
}
const defaultOvenParameters = new DefaultOvenParameters()

const temperatureKeys = [StageField.cavitySetpoint, StageField.probeSetpoint]
const rangeTemperatureKeys = [...temperatureKeys, StageField.preheat]

export function isStageStep(
  step: RecipeStep,
  userTempUnit: UserTempUnit
): boolean {
  return (
    step.mode.primary === ePrimaryState.use &&
    step.mode.secondary === eSecondaryState.enthalpic &&
    step.mode.tertiary === eTertiaryState.convection
  )
}

export function createStageFromGroup(
  unitType: UnitType,
  userTempUnit: UserTempUnit,
  existing?: { [key: string]: any }
): FormGroup {
  const mode: RecipeStepMode = {
    primary: ePrimaryState.use,
    secondary: eSecondaryState.enthalpic,
    tertiary: eTertiaryState.convection
  }
  const stageType = StageType.stage
  const rangeValues: Record<
    StageField,
    INumberRanges
  > = getRangeValueByTemperatureType(unitType, stageType, userTempUnit)

  const res = new FormGroup({
    name: new FormControl(""),
    description: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl(""),
    cavitySetpoint: new FormControl(rangeValues.cavitySetpoint.default, [
      Validators.required,
      Validators.min(rangeValues.cavitySetpoint.min),
      Validators.max(rangeValues.cavitySetpoint.max)
    ]),
    timeSetpoint: new FormControl(rangeValues.timeSetpoint.default, [
      Validators.required,
      timeFormatValidator(),
      minusOneOrOtherValidator(minTimeValidator(rangeValues.timeSetpoint.min)),
      minusOneOrOtherValidator(maxTimeValidator(rangeValues.timeSetpoint.max))
    ]),
    probeSetpoint: new FormControl(rangeValues.probeSetpoint.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.probeSetpoint.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.probeSetpoint.max))
    ]),
    fanSpeed: new FormControl(rangeValues.fanSpeed.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.fanSpeed.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.fanSpeed.max))
    ]),
    humidity: new FormControl(rangeValues.humidity.default, [
      minusOneOrOtherValidator(Validators.min(rangeValues.humidity.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.humidity.max))
    ]),
    mode: new FormControl(mode)
  })

  if (existing) {
    res.patchValue(existing)
  }
  return res
}

// Only for Cook & Hold
export function isCookStep(
  step: RecipeStep,
  userTempUnit: UserTempUnit
): boolean {
  // haloheat is Cook & Hold's version of convection
  return (
    step.mode.primary === ePrimaryState.use &&
    step.mode.secondary === eSecondaryState.enthalpic &&
    step.mode.tertiary === eTertiaryState.haloheat
  )
}

export function createCookStageFromGroup(
  unitType: UnitType,
  userTempUnit: UserTempUnit,
  existing?: { [key: string]: any }
): FormGroup {
  const mode: RecipeStepMode = {
    primary: ePrimaryState.use,
    secondary: eSecondaryState.enthalpic,
    tertiary:
      unitType === UnitType.CookAndHold ||
      unitType === UnitType.CookAndHoldNoSmoker
        ? eTertiaryState.haloheat
        : eTertiaryState.convection
  }
  const stageType = StageType.cook
  const rangeValues: Record<
    StageField,
    INumberRanges
  > = getRangeValueByTemperatureType(unitType, stageType, userTempUnit)

  const res = new FormGroup({
    name: new FormControl(""),
    description: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl(""),
    cavitySetpoint: new FormControl(rangeValues.cavitySetpoint.default, [
      Validators.required,
      Validators.min(rangeValues.cavitySetpoint.min),
      Validators.max(rangeValues.cavitySetpoint.max)
    ]),
    timeSetpoint: new FormControl(rangeValues.timeSetpoint.default, [
      Validators.required,
      timeFormatValidator(),
      minusOneOrOtherValidator(minTimeValidator(rangeValues.timeSetpoint.min)),
      minusOneOrOtherValidator(maxTimeValidator(rangeValues.timeSetpoint.max))
    ]),
    probeSetpoint: new FormControl(rangeValues.probeSetpoint.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.probeSetpoint.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.probeSetpoint.max))
    ]),
    fanSpeed: new FormControl(rangeValues.fanSpeed.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.fanSpeed.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.fanSpeed.max))
    ]),
    mode: new FormControl(mode),
    smoker: new FormControl(false),
    latch: new FormControl(false)
  })

  if (existing) {
    res.patchValue(existing)
  }
  return res
}

export function isColdSmokeStep(
  step: RecipeStep,
  userTempUnit: UserTempUnit
): boolean {
  return (
    step.mode.primary === ePrimaryState.use &&
    step.mode.secondary === eSecondaryState.enthalpic &&
    step.mode.tertiary === eTertiaryState.haloheat &&
    step.smoker === true &&
    step.cavitySetpoint === coldSmokeByUserTemp(userTempUnit)
  )
}

export function createColdSmokeStageFromGroup(
  unitType: UnitType,
  userTempUnit: UserTempUnit,
  existing?: { [key: string]: any }
): FormGroup {
  const mode: RecipeStepMode = {
    primary: ePrimaryState.use,
    secondary: eSecondaryState.enthalpic,
    tertiary: eTertiaryState.haloheat
  }
  const stageType = StageType.coldSmoke
  const rangeValues: Record<
    StageField,
    INumberRanges
  > = getRangeValueByTemperatureType(unitType, stageType, userTempUnit)

  const res = new FormGroup({
    name: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl(""),
    cavitySetpoint: new FormControl(coldSmokeByUserTemp(userTempUnit)),
    timeSetpoint: new FormControl(rangeValues.timeSetpoint.default, [
      Validators.required,
      Validators.minLength(6),
      minTimeValidator(rangeValues.timeSetpoint.min),
      maxTimeValidator(rangeValues.timeSetpoint.max)
    ]),
    probeSetpoint: new FormControl(-1),
    description: new FormControl(""),
    mode: new FormControl(mode),
    smoker: new FormControl(true),
    latch: new FormControl(false)
  })
  if (existing) {
    res.patchValue(existing)
  }
  return res
}

export function isHoldStep(
  step: RecipeStep,
  userTempUnit: UserTempUnit
): boolean {
  return (
    step.mode.primary === ePrimaryState.use &&
    step.mode.secondary === eSecondaryState.enthalpic &&
    step.mode.tertiary === eTertiaryState.hold
  )
}

export function createHoldStageFromGroup(
  unitType: UnitType,
  userTempUnit: UserTempUnit,
  existing?: { [key: string]: any }
): FormGroup {
  const mode: RecipeStepMode = {
    primary: ePrimaryState.use,
    secondary: eSecondaryState.enthalpic,
    tertiary: eTertiaryState.hold
  }
  const stageType = StageType.hold
  const rangeValues: Record<
    StageField,
    INumberRanges
  > = getRangeValueByTemperatureType(unitType, stageType, userTempUnit)

  const res = new FormGroup({
    name: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl(""),
    cavitySetpoint: new FormControl(rangeValues.cavitySetpoint.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.cavitySetpoint.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.cavitySetpoint.max))
    ]),
    probeSetpoint: new FormControl(rangeValues.probeSetpoint.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.probeSetpoint.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.probeSetpoint.max))
    ]),
    timeSetpoint: new FormControl(0),
    description: new FormControl(""),
    mode: new FormControl(mode),
    smoker: new FormControl(false),
    latch: new FormControl(false)
  })
  if (existing) {
    res.patchValue(existing)
  }
  return res
}

export function isPreheatStep(
  step: RecipeStep,
  userTempUnit: UserTempUnit
): boolean {
  return (
    step.mode.primary === ePrimaryState.use &&
    step.mode.secondary === eSecondaryState.regulate &&
    step.mode.tertiary === eTertiaryState.preheat
  )
}

export function createPreheatStageFromGroup(
  unitType: UnitType,
  userTempUnit: UserTempUnit,
  existing?: { [key: string]: any }
): FormGroup {
  const mode: RecipeStepMode = {
    primary: ePrimaryState.use,
    secondary: eSecondaryState.regulate,
    tertiary: eTertiaryState.preheat
  }
  const stageType = StageType.preheat
  const rangeValues: Record<
    StageField,
    INumberRanges
  > = getRangeValueByTemperatureType(unitType, stageType, userTempUnit)

  const res = new FormGroup({
    name: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl(""),
    cavitySetpoint: new FormControl(rangeValues.preheat.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.preheat.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.preheat.max))
    ]),
    probeSetpoint: new FormControl(-1),
    timeSetpoint: new FormControl(-1),
    description: new FormControl(""),
    mode: new FormControl(mode),
    smoker: new FormControl(false),
    latch: new FormControl(false)
  })
  if (existing) {
    res.patchValue(existing)
  }
  return res
}

export function isSteamStep(
  step: RecipeStep,
  userTempUnit: UserTempUnit
): boolean {
  // TODO: JASON: Verify that the secondary state is correct
  return (
    step.mode.primary === ePrimaryState.use &&
    step.mode.secondary === eSecondaryState.enthalpic &&
    step.mode.tertiary === eTertiaryState.steam
  )
}

export function createSteamStageFromGroup(
  unitType: UnitType,
  userTempUnit: UserTempUnit,
  existing?: { [key: string]: any }
): FormGroup {
  const mode: RecipeStepMode = {
    primary: ePrimaryState.use,
    secondary: eSecondaryState.enthalpic,
    tertiary: eTertiaryState.steam
  }
  const stageType = StageType.steam
  const rangeValues: Record<
    StageField,
    INumberRanges
  > = getRangeValueByTemperatureType(unitType, stageType, userTempUnit)

  const res = new FormGroup({
    name: new FormControl(""),
    description: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl(""),
    cavitySetpoint: new FormControl(rangeValues.cavitySetpoint.default, [
      Validators.required,
      Validators.min(rangeValues.cavitySetpoint.min),
      Validators.max(rangeValues.cavitySetpoint.max)
    ]),
    timeSetpoint: new FormControl(rangeValues.timeSetpoint.default, [
      Validators.required,
      Validators.minLength(6),
      minusOneOrOtherValidator(minTimeValidator(rangeValues.timeSetpoint.min)),
      minusOneOrOtherValidator(maxTimeValidator(rangeValues.timeSetpoint.max))
    ]),
    probeSetpoint: new FormControl(rangeValues.probeSetpoint.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.probeSetpoint.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.probeSetpoint.max))
    ]),
    fanSpeed: new FormControl(rangeValues.fanSpeed.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.fanSpeed.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.fanSpeed.max))
    ]),
    humidity: new FormControl(rangeValues.humidity.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.humidity.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.humidity.max))
    ]),
    mode: new FormControl(mode)
  })

  if (existing) {
    res.patchValue(existing)
  }
  return res
}

export function isCombiStep(
  step: RecipeStep,
  userTempUnit: UserTempUnit
): boolean {
  // TODO: JASON: Verify that the secondary state is correct
  return (
    step.mode.primary === ePrimaryState.use &&
    step.mode.secondary === eSecondaryState.enthalpic &&
    step.mode.tertiary === eTertiaryState.combi
  )
}

// Jason really wanted to implement this but the cold smoker really threw a wrench into things.
export function modeToStageType(mode: RecipeStepMode): StageType {
  if (
    mode.primary === ePrimaryState.use &&
    mode.secondary === eSecondaryState.action &&
    mode.tertiary === eTertiaryState.wait
  ) {
    return StageType.action
  }
  if (
    mode.primary === ePrimaryState.use &&
    mode.secondary === eSecondaryState.enthalpic &&
    mode.tertiary === eTertiaryState.convection
  ) {
    return StageType.stage
  }
  if (
    mode.primary === ePrimaryState.use &&
    mode.secondary === eSecondaryState.enthalpic &&
    mode.tertiary === eTertiaryState.haloheat
  ) {
    return StageType.cook
  }
  /*
  if (mode.primary === ePrimaryState.use &&
      mode.secondary === eSecondaryState.enthalpic &&
      mode.tertiary === eTertiaryState.haloheat &&
      step.smoker === true &&
      step.cavitySetpoint === coldSmokeByUserTemp(userTempUnit)
  ) {
    return StageType.coldSmoke;
  }
  */
  if (
    mode.primary === ePrimaryState.use &&
    mode.secondary === eSecondaryState.enthalpic &&
    mode.tertiary === eTertiaryState.hold
  ) {
    return StageType.hold
  }
  if (
    mode.primary === ePrimaryState.use &&
    mode.secondary === eSecondaryState.regulate &&
    mode.tertiary === eTertiaryState.preheat
  ) {
    return StageType.preheat
  }
  if (
    mode.primary === ePrimaryState.use &&
    mode.secondary === eSecondaryState.enthalpic &&
    mode.tertiary === eTertiaryState.steam
  ) {
    return StageType.steam
  }
  if (
    mode.primary === ePrimaryState.use &&
    mode.secondary === eSecondaryState.enthalpic &&
    mode.tertiary === eTertiaryState.combi
  ) {
    return StageType.combi
  }
  return undefined as any
}

/**
 * Creates the correct recipe step mode settings for the given stage type.
 * Defaults to "normal" (non-Cook & Hold) ovens. To use Cook & Hold-specific
 * settings for StageType.cook, pass `true` to the `isCookAndHold` argument.
 * @param stageType step type
 * @param isCookAndHold if `true` and `stageType` is `cook`, the resulting recipe
 *                        step mode's `tertiary` value will be `haloheat` instead of `convection`
 * @returns a RecipeStepMode configured specifically for the given stage type
 */
export function getRecipeStepMode(
  stageType: StageType,
  isCookAndHold: boolean = false
): RecipeStepMode {
  let mode: RecipeStepMode = {} as RecipeStepMode
  if (StageType.combi === stageType) {
    mode = {
      primary: ePrimaryState.use,
      secondary: eSecondaryState.enthalpic,
      tertiary: eTertiaryState.combi
    }
  } else if (StageType.steam === stageType) {
    mode = {
      primary: ePrimaryState.use,
      secondary: eSecondaryState.enthalpic,
      tertiary: eTertiaryState.steam
    }
  } else if (StageType.cook === stageType) {
    mode = {
      primary: ePrimaryState.use,
      secondary: eSecondaryState.enthalpic,
      tertiary: isCookAndHold
        ? eTertiaryState.haloheat
        : eTertiaryState.convection
    }
  } else if (StageType.preheat === stageType) {
    mode = {
      primary: ePrimaryState.use,
      secondary: eSecondaryState.regulate,
      tertiary: eTertiaryState.preheat
    }
  } else if (StageType.hold === stageType) {
    mode = {
      primary: ePrimaryState.use,
      secondary: eSecondaryState.enthalpic,
      tertiary: eTertiaryState.hold
    }
  } else if (StageType.coldSmoke === stageType) {
    mode = {
      primary: ePrimaryState.use,
      secondary: eSecondaryState.enthalpic,
      tertiary: eTertiaryState.haloheat
    }
  }
  return mode
}

/**
 * All recipe step types for Converge have the same input fields. This function
 * creates a new FormGroup for any type of Converge step (except Action step).
 * @param userTempUnit current user's Fahrenheit or Celsius preference
 * @param stageType step's type
 * @param existing any existing FormControl/values map that should be used to
 *                  populate the input controls
 * @returns a new Converge FormGroup
 */
export function createConvergeStageFormGroup(
  userTempUnit: UserTempUnit,
  stageType: StageType,
  existing?: { [key: string]: any }
): FormGroup {
  const rangeValues: Record<
    StageField,
    INumberRanges
  > = getRangeValueByTemperatureType(
    UnitType.Converge,
    stageType,
    userTempUnit
  )

  const res = new FormGroup({
    name: new FormControl(""),
    description: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl(""),
    cavitySetpoint: new FormControl(rangeValues.cavitySetpoint.default, [
      Validators.required,
      Validators.min(rangeValues.cavitySetpoint.min),
      Validators.max(rangeValues.cavitySetpoint.max)
    ]),
    timeSetpoint: new FormControl(rangeValues.timeSetpoint.default, [
      Validators.required,
      Validators.minLength(6),
      minusOneOrOtherValidator(minTimeValidator(rangeValues.timeSetpoint.min)),
      minusOneOrOtherValidator(maxTimeValidator(rangeValues.timeSetpoint.max))
    ]),
    probeSetpoint: new FormControl(rangeValues.probeSetpoint.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.probeSetpoint.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.probeSetpoint.max))
    ]),
    fanSpeed: new FormControl(rangeValues.fanSpeed.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.fanSpeed.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.fanSpeed.max))
    ]),
    humidity: new FormControl(rangeValues.humidity.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.humidity.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.humidity.max))
    ]),
    mode: new FormControl(getRecipeStepMode(stageType))
  })

  if (existing) {
    res.patchValue(existing)
  }
  return res
}

export function createProdigiProFormGroup(
  userTempUnit: UserTempUnit,
  stageType: StageType,
  temperatureTypePipe: TemperatureTypePipe,
  existing?: { [key: string]: any }
): FormGroup {
  const rangeValues: Record<
    StageField,
    INumberRanges
  > = getRangeValueByTemperatureType(
    UnitType.ProdigiPro,
    stageType,
    userTempUnit
  )

  const cavitySetpointForm = new FormControl(
    rangeValues.cavitySetpoint.default
  )
  let specialStage = null
  if (stageType === StageType.preheat) {
    specialStage = createPreheatStageFromGroup(
      UnitType.ProdigiPro,
      userTempUnit,
      existing
    )
  } else if (stageType === StageType.action) {
    specialStage = createActionFormGroup(existing, UnitType.ProdigiPro)
  }
  const res = new FormGroup({
    name: new FormControl(""),
    description: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl(""),
    cavitySetpoint: cavitySetpointForm,
    timeSetpoint: new FormControl(rangeValues.timeSetpoint.default, [
      Validators.required,
      Validators.minLength(6),
      minusOneTwoOrOtherValidator(
        minTimeValidator(rangeValues.timeSetpoint.min)
      ),
      minusOneTwoOrOtherValidator(
        maxTimeValidator(rangeValues.timeSetpoint.max)
      )
    ]),
    probeSetpoint: new FormControl(rangeValues.probeSetpoint.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.probeSetpoint.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.probeSetpoint.max))
    ]),
    fanSpeed: new FormControl(rangeValues.fanSpeed.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.fanSpeed.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.fanSpeed.max))
    ]),
    humidity: new FormControl(rangeValues.humidity.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.humidity.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.humidity.max))
    ]),
    powerLevel: new FormControl(rangeValues.powerLevel.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.powerLevel.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.powerLevel.max))
    ]),
    greaseCollection: new FormControl(false, [Validators.required]),
    smoker: new FormControl(false, [Validators.required]),
    multiShelfTimer: new FormArray([]),
    deltaT: new FormControl(false, Validators.required),
    mode: new FormControl(getRecipeStepMode(stageType))
  })

  // cavity setpoint validator depends on other values in the form
  cavitySetpointForm.setValidators([
    Validators.required,
    recipeModifierOrOtherValidator(
      res,
      temperatureTypePipe,
      Validators.min(rangeValues.cavitySetpoint.min)
    ),
    recipeModifierOrOtherValidator(
      res,
      temperatureTypePipe,
      Validators.max(rangeValues.cavitySetpoint.max)
    )
  ])
  cavitySetpointForm.updateValueAndValidity()

  if (
    specialStage !== null &&
    (stageType === StageType.preheat || stageType === StageType.action)
  ) {
    addSpecialStageFormControls(specialStage, res)
    return specialStage
  } else if (specialStage === null && existing) {
    res.patchValue(existing)
    if (existing.multiShelfTimer && existing.multiShelfTimer.length > 0) {
      for (const shelfTimer of existing.multiShelfTimer) {
        buildMultiShelfTimerFormArray(
          res.controls.multiShelfTimer as FormArray,
          shelfTimer
        )
      }
    }
  }

  return res
}

export function createCombiStageFromGroup(
  unitType: UnitType,
  userTempUnit: UserTempUnit,
  existing?: { [key: string]: any }
): FormGroup {
  const mode: RecipeStepMode = {
    primary: ePrimaryState.use,
    secondary: eSecondaryState.enthalpic,
    tertiary: eTertiaryState.combi
  }
  const stageType = StageType.combi
  const rangeValues: Record<
    StageField,
    INumberRanges
  > = getRangeValueByTemperatureType(unitType, stageType, userTempUnit)

  const res = new FormGroup({
    name: new FormControl(""),
    description: new FormControl(""),
    step: new FormControl(0),
    visual: new FormControl(""),
    cavitySetpoint: new FormControl(rangeValues.cavitySetpoint.default, [
      Validators.required,
      Validators.min(rangeValues.cavitySetpoint.min),
      Validators.max(rangeValues.cavitySetpoint.max)
    ]),
    timeSetpoint: new FormControl(rangeValues.timeSetpoint.default, [
      Validators.required,
      Validators.minLength(6),
      minusOneOrOtherValidator(minTimeValidator(rangeValues.timeSetpoint.min)),
      minusOneOrOtherValidator(maxTimeValidator(rangeValues.timeSetpoint.max))
    ]),
    probeSetpoint: new FormControl(rangeValues.probeSetpoint.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.probeSetpoint.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.probeSetpoint.max))
    ]),
    fanSpeed: new FormControl(rangeValues.fanSpeed.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.fanSpeed.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.fanSpeed.max))
    ]),
    humidity: new FormControl(rangeValues.humidity.default, [
      Validators.required,
      minusOneOrOtherValidator(Validators.min(rangeValues.humidity.min)),
      minusOneOrOtherValidator(Validators.max(rangeValues.humidity.max))
    ]),
    mode: new FormControl(mode)
  })

  if (existing) {
    res.patchValue(existing)
  }
  return res
}

export function fillStepsFormArrayFromRecipe(
  recipe: RecipeBody,
  formArray: FormArray,
  userTemp: UserTempUnit,
  temperatureTypePipe?: TemperatureTypePipe
) {
  if (
    recipe &&
    recipe.content &&
    recipe.content.steps &&
    recipe.content.steps.length > 0 &&
    Array.isArray(recipe.content.steps)
  ) {
    for (let step of recipe.content.steps) {
      if (userTemp === "°C") {
        step = convertStepValues(step, fahrenheitToCelsius)
      }

      if (step.hasOwnProperty("mode")) {
        let fg: FormGroup = null as any
        if (recipe.unitType === UnitType.ProdigiPro) {
          fg = createProdigiProFormGroup(
            userTemp,
            modeToStageType(step.mode),
            temperatureTypePipe!,
            step
          )
        } else if (isActionStep(step, userTemp)) {
          fg = createActionFormGroup(step, recipe.unitType)
        } else if (isStageStep(step, userTemp)) {
          fg = createStageFromGroup(recipe.unitType, userTemp, step)
        } else if (isColdSmokeStep(step, userTemp)) {
          fg = createColdSmokeStageFromGroup(recipe.unitType, userTemp, step)
        } else if (isCookStep(step, userTemp)) {
          fg = createCookStageFromGroup(recipe.unitType, userTemp, step)
        } else if (isHoldStep(step, userTemp)) {
          fg = createHoldStageFromGroup(recipe.unitType, userTemp, step)
        } else if (isPreheatStep(step, userTemp)) {
          fg = createPreheatStageFromGroup(recipe.unitType, userTemp, step)
        } else if (isSteamStep(step, userTemp)) {
          fg = createSteamStageFromGroup(recipe.unitType, userTemp, step)
        } else if (isCombiStep(step, userTemp)) {
          fg = createCombiStageFromGroup(recipe.unitType, userTemp, step)
        }
        if (fg !== null) {
          formArray.push(fg)
        }
      }
    }
  }
}

export function fillImagesFormArrayFromRecipe(
  recipe: RecipeBody,
  formArray: FormArray
) {
  if (
    recipe.content.image &&
    Array.isArray(recipe.content.image) &&
    recipe.content.image.length > 0
  ) {
    for (const image of recipe.content.image) {
      formArray.push(new FormControl(image))
    }
  } else {
    // in the case that a recipe has no images, put an empty placeholder form
    // in the "cover image slot" at index 0 to prevent the first gallery image
    // from automatically being promoted to a cover image
    formArray.push(new FormControl({}))
  }
}

export function fillRecipeActionImagesFromRecipe(recipe: RecipeBody) {
  if (recipe.content.image && Array.isArray(recipe.content.image)) {
    // This is a bit hacky, but convert the image ID action visual field back into
    // full recipe image form values by splicing images back into the forms
    for (const step of recipe.content.steps!) {
      const imageForStep = recipe.content.image.filter(
        i => i.id === step.visual
      )[0]
      if (imageForStep) {
        step.visual = imageForStep
      } else {
        step.visual = {}
      }
    }
  }
}

export function fillGroupFormArrayRecipe(
  recipe: RecipeBody,
  formArray: FormArray
) {
  if (recipe.content.group && Array.isArray(recipe.content.group)) {
    for (const group of recipe.content.group) {
      formArray.push(
        new FormGroup({
          name: new FormControl(group.name),
          position: new FormControl(group.position)
        })
      )
    }
  }
}
export function getRangeValueByUnitType(
  unitType: UnitType,
  stageType: StageType
): Record<StageField, INumberRanges> {
  return defaultOvenParameters.getParameters(unitType, stageType)
}

export function getRangeValueByTemperatureType(
  unitType: UnitType,
  stageType: StageType,
  userTempUnit: string
): Record<StageField, INumberRanges> {
  const values = { ...getRangeValueByUnitType(unitType, stageType) }
  if (userTempUnit === "°C") {
    for (const key of rangeTemperatureKeys) {
      if (key in values) {
        values[key] = rangeToCelsius(values[key])
      }
    }
  }

  return values
}

export function convertStepValues(
  step: any,
  convertFn: (v: number) => number,
  convert0C: boolean = false
) {
  let value
  for (const key of temperatureKeys) {
    if (key in step) {
      value = step[key]
      // The previous implementation failed to convert 0 C to 32 F, which was a potential scenario with the
      // Prodigi Cold Smoke modifier.
      if (value !== -1 && (convert0C || value !== 0)) {
        step[key] = convertFn(value)
      }
    }
  }

  return step
}

export function coldSmokeByUserTemp(userTemp: UserTempUnit) {
  return userTemp === "°C" ? 15 : 60
}

export function getRecipeDidByUnitType(unitType: UnitType): number {
  switch (unitType) {
    case UnitType.CookAndHold:
    case UnitType.CookAndHoldNoSmoker:
      return 6
    case UnitType.Vector:
      return 2
    case UnitType.VectorF:
      return 7
    case UnitType.VectorFG:
      return 11
    case UnitType.Converge:
      // TODO Update this device type ID when Alto-Shaam knows what it will be
      return 10
    case UnitType.ProdigiPro:
      return 12
    default:
      return undefined as any
  }
}

export function patchAndAddValue(
  oldFormGroup: FormGroup,
  formGroup: FormGroup
) {
  Object.keys(oldFormGroup.getRawValue()).forEach(key => {
    if (formGroup.controls[key]) {
      formGroup.controls[key].patchValue(oldFormGroup.getRawValue()[key])
    } else {
      formGroup.addControl(key, oldFormGroup.controls[key])
    }
  })
}

/**
 * This function is for filling default values into special case stages (preheat, action, etc) on ovens with
 * very particular JSON attributes.
 *
 * i.e. Prodigi ovens require having all stage modifier JSON attributes in all stages regardless of if that stage
 * can make use of them. If adding a preheat stage to a Prodigi oven, the specialCaseFormGroup would be the
 * preheat form group, and the templateFormGroup would be a Prodigi recipe form group filled with defaults.
 *
 * @param specialCaseFormGroup the form that needs to be updated with additional default values
 * @param templateFormGroup the form containing all particular default attributes
 */
function addSpecialStageFormControls(
  specialCaseFormGroup: FormGroup,
  templateFormGroup: FormGroup
) {
  Object.keys(templateFormGroup.getRawValue()).forEach(key => {
    if (!specialCaseFormGroup.controls[key]) {
      specialCaseFormGroup.addControl(key, templateFormGroup.controls[key])
    }
  })
}
