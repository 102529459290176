// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keycloakBaseUrl: "https://cheflinc-dev.alto-shaam.com/auth",
  apiBasePath: "https://cheflincapi-dev.alto-shaam.com/cheflinc",
  mqttBrokerUrl: "cheflinc-dev.alto-shaam.com",
  region: "us-east-1",
  userPoolId: "us-east-1_LCZnqZX2X",
  userPoolWebClientId: "6bg5ll229k6015laag5dlh6ibf",
  mockApiBasePath: "http://localhost:4200/api",
  reach: {
    // acme dev
    api: "https://api.dev.cygnusreach.com",
    key: "RwU6wNoUnR2LMHCkJNIQD3WQXAR3URVR8ujlrNe4"
    // acme prod
    // api: "https://api.cygnusreach.com",
    // key: "zCZmzu4PYG3s9WNAWpIti1TBcyqlhxN25YWSyli1"
  },
  timeoutMs: 29000 // A timeout of 29 seconds is set as that is the maximum API Gateway to Lambda allowed wait
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
