import { ArrayDataSource } from "@angular/cdk/collections"
import { Input } from "@angular/core"
import { Component, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"

/**
 * Displays a key-value pair side-by-side. If the value changes,
 * the display is updated to indicate a new value has been received.
 */
@Component({
  selector: "app-key-value",
  templateUrl: "./key-value.component.html",
  styleUrls: ["./key-value.component.scss"]
})
export class KeyValueComponent implements OnInit {
  /**
   * All metadata as a JSON object
   */
  @Input() metadata: any

  /**
   * All values from unit, keyed by the keys in `metadata`
   */
  @Input() values: any

  /**
   * Transformations to apply to the raw value passed into `value`, if any
   */
  @Input() labels: { [key: string]: any }
  /**
   * Array of key names whose values have recently been updated
   */
  @Input() changedValues: any

  /**
   * Metadata key to use to find and display the proper label and value from `metadata`.
   * That is, `metadata[key]` should contain `{ key: 'SOME_NASTY_STRING', 'label': 'Display String' }`.
   */
  @Input() key: string

  @Input() value?: any

  get formattedValue(): string {
    return (Array.isArray(this.value) ? this.value : [this.value])
      .map(v =>
        v === null || v === undefined
          ? "--"
          : this.labels && this.labels[v]
          ? this.ts.instant(this.labels[v])
          : v
      )
      .join(" ")
  }

  constructor(private ts: TranslateService) {}

  ngOnInit() {}
}
