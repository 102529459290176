import { formatDate } from "@angular/common"
import { EChartOption } from "echarts"
import { ChartConfiguration, ResolutionType } from "src/app/api"
import { UserTempUnit } from "src/app/core/mykeycloak.service"
import { toUserTemp } from "src/app/shared/temperature/temp-utils"

export interface ChartDataItem {
  actualFan: number
  actualTemp: number
  heaterStatus: number
  targetFan: number
  targetTemp: number
  probeTemp1: number
  timestamp: string
}
export type ChartData = ChartDataItem[]
export type VariablesMap = Record<string, ChartConfiguration.VariableTypesEnum>

export function getDefaultChartOptions(): EChartOption {
  return {
    grid: {
      containLabel: true
    },
    xAxis: {
      silent: false,
      splitLine: {
        show: false
      },
      type: "category"
    },
    tooltip: {
      trigger: "axis"
    },
    textStyle: {
      fontSize: 14
    },
    animationEasing: "elasticOut",
    animationDelayUpdate: function(idx: any) {
      return idx * 5
    }
  }
}

export interface ChartYAxis {
  type: string
  yAxis: EChartOption.YAxis
}

export function getYAxisIndexByVariableType(
  varType: string,
  axes: ChartYAxis[]
): number {
  return axes.findIndex(a => a.type === varType)
}

function getPositionAndOffsetByAxisNum(
  axisNum: number
): ["left" | "right", number] {
  switch (axisNum) {
    case 0:
      return ["left", 0]
    case 1:
      return ["right", 0]
    case 2:
      return ["right", 80]
    case 3:
      return ["left", 80]
  }
  throw new Error("Cannot define more than 4 Y Axes")
}

export interface YAxisDefinition {
  name: string
  unit: string
  min?: number
  max?: number
}

const defaultDefinition: YAxisDefinition = {
  name: "",
  unit: ""
}

export function createYAxisFromChartConfiguration(
  config: ChartConfiguration,
  definitions: Record<string, YAxisDefinition>
): ChartYAxis[] {
  const res: ChartYAxis[] = []
  config.graph.forEach(v => {
    const vType = config.variableTypes[v]

    if (getYAxisIndexByVariableType(vType, res) !== -1) {
      return
    }

    const def = definitions[vType] || defaultDefinition
    const [position, offset] = getPositionAndOffsetByAxisNum(res.length)
    res.push({
      type: vType,
      yAxis: {
        type: "value",
        name: def.name,
        min: def.min || 0,
        max: def.max,
        position,
        offset,
        axisLabel: {
          formatter: `{value} ${def.unit}`
        }
      }
    })
  })

  return res
}

export function getSeriesName(
  varName: string,
  varType: ChartConfiguration.VariableTypesEnum,
  tempUnit: UserTempUnit
): string {
  switch (varType) {
    case "temperature":
      return `${varName} [${tempUnit}]`
    case "percentage":
      return `${varName} [%]`
    case "RPM":
      return `${varName} [RPM]`
    case "dB":
      return `${varName} [dB]`
    default:
      return ""
  }
}

function formatValue(
  value: number,
  varType: ChartConfiguration.VariableTypesEnum,
  tempUnit: UserTempUnit
): string {
  return varType === "temperature"
    ? toUserTemp(value, tempUnit)
    : value.toFixed()
}

function createDataRow(
  items: ChartData,
  varName: string,
  varType: ChartConfiguration.VariableTypesEnum,
  tempUnit: UserTempUnit,
  field: string
): Array<string> {
  if (!items) {
    return []
  }
  const formattedValues: Array<string> = items.map(item => {
    const val = (item as any)[field]
    return val || val === 0 ? formatValue(val, varType, tempUnit) : "Unknown"
  })
  const name = getSeriesName(varName, varType, tempUnit)
  return [name, ...formattedValues]
}

export function getDateFormatByResolution(resolution: ResolutionType): string {
  switch (resolution) {
    case "minute":
    case "hour":
      return "short"
    case "day":
      return "shortDate"
    case "month":
      return "MMM yyyy"
  }
}

export function chartDataToDataset(
  data: ChartData,
  configuration: ChartConfiguration,
  configIndex: number,
  localeId: string,
  tempUnit: UserTempUnit,
  resolution: ResolutionType
): Array<Array<string | null>> {
  const chartElements = Object.values(data)
  if (!chartElements || chartElements.length < 1) {
    return new Array()
  }
  // By default .sort() will convert to a string and then sort, hence the custom method here
  const formattedDates: (string | null)[] = chartElements
    .flatMap(val => val.timestamp)
    .map(date =>
      date
        ? formatDate(date, getDateFormatByResolution(resolution), localeId)
        : null
    )
    .sort((a, b) => {
      return new Date(a!).getTime() - new Date(b!).getTime()
    })

  const dataRows = configuration.graph.map(varName => {
    const varType = configuration.variableTypes[
      varName
    ] as ChartConfiguration.VariableTypesEnum
    const field = configuration.graphMap[varName]
    return createDataRow(data, varName, varType, tempUnit, field)
  })

  return [["date", ...formattedDates], ...dataRows]
}
