import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { _ } from 'src/app/core/translation-marker';
import { UnitsService } from '../api';
import { DialogModalService } from '../core/dialog-modal.service';
import { ModalCloseEvent } from '../shared/generic-modal/generic-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DeleteUnitRecipeService {
  constructor(
    private dialogService: DialogModalService,
    private unitsService: UnitsService,
    private ts: TranslateService
  ) {}

  unitIsOnline: boolean;

  deleteRecipes(
    unitIsOnline: boolean,
    unitId: string,
    recipeIds: string[]
  ): Observable<ModalCloseEvent> {
    this.unitIsOnline = unitIsOnline;
    return this.openDeleteConfirmModal(unitId, recipeIds).pipe(
      mergeMap(() => this.openSuccessModal(recipeIds))
    );
  }

  private openDeleteConfirmModal(
    unitId: string,
    recipeIds: string[]
  ): Observable<any> {
    const delete$ = this.unitsService.deleteRecipesFromCookbook(
      unitId,
      recipeIds
    );
    const detailMsg = `<p>${this.ts.instant(
      'UNITS.DELETE_RECIPES.DESC1'
    )}</p><p>${this.ts.instant('UNITS.DELETE_RECIPES.DESC2')}</p>`;

    return this.dialogService.dangerConfirm({
      title: this.ts.instant('UNITS.DELETE_RECIPES.TITLE'),
      detail: detailMsg,
      acceptButtonLabel: this.ts.instant('GLOBAL.DELETE'),
      beforeAccept$: delete$
    }).accept$;
  }

  private openSuccessModal(recipeIds: string[]): Observable<ModalCloseEvent> {
    const msg = this.unitIsOnline
      ? _('UNITS.DELETE_RECIPES.CONFIRM')
      : _('UNITS.DELETE_RECIPES.CONFIRM_OFFLINE');
    return this.dialogService.success({
      icon: 'recipe-white.svg',
      message: this.ts.instant(msg, {
        num: recipeIds.length
      }),
      detail: this.ts.instant('GLOBAL.OK_CLICK'),
      acceptButtonLabel: this.ts.instant('GLOBAL.CONTINUE')
    }).close$;
  }
}
