/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * 0 = lista ricette preferite * 1 = manual cooking / professional cooking * 2 = main menu
 */
export type UnitSettingsHomeEnum = 0 | 1 | 2;

export const UnitSettingsHomeEnum = {
  NUMBER_0: 0 as UnitSettingsHomeEnum,
  NUMBER_1: 1 as UnitSettingsHomeEnum,
  NUMBER_2: 2 as UnitSettingsHomeEnum
};
