import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import {
  MediaFile,
  MediaFileDisplayStyle as DisplayStyle,
  MediaFileMetadata
} from "../models/remote-support/media"

@Component({
  selector: "app-media-file-display",
  templateUrl: "./media-file-display.component.html",
  styleUrls: ["./media-file-display.component.scss"]
})
export class MediaFileDisplayComponent implements OnInit {
  _file: MediaFileMetadata
  @Input()
  set file(file: MediaFileMetadata) {
    this._file = file
    this.displayStyle = file.getDisplayStyle()
  }
  get file(): MediaFileMetadata {
    return this._file
  }

  @Input() editable = false
  @Input() viewable = false
  @Input() borderRadii: false | "top" | "bottom" | "all" = false

  @Output() edit = new EventEmitter()
  @Output() view = new EventEmitter()

  // Reference path
  coverPlaceholder = "/assets/static/images/icons/broken-image-dark.svg"

  // Template references vars
  DisplayStyle = DisplayStyle

  // Display information
  displayStyle: DisplayStyle

  constructor() {}

  ngOnInit() {}

  handleError(
    event: any,
    messagePrefix?: string,
    withSourceTag: boolean = true
  ) {
    if (!(this.file instanceof MediaFile)) {
      return
    }

    // For videos, first check for playability with canPlayType - MediaSource.isTypeSupported returns false negatives for webms without codecs specified
    // This isn't perfect - for example, Firefox reports .mov support as "maybe" but errors out
    if (this.file.type.startsWith("video")) {
      // .mov files appear to cause issues even when support is listed as "maybe",
      // so generally attempt rendering without types
      if (this.file.type === "video/quicktime") {
        if (withSourceTag) {
          console.log(
            `${
              messagePrefix ? messagePrefix + ": " : ""
            }First stage of attempting to handle .mov issues`
          )
          this.file.renderIssue = "mov"
        } else {
          console.log(
            `${
              messagePrefix ? messagePrefix + ": " : ""
            }Failed to handle .mov issues, may be unsupported or corrupted`
          )
          this.file.renderIssue = "unsupported"
        }
        this.displayStyle = this.file.getDisplayStyle()
        return
      }

      const video = document.createElement("video")
      const canPlay = video.canPlayType(this.file.type)
      // console.log(`got canPlayType for ${this.file.type} as ${canPlay}`)
      if (canPlay === "") {
        console.log(
          `${
            messagePrefix ? messagePrefix + ": " : ""
          }Got unsupported media type (${this.file.type})`
        )
        this.file.renderIssue = "unsupported"
        this.displayStyle = this.file.getDisplayStyle()
        return
      }
    }

    // If the container type is supported, mov conversion fails, or is an image,
    // set render issue to generalized error
    console.log(
      `${messagePrefix ? messagePrefix + ": " : ""}Error rendering file ${
        this.file.fileName
      }:`
    )
    console.log(event)
    this.file.renderIssue = "error"
    this.displayStyle = this.file.getDisplayStyle()
  }
}
