import { ImageBase64 } from "../api"

export function readFileContent(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result as string)
    }
    fileReader.onerror = () => {
      reject()
    }
    fileReader.readAsDataURL(file)
  })
}

export function fromFileContentToBase64Image(
  type: string,
  content: string,
  name: string,
  isCover?: boolean
): ImageBase64 {
  return {
    mimeType: type,
    data: content.replace(/data:(.*);base64,/, ""),
    name,
    isCover
  }
}

export function convertSizeToMb(size: number): string {
  const value = (size / 1048576).toFixed(2)
  return value + " MB"
}

export function convertSizeToKb(size: number): string {
  const value = (size / 1024).toFixed(2)
  return value + " KB"
}
