import { Component, Input, OnInit } from '@angular/core';
import { Unit } from 'src/app/api';

@Component({
  selector: 'app-unit-summary',
  templateUrl: './unit-summary.component.html',
  styleUrls: ['./unit-summary.component.scss']
})
export class UnitSummaryComponent implements OnInit {
  @Input() unit: Unit;

  constructor() {}

  ngOnInit() {}
}
