import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

interface Breadcrumb {
  url: string;
  data: any;
  params: Params;
  label: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[];

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.breadcrumbs = [];
    let currentRoute: ActivatedRoute | undefined = this.activatedRoute.root;
    let url = '';
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = undefined;

      childrenRoutes.forEach(route => {
        if (route.outlet === 'primary') {
          const routeSnapshot = route.snapshot;
          const routeUrl = routeSnapshot.url
            .map(segment => segment.path)
            .join('/');

          if (routeUrl) {
            url += '/' + routeUrl;
          }

          const routeConfig = routeSnapshot.routeConfig;

          if (routeConfig && routeConfig.data && routeConfig.data['title']) {
            this.breadcrumbs.push({
              label: routeConfig.data['title'],
              data: route.snapshot.data,
              params: route.snapshot.params,
              url: url
            });
          }

          currentRoute = route;
        }
      });
    } while (currentRoute);
  }
}
