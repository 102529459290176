import { Component, EventEmitter, Input, Output } from "@angular/core"
import { PageChangedEvent } from "ngx-bootstrap/pagination"

@Component({
  selector: "app-data-table-paging",
  templateUrl: "./data-table-paging.component.html",
  styleUrls: ["./data-table-paging.component.scss"]
})
export class DataTablePagingComponent {
  @Input() currentPage = 0
  @Input() itemCount = 0
  @Input() itemsPerPage = 50
  @Output() pageChanged: EventEmitter<number> = new EventEmitter()

  constructor() {}

  changePage(page: number) {
    this.pageChanged.emit(page)
  }

  onPageChange(event: PageChangedEvent) {
    this.pageChanged.emit(event.page - 1)
  }
}
