/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable, of } from "rxjs";

import { ChartConfiguration } from "../model/chartConfiguration";
import { ChartTableResult } from "../model/chartTableResult";
import { CookbookRecipeList } from "../model/cookbookRecipeList";
import { ResolutionType } from "../model/resolutionType";
import { Unit } from "../model/unit";
import { UnitDetail } from "../model/unitDetail";
import { UnitEditData } from "../model/unitEditData";
import { UnitLastStatus } from "../model/unitLastStatus";
import { UnitODataResult } from "../model/unitODataResult";
import { UnitRemoteControlCommand } from "../model/unitRemoteControlCommand";
import { UnitSettingsModel } from "../model/unitSettingsModel";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";
import { TelemetryResponse } from "../model/telemetry/telemetryResponse";
import { map } from "rxjs/internal/operators/map";
import { UnitSettingsTemplate } from "../model/unitSettingsTemplate";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class UnitsService {
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Creates a unit
   *
   * @param Unit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createUnit(
    Unit?: Unit,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public createUnit(
    Unit?: Unit,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public createUnit(
    Unit?: Unit,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public createUnit(
    Unit?: Unit,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.configuration.basePath}/units`,
      Unit,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete some recipes from the unit cookbook
   *
   * @param unitId Id of the unit
   * @param recipeIds Id of recipes to delete
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteRecipesFromCookbook(
    unitId: string,
    recipeIds: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteRecipesFromCookbook(
    unitId: string,
    recipeIds: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteRecipesFromCookbook(
    unitId: string,
    recipeIds: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteRecipesFromCookbook(
    unitId: string,
    recipeIds: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling deleteRecipesFromCookbook."
      );
    }
    if (recipeIds === null || recipeIds === undefined) {
      throw new Error(
        "Required parameter recipeIds was null or undefined when calling deleteRecipesFromCookbook."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (recipeIds) {
      recipeIds.forEach(element => {
        queryParameters = queryParameters.append("recipeIds", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/cookbook`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Deletes one or more units
   *
   * @param unitIds Array of unitId of units to delete
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteUnits(
    unitIds?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public deleteUnits(
    unitIds?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteUnits(
    unitIds?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteUnits(
    unitIds?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (unitIds) {
      unitIds.forEach(element => {
        queryParameters = queryParameters.append("unitIds", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(`${this.configuration.basePath}/units`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Duplicates a recipe in the cookbook
   *
   * @param unitId Id of the unit
   * @param recipeId Id of the recipe
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public duplicateCookbookRecipe(
    unitId: string,
    recipeId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public duplicateCookbookRecipe(
    unitId: string,
    recipeId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public duplicateCookbookRecipe(
    unitId: string,
    recipeId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public duplicateCookbookRecipe(
    unitId: string,
    recipeId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling duplicateCookbookRecipe."
      );
    }
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling duplicateCookbookRecipe."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<string>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/cookbook/${encodeURIComponent(String(recipeId))}/duplicate`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit a recipe in the cookbook
   *
   * @param unitId Id of the unit
   * @param recipeId Id of the recipe
   * @param request_body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editCookbookRecipe(
    unitId: string,
    recipeId: string,
    request_body?: { [key: string]: any },
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editCookbookRecipe(
    unitId: string,
    recipeId: string,
    request_body?: { [key: string]: any },
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editCookbookRecipe(
    unitId: string,
    recipeId: string,
    request_body?: { [key: string]: any },
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editCookbookRecipe(
    unitId: string,
    recipeId: string,
    request_body?: { [key: string]: any },
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling editCookbookRecipe."
      );
    }
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling editCookbookRecipe."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/cookbook/${encodeURIComponent(String(recipeId))}`,
      request_body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edits an existing unit
   *
   * @param unitId Id of the unit
   * @param UnitEditData
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editUnit(
    unitId: string,
    UnitEditData?: UnitEditData,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editUnit(
    unitId: string,
    UnitEditData?: UnitEditData,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editUnit(
    unitId: string,
    UnitEditData?: UnitEditData,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editUnit(
    unitId: string,
    UnitEditData?: UnitEditData,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling editUnit."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}`,
      UnitEditData,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit unit settings
   *
   * @param unitId Id of the unit
   * @param UnitSettingsModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editUnitSettings(
    unitId: string,
    unitSettingsTemplate?: UnitSettingsTemplate,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editUnitSettings(
    unitId: string,
    unitSettingsTemplate?: UnitSettingsTemplate,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editUnitSettings(
    unitId: string,
    unitSettingsTemplate?: UnitSettingsTemplate,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editUnitSettings(
    unitId: string,
    unitSettingsTemplate?: UnitSettingsTemplate,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling editUnitSettings."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/settings`,
      unitSettingsTemplate,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Export a ZIP with multiple recipes from Unit Cookbook
   *
   * @param unitId Id of the unit
   * @param request_body Array of recipe IDs
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportCookbookRecipes(
    unitId: string,
    request_body?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Blob>;
  public exportCookbookRecipes(
    unitId: string,
    request_body?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public exportCookbookRecipes(
    unitId: string,
    request_body?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public exportCookbookRecipes(
    unitId: string,
    request_body?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling exportCookbookRecipes."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/cookbook/export`,
      request_body,
      {
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Export a ZIP with multiple recipes from Unit Cookbook
   *
   * @param unitId Id of the unit
   * @param request_body Array of recipe IDs
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportCookbookRecipesCTP(
    unitId: string,
    request_body?: Array<string>
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling exportCookbookRecipes."
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/cookbook/export/ctp`,
      request_body,
      {
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }

  /**
   * Unit data export
   *
   * @param unitId Id of the unit
   * @param chamber Chamber number
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param timezone User timezone
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportsUnitData(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    timezone: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Blob>;
  public exportsUnitData(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    timezone: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public exportsUnitData(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    timezone: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public exportsUnitData(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    timezone: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling exportsUnitData."
      );
    }
    if (chamber === null || chamber === undefined) {
      throw new Error(
        "Required parameter chamber was null or undefined when calling exportsUnitData."
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        "Required parameter from was null or undefined when calling exportsUnitData."
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        "Required parameter to was null or undefined when calling exportsUnitData."
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        "Required parameter timezone was null or undefined when calling exportsUnitData."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (chamber !== undefined && chamber !== null) {
      queryParameters = queryParameters.set("chamber", <any>chamber);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set("from", <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set("to", <any>to);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set("timezone", <any>timezone);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["*/*"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/data/export`,
      {
        params: queryParameters,
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get Recipe detail from Unit Cookbook
   *
   * @param unitId Id of the unit
   * @param recipeId Id of the recipe
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCookbookRecipe(
    unitId: string,
    recipeId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public getCookbookRecipe(
    unitId: string,
    recipeId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public getCookbookRecipe(
    unitId: string,
    recipeId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public getCookbookRecipe(
    unitId: string,
    recipeId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getCookbookRecipe."
      );
    }
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        "Required parameter recipeId was null or undefined when calling getCookbookRecipe."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/cookbook/${encodeURIComponent(String(recipeId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Base information of a unit
   *
   * @param unitId Id of the unit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnit(
    unitId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnitDetail>;
  public getUnit(
    unitId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UnitDetail>>;
  public getUnit(
    unitId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UnitDetail>>;
  public getUnit(
    unitId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getUnit."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnitDetail>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getUnitBySerial(
    serialNumber: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnitDetail[]> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnitDetail[]>(
      `${this.configuration.basePath}/units/serial/${encodeURIComponent(
        String(serialNumber)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get unit cookbook  (filtrable and paginable)
   *
   * @param unitId Id of the unit
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param recipeName Used to filter the list based on the recipe name
   * @param categories Used to filter the list based on categories
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitCookbook(
    unitId: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    recipeName?: string,
    categories?: Array<string>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<CookbookRecipeList>;
  public getUnitCookbook(
    unitId: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    recipeName?: string,
    categories?: Array<string>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<CookbookRecipeList>>;
  public getUnitCookbook(
    unitId: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    recipeName?: string,
    categories?: Array<string>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<CookbookRecipeList>>;
  public getUnitCookbook(
    unitId: string,
    $top?: number,
    $skip?: number,
    $count?: boolean,
    recipeName?: string,
    categories?: Array<string>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getUnitCookbook."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }
    if (recipeName !== undefined && recipeName !== null) {
      queryParameters = queryParameters.set("recipeName", <any>recipeName);
    }
    if (categories) {
      categories.forEach(element => {
        queryParameters = queryParameters.append("categories", <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CookbookRecipeList>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/cookbook`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get List of categories used in the unit cookbook
   *
   * @param unitId Id of the unit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitCookbookCategories(
    unitId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<string>>;
  public getUnitCookbookCategories(
    unitId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<string>>>;
  public getUnitCookbookCategories(
    unitId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<string>>>;
  public getUnitCookbookCategories(
    unitId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getUnitCookbookCategories."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<string>>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/cookbook/categories`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get unit data
   *
   * @param unitId Id of the unit
   * @param chamber Chamber number
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param resolution Resolution of the data displayed in the chart
   * @param timezone User timezone
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitDataChart(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<any>>;
  public getUnitDataChart(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<any>>>;
  public getUnitDataChart(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<any>>>;
  public getUnitDataChart(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getUnitDataChart."
      );
    }
    if (chamber === null || chamber === undefined) {
      throw new Error(
        "Required parameter chamber was null or undefined when calling getUnitDataChart."
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        "Required parameter from was null or undefined when calling getUnitDataChart."
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        "Required parameter to was null or undefined when calling getUnitDataChart."
      );
    }
    if (resolution === null || resolution === undefined) {
      throw new Error(
        "Required parameter resolution was null or undefined when calling getUnitDataChart."
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        "Required parameter timezone was null or undefined when calling getUnitDataChart."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (chamber !== undefined && chamber !== null) {
      queryParameters = queryParameters.set("chamber", <any>chamber);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set("from", <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set("to", <any>to);
    }
    if (resolution !== undefined && resolution !== null) {
      queryParameters = queryParameters.set("resolution", <any>resolution);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set("timezone", <any>timezone);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<any>>(
        `${this.configuration.basePath}/units/${encodeURIComponent(
          String(unitId)
        )}/data/chart`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      )
      .pipe(
        map(response => {
          const responseTyped = (response as any) as Array<any>;
          responseTyped.forEach(dataPoint => {
            // Iterate through each data point. For each one extract probe data to probe fields
            dataPoint.probeTemp.forEach((probe: any, index: number) => {
              (dataPoint as any)["probeTemp" + (index + 1)] = probe;
            });
          });
          return responseTyped;
        })
      );
  }

  /**
   * Get the configuration for charts and tables of \&quot;Unit Data\&quot; page
   *
   * @param unitId Id of the unit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitDataConfiguration(
    unitId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ChartConfiguration>;
  public getUnitDataConfiguration(
    unitId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ChartConfiguration>>;
  public getUnitDataConfiguration(
    unitId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ChartConfiguration>>;
  public getUnitDataConfiguration(
    unitId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getUnitDataConfiguration."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ChartConfiguration>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/data/configuration`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get unit data
   *
   * @param unitId Id of the unit
   * @param chamber Chamber number
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param resolution Resolution of the data displayed in the chart
   * @param timezone User timezone
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $filter Filter items by property values, see [OData Filtering](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionfilter)
   * @param $orderby Order items by property values, see [OData Sorting](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionorderby)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitDataTable(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ChartTableResult>;
  public getUnitDataTable(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ChartTableResult>>;
  public getUnitDataTable(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ChartTableResult>>;
  public getUnitDataTable(
    unitId: string,
    chamber: number,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getUnitDataTable."
      );
    }
    if (chamber === null || chamber === undefined) {
      throw new Error(
        "Required parameter chamber was null or undefined when calling getUnitDataTable."
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        "Required parameter from was null or undefined when calling getUnitDataTable."
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        "Required parameter to was null or undefined when calling getUnitDataTable."
      );
    }
    if (resolution === null || resolution === undefined) {
      throw new Error(
        "Required parameter resolution was null or undefined when calling getUnitDataTable."
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        "Required parameter timezone was null or undefined when calling getUnitDataTable."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (chamber !== undefined && chamber !== null) {
      queryParameters = queryParameters.set("chamber", <any>chamber);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set("from", <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set("to", <any>to);
    }
    if (resolution !== undefined && resolution !== null) {
      queryParameters = queryParameters.set("resolution", <any>resolution);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set("timezone", <any>timezone);
    }
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = queryParameters.set("$filter", <any>$filter);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append("$orderby", <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<ChartTableResult>(
        `${this.configuration.basePath}/units/${encodeURIComponent(
          String(unitId)
        )}/data/table`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      )
      .pipe(
        map(response => {
          const responseTyped = (response as any) as ChartTableResult;
          responseTyped.result.forEach(dataPoint => {
            // Iterate through each data point. For each one extract probe data to probe fields
            dataPoint.probeTemp.forEach((probe: any, index: number) => {
              (dataPoint as any)["probeTemp" + (index + 1)] = probe;
            });
          });
          return responseTyped;
        })
      );
  }

  /**
   * Get list of variables to show in Service Monitor page with their label
   *
   * @param unitId Id of the unit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitServiceMonitor(
    unitId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<TelemetryResponse>;
  public getUnitServiceMonitor(
    unitId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<TelemetryResponse>>;
  public getUnitServiceMonitor(
    unitId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<TelemetryResponse>>;
  public getUnitServiceMonitor(
    unitId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getUnitServiceMonitor."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<{ [key: string]: string }>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/serviceMonitor`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Unit current settings
   *
   * @param unitId Id of the unit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitSettings(
    unitId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnitSettingsTemplate>;
  public getUnitSettings(
    unitId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UnitSettingsTemplate>>;
  public getUnitSettings(
    unitId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UnitSettingsTemplate>>;
  public getUnitSettings(
    unitId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getUnitSettings."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnitSettingsTemplate>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/settings`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Unit current operating status
   *
   * @param unitId Id of the unit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnitStatus(
    unitId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnitLastStatus>;
  public getUnitStatus(
    unitId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UnitLastStatus>>;
  public getUnitStatus(
    unitId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UnitLastStatus>>;
  public getUnitStatus(
    unitId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling getUnitStatus."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnitLastStatus>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/status`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get list of units (with filters and pagination)
   * Used in administration and in units list inside location
   * Note:  Filtering can only test equality comparison and combine comparisons with AND
   *        This can also only test on these fields: unitType, locationId, unitName
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $filter Filter items by property values, see [OData Filtering](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionfilter)
   * @param $orderby Order items by property values, see [OData Sorting](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionorderby)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param connected Unit connected filter
   * @param isInAlarm Unit is in alarm filter
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUnits(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    connected?: boolean,
    isInAlarm?: boolean,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UnitODataResult>;
  public getUnits(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    connected?: boolean,
    isInAlarm?: boolean,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UnitODataResult>>;
  public getUnits(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    connected?: boolean,
    isInAlarm?: boolean,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UnitODataResult>>;
  public getUnits(
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    connected?: boolean,
    isInAlarm?: boolean,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set("$top", <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set("$skip", <any>$skip);
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = queryParameters.set("$filter", <any>$filter);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append("$orderby", <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set("$count", <any>$count);
    }
    if (connected !== undefined && connected !== null) {
      queryParameters = queryParameters.set("connected", <any>connected);
    }
    if (isInAlarm !== undefined && isInAlarm !== null) {
      queryParameters = queryParameters.set("isInAlarm", <any>isInAlarm);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UnitODataResult>(
      `${this.configuration.basePath}/units`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Sends a Remote Control command
   *
   * @param unitId Id of the unit
   * @param command The command to send to the oven
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendRemoteControlCommand(
    unitId: string,
    command: UnitRemoteControlCommand,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public sendRemoteControlCommand(
    unitId: string,
    command: UnitRemoteControlCommand,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public sendRemoteControlCommand(
    unitId: string,
    command: UnitRemoteControlCommand,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public sendRemoteControlCommand(
    unitId: string,
    command: UnitRemoteControlCommand,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling sendRemoteControlCommand."
      );
    }
    if (command === null || command === undefined) {
      throw new Error(
        "Required parameter command was null or undefined when calling sendRemoteControlCommand."
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (command !== undefined && command !== null) {
      queryParameters = queryParameters.set("command", <any>command);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/remoteControl`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Send a command to the oven to starts the real-time sending of data on mqtt topic
   * This API should be called every 1 minute to maintain the connection active
   * @param unitId Id of the unit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startsUnitRealtime(
    unitId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public startsUnitRealtime(
    unitId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public startsUnitRealtime(
    unitId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public startsUnitRealtime(
    unitId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        "Required parameter unitId was null or undefined when calling startsUnitRealtime."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/units/${encodeURIComponent(
        String(unitId)
      )}/startRealtime`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public copyCookbookRecipesToCompanyLibrary(
    unitId: string,
    recipeIds: string[]
  ): Observable<any> {
    return this.httpClient.post(
      `/units/${encodeURIComponent(
        String(unitId)
      )}/cookbook/copy-to-company-library`,
      recipeIds,
      {
        responseType: "json"
      }
    );
  }

  public getMobileVisionUnit(unitId: string): Observable<any> {
    return this.httpClient.get<any>("/mobile-vision/unit/" + unitId, {
      responseType: "json"
    });
  }

  public getMobileVisionUnitStatus(unitId: string): Observable<any> {
    return this.httpClient.get<any>(
      "/mobile-vision/unit/" + unitId + "/status",
      { responseType: "json" }
    );
  }

  public getMobileVisionUnitAlerts(unitId: string): Observable<any> {
    return this.httpClient.get<any>(
      "/mobile-vision/unit/" + unitId + "/alerts",
      { responseType: "json" }
    );
  }

  public getMobileVisionUnitChart(
    unitId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string
  ): Observable<Array<any>> {
    const queryString = `from=${from}&to=${to}&resolution=${resolution}&timezone=${timezone}`;
    return this.httpClient.get<any>(
      "/mobile-vision/unit/" + unitId + "/chart",
      {
        responseType: "json",
        params: new HttpParams({ fromString: queryString })
      }
    );
  }
}
