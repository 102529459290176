/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ChartConfiguration } from '../model/chartConfiguration';
import { ChartTableResult } from '../model/chartTableResult';
import { ResolutionType } from '../model/resolutionType';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath = configuration.basePath || basePath;
    } else {
      // THIS WILL THROW AN NPE! It looks like this was never tested.
      this.configuration.basePath = basePath;
    }
  }

  /**
   * Cooks by recipe report - export
   *
   * @param recipeId Id of the recipe
   * @param filterBy Specify if filterId is relative to a unit or a location
   * @param filterId Id of the filter element
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param timezone User timezone
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportRecipeCooks(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    timezone: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public exportRecipeCooks(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    timezone: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public exportRecipeCooks(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    timezone: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public exportRecipeCooks(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    timezone: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        'Required parameter recipeId was null or undefined when calling exportRecipeCooks.'
      );
    }
    if (filterBy === null || filterBy === undefined) {
      throw new Error(
        'Required parameter filterBy was null or undefined when calling exportRecipeCooks.'
      );
    }
    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling exportRecipeCooks.'
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling exportRecipeCooks.'
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling exportRecipeCooks.'
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        'Required parameter timezone was null or undefined when calling exportRecipeCooks.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (recipeId !== undefined && recipeId !== null) {
      queryParameters = queryParameters.set('recipeId', <any>recipeId);
    }
    if (filterBy !== undefined && filterBy !== null) {
      queryParameters = queryParameters.set('filterBy', <any>filterBy);
    }
    if (filterId !== undefined && filterId !== null) {
      queryParameters = queryParameters.set('filterId', <any>filterId);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set('timezone', <any>timezone);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get('/reports/recipeCooks/export', {
      params: queryParameters,
      responseType: 'blob',
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * WIFI signal strength report - export
   *
   * @param unitId Id of the unit
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param timezone User timezone
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportWifiSignal(
    unitId: string,
    from: string,
    to: string,
    timezone: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public exportWifiSignal(
    unitId: string,
    from: string,
    to: string,
    timezone: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public exportWifiSignal(
    unitId: string,
    from: string,
    to: string,
    timezone: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public exportWifiSignal(
    unitId: string,
    from: string,
    to: string,
    timezone: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        'Required parameter unitId was null or undefined when calling exportWifiSignal.'
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling exportWifiSignal.'
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling exportWifiSignal.'
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        'Required parameter timezone was null or undefined when calling exportWifiSignal.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (unitId !== undefined && unitId !== null) {
      queryParameters = queryParameters.set('unitId', <any>unitId);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set('timezone', <any>timezone);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get('/reports/wifiSignal/export', {
      params: queryParameters,
      responseType: 'blob',
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Cooks by recipe report - chart data
   *
   * @param recipeId Id of the recipe
   * @param filterBy Specify if filterId is relative to a unit or a location
   * @param filterId Id of the filter element
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param resolution Resolution of the data displayed in the chart
   * @param timezone User timezone
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipeCooksChartData(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<{ [key: string]: Array<Array<any>> }>;
  public getRecipeCooksChartData(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<{ [key: string]: Array<Array<any>> }>>;
  public getRecipeCooksChartData(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<{ [key: string]: Array<Array<any>> }>>;
  public getRecipeCooksChartData(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        'Required parameter recipeId was null or undefined when calling getRecipeCooksChartData.'
      );
    }
    if (filterBy === null || filterBy === undefined) {
      throw new Error(
        'Required parameter filterBy was null or undefined when calling getRecipeCooksChartData.'
      );
    }
    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling getRecipeCooksChartData.'
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling getRecipeCooksChartData.'
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling getRecipeCooksChartData.'
      );
    }
    if (resolution === null || resolution === undefined) {
      throw new Error(
        'Required parameter resolution was null or undefined when calling getRecipeCooksChartData.'
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        'Required parameter timezone was null or undefined when calling getRecipeCooksChartData.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (recipeId !== undefined && recipeId !== null) {
      queryParameters = queryParameters.set('recipeId', <any>recipeId);
    }
    if (filterBy !== undefined && filterBy !== null) {
      queryParameters = queryParameters.set('filterBy', <any>filterBy);
    }
    if (filterId !== undefined && filterId !== null) {
      queryParameters = queryParameters.set('filterId', <any>filterId);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to);
    }
    if (resolution !== undefined && resolution !== null) {
      queryParameters = queryParameters.set('resolution', <any>resolution);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set('timezone', <any>timezone);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<{ [key: string]: Array<Array<any>> }>(
      '/reports/recipeCooks/chart',
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Cooks by recipe report - configuration
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipeCooksConfiguration(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ChartConfiguration>;
  public getRecipeCooksConfiguration(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ChartConfiguration>>;
  public getRecipeCooksConfiguration(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ChartConfiguration>>;
  public getRecipeCooksConfiguration(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ChartConfiguration>(
      '/reports/recipeCooks/configuration',
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Cooks by recipe report - table data
   *
   * @param recipeId Id of the recipe
   * @param filterBy Specify if filterId is relative to a unit or a location
   * @param filterId Id of the filter element
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param resolution Resolution of the data displayed in the chart
   * @param timezone User timezone
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $filter Filter items by property values, see [OData Filtering](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionfilter)
   * @param $orderby Order items by property values, see [OData Sorting](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionorderby)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipeCooksTableData(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ChartTableResult>;
  public getRecipeCooksTableData(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ChartTableResult>>;
  public getRecipeCooksTableData(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ChartTableResult>>;
  public getRecipeCooksTableData(
    recipeId: string,
    filterBy: 'unit' | 'location',
    filterId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (recipeId === null || recipeId === undefined) {
      throw new Error(
        'Required parameter recipeId was null or undefined when calling getRecipeCooksTableData.'
      );
    }
    if (filterBy === null || filterBy === undefined) {
      throw new Error(
        'Required parameter filterBy was null or undefined when calling getRecipeCooksTableData.'
      );
    }
    if (filterId === null || filterId === undefined) {
      throw new Error(
        'Required parameter filterId was null or undefined when calling getRecipeCooksTableData.'
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling getRecipeCooksTableData.'
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling getRecipeCooksTableData.'
      );
    }
    if (resolution === null || resolution === undefined) {
      throw new Error(
        'Required parameter resolution was null or undefined when calling getRecipeCooksTableData.'
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        'Required parameter timezone was null or undefined when calling getRecipeCooksTableData.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (recipeId !== undefined && recipeId !== null) {
      queryParameters = queryParameters.set('recipeId', <any>recipeId);
    }
    if (filterBy !== undefined && filterBy !== null) {
      queryParameters = queryParameters.set('filterBy', <any>filterBy);
    }
    if (filterId !== undefined && filterId !== null) {
      queryParameters = queryParameters.set('filterId', <any>filterId);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to);
    }
    if (resolution !== undefined && resolution !== null) {
      queryParameters = queryParameters.set('resolution', <any>resolution);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set('timezone', <any>timezone);
    }
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set('$top', <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set('$skip', <any>$skip);
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = queryParameters.set('$filter', <any>$filter);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append('$orderby', <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set('$count', <any>$count);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ChartTableResult>('/reports/recipeCooks/table', {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * WIFI signal strength report - chart data
   *
   * @param unitId Id of the unit
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param resolution Resolution of the data displayed in the chart
   * @param timezone User timezone
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getWifiSignalChartData(
    unitId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<{ [key: string]: Array<Array<any>> }>;
  public getWifiSignalChartData(
    unitId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<{ [key: string]: Array<Array<any>> }>>;
  public getWifiSignalChartData(
    unitId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<{ [key: string]: Array<Array<any>> }>>;
  public getWifiSignalChartData(
    unitId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        'Required parameter unitId was null or undefined when calling getWifiSignalChartData.'
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling getWifiSignalChartData.'
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling getWifiSignalChartData.'
      );
    }
    if (resolution === null || resolution === undefined) {
      throw new Error(
        'Required parameter resolution was null or undefined when calling getWifiSignalChartData.'
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        'Required parameter timezone was null or undefined when calling getWifiSignalChartData.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (unitId !== undefined && unitId !== null) {
      queryParameters = queryParameters.set('unitId', <any>unitId);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to);
    }
    if (resolution !== undefined && resolution !== null) {
      queryParameters = queryParameters.set('resolution', <any>resolution);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set('timezone', <any>timezone);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<{ [key: string]: Array<Array<any>> }>(
      '/reports/wifiSignal/chart',
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * WIFI signal strength report - configuration
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getWifiSignalConfiguration(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ChartConfiguration>;
  public getWifiSignalConfiguration(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ChartConfiguration>>;
  public getWifiSignalConfiguration(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ChartConfiguration>>;
  public getWifiSignalConfiguration(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ChartConfiguration>(
      '/reports/wifiSignal/configuration',
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * WIFI signal strength report - table data
   *
   * @param unitId Id of the unit
   * @param from First date of the data to query
   * @param to Last date of the data to query
   * @param resolution Resolution of the data displayed in the chart
   * @param timezone User timezone
   * @param $top Show only the first n items, see [OData Paging - Top](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptiontop)
   * @param $skip Skip the first n items, see [OData Paging - Skip](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionskip)
   * @param $filter Filter items by property values, see [OData Filtering](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionfilter)
   * @param $orderby Order items by property values, see [OData Sorting](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptionorderby)
   * @param $count Include count of items, see [OData Count](http://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part1-protocol.html#sec_SystemQueryOptioncount)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getWifiSignalTableData(
    unitId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ChartTableResult>;
  public getWifiSignalTableData(
    unitId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ChartTableResult>>;
  public getWifiSignalTableData(
    unitId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ChartTableResult>>;
  public getWifiSignalTableData(
    unitId: string,
    from: string,
    to: string,
    resolution: ResolutionType,
    timezone: string,
    $top?: number,
    $skip?: number,
    $filter?: string,
    $orderby?: Array<string>,
    $count?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (unitId === null || unitId === undefined) {
      throw new Error(
        'Required parameter unitId was null or undefined when calling getWifiSignalTableData.'
      );
    }
    if (from === null || from === undefined) {
      throw new Error(
        'Required parameter from was null or undefined when calling getWifiSignalTableData.'
      );
    }
    if (to === null || to === undefined) {
      throw new Error(
        'Required parameter to was null or undefined when calling getWifiSignalTableData.'
      );
    }
    if (resolution === null || resolution === undefined) {
      throw new Error(
        'Required parameter resolution was null or undefined when calling getWifiSignalTableData.'
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        'Required parameter timezone was null or undefined when calling getWifiSignalTableData.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (unitId !== undefined && unitId !== null) {
      queryParameters = queryParameters.set('unitId', <any>unitId);
    }
    if (from !== undefined && from !== null) {
      queryParameters = queryParameters.set('from', <any>from);
    }
    if (to !== undefined && to !== null) {
      queryParameters = queryParameters.set('to', <any>to);
    }
    if (resolution !== undefined && resolution !== null) {
      queryParameters = queryParameters.set('resolution', <any>resolution);
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = queryParameters.set('timezone', <any>timezone);
    }
    if ($top !== undefined && $top !== null) {
      queryParameters = queryParameters.set('$top', <any>$top);
    }
    if ($skip !== undefined && $skip !== null) {
      queryParameters = queryParameters.set('$skip', <any>$skip);
    }
    if ($filter !== undefined && $filter !== null) {
      queryParameters = queryParameters.set('$filter', <any>$filter);
    }
    if ($orderby) {
      $orderby.forEach(element => {
        queryParameters = queryParameters.append('$orderby', <any>element);
      });
    }
    if ($count !== undefined && $count !== null) {
      queryParameters = queryParameters.set('$count', <any>$count);
    }

    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ChartTableResult>('/reports/wifiSignal/table', {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
