import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { UnitSettingsService } from 'src/app/api';

export interface UnitSettingsDuplicateResult {
  outcome: 'goToEdit' | 'saveAsCopy';
  newId: string;
}

@Component({
  selector: 'app-duplicate-unit-settings-modal',
  templateUrl: './duplicate-unit-settings-modal.component.html',
  styleUrls: ['./duplicate-unit-settings-modal.component.scss']
})
export class DuplicateUnitSettingsModalComponent implements OnInit {
  @Input() settingsId: string;
  @Input() duplicateFunc: Function;
  @Output() unitSettingsDuplicated = new EventEmitter<
    UnitSettingsDuplicateResult
  >();
  goToEditLoading = false;
  saveAsCopyLoading = false;

  constructor(
    private modalRef: BsModalRef,
    private unitSettingsService: UnitSettingsService
  ) {}

  ngOnInit() {}

  cancel() {
    this.modalRef.hide();
  }

  onGoToEdit() {
    this.goToEditLoading = true;
    this.duplicateUnitSettings().subscribe(
      newId => {
        this.modalRef.hide();
        this.unitSettingsDuplicated.emit({ outcome: 'goToEdit', newId });
      },
      () => {
        this.goToEditLoading = false;
      }
    );
  }

  onSaveAsCopy() {
    this.saveAsCopyLoading = true;
    this.duplicateUnitSettings().subscribe(
      newId => {
        this.modalRef.hide();
        this.unitSettingsDuplicated.emit({ outcome: 'saveAsCopy', newId });
      },
      () => {
        this.saveAsCopyLoading = false;
      }
    );
  }

  private duplicateUnitSettings(): Observable<string> {
    return this.duplicateFunc();
  }
}
