import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { BsModalService } from "ngx-bootstrap/modal"
import { Observable } from "rxjs"
import { Alert, AlertsService } from "../api"
import {
  ViewAlertActionType,
  ViewAlertModalComponent
} from "../shared/alerts-table/view-alert-modal/view-alert-modal.component"

@Injectable({
  providedIn: "root"
})
export class GlobalAlertService {
  constructor(
    private router: Router,
    private modalService: BsModalService,
    private alertsService: AlertsService
  ) {}

  goToUnit(unitId: string) {
    this.router.navigate(["/units", unitId])
  }

  public markAlertsAsRead(alertIds: string[]): Observable<void> {
    return this.alertsService.markAlertsAsRead(alertIds)
  }
  public markAsRead(alertId: string): Observable<void> {
    return this.alertsService.setAlertReadStatus(alertId)
  }

  shareAccess(unitId: string) {}

  viewAlert(alert: Alert, hideGoToUnitBtn: boolean) {
    const initialState: Partial<ViewAlertModalComponent> = {
      alert,
      hideGoToUnitBtn: hideGoToUnitBtn
    }
    const modalRef = this.modalService.show(ViewAlertModalComponent, {
      initialState
    })
    const component = modalRef.content as ViewAlertModalComponent
    component.action.subscribe((res: ViewAlertActionType) => {
      if (res === "goToUnit") {
        this.goToUnit(alert.unitId)
      } else if (res === "shareAccess") {
        this.shareAccess(alert.unitId)
      }
    })
  }
}
