import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RecipeBase, RecipeLibrary } from 'src/app/api';
import { CreateLibraryComponent } from '../create-library/create-library.component';

@Component({
  selector: 'app-change-visibility',
  templateUrl: './change-visibility.component.html',
  styleUrls: ['./change-visibility.component.scss']
})
export class ChangeVisibilityComponent implements OnInit {
  @Input() recipes: RecipeBase[];
  @Input() initialLibrary: RecipeLibrary;
  @Input() selectedLibraryFg: FormControl;

  get currentLibrary(): RecipeLibrary {
    return (
      (this.selectedLibraryFg.value as RecipeLibrary) || this.initialLibrary
    );
  }

  get selectedIconColor(): 'white' | 'red' {
    return this.selectedLibraryFg.value ? 'white' : 'red';
  }

  constructor(private modalService: BsModalService) {}

  ngOnInit() {}

  onLibrarySelected(library: RecipeLibrary) {
    this.selectedLibraryFg.setValue(library);
  }

  openCreateModal() {
    const modalRef = this.modalService.show(CreateLibraryComponent);
    const component = modalRef.content as CreateLibraryComponent;
    component.librarySelected.subscribe((l: RecipeLibrary) => {
      this.selectedLibraryFg.setValue(l);
    });
  }
}
