import { CommandType, NotificationType } from "../consts/remote-support/enums"

export namespace RemoteSupportUtil {
  export const PATH_SESSION_BASE = "/session"
  export const PATH_MEDIA = "/media"
  export const PATH_MEDIA_LIST = "/media-list"
  export const PATH_MEDIA_UPLOAD_URL = "/media-upload-url"
  export const PATH_MEDIA_DOWNLOAD_URL = "/media-download-url"
  export const PATH_HISTORY = "/history"
  export const PATH_HISTORY_LIST = "/history-list"
  export const SUFFIX_SIZE = "-size"
  export const SUFFIX_SIZE_MODIFIED = "-all"

  export const UPLOAD_LIMIT = 4500000
  export const DOWNLOAD_LIMIT = 4500000

  export function formatDate(date: Date): string {
    return date.toLocaleString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit"
    })
  }

  export function msToString(ms: number): string {
    let seconds = Math.floor(ms / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    hours = hours - days * 24
    minutes = minutes - days * 24 * 60 - hours * 60
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

    const hoursDisplay = hours.toString().padStart(2, "0")
    const minutesDisplay = minutes.toString().padStart(2, "0")
    const secondsDisplay = seconds.toString().padStart(2, "0")
    return `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`
  }
}
