import { AgmCoreModule } from "@agm/core"
import { DragDropModule } from "@angular/cdk/drag-drop"
import { ScrollingModule } from "@angular/cdk/scrolling"
import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import {
  MatDialogModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatToolbarModule
} from "@angular/material"
import { MatSlideToggleModule } from "@angular/material/slide-toggle"
import { RouterModule } from "@angular/router"
import { ConvergeMonitorComponent } from "@app/unit/service-monitor/converge-monitor/converge-monitor.component"
import { CookAndHoldMonitorComponent } from "@app/unit/service-monitor/cook-and-hold-monitor/cook-and-hold-monitor.component"
import { KeyValueComponent } from "@app/unit/service-monitor/key-value/key-value.component"
import { ProdigiMonitorComponent } from "@app/unit/service-monitor/prodigi-monitor/prodigi-monitor.component"
import { ServiceMonitorComponent } from "@app/unit/service-monitor/service-monitor.component"
import { VectorMonitorComponent } from "@app/unit/service-monitor/vector-monitor/vector-monitor.component"
import { UnitAlertsComponent } from "@app/unit/unit-alerts/unit-alerts.component"
import { UnitCookbookComponent } from "@app/unit/unit-cookbook/unit-cookbook.component"
import { UnitDashboardComponent } from "@app/unit/unit-dashboard/unit-dashboard.component"
import { UnitDataTableComponent } from "@app/unit/unit-data/unit-data-table/unit-data-table.component"
import { UnitDataComponent } from "@app/unit/unit-data/unit-data.component"
import { UnitOverviewComponent } from "@app/unit/unit-overview/unit-overview.component"
import { NgSelectModule } from "@ng-select/ng-select"
import { TranslateModule } from "@ngx-translate/core"
import { NgxDatatableModule } from "@swimlane/ngx-datatable"
import { IMaskModule } from "angular-imask"
import { TreeModule } from "angular-tree-component"
import { AccordionModule } from "ngx-bootstrap/accordion"
import { BsDatepickerModule } from "ngx-bootstrap/datepicker"
import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { ModalModule } from "ngx-bootstrap/modal"
import { PaginationModule } from "ngx-bootstrap/pagination"
import { TabsModule } from "ngx-bootstrap/tabs"
import { TypeaheadModule } from "ngx-bootstrap/typeahead"
import { NgxEchartsCoreModule } from "ngx-echarts/core"
import { LocationShareDetailModalComponent } from "../location/location-share-list/location-share-detail-modal/location-share-detail-modal.component"
import { LocationShareModalComponent } from "../location/location-share-modal/location-share-modal.component"
import { ActionTypePipe } from "./action-type.pipe"
import { AddressShortPipe } from "./address-short.pipe"
import { AddressComponent } from "./address/address.component"
import { AdminLocationsTableComponent } from "./admin-locations-table/admin-locations-table.component"
import { AdminsListPipe } from "./admins-list.pipe"
import { AlertsTableComponent } from "./alerts-table/alerts-table.component"
import { ViewAlertModalComponent } from "./alerts-table/view-alert-modal/view-alert-modal.component"
import { AssignGroupsComponent } from "./assign-groups/assign-groups.component"
import { AssignLocationsComponent } from "./assign-locations/assign-locations.component"
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component"
import { CancellableSearchInputComponent } from "./cancellable-search-input/cancellable-search-input.component"
import { CardBodyComponent } from "./card/card-body/card-body.component"
import { CardFooterComponent } from "./card/card-footer/card-footer.component"
import { CardComponent } from "./card/card.component"
import { CategoryAssignComponent } from "./category-assign/category-assign.component"
import { CreateCategoryComponent } from "./category-assign/create-category/create-category.component"
import { ChangeVisibilityModalComponent } from "./change-visibility-modal/change-visibility-modal.component"
import { ChangeVisibilityComponent } from "./change-visibility/change-visibility.component"
import { ChartTableComponent } from "./chart-table/chart-table.component"
import { CircleProgressComponent } from "./circle-progress/circle-progress.component"
import { ControlErrorsComponent } from "./control-errors/control-errors.component"
import { CoordinateWidgetComponent } from "./coordinate-widget/coordinate-widget.component"
import { CounterListComponent } from "./counter-list/counter-list.component"
import { CounterComponent } from "./counter-list/counter/counter.component"
import { CreateLibraryComponent } from "./create-library/create-library.component"
import { CustomDataSpinnerComponent } from "./custom-data-spinner/custom-data-spinner.component"
import { CustomDatePickerComponent } from "./custom-date-picker/custom-date-picker.component"
import { DataSourcePaginationComponent } from "./data-source-pagination/data-source-pagination.component"
import { DataSourceSearchFieldComponent } from "./data-source-search-field/data-source-search-field.component"
import { DataSourceSearchManyFieldsComponent } from "./data-source-search-many-fields/data-source-search-many-fields.component"
import { DataSourceSelectAllComponent } from "./data-source-select-all/data-source-select-all.component"
import { DataTablePagingComponent } from "./data-table-paging/data-table-paging.component"
import { GenericDataTableComponent } from "./data-table/data-table.component"
import { DatatableComponent } from "./datatable/datatable.component"
import { DbUnitPipe } from "./db-unit.pipe"
import { DecamelizePipe } from "./decamelize.pipe"
import { DialogModalComponent } from "./dialog-modal/dialog-modal.component"
import { GenericDialogComponent } from "./dialogs/generic-dialog/generic-dialog.component"
import { MediaPreviewComponent } from "./dialogs/media-preview/media-preview.component"
import { DuplicateRecipeModalComponent } from "./duplicate-recipe-modal/duplicate-recipe-modal.component"
import { DuplicateUnitSettingsModalComponent } from "./duplicate-unit-settings-modal/duplicate-unit-settings-modal.component"
import { EditCompanyFormComponent } from "./edit-company-form/edit-company-form.component"
import { EditRecipeComponent } from "./edit-recipe/edit-recipe.component"
import { EditVisibilityModalComponent } from "./edit-recipe/edit-visibility-modal/edit-visibility-modal.component"
import { ImageUploadButtonComponent } from "./edit-recipe/image-upload-button/image-upload-button.component"
import { ImagesUploadModalComponent } from "./edit-recipe/images-upload-modal/images-upload-modal.component"
import { LibraryToIdAndLabelPipe } from "./edit-recipe/library-to-id-and-label.pipe"
import { ActionStepComponent } from "./edit-recipe/recipe-steps-editor/action-step/action-step.component"
import { AddStepModalComponent } from "./edit-recipe/recipe-steps-editor/add-step-modal/add-step-modal.component"
import { CombiStepComponent } from "./edit-recipe/recipe-steps-editor/combi-step/combi-step.component"
import { ConvergeStepComponent } from "./edit-recipe/recipe-steps-editor/converge-step/converge-step.component"
import { EditActionModalComponent } from "./edit-recipe/recipe-steps-editor/edit-action-modal/edit-action-modal.component"
import { EditCombiModalComponent } from "./edit-recipe/recipe-steps-editor/edit-combi-modal/edit-combi-modal.component"
import { EditConvergeStageModalComponent } from "./edit-recipe/recipe-steps-editor/edit-converge-stage-modal/edit-converge-stage-modal.component"
import { EditCookStageModalComponent } from "./edit-recipe/recipe-steps-editor/edit-cook-stage-modal/edit-cook-stage-modal.component"
import { EditHoldStageModalComponent } from "./edit-recipe/recipe-steps-editor/edit-hold-stage-modal/edit-hold-stage-modal.component"
import { EditPreheatStepComponent } from "./edit-recipe/recipe-steps-editor/edit-preheat-step/edit-preheat-step.component"
import { EditProdigiProStageModalComponent } from "./edit-recipe/recipe-steps-editor/edit-prodigi-pro-stage-modal/edit-prodigi-pro-stage-modal.component"
import { EditSmokeStageModalComponent } from "./edit-recipe/recipe-steps-editor/edit-smoke-stage-modal/edit-smoke-stage-modal.component"
import { EditStageModalComponent } from "./edit-recipe/recipe-steps-editor/edit-stage-modal/edit-stage-modal.component"
import { EditSteamModalComponent } from "./edit-recipe/recipe-steps-editor/edit-steam-modal/edit-steam-modal.component"
import { HoldStepComponent } from "./edit-recipe/recipe-steps-editor/hold-step/hold-step.component"
import { MultiShelfTimerModalComponent } from "./edit-recipe/recipe-steps-editor/multi-shelf-timer-modal/multi-shelf-timer-modal.component"
import { PreheatStepComponent } from "./edit-recipe/recipe-steps-editor/preheat-step/preheat-step.component"
import { ProdigiProStepComponent } from "./edit-recipe/recipe-steps-editor/prodigi-pro-step/prodigi-pro-step.component"
import { RecipeStepsEditorComponent } from "./edit-recipe/recipe-steps-editor/recipe-steps-editor.component"
import { SmokeStepComponent } from "./edit-recipe/recipe-steps-editor/smoke-step/smoke-step.component"
import { StageModifierModalComponent } from "./edit-recipe/recipe-steps-editor/stage-modifier-modal/stage-modifier-modal.component"
import { StageStepComponent } from "./edit-recipe/recipe-steps-editor/stage-step/stage-step.component"
import { SteamStepComponent } from "./edit-recipe/recipe-steps-editor/steam-step/steam-step.component"
import { StepMenuComponent } from "./edit-recipe/recipe-steps-editor/step-menu/step-menu.component"
import { ExportDataModalComponent } from "./export-data-modal/export-data-modal.component"
import { FilterPipe } from "./filter.pipe"
import { FiltersResetComponent } from "./filters-reset/filters-reset.component"
import { FooterComponent } from "./footer/footer.component"
import { GenericChartComponent } from "./generic-chart/generic-chart.component"
import { GenericModalComponent } from "./generic-modal/generic-modal.component"
import { GroupAssignComponent } from "./group-assign/group-assign.component"
import { GroupsFilterComponent } from "./groups-filter/groups-filter.component"
import { GroupsSelectDropdownComponent } from "./groups-select-dropdown/groups-select-dropdown.component"
import { ImageUploaderControlComponent } from "./image-uploader-control/image-uploader-control.component"
import { ImageUploaderComponent } from "./image-uploader/image-uploader.component"
import { KeysPipe } from "./keys.pipe"
import { LibrariesListComponent } from "./libraries-list/libraries-list.component"
import { LibraryIconPipe } from "./library-icon.pipe"
import { ListPaginationComponent } from "./list-pagination/list-pagination.component"
import { LocationFormComponent } from "./location-form/location-form.component"
import { LocationSummaryComponent } from "./location-summary/location-summary.component"
import { LocationsFilterComponent } from "./locations-filter/locations-filter.component"
import { LocationsTreeComponent } from "./locations-tree/locations-tree.component"
import { MediaFileDisplayComponent } from "./media-file-display/media-file-display.component"
import { OverflowMenuComponent } from "./overflow-menu/overflow-menu.component"
import { PadStartPipe } from "./pad-start.pipe"
import { PercentageUnitPipe } from "./percentage-unit.pipe"
import { RecipeCardComponent } from "./recipe-card/recipe-card.component"
import { RecipeImagePipe } from "./recipe-image.pipe"
import { RecipeSelectionComponent } from "./recipe-selection/recipe-selection.component"
import { RemovableItemComponent } from "./removable-items/removable-item/removable-item.component"
import { RemovableItemsComponent } from "./removable-items/removable-items.component"
import { RpmUnitPipe } from "./rpm-unit.pipe"
import { ShowAllComponent } from "./show-all/show-all.component"
import { SortPipe } from "./sort.pipe"
import { SpinnerComponent } from "./spinner/spinner.component"
import { SubmitButtonComponent } from "./submit-button/submit-button.component"
import { SvgIconComponent } from "./svg-icon/svg-icon.component"
import { TabLayoutComponent } from "./tab-layout-component/tab-layout.component"
import { TemperatureTypePipe } from "./temperature-type.pipe"
import { TemperaturePipe } from "./temperature/temperature.pipe"
import { TimeRangePipe } from "./time-setpoint/time-range.pipe"
import { TimeSetpointComponent } from "./time-setpoint/time-setpoint.component"
import { TreeComponent } from "./tree/tree.component"
import { TruncatePipe } from "./truncate.pipe"
import { UnitCategoryFilterComponent } from "./unit-category-filter/unit-category-filter.component"
import { UnitCategoryPipe } from "./unit-category.pipe"
import { UnitRemoteControlModalComponent } from "./unit-remote-control-modal/unit-remote-control-modal.component"
import { UnitSettingsFormComponent } from "./unit-settings-form/unit-settings-form.component"
import { UnitSettingsModalComponent } from "./unit-settings-modal/unit-settings-modal.component"
import { UnitSettingsTableComponent } from "./unit-settings-table/unit-settings-table.component"
import { UnitSummaryComponent } from "./unit-summary/unit-summary.component"
import { UnitTypeFilterComponent } from "./unit-type-filter/unit-type-filter.component"
import { UnitTypeSelectComponent } from "./unit-type-select/unit-type-select.component"
import { UnitTypePipe } from "./unit-type.pipe"
import { UnitsGridComponent } from "./units-grid/units-grid.component"
import { UnitsListComponent } from "./units-list/units-list.component"
import { UnitsTableComponent } from "./units-table/units-table.component"
import { UserRolePipe } from "./user-role.pipe"
import { UsersListPipe } from "./users-list.pipe"
import { ViewRecipeComponent } from "./view-recipe/view-recipe.component"
import { WeekDaySelectorComponent } from "./week-day-selector/week-day-selector.component"

@NgModule({
  imports: [
    CommonModule,
    AccordionModule,
    AgmCoreModule,
    BsDatepickerModule,
    BsDropdownModule,
    FormsModule,
    IMaskModule,
    ModalModule,
    NgxDatatableModule,
    DragDropModule,
    NgxEchartsCoreModule,
    MatSlideToggleModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    TabsModule,
    TranslateModule,
    TreeModule,
    TypeaheadModule,
    NgSelectModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    ActionStepComponent,
    ActionTypePipe,
    AddStepModalComponent,
    AddressComponent,
    AddressShortPipe,
    AdminLocationsTableComponent,
    AdminsListPipe,
    AlertsTableComponent,
    AssignGroupsComponent,
    AssignLocationsComponent,
    BreadcrumbComponent,
    CancellableSearchInputComponent,
    CardBodyComponent,
    CardComponent,
    CardFooterComponent,
    CategoryAssignComponent,
    ChangeVisibilityComponent,
    ChangeVisibilityModalComponent,
    ChartTableComponent,
    CircleProgressComponent,
    CombiStepComponent,
    ControlErrorsComponent,
    ConvergeStepComponent,
    CoordinateWidgetComponent,
    CounterComponent,
    CounterListComponent,
    CreateCategoryComponent,
    CreateLibraryComponent,
    CustomDataSpinnerComponent,
    CustomDatePickerComponent,
    DataSourcePaginationComponent,
    DataSourceSearchFieldComponent,
    DataSourceSearchManyFieldsComponent,
    DataSourceSelectAllComponent,
    DataTablePagingComponent,
    DatatableComponent,
    DbUnitPipe,
    DecamelizePipe,
    DialogModalComponent,
    DuplicateRecipeModalComponent,
    DuplicateUnitSettingsModalComponent,
    EditActionModalComponent,
    EditCombiModalComponent,
    EditCompanyFormComponent,
    EditConvergeStageModalComponent,
    EditCookStageModalComponent,
    EditHoldStageModalComponent,
    EditPreheatStepComponent,
    EditProdigiProStageModalComponent,
    EditRecipeComponent,
    EditSmokeStageModalComponent,
    EditStageModalComponent,
    EditSteamModalComponent,
    EditVisibilityModalComponent,
    ExportDataModalComponent,
    FilterPipe,
    FiltersResetComponent,
    FooterComponent,
    GenericChartComponent,
    GenericDataTableComponent,
    GenericModalComponent,
    GroupAssignComponent,
    GroupsFilterComponent,
    GroupsSelectDropdownComponent,
    HoldStepComponent,
    ImageUploadButtonComponent,
    ImageUploaderComponent,
    ImageUploaderControlComponent,
    ImagesUploadModalComponent,
    KeysPipe,
    LibrariesListComponent,
    LibraryIconPipe,
    LibraryToIdAndLabelPipe,
    ListPaginationComponent,
    ListPaginationComponent,
    LocationFormComponent,
    LocationShareDetailModalComponent,
    LocationShareModalComponent,
    LocationSummaryComponent,
    LocationsFilterComponent,
    LocationsTreeComponent,
    OverflowMenuComponent,
    PadStartPipe,
    PercentageUnitPipe,
    PreheatStepComponent,
    ProdigiProStepComponent,
    RecipeCardComponent,
    RecipeImagePipe,
    RecipeSelectionComponent,
    RecipeStepsEditorComponent,
    RemovableItemComponent,
    RemovableItemsComponent,
    RpmUnitPipe,
    ShowAllComponent,
    SmokeStepComponent,
    SortPipe,
    SpinnerComponent,
    StageStepComponent,
    SteamStepComponent,
    StepMenuComponent,
    SubmitButtonComponent,
    SubmitButtonComponent,
    SvgIconComponent,
    TabLayoutComponent,
    TemperaturePipe,
    TemperatureTypePipe,
    TimeRangePipe,
    TimeSetpointComponent,
    TreeComponent,
    TruncatePipe,
    UnitCategoryFilterComponent,
    UnitCategoryFilterComponent,
    UnitCategoryPipe,
    UnitRemoteControlModalComponent,
    UnitSettingsFormComponent,
    UnitSettingsModalComponent,
    UnitSettingsTableComponent,
    UnitSummaryComponent,
    UnitTypeFilterComponent,
    UnitTypePipe,
    UnitTypeSelectComponent,
    UnitsGridComponent,
    UnitsListComponent,
    UnitsTableComponent,
    UserRolePipe,
    UsersListPipe,
    ViewAlertModalComponent,
    ViewRecipeComponent,
    EditProdigiProStageModalComponent,
    StageModifierModalComponent,
    MultiShelfTimerModalComponent,
    WeekDaySelectorComponent,
    GenericDialogComponent,
    CookAndHoldMonitorComponent,
    ConvergeMonitorComponent,
    ProdigiMonitorComponent,
    UnitDashboardComponent,
    UnitOverviewComponent,
    UnitAlertsComponent,
    UnitCookbookComponent,
    UnitDataComponent,
    UnitDataTableComponent,
    ServiceMonitorComponent,
    VectorMonitorComponent,
    KeyValueComponent,
    MediaFileDisplayComponent,
    MediaPreviewComponent
  ],
  exports: [
    AccordionModule,
    ActionTypePipe,
    AddressComponent,
    AddressShortPipe,
    AdminLocationsTableComponent,
    AdminsListPipe,
    AlertsTableComponent,
    AssignGroupsComponent,
    AssignLocationsComponent,
    BreadcrumbComponent,
    BsDatepickerModule,
    BsDropdownModule,
    CancellableSearchInputComponent,
    CardBodyComponent,
    CardComponent,
    CardFooterComponent,
    CategoryAssignComponent,
    ChangeVisibilityComponent,
    ChartTableComponent,
    ChartTableComponent,
    CircleProgressComponent,
    CommonModule,
    ControlErrorsComponent,
    CoordinateWidgetComponent,
    CounterListComponent,
    CustomDataSpinnerComponent,
    CustomDatePickerComponent,
    DataSourcePaginationComponent,
    DataSourceSearchFieldComponent,
    DataSourceSearchManyFieldsComponent,
    DataSourceSelectAllComponent,
    DataTablePagingComponent,
    DatatableComponent,
    DecamelizePipe,
    EditCompanyFormComponent,
    FilterPipe,
    FiltersResetComponent,
    FooterComponent,
    GenericChartComponent,
    GenericDataTableComponent,
    GenericModalComponent,
    GroupAssignComponent,
    GroupsFilterComponent,
    GroupsSelectDropdownComponent,
    IMaskModule,
    ImageUploaderComponent,
    ImageUploaderControlComponent,
    LibrariesListComponent,
    LibraryIconPipe,
    ListPaginationComponent,
    LocationFormComponent,
    LocationSummaryComponent,
    LocationsFilterComponent,
    LocationsTreeComponent,
    ModalModule,
    NgxDatatableModule,
    DragDropModule,
    NgxEchartsCoreModule,
    OverflowMenuComponent,
    PadStartPipe,
    PaginationModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    RecipeCardComponent,
    RecipeImagePipe,
    RecipeSelectionComponent,
    RemovableItemComponent,
    RemovableItemsComponent,
    ScrollingModule,
    ShowAllComponent,
    SortPipe,
    SpinnerComponent,
    SubmitButtonComponent,
    SvgIconComponent,
    TabLayoutComponent,
    TabsModule,
    TimeRangePipe,
    TimeSetpointComponent,
    TranslateModule,
    TreeComponent,
    TruncatePipe,
    TypeaheadModule,
    UnitCategoryFilterComponent,
    UnitCategoryPipe,
    UnitSettingsFormComponent,
    UnitSettingsTableComponent,
    UnitSummaryComponent,
    UnitTypeFilterComponent,
    UnitTypePipe,
    UnitTypeSelectComponent,
    UnitsListComponent,
    UnitsTableComponent,
    UserRolePipe,
    UsersListPipe,
    CookAndHoldMonitorComponent,
    ConvergeMonitorComponent,
    ProdigiMonitorComponent,
    UnitDashboardComponent,
    UnitOverviewComponent,
    UnitAlertsComponent,
    UnitCookbookComponent,
    UnitDataComponent,
    UnitDataTableComponent,
    ServiceMonitorComponent,
    VectorMonitorComponent,
    KeyValueComponent,
    MediaFileDisplayComponent
  ],
  entryComponents: [
    DialogModalComponent,
    AddStepModalComponent,
    AssignGroupsComponent,
    AssignLocationsComponent,
    CategoryAssignComponent,
    ChangeVisibilityModalComponent,
    CreateCategoryComponent,
    CreateLibraryComponent,
    DuplicateRecipeModalComponent,
    DuplicateUnitSettingsModalComponent,
    EditActionModalComponent,
    EditCombiModalComponent,
    EditConvergeStageModalComponent,
    EditCookStageModalComponent,
    EditHoldStageModalComponent,
    EditPreheatStepComponent,
    EditProdigiProStageModalComponent,
    EditSmokeStageModalComponent,
    EditStageModalComponent,
    EditSteamModalComponent,
    EditVisibilityModalComponent,
    ExportDataModalComponent,
    ImagesUploadModalComponent,
    LocationShareDetailModalComponent,
    LocationShareModalComponent,
    MultiShelfTimerModalComponent,
    StageModifierModalComponent,
    UnitRemoteControlModalComponent,
    UnitSettingsFormComponent,
    UnitSettingsModalComponent,
    UnitSettingsTableComponent,
    ViewAlertModalComponent,
    GenericDialogComponent,
    MediaPreviewComponent,
    CookAndHoldMonitorComponent,
    ConvergeMonitorComponent,
    ProdigiMonitorComponent,
    UnitDashboardComponent,
    UnitOverviewComponent,
    UnitAlertsComponent,
    UnitCookbookComponent,
    UnitDataComponent,
    UnitDataTableComponent,
    ServiceMonitorComponent,
    VectorMonitorComponent,
    KeyValueComponent
  ]
})
export class SharedModule {}
