/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { ChangePasswordBody } from "../model/changePasswordBody";
import { CompleteProfileBody } from "../model/completeProfileBody";
import { InlineObject } from "../model/inlineObject";
import { RegisterUserBody } from "../model/registerUserBody";
import { UserProfile } from "../model/userProfile";
import { UserSettings } from "../model/userSettings";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";
import { UserWithAttributes } from "../model/user/user";

@Injectable({
  providedIn: "root"
})
export class ProfileService {
  protected basePath = "https://asdev.kalpa.it/api";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Completes the profile of a self-registered admin user
   * This API is accessible to users without a role and company_id. It will create and empty company, assign it&#39;s company id to the user, and add the passed attributes to the user.
   * @param CompleteProfileBody
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public completeAdminProfile(
    CompleteProfileBody?: CompleteProfileBody,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public completeAdminProfile(
    CompleteProfileBody?: CompleteProfileBody,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public completeAdminProfile(
    CompleteProfileBody?: CompleteProfileBody,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public completeAdminProfile(
    CompleteProfileBody?: CompleteProfileBody,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/profile/completeAdminProfile`,
      CompleteProfileBody,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit settings for the current user
   *
   * @param UserSettings
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editSettings(
    UserSettings?: UserSettings,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editSettings(
    UserSettings?: UserSettings,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editSettings(
    UserSettings?: UserSettings,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editSettings(
    UserSettings?: UserSettings,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/profile/settings`,
      UserSettings,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Edit user profile
   *
   * @param UserProfile
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editUserProfile(
    UserProfile?: UserProfile,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public editUserProfile(
    UserProfile?: UserProfile,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public editUserProfile(
    UserProfile?: UserProfile,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public editUserProfile(
    UserProfile?: UserProfile,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/profile/myProfile`,
      UserProfile,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get settings for the current user
   */
  public getUserWithAttributes(): Observable<UserWithAttributes> {
    return this.httpClient.get<UserWithAttributes>("/profile/settings");
  }

  /**
   * Register an account admin for a company
   *
   * @param RegisterUserBody
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public registerAccountAdmin(
    RegisterUserBody?: RegisterUserBody,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public registerAccountAdmin(
    RegisterUserBody?: RegisterUserBody,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public registerAccountAdmin(
    RegisterUserBody?: RegisterUserBody,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public registerAccountAdmin(
    RegisterUserBody?: RegisterUserBody,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/profile/registerAdmin`,
      RegisterUserBody,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Send new verification link to the current user
   *
   * @param InlineObject
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendVerificationLink(
    InlineObject?: InlineObject,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public sendVerificationLink(
    InlineObject?: InlineObject,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public sendVerificationLink(
    InlineObject?: InlineObject,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public sendVerificationLink(
    InlineObject?: InlineObject,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (oauth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/profile/sendVerificationLink`,
      InlineObject,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
