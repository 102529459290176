import { Pipe, PipeTransform } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { _ } from "src/app/core/translation-marker"
import { AuthenticationService } from "../core/authentication.service"
import { fahrenheitToCelsius } from "./temperature/temp-utils"

@Pipe({
  name: "temperatureType"
})
export class TemperatureTypePipe implements PipeTransform {
  tempUnitPreferenceF = true
  constructor(private ts: TranslateService, private as: AuthenticationService) {
    this.tempUnitPreferenceF = this.as.getUserTemp() === "°C" ? false : true
  }

  transform(value: any): any {
    let valueToReturn = value
    const unitOfMeasure = this.getMeasure()
    if (value && !isNaN(value) && !this.tempUnitPreferenceF) {
      valueToReturn = fahrenheitToCelsius(value)
    }
    return valueToReturn + " " + this.ts.instant(unitOfMeasure)
  }

  getValue(value: any): number {
    if (value && !isNaN(value) && !this.tempUnitPreferenceF) {
      return fahrenheitToCelsius(value)
    }
    return parseInt(value, 10)
  }

  getMeasure(): any {
    let unitOfMeasure = _("TEMPERATURES.FAHRENHEIT")
    if (!this.tempUnitPreferenceF) {
      unitOfMeasure = _("TEMPERATURES.CELSIUS")
    }

    return this.ts.instant(unitOfMeasure)
  }

  getValueForUnitSettings(value: number, units: boolean): number {
    if (value !== null && !isNaN(value) && !units) {
      value = fahrenheitToCelsius(value)
    }
    return value
  }
}
