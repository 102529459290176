import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { CookbookRecipe, RecipeBase } from "src/app/api"
import { PermissionService } from "src/app/core/permission.service"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"

@Component({
  selector: "app-recipe-card",
  templateUrl: "./recipe-card.component.html",
  styleUrls: ["./recipe-card.component.scss"]
})
export class RecipeCardComponent implements OnInit {
  @Input() recipe: RecipeBase | CookbookRecipe
  @Input() selectable = true
  @Input() menuTpl?: TemplateRef<any>
  @Input() selectedHandler?: SelectedItemsHandler<RecipeBase>
  @Input() showFooter = true
  @Input() isCompanyLibrary ? = false
  @Output() linkClicked = new EventEmitter<RecipeBase>()

  status: string
  statusLabel: string
  statusIconSrc: string

  constructor(private ts: TranslateService, public auth: PermissionService) {}

  ngOnInit() {
    if ("synced" in this.recipe) {
      switch (this.recipe.synced) {
        case CookbookRecipe.SyncedEnum.AssignPending:
          this.status = "added"
          this.statusLabel = this.ts.instant(
            "UNITS.RECIPE_STATUS.STATUSES.ADDED"
          )
          this.statusIconSrc = "/assets/static/images/icons/retry-white.svg"
          break
        case CookbookRecipe.SyncedEnum.SyncFailed:
          this.status = "failed"
          this.statusLabel = this.ts.instant(
            "UNITS.RECIPE_STATUS.STATUSES.FAILED"
          )
          this.statusIconSrc = "/assets/static/images/icons/cancel-red.svg"
          break
        case CookbookRecipe.SyncedEnum.EditedPending:
          this.status = "edited"
          this.statusLabel = this.ts.instant(
            "UNITS.RECIPE_STATUS.STATUSES.EDITED"
          )
          this.statusIconSrc = "/assets/static/images/icons/retry-white.svg"
          break
        default:
          break
      }
    }
  }
}
