import { Injectable } from "@angular/core"
import { BsModalService } from "ngx-bootstrap/modal"
import {
  DialogModalComponent,
  DialogModalOptions
} from "../shared/dialog-modal/dialog-modal.component"
import { modalType } from "../shared/generic-modal/generic-modal.component"

@Injectable({
  providedIn: "root"
})
export class DialogModalService {
  constructor(private modalService: BsModalService) {}

  success(options: DialogModalOptions) {
    return this.openModal(options, "SUCCESS")
  }

  successConfirm(options: DialogModalOptions) {
    return this.openModal(options, "SUCCESS_CONFIRM")
  }

  danger(options: DialogModalOptions) {
    return this.openModal(options, "DANGER")
  }

  dangerConfirm(options: DialogModalOptions) {
    return this.openModal(options, "DANGER_CONFIRM")
  }

  openModal(options: DialogModalOptions, type: modalType) {
    const initialState: DialogModalOptions = {
      ...options,
      type: type
    }

    const modalRef = this.modalService.show(DialogModalComponent, {
      initialState,
      backdrop: false
    })
    return modalRef.content as DialogModalComponent
  }
}
