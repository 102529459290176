import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormGroup } from "@angular/forms"
import {
  convertTempOrNoProbe,
  evaluateModifiers,
  ModifierDefaultValues,
  PowerLevelEnum,
  RecipeModifiersType,
  StageType
} from "src/app/api"
import { TemperatureTypePipe } from "src/app/shared/temperature-type.pipe"
import { modeToStageType } from "../form-groups"

@Component({
  selector: "app-prodigi-pro-step",
  templateUrl: "./prodigi-pro-step.component.html",
  styleUrls: ["./prodigi-pro-step.component.scss"]
})
export class ProdigiProStepComponent implements OnInit {
  @Input() formGroup: FormGroup
  @Input() unitType: string
  @Input() index: number
  @Input() editingEnabled = true
  @Output() edit = new EventEmitter<void>()
  @Output() delete = new EventEmitter<void>()

  powerLevelEnum = PowerLevelEnum
  recipeModifiersType = RecipeModifiersType
  modifiers: RecipeModifiersType[]
  modifierDefaultValues = ModifierDefaultValues
  allModifiers = Object.values(RecipeModifiersType)

  private stageType: StageType
  private modeMap = {} as {
    [key: string]: any;
  }

  constructor(private temperatureTypePipe: TemperatureTypePipe) {
    this.modeMap[`${StageType.cook}`] = {
      title: "RECIPE_LIBRARY.EDITOR.CONVECTION",
      image: "cooking-primary-dark.svg"
    }
    this.modeMap[`${StageType.stage}`] = {
      title: "RECIPE_LIBRARY.EDITOR.CONVECTION",
      image: "cooking-primary-dark.svg"
    }
    this.modeMap[`${StageType.combi}`] = {
      title: "RECIPE_LIBRARY.EDITOR.COMBI",
      image: "combi.png"
    }
    this.modeMap[`${StageType.steam}`] = {
      title: "RECIPE_LIBRARY.EDITOR.STEAM",
      image: "steam.png"
    }
  }

  ngOnInit(): void {
    this.stageType = modeToStageType(this.formGroup.value.mode)
    this.modifiers = evaluateModifiers(
      this.formGroup.value,
      this.stageType,
      this.temperatureTypePipe
    )
  }

  getStepTitle(): string {
    return this.modeMap[this.stageType.toString()].title
  }

  getStepImageName(): string {
    return this.modeMap[this.stageType.toString()].image
  }

  isReadOnlyTemperature(temp: number) {
    return (
      this.modifiers.includes(RecipeModifiersType.coldSmoke) ||
      this.modifiers.includes(RecipeModifiersType.ovenRest)
    )
  }
}
