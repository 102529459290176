export namespace StringUtil {
  // Different options for the encoding to send to the device
  export const enum ENCODING_OPTIONS {
    HEX_ENCODING = "hex",
    UTF8_ENCODING = "utf-8"
  }

  export function decodeUint8Array(data: Uint8Array): string {
    return new TextDecoder(ENCODING_OPTIONS.UTF8_ENCODING).decode(data)
  }

  export function decodeUint8ArrayToJson(data: Uint8Array): any {
    return JSON.parse(
      new TextDecoder(ENCODING_OPTIONS.UTF8_ENCODING).decode(data)
    )
  }
}
