import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core"
import { BsModalRef } from "ngx-bootstrap/modal"
import {
  UnitDetail,
  UnitSettingsModel,
  UnitSettingsTemplate,
  UnitsService
} from "src/app/api"
import { PermissionService } from "src/app/core/permission.service"
import { checkFormValidity } from "src/app/core/utils"
import { unitSettingsTransformations } from "src/app/unit-settings/create-unit-settings/create-unit-settings.component"
import { UnitSettingsFormComponent } from "../unit-settings-form/unit-settings-form.component"

export type UnitSettingsActionType = "saveUnitSettings"

@Component({
  selector: "app-unit-settings-modal",
  templateUrl: "./unit-settings-modal.component.html",
  styleUrls: ["./unit-settings-modal.component.scss"]
})
export class UnitSettingsModalComponent implements OnInit {
  @ViewChild(UnitSettingsFormComponent, { static: false })
  unitSettingsForm: UnitSettingsFormComponent
  unitSettingsValue: any
  @Input() currUnitSettings?: UnitSettingsModel
  @Input() unit: UnitDetail
  @Input() isPresent: boolean
  loading = false

  @Output() action = new EventEmitter<UnitSettingsActionType>()

  constructor(
    private modalRef: BsModalRef,
    private unitService: UnitsService,
    public auth: PermissionService
  ) {}

  ngOnInit() {}

  close() {
    this.modalRef.hide()
  }

  saveUnitSettings() {
    this.unitSettingsValue = this.unitSettingsForm.unitSettingsForm.getRawValue()
    const unitSettingsTemplate = {
      settings: this.unitSettingsValue
    } as UnitSettingsTemplate
    unitSettingsTransformations(unitSettingsTemplate)

    if (checkFormValidity(this.unitSettingsForm.unitSettingsForm)) {
      this.loading = true
      this.unitService
        .editUnitSettings(this.unit.unitId, unitSettingsTemplate)
        .subscribe(() => {
          this.loading = false
          this.close()
        })
    }
  }
}
