import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router"
import { Observable } from "rxjs"
import { CompaniesService, CompanyOnboarding } from "../api"
import { AuthenticationService } from "../core/authentication.service"

@Injectable()
export class OnboardingGuard implements CanActivate {
  sections = [
    "user",
    "email_confirm",
    "company_data",
    "add_location",
    "add_units",
    "create_groups",
    "add_users",
    "account_settings",
    "completed"
  ]

  constructor(
    protected router: Router,
    protected authService: AuthenticationService,
    protected companyService: CompaniesService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (this.authService.isAuthenticated()) {
        const onboardingStep = await this.checkOnboardingStep()
        if (onboardingStep === null) {
          // This means customer has successfully finished onboarding don't navigate to onboarding
          if (state.url.includes("signup")) {
            this.router.navigate(["/"])
          }
        } else if (onboardingStep >= 0) {
          // +2 offset since backend doesn't account for user and email confirm step
          this.navigate(state.url, onboardingStep + 2)
        } else {
          // If get onboarding step fails go to first step
          this.navigate(state.url, 0)
        }
      } else {
        // Go to first step since no company association
        this.navigate(state.url, 0)
      }
      resolve(true)
    })
  }

  navigate(currentUrl: string, onboardingStep: number) {
    const path = ["/signup"]
    path.push(this.sections[onboardingStep])

    // If you aren't already at this path then navigate
    if (currentUrl !== path.join("/")) {
      this.router.navigate(path)
    }
  }

  async checkOnboardingStep(): Promise<number | null> {
    const companyId = this.authService.getActiveCompany()
    if (companyId) {
      return this.companyService.getOnboardingStep(companyId).toPromise()
    } else {
      return Promise.resolve(-1)
    }
  }
}
