/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type UnitStatusOperatingMode =
  | 'IDLE'
  | 'COOKING'
  | 'PREHEATING'
  | 'COOLINGDOWN';

export const UnitStatusOperatingMode = {
  IDLE: 'IDLE' as UnitStatusOperatingMode,
  COOKING: 'COOKING' as UnitStatusOperatingMode,
  PREHEATING: 'PREHEATING' as UnitStatusOperatingMode,
  COOLINGDOWN: 'COOLINGDOWN' as UnitStatusOperatingMode
};
