import { Pipe, PipeTransform } from '@angular/core';
import padStart from 'lodash-es/padStart';

@Pipe({
  name: 'padStart'
})
export class PadStartPipe implements PipeTransform {
  transform(value: any, length = 2, fillChar = '0'): any {
    return padStart(String(value), length, fillChar);
  }
}
