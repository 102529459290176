import { Component, EventEmitter, Input, Output } from "@angular/core"
import { FormControl } from "@angular/forms"
import { guid } from "src/app/core/utils"

@Component({
  selector: "app-show-all",
  templateUrl: "./show-all.component.html",
  styleUrls: ["./show-all.component.scss"]
})
export class ShowAllComponent {
  @Output() showAllChanged: EventEmitter<boolean> = new EventEmitter()
  @Input() showAll: boolean
  showAllFormControl: FormControl
  showAllCheckboxId = guid()

  constructor() {
    this.showAllFormControl = new FormControl(false)
    this.showAllFormControl.valueChanges.subscribe((val: boolean) => {
      this.showAll = val
      // Notify parent component the Show All checkbox's vale has changed
      this.showAllChanged.emit(val)
    })
  }
}
