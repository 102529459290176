import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientFiltrableDataSource, notEqual } from 'filtrable-data-source';
import { RecipeLibrary } from 'src/app/api';
import { LibrariesService } from '../../core/libraries.service';

@Component({
  selector: 'app-libraries-list',
  templateUrl: './libraries-list.component.html',
  styleUrls: ['./libraries-list.component.scss']
})
export class LibrariesListComponent implements OnInit {
  /**
   * If this value is present, the library with the passed libraryId will be removed from the list
   */
  @Input() skipLibraryId?: string;
  @Output() selectedLibrary = new EventEmitter<RecipeLibrary>();

  dataSource = new ClientFiltrableDataSource<RecipeLibrary>();

  constructor(private librariesService: LibrariesService) {}

  ngOnInit() {
    this.refreshList();
    if (this.skipLibraryId) {
      this.dataSource.setFilter('libraryId', notEqual(this.skipLibraryId));
      this.dataSource.applyFilters();
    }
  }

  refreshList() {
    this.dataSource.setItems(this.librariesService.usedLibraries);
  }
}
