import { UserTempUnit } from "src/app/core/mykeycloak.service"
import { INumberRanges } from "../edit-recipe/recipe-steps-editor/form-groups"

export function celsiusToFahrenheit(value: number): number {
  return parseInt((value / (5 / 9) + 32).toFixed(), 10)
}

export function fahrenheitToCelsius(value: number): number {
  return parseInt((((value - 32) * 5) / 9).toFixed(), 10)
}

export function rangeToCelsius(range: INumberRanges): INumberRanges {
  return {
    min: fahrenheitToCelsius(range.min),
    max: fahrenheitToCelsius(range.max),
    step: Math.floor(fahrenheitToCelsius(range.step)),
    default: fahrenheitToCelsius(range.default)
  }
}

export function toUserTemp(value: number | null, tempUnit: UserTempUnit) {
  if (value || value === 0) {
    const converted = tempUnit === "°F" ? value : fahrenheitToCelsius(value)
    return converted.toFixed()
  }
  return ""
}
