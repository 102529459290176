import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-unit-data-table',
  templateUrl: './unit-data-table.component.html',
  styleUrls: ['./unit-data-table.component.scss']
})
export class UnitDataTableComponent implements OnInit {
  @Input() dataSource: any;
  @Input() column: TableColumn;

  selected: any[] = [];
  constructor() {}

  ngOnInit() {}
}
