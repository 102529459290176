import { TemperatureTypePipe } from "src/app/shared/temperature-type.pipe";
import { RecipeStep } from "./recipeBody";
import { StageType } from "./stageType";

export enum RecipeModifiersEnum {
  powerLevel = "RECIPE_LIBRARY.EDITOR.POWER_LEVEL",
  coldSmoke = "RECIPE_LIBRARY.EDITOR.COLD_SMOKE",
  hotSmoke = "RECIPE_LIBRARY.EDITOR.HOT_SMOKE",
  greaseCollection = "RECIPE_LIBRARY.EDITOR.GREASE_COLLECTION",
  multiShelfTimer = "RECIPE_LIBRARY.EDITOR.MULTI_SHELF_TIMER",
  dehydrate = "RECIPE_LIBRARY.EDITOR.DEHYDRATE",
  ovenRest = "RECIPE_LIBRARY.EDITOR.OVEN_REST"
  // deltaT = "RECIPE_LIBRARY.EDITOR.DELTA_T"
}

export enum RecipeModifiersType {
  powerLevel = "powerLevel",
  coldSmoke = "coldSmoke",
  hotSmoke = "hotSmoke",
  greaseCollection = "greaseCollection",
  multiShelfTimer = "multiShelfTimer",
  dehydrate = "dehydrate",
  ovenRest = "ovenRest"
  // deltaT = "deltaT"
}

export enum PowerLevelEnum {
  reduced = 0,
  eco = 1,
  turbo = 2
}

export const ModifiersDisableProbeCook: RecipeModifiersType[] = [
  RecipeModifiersType.coldSmoke,
  RecipeModifiersType.hotSmoke,
  RecipeModifiersType.multiShelfTimer,
  RecipeModifiersType.ovenRest
];

export interface IModifierValues {
  smoker: boolean;
  humidity: number;
  timeSetpoint: number;
  fanSpeed: number;
  cavitySetpoint: number;
  probeSetpoint: number;
  cookingMode: "time" | "probe";
}

export const ModifierDefaultValues: { [key: string]: IModifierValues } = {
  [RecipeModifiersType.coldSmoke]: {
    smoker: true,
    humidity: 100,
    timeSetpoint: 60,
    fanSpeed: 20,
    cavitySetpoint: 32,
    probeSetpoint: -1,
    cookingMode: "time"
  },
  [RecipeModifiersType.hotSmoke]: {
    smoker: true,
    humidity: 100,
    timeSetpoint: 60,
    fanSpeed: 20,
    cavitySetpoint: 375,
    probeSetpoint: -1,
    cookingMode: "time"
  },
  // Only use timeSetpoint, probeSetpoint, and cookingMode for multiShelfTimer
  [RecipeModifiersType.multiShelfTimer]: {
    smoker: false,
    humidity: 0,
    timeSetpoint: -2,
    fanSpeed: 60,
    cavitySetpoint: 85,
    probeSetpoint: -1,
    cookingMode: "time"
  },
  [RecipeModifiersType.dehydrate]: {
    smoker: false,
    humidity: 0,
    timeSetpoint: 60,
    fanSpeed: 60,
    cavitySetpoint: 140,
    probeSetpoint: -1,
    cookingMode: "time"
  },
  [RecipeModifiersType.ovenRest]: {
    smoker: false,
    humidity: 100,
    timeSetpoint: 900,
    fanSpeed: 20,
    cavitySetpoint: -1,
    probeSetpoint: -1,
    cookingMode: "time"
  }
};

// Fan speed must be >= 40% if temperature >= 450
const MIN_FAN_SPEED_THRESHOLD_TEMP = 450;
export const MIN_FAN_SPEED_THRESHOLD_PERCENT = 40;

// Power level can only be eco if fan speed >= 60%, turbo if fan speed >= 80%
const MIN_ECO_FAN_SPEED_PERCENT = 60;
const MIN_TURBO_FAN_SPEED_PERCENT = 80;

export function convertTempOrNoProbe(
  temperatureType: TemperatureTypePipe,
  value: number
) {
  return value >= 0 ? temperatureType.getValue(value) : -1;
}

export function evaluateModifiers(
  stage: RecipeStep,
  stageType: StageType,
  tempPipe: TemperatureTypePipe
): RecipeModifiersType[] {
  let modifiers: Array<RecipeModifiersType> = [];
  // Power Level
  if (stage.powerLevel !== undefined && PowerLevelEnum[stage.powerLevel]) {
    modifiers.push(RecipeModifiersType.powerLevel);
  }

  // Cold Smoke
  const coldSmokeConfig = ModifierDefaultValues[RecipeModifiersType.coldSmoke];
  if (
    stage.smoker === coldSmokeConfig.smoker &&
    stage.cavitySetpoint ===
      convertTempOrNoProbe(tempPipe, coldSmokeConfig.cavitySetpoint) &&
    [
      StageType.cook,
      StageType.stage,
      StageType.combi,
      StageType.steam
    ].includes(stageType)
  ) {
    modifiers.push(RecipeModifiersType.coldSmoke);
  }

  // Hot Smoke
  const hotSmokeConfig = ModifierDefaultValues[RecipeModifiersType.hotSmoke];
  if (
    stage.smoker === hotSmokeConfig.smoker &&
    stage.cavitySetpoint! >
      convertTempOrNoProbe(tempPipe, coldSmokeConfig.cavitySetpoint) &&
    [StageType.cook, StageType.stage, StageType.combi].includes(stageType)
  ) {
    modifiers.push(RecipeModifiersType.hotSmoke);
  }

  // Grease Collection
  if (stage.greaseCollection) {
    modifiers.push(RecipeModifiersType.greaseCollection);
  }

  //Multi-shelf Timer
  if (stage.multiShelfTimer && stage.multiShelfTimer.length > 0) {
    modifiers.push(RecipeModifiersType.multiShelfTimer);
  }

  // Dehydrate
  const dehydrateConfig = ModifierDefaultValues[RecipeModifiersType.dehydrate];
  if (
    stage.cavitySetpoint ===
      convertTempOrNoProbe(tempPipe, dehydrateConfig.cavitySetpoint) &&
    stage.humidity === dehydrateConfig.humidity &&
    stage.fanSpeed === dehydrateConfig.fanSpeed &&
    stage.timeSetpoint !== -1 &&
    stage.probeSetpoint ===
      convertTempOrNoProbe(tempPipe, dehydrateConfig.probeSetpoint) &&
    (stageType === StageType.cook || stageType === StageType.stage)
  ) {
    modifiers.push(RecipeModifiersType.dehydrate);
  }

  // Oven Rest
  if (
    stage.cavitySetpoint === -1 &&
    stage.timeSetpoint !== -1 &&
    stage.probeSetpoint === -1 &&
    (stageType === StageType.cook || stageType === StageType.stage)
  ) {
    modifiers.push(RecipeModifiersType.ovenRest);
  }

  // Delta T

  return modifiers;
}

export function fanSpeedValidForTemperature(
  fanSpeed: number,
  temperature: number,
  tempPipe: TemperatureTypePipe
) {
  return (
    !temperature ||
    temperature <=
      convertTempOrNoProbe(tempPipe, MIN_FAN_SPEED_THRESHOLD_TEMP) ||
    fanSpeed >= MIN_FAN_SPEED_THRESHOLD_PERCENT
  );
}

// Reduced is allowed at any fan speed, eco at >= 60%, and turbo at >= 80%
// it is a little bit inelegant to implement this as an if-else chain but there are
// few enough scenarios that it works out
export function getMaxPowerLevelForFanSpeed(fanSpeed: number) {
  if (fanSpeed >= MIN_TURBO_FAN_SPEED_PERCENT) {
    return PowerLevelEnum.turbo;
  } else if (fanSpeed >= MIN_ECO_FAN_SPEED_PERCENT) {
    return PowerLevelEnum.eco;
  } else {
    return PowerLevelEnum.reduced;
  }
}

export function powerLevelValidForFanSpeed(
  powerLevel: PowerLevelEnum,
  fanSpeed: number
) {
  return powerLevel <= getMaxPowerLevelForFanSpeed(fanSpeed);
}
