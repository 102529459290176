import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-signup-layout-component',
  templateUrl: './signup-layout-component.component.html',
  styleUrls: ['./signup-layout-component.component.scss']
})
export class SignupLayoutComponentComponent implements OnInit {
  public currentSection = 0;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.refreshStep();
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.refreshStep();
      }
    });
  }

  private refreshStep() {
    this.route.url.subscribe(() => {
      const firstChild = this.route.snapshot.firstChild;
      let secondChild = null;
      if (firstChild && firstChild.children[0].firstChild) {
        secondChild = firstChild.children[0].firstChild;
      }
      if (!secondChild) {
        return;
      }
      this.currentSection = secondChild.data.section_id;
    });
  }
}
