import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { BsModalRef } from "ngx-bootstrap/modal"
import {
  RecipeModifiersEnum,
  RecipeModifiersType,
  StageType
} from "src/app/api"
import { DialogModalService } from "src/app/core/dialog-modal.service"

@Component({
  selector: "app-stage-modifier-modal",
  templateUrl: "./stage-modifier-modal.component.html",
  styleUrls: ["./stage-modifier-modal.component.scss"]
})
export class StageModifierModalComponent implements OnInit {
  recipeModifiersEnum = RecipeModifiersEnum
  recipeModifiersType = RecipeModifiersType
  displayModifiers: RecipeModifiersType[] = Object.values(
    this.recipeModifiersType
  )
  mutuallyExclusiveModifiers: RecipeModifiersType[] = [
    RecipeModifiersType.coldSmoke,
    RecipeModifiersType.hotSmoke,
    RecipeModifiersType.dehydrate,
    RecipeModifiersType.ovenRest
  ]
  bannedModifiers: RecipeModifiersType[] = []
  modifiers: RecipeModifiersType[]
  stageType: StageType
  @Output() modifierSelected = new EventEmitter<string>()

  constructor(
    private modalRef: BsModalRef,
    private dialogService: DialogModalService,
    private ts: TranslateService
  ) {}

  ngOnInit() {
    if (![StageType.cook, StageType.stage].includes(this.stageType)) {
      this.bannedModifiers.push(RecipeModifiersType.dehydrate)
      this.bannedModifiers.push(RecipeModifiersType.ovenRest)
    }
    if (this.stageType === StageType.steam) {
      this.bannedModifiers.push(RecipeModifiersType.hotSmoke)
    }
  }

  selected(modifierType: RecipeModifiersType) {
    if (
      this.mutuallyExclusiveModifiers.includes(modifierType) &&
      this.modifiers.some(mutuallyExclusiveModifier =>
        this.mutuallyExclusiveModifiers.includes(mutuallyExclusiveModifier)
      )
    ) {
      this.dialogService
        .dangerConfirm({
          title: this.ts.instant(
            "RECIPE_LIBRARY.EDITOR.OVERWRITE_MODIFIER_TITLE"
          ),
          message: this.ts.instant(
            "RECIPE_LIBRARY.EDITOR.OVERWRITE_MODIFIER_MESSAGE"
          )
        })
        .accept$.subscribe(() => {
          this.modalRef.hide()
          this.modifierSelected.emit(modifierType)
        })
    } else {
      this.modalRef.hide()
      this.modifierSelected.emit(modifierType)
    }
  }

  cancel() {
    this.modalRef.hide()
  }
}
