import { Component, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { BsModalRef } from "ngx-bootstrap/modal"
import { StageType } from "src/app/api"
import { AuthenticationService } from "src/app/core/authentication.service"
import { numberRangeToArray } from "src/app/core/utils"
import { GenericEditStepModalComponent } from "../generic-edit-step-modal-component"

@Component({
  selector: "app-edit-combi-modal",
  templateUrl: "./edit-combi-modal.component.html",
  styleUrls: ["./edit-combi-modal.component.scss"]
})
export class EditCombiModalComponent extends GenericEditStepModalComponent
  implements OnInit {
  hasProbeCooking = true

  cookingModeFormControl = new FormControl()

  public fanSpeedOptions: Array<number>
  public humidityOptions: Array<number>

  get timeSetpointControl(): FormControl {
    return this.formGroup.controls["timeSetpoint"] as FormControl
  }

  get probeSetpointControl(): FormControl {
    return this.formGroup.controls["probeSetpoint"] as FormControl
  }

  get humidityControl(): FormControl {
    return this.formGroup.controls["humidity"] as FormControl
  }

  get fanSpeedControl(): FormControl {
    return this.formGroup.controls["fanSpeed"] as FormControl
  }

  constructor(modalRef: BsModalRef, authService: AuthenticationService) {
    super(modalRef, authService)
    this.cookingModeFormControl.valueChanges.subscribe(
      (val: "probe" | "time") => {
        if (val === "time") {
          this.probeSetpointControl.setValue(-1)

          if (this.timeSetpointControl.value === -1) {
            this.timeSetpointControl.setValue(
              this.stageNRanges.timeSetpoint.default
            )
          }
        } else {
          setTimeout(() => {
            this.timeSetpointControl.setValue(-1)
          }, 0)

          if (this.probeSetpointControl.value === -1) {
            this.probeSetpointControl.setValue(
              this.stageNRanges.probeSetpoint.default
            )
          }
        }
      }
    )
  }

  ngOnInit() {
    this.stageType = StageType.combi
    super.ngOnInit()
    if (this.timeSetpointControl.value === -1) {
      this.cookingModeFormControl.setValue("probe")
    } else {
      this.cookingModeFormControl.setValue("time")
    }

    this.fanSpeedOptions = numberRangeToArray(
      this.stageNRanges.fanSpeed.min,
      this.stageNRanges.fanSpeed.max,
      this.stageNRanges.fanSpeed.step
    )
    this.humidityOptions = numberRangeToArray(
      this.stageNRanges.humidity.min,
      this.stageNRanges.humidity.max,
      this.stageNRanges.humidity.step
    )
  }
}
