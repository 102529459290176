export enum LocalStorageKey {
  idJWT = "idJWT",
  refreshJWT = "refreshJWT",
  userInfo = "currentUser",
  userId = "userId",
  userCompany = "userCompany",
  activeCompany = "activeCompany",
  userRole = "userRole",
  groupId = "groupId",
  locationId = "locationId",
  email = "email"
}
