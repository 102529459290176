import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/core/authentication.service";
import { getTimeRemainingOnJwtToken } from "src/app/core/utils";
import { LocalStorageKey } from "src/app/models/enums/local-storage";
import { environment } from "src/environments/environment";

@Injectable()
export class ApiRequestInterceptor implements HttpInterceptor {
  apiBasePath: string = environment.apiBasePath;

  constructor(
    private authService: AuthenticationService,
    private jwtService: JwtHelperService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // If the requested URL is relative, prefix it with the environment-specific API base path
    if (req.url) {
      if (req.url.startsWith("https://asdev.kalpa.it/api")) {
        // Sorry future devs, this is a patch to combat the hardcoded API URL in the generated API client files. Once they've been cleaned up this check can be removed.
        req = req.clone({
          url: req.url.replace("https://asdev.kalpa.it/api", "")
        });
      }
      if (req.url.startsWith("/")) {
        req = req.clone({ url: this.apiBasePath + req.url });
      }
    }

    // Get token, if non null apply it to the request and check if it needs to be refreshed
    const token = this.authService.getIdToken();
    if (token) {
      req = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + token)
      });

      // If there is less than 30 minutes remaining in the session,
      // then the user is still active and we should refresh their session
      const jwtToken = this.jwtService.decodeToken(token);
      if (getTimeRemainingOnJwtToken(jwtToken) < 30 * 60) {
        this.authService.refreshSession();
      }
    }

    return next.handle(req);
  }
}
