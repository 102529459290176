import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.sass']
})
export class SvgIconComponent implements OnInit {
  @Input() icon: string;
  @Input() width = '100%';
  @Input() fill: string;
  @Input() height?: string;

  basePath = '/assets/static/images/sprite.svg#';

  constructor() {}

  ngOnInit() {}
}
