import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { ActivatedRoute } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { TableColumn } from "@swimlane/ngx-datatable"
import { saveAs } from "file-saver"
import { ODataFiltrableDataSource } from "filtrable-data-source"
import { DateTime } from "luxon"
import { BsModalService } from "ngx-bootstrap/modal"
import { BehaviorSubject } from "rxjs"
import { map } from "rxjs/operators"
import {
  ChartConfiguration,
  ChartTableRow,
  ResolutionType,
  UnitDetail,
  UnitsService
} from "src/app/api"
import { findResolutionLevel, getLocalTimezone } from "src/app/core/utils"
import {
  adjustDateRange,
  getOneDayAgo
} from "src/app/shared/custom-date-picker/utils"
import { ExportDataModalComponent } from "src/app/shared/export-data-modal/export-data-modal.component"
import { ChartData } from "../../core/chart-utils"

@Component({
  selector: "app-unit-data",
  templateUrl: "./unit-data.component.html",
  styleUrls: ["./unit-data.component.scss"]
})
export class UnitDataComponent implements OnInit {
  dataSource = new ODataFiltrableDataSource<ChartTableRow>()
  chartData$ = new BehaviorSubject<ChartData | undefined>(undefined)
  columns: TableColumn[] = []

  unit: UnitDetail
  updateTime: Date
  rangeFormControl: FormControl
  templateFormControl: FormControl
  chambers: Array<string> = []
  configuration: ChartConfiguration
  originalConfiguration: ChartConfiguration
  isLoadingData = true
  resolution: ResolutionType = "minute"

  constructor(
    private route: ActivatedRoute,
    private unitsService: UnitsService,
    private ts: TranslateService,
    private modalService: BsModalService,
    @Inject(LOCALE_ID) private localeId: string
  ) {}

  ngOnInit() {
    this.rangeFormControl = new FormControl(getOneDayAgo())
    this.templateFormControl = new FormControl(0)

    this.unit = this.route.parent!.snapshot.data.unit
    this.configuration = this.originalConfiguration = this.route.snapshot.data.configuration
    this.updateTime = new Date()

    for (let i = 0; i < this.configuration.chamberCount; i++) {
      this.chambers.push(this.ts.instant("UNIT.CHAMBER") + " " + (i + 1))
    }

    this.rangeFormControl.valueChanges.subscribe(() => {
      this.updateGraphAndTable()
    })

    this.templateFormControl.valueChanges.subscribe(() => {
      this.updateGraphAndTable()
    })

    this.updateGraphAndTable()
  }

  updateGraphAndTable() {
    const adjustedRange = adjustDateRange(this.rangeFormControl.value)
    const params = {
      unitId: this.unit.unitId,
      chamber: this.templateFormControl.value,
      from: adjustedRange[0].toISOString(),
      to: adjustedRange[1].toISOString(),
      resolution: findResolutionLevel(adjustedRange[0], adjustedRange[1])
    }

    this.getGraphData(params)
    this.getTableData(params)
    this.resolution = params.resolution
  }

  refresh() {
    this.unitsService
      .getUnit(this.unit.unitId)
      .subscribe((data: UnitDetail) => {
        this.unit = data
      })
    this.updateTime = new Date()
  }

  getGraphData(params: any) {
    this.unitsService
      .getUnitDataChart(
        params.unitId,
        params.chamber,
        params.from,
        params.to,
        params.resolution,
        getLocalTimezone()
      )
      .subscribe(response => {
        this.isLoadingData = false
        this.chartData$.next((response as unknown) as ChartData)
      })
  }

  getTableData(params: any) {
    this.dataSource.fetchFunction = (top, skip, filter, orderby, count) => {
      return this.unitsService
        .getUnitDataTable(
          params.unitId,
          params.chamber,
          params.from,
          params.to,
          params.resolution,
          getLocalTimezone(),
          top,
          skip,
          filter,
          orderby,
          count
        )
        .pipe(
          map(response => {
            this.checkAndRemoveProbes(response.result)
            return response
          })
        )
    }
    this.dataSource.setSort({ prop: "timestamp", dir: "asc" })
    this.dataSource.loadData()
  }

  exportData() {
    const modalRef = this.modalService.show(ExportDataModalComponent)
    const component = modalRef.content as ExportDataModalComponent
    component.export.subscribe((range: [Date, Date]) => {
      this.unitsService
        .exportsUnitData(
          this.unit.unitId,
          this.templateFormControl.value,
          range[0].toISOString(),
          range[1].toISOString(),
          getLocalTimezone()
        )
        .subscribe(data => {
          saveAs(
            data,
            DateTime.fromJSDate(new Date()).toFormat("yyyyMMdd_HHmm") +
              "_" +
              this.unit.unitId +
              ".csv"
          )
        })
    })
  }

  checkAndRemoveProbes(result: Array<ChartTableRow>) {
    // Go through each probe temp field and check if there are any values in it.
    // If not then remove from table columns
    const remove: string[] = []
    for (let i = 0; i < 6; i++) {
      if (
        result.filter(row => (row as any)["probeTemp" + (i + 1)]).length === 0
      ) {
        remove.push("Probe " + (i + 1))
      }
    }
    const newTable = this.originalConfiguration.table.filter(
      column => !remove.includes(column)
    )
    // Update configuration so that change gets detected in angular and columns are changed
    if (this.configuration.table.length !== newTable.length) {
      this.configuration = {
        ...this.configuration,
        table: newTable
      }
    }
  }
}
