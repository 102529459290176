/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RecipeImage } from "./recipeBody";

export interface CookbookRecipe {
  recipeId: string;
  recipeName: string;
  /**
   * Total cooking time in seconds
   */
  cookTime: string;
  coverImageId?: RecipeImage;
  synced: CookbookRecipe.SyncedEnum;
  categories: string[];
  featured: boolean;
}

export namespace CookbookRecipe {
  export type SyncedEnum =
    | "synced"
    | "not_synced"
    | "sync_pending"
    | "sync_failed";
  export const SyncedEnum = {
    Synced: "synced" as SyncedEnum,
    AssignPending: "not_synced" as SyncedEnum,
    EditedPending: "sync_pending" as SyncedEnum,
    SyncFailed: "sync_failed" as SyncedEnum
  };
}
