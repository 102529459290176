import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { guid } from '../../core/utils';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @HostBinding('class') hostClass = 'card';
  @Input() selectable = true;
  @Input() checkboxName: string;
  @Input() checkboxValue: any;
  @HostBinding('class.selected') @Input() selected: boolean;
  @Output() selectCard = new EventEmitter();
  @Output() imageClicked = new EventEmitter();
  @Input() coverPlaceholder = '/assets/static/images/placeholder.svg';

  _coverUrl: string;
  id = guid();

  get coverUrl(): string {
    return this._coverUrl;
  }

  @Input('coverUrl')
  set setCoverUrl(coverUrl: string) {
    if (!coverUrl) {
      this._coverUrl = this.coverPlaceholder;
      return;
    }
    this._coverUrl = coverUrl;
  }

  constructor() {}

  ngOnInit() {}
}
