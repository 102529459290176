import { Component, OnInit } from "@angular/core"
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import {
  convertTempOrNoProbe,
  evaluateModifiers,
  fanSpeedValidForTemperature,
  getMaxPowerLevelForFanSpeed,
  IModifierValues,
  MIN_FAN_SPEED_THRESHOLD_PERCENT,
  ModifierDefaultValues,
  ModifiersDisableProbeCook,
  PowerLevelEnum,
  powerLevelValidForFanSpeed,
  RecipeModifiersEnum,
  RecipeModifiersType,
  StageType
} from "src/app/api"
import { AuthenticationService } from "src/app/core/authentication.service"
import { numberRangeToArray } from "src/app/core/utils"
import { TemperatureTypePipe } from "src/app/shared/temperature-type.pipe"
import { GenericEditStepModalComponent } from "../generic-edit-step-modal-component"
import {
  DefaultShelfTimer,
  MultiShelfTimerModalComponent,
  ShelfTimer
} from "../multi-shelf-timer-modal/multi-shelf-timer-modal.component"
import { StageModifierModalComponent } from "../stage-modifier-modal/stage-modifier-modal.component"

@Component({
  selector: "app-edit-prodigi-pro-stage-modal",
  templateUrl: "./edit-prodigi-pro-stage-modal.component.html",
  styleUrls: ["./edit-prodigi-pro-stage-modal.component.scss"]
})
export class EditProdigiProStageModalComponent
  extends GenericEditStepModalComponent
  implements OnInit {
  hasProbeCooking = true
  stageType: StageType
  recipeModifiersEnum = RecipeModifiersEnum
  recipeModifiersType = RecipeModifiersType
  powerLevelEnum = PowerLevelEnum
  modifierDefaultValues = ModifierDefaultValues

  cookingModeFormControl = new FormControl()

  fanSpeedOptions: Array<number>
  humidityOptions: Array<number>
  powerLevelOptions: Array<number>
  previousFormValues = {
    humidity: -1,
    cavitySetpoint: -1,
    timeSetpoint: -1,
    fanSpeed: -1
  }
  multiShelfTimerIsValid = true

  modifiers: RecipeModifiersType[]

  get timeSetpointControl(): FormControl {
    return this.formGroup.controls["timeSetpoint"] as FormControl
  }

  get probeSetpointControl(): FormControl {
    return this.formGroup.controls["probeSetpoint"] as FormControl
  }

  get humidityControl(): FormControl {
    return this.formGroup.controls["humidity"] as FormControl
  }

  get fanSpeedControl(): FormControl {
    return this.formGroup.controls["fanSpeed"] as FormControl
  }

  get powerLevelControl(): FormControl {
    return this.formGroup.controls["powerLevel"] as FormControl
  }

  get cavitySetpointControl(): FormControl {
    return this.formGroup.controls["cavitySetpoint"] as FormControl
  }

  get greaseCollectionControl(): FormControl {
    return this.formGroup.controls["greaseCollection"] as FormControl
  }

  get smokerControl(): FormControl {
    return this.formGroup.controls["smoker"] as FormControl
  }

  get multiShelfTimerControl(): FormArray {
    return this.formGroup.controls["multiShelfTimer"] as FormArray
  }

  get stageTypeI18nKey(): string {
    // This is a weirdo case where the desired text for the "cook" or "stage" stage is "convection",
    // but the translation key for "cook" has a value of "cook" so we have to use a different
    // key if the current stage type is cook.
    return `RECIPE_LIBRARY.EDITOR.${
      StageType.cook === this.stageType || StageType.stage === this.stageType
        ? "CONVECTION"
        : this.stageType.toUpperCase()
    }`
  }

  constructor(
    modalRef: BsModalRef,
    authService: AuthenticationService,
    private temperatureTypePipe: TemperatureTypePipe,
    private modalService: BsModalService
  ) {
    super(modalRef, authService)
    this.cookingModeFormControl.valueChanges.subscribe(
      (val: "probe" | "time") => {
        if (!this.cookingModeFormControl.disabled) {
          if (
            this.modifiers.some(modifier =>
              ModifiersDisableProbeCook.includes(modifier)
            )
          ) {
            this.cookingModeFormControl.disable()
            return
          }
          if (val === "time") {
            this.probeSetpointControl.setValue(-1)

            if (this.timeSetpointControl.value === -1) {
              this.timeSetpointControl.setValue(
                this.stageNRanges.timeSetpoint.default
              )
            }
          } else {
            setTimeout(() => {
              this.timeSetpointControl.setValue(-1)
            }, 0)

            if (this.probeSetpointControl.value === -1) {
              this.probeSetpointControl.setValue(
                this.stageNRanges.probeSetpoint.default
              )
            }
          }
        } else if (
          this.cookingModeFormControl.disabled &&
          !this.modifiers.some(modifier =>
            ModifiersDisableProbeCook.includes(modifier)
          )
        ) {
          this.cookingModeFormControl.enable()
        }
      }
    )
  }

  ngOnInit() {
    super.ngOnInit()
    this.modifiers = evaluateModifiers(
      this.formGroup.value,
      this.stageType,
      this.temperatureTypePipe
    )
    if (
      this.greaseCollectionControl.value !== null &&
      !this.modifiers.includes(RecipeModifiersType.greaseCollection)
    ) {
      if (
        this.modifiers.includes(RecipeModifiersType.coldSmoke) ||
        this.modifiers.includes(RecipeModifiersType.hotSmoke)
      ) {
        this.modifiers.splice(2, 0, RecipeModifiersType.greaseCollection)
      } else {
        this.modifiers.splice(1, 0, RecipeModifiersType.greaseCollection)
      }
    }

    this.multiShelfTimerControl.statusChanges.subscribe(status => {
      if (status !== "VALID") {
        this.multiShelfTimerIsValid = false
      } else {
        this.multiShelfTimerIsValid = true
      }
    })

    if (
      this.timeSetpointControl.value === -1 &&
      !this.modifiers.includes(RecipeModifiersType.multiShelfTimer)
    ) {
      this.cookingModeFormControl.setValue("probe")
    } else {
      this.cookingModeFormControl.setValue("time")
    }

    this.fanSpeedOptions = numberRangeToArray(
      this.stageNRanges.fanSpeed.min,
      this.stageNRanges.fanSpeed.max,
      this.stageNRanges.fanSpeed.step
    )
    this.humidityOptions = numberRangeToArray(
      this.stageNRanges.humidity.min,
      this.stageNRanges.humidity.max,
      this.stageNRanges.humidity.step
    )
    this.powerLevelOptions = numberRangeToArray(
      this.stageNRanges.powerLevel.min,
      this.stageNRanges.powerLevel.max,
      this.stageNRanges.powerLevel.step
    )

    this.setRevertFormValues()
    if (this.modifiers.includes(RecipeModifiersType.dehydrate)) {
      this.previousFormValues["humidity"] = this.stageNRanges.humidity.default
      this.previousFormValues[
        "cavitySetpoint"
      ] = this.stageNRanges.cavitySetpoint.default
      this.previousFormValues["fanSpeed"] = this.stageNRanges.fanSpeed.default
    }

    // It is easier to automatically correct fan speed/power level modifier when temperature
    // and fan speed change, rather than increasing the complexity of form validators
    this.cavitySetpointControl.valueChanges.subscribe(_ =>
      this.applyFanSpeedConstraints()
    )
    this.fanSpeedControl.valueChanges.subscribe(_ =>
      this.applyPowerLevelConstraints()
    )
  }

  spliceMutuallyExclusive(incompatibleModifiers: RecipeModifiersType[]) {
    for (const incompatible of incompatibleModifiers) {
      if (this.modifiers.includes(incompatible)) {
        this.modifiers.splice(this.modifiers.indexOf(incompatible), 1)
      }
    }
  }

  addModifier() {
    // Open add-stage-modifier-modal
    const initialState: Partial<StageModifierModalComponent> = {
      modifiers: this.modifiers,
      stageType: this.stageType
    }
    const modifierModal = this.modalService.show(StageModifierModalComponent, {
      initialState
    })
    modifierModal.content.modifierSelected.subscribe(
      (newModifier: RecipeModifiersType) => {
        switch (newModifier) {
          case RecipeModifiersType.coldSmoke:
            this.spliceMutuallyExclusive([
              RecipeModifiersType.hotSmoke,
              RecipeModifiersType.dehydrate,
              RecipeModifiersType.ovenRest
            ])
            this.setRevertFormValues()
            this.cavitySetpointControl.clearValidators()
            this.setModifierValues(
              ModifierDefaultValues[RecipeModifiersType.coldSmoke],
              RecipeModifiersType.coldSmoke
            )
            this.cavitySetpointControl.updateValueAndValidity()
            break
          case RecipeModifiersType.hotSmoke:
            this.spliceMutuallyExclusive([
              RecipeModifiersType.coldSmoke,
              RecipeModifiersType.dehydrate,
              RecipeModifiersType.ovenRest
            ])
            this.setRevertFormValues()
            this.cavitySetpointControl.setValidators([
              Validators.required,
              Validators.min(this.stageNRanges.cavitySetpoint.min),
              Validators.max(this.stageNRanges.cavitySetpoint.max)
            ])
            this.setModifierValues(
              ModifierDefaultValues[RecipeModifiersType.hotSmoke],
              RecipeModifiersType.hotSmoke
            )
            this.cavitySetpointControl.updateValueAndValidity()
            break
          case RecipeModifiersType.multiShelfTimer:
            this.setModifierValues(
              {
                smoker: this.smokerControl.value,
                humidity: this.humidityControl.value,
                timeSetpoint:
                  ModifierDefaultValues[RecipeModifiersType.multiShelfTimer]
                    .timeSetpoint,
                fanSpeed: this.fanSpeedControl.value,
                cavitySetpoint: this.cavitySetpointControl.value,
                probeSetpoint:
                  ModifierDefaultValues[RecipeModifiersType.multiShelfTimer]
                    .probeSetpoint,
                cookingMode:
                  ModifierDefaultValues[RecipeModifiersType.multiShelfTimer]
                    .cookingMode
              },
              RecipeModifiersType.multiShelfTimer
            )
            const item = new FormGroup({
              mode: new FormControl(DefaultShelfTimer.mode),
              sound: new FormControl(DefaultShelfTimer.sound),
              targetValue: new FormControl(DefaultShelfTimer.targetValue, [
                Validators.required,
                Validators.min(-1)
              ]),
              text: new FormControl(DefaultShelfTimer.text, Validators.required)
            })
            this.multiShelfTimerControl.push(item)
            this.multiShelfTimerControl.push(item)
            this.multiShelfTimerControl.push(item)
            this.multiShelfTimerControl.push(item)
            this.multiShelfTimerControl.push(item)
            break
          case RecipeModifiersType.dehydrate:
            this.spliceMutuallyExclusive([
              RecipeModifiersType.ovenRest,
              RecipeModifiersType.coldSmoke,
              RecipeModifiersType.hotSmoke
            ])
            this.setRevertFormValues()
            this.cavitySetpointControl.setValidators([
              Validators.required,
              Validators.min(this.stageNRanges.cavitySetpoint.min),
              Validators.max(this.stageNRanges.cavitySetpoint.max)
            ])
            this.setModifierValues(
              ModifierDefaultValues[RecipeModifiersType.dehydrate],
              RecipeModifiersType.dehydrate
            )
            this.cavitySetpointControl.updateValueAndValidity()
            break
          case RecipeModifiersType.ovenRest:
            this.spliceMutuallyExclusive([
              RecipeModifiersType.dehydrate,
              RecipeModifiersType.coldSmoke,
              RecipeModifiersType.hotSmoke
            ])
            this.setRevertFormValues()
            this.cavitySetpointControl.clearValidators()
            this.setModifierValues(
              ModifierDefaultValues[RecipeModifiersType.ovenRest],
              RecipeModifiersType.ovenRest
            )
            this.cavitySetpointControl.updateValueAndValidity()
            break
        }

        if (ModifiersDisableProbeCook.includes(newModifier)) {
          this.cookingModeFormControl.disable()
        }

        // Add selected modifier and preserve modifier order
        const temp: RecipeModifiersType[] = []
        for (const type of Object.values(this.recipeModifiersType)) {
          if (this.modifiers.includes(type) || type === newModifier) {
            temp.push(type)
          }
        }
        this.modifiers = temp
      }
    )
  }

  deleteModifier(modifier: RecipeModifiersType) {
    this.modifiers = this.modifiers.filter(value => value !== modifier)
    switch (modifier) {
      case RecipeModifiersType.powerLevel:
        // Power level is a required modifier, cannot delete it, but set to default value if somehow deleted
        this.powerLevelControl.setValue(this.powerLevelEnum.eco)
        break
      case RecipeModifiersType.coldSmoke:
      case RecipeModifiersType.hotSmoke:
        this.smokerControl.setValue(false)
        this.revertForm()
        break
      case RecipeModifiersType.greaseCollection:
        // Grease collection is a required modifier, cannot delete it, but set to default value if somehow deleted
        this.greaseCollectionControl.setValue(false)
        break
      case RecipeModifiersType.multiShelfTimer:
        this.multiShelfTimerControl.clear()
        this.revertForm(modifier)
        break
      case RecipeModifiersType.dehydrate:
        this.revertForm()
        break
      case RecipeModifiersType.ovenRest:
        this.revertForm()
        this.cavitySetpointControl.updateValueAndValidity()
        break
      default:
        break
    }
  }

  isReadOnlyTemperature(temp: number) {
    return (
      this.modifiers.includes(RecipeModifiersType.coldSmoke) ||
      this.modifiers.includes(RecipeModifiersType.ovenRest)
    )
  }

  setModifierValues(
    {
      smoker,
      humidity,
      timeSetpoint,
      fanSpeed,
      cavitySetpoint,
      probeSetpoint,
      cookingMode
    }: IModifierValues,
    recipeModifier: RecipeModifiersType
  ) {
    this.smokerControl.setValue(smoker)
    this.humidityControl.setValue(humidity)
    this.fanSpeedControl.setValue(fanSpeed)

    if (recipeModifier !== RecipeModifiersType.multiShelfTimer) {
      this.cavitySetpointControl.setValue(
        recipeModifier === RecipeModifiersType.hotSmoke
          ? this.previousFormValues["cavitySetpoint"] === null ||
            this.previousFormValues["cavitySetpoint"] <
              this.stageNRanges.cavitySetpoint.min
            ? convertTempOrNoProbe(
                this.temperatureTypePipe,
                ModifierDefaultValues[RecipeModifiersType.hotSmoke]
                  .cavitySetpoint
              )
            : this.previousFormValues["cavitySetpoint"]
          : convertTempOrNoProbe(this.temperatureTypePipe, cavitySetpoint)
      )
    }

    if (!this.modifiers.includes(RecipeModifiersType.multiShelfTimer)) {
      this.timeSetpointControl.setValue(timeSetpoint)
      this.cookingModeFormControl.setValue(cookingMode)
      this.probeSetpointControl.setValue(
        convertTempOrNoProbe(this.temperatureTypePipe, probeSetpoint)
      )
    }
  }

  setRevertFormValues() {
    this.previousFormValues["humidity"] = this.humidityControl.value
    this.previousFormValues[
      "cavitySetpoint"
    ] = this.cavitySetpointControl.value
    this.previousFormValues["timeSetpoint"] = this.timeSetpointControl.value
    this.previousFormValues["fanSpeed"] = this.fanSpeedControl.value
  }

  revertForm(recipeModifier?: RecipeModifiersType) {
    switch (recipeModifier) {
      case RecipeModifiersType.multiShelfTimer:
        this.cookingModeFormControl.setValue("time")
        this.probeSetpointControl.setValue(-1)
        this.timeSetpointControl.setValue(
          this.stageNRanges.timeSetpoint.default
        )
        break
      default:
        this.cavitySetpointControl.setValidators([
          Validators.required,
          Validators.min(this.stageNRanges.cavitySetpoint.min),
          Validators.max(this.stageNRanges.cavitySetpoint.max)
        ])
        this.cavitySetpointControl.setValue(
          this.previousFormValues["cavitySetpoint"] === null ||
            this.previousFormValues["cavitySetpoint"] <
              this.stageNRanges.cavitySetpoint.min
            ? this.stageNRanges.cavitySetpoint.default
            : this.previousFormValues["cavitySetpoint"]
        )
        this.cavitySetpointControl.updateValueAndValidity()
        this.fanSpeedControl.setValue(
          this.previousFormValues["fanSpeed"] === null ||
            this.previousFormValues["fanSpeed"] === -1
            ? this.stageNRanges.fanSpeed.default
            : this.previousFormValues["fanSpeed"]
        )
        this.humidityControl.setValue(
          this.previousFormValues["humidity"] === null ||
            this.previousFormValues["humidity"] === -1
            ? this.stageNRanges.humidity.default
            : this.previousFormValues["humidity"]
        )
        if (
          !this.modifiers.some(modifier =>
            ModifiersDisableProbeCook.includes(modifier)
          )
        ) {
          this.cookingModeFormControl.enable()
          this.timeSetpointControl.setValue(
            this.previousFormValues["timeSetpoint"] === null ||
              this.previousFormValues["timeSetpoint"] < 0
              ? this.stageNRanges.timeSetpoint.default
              : this.previousFormValues["timeSetpoint"]
          )
          this.probeSetpointControl.setValue(-1)
          this.cookingModeFormControl.setValue("time")
        }
        break
    }
  }

  openMultiShelfTimerModal() {
    const multiShelfTimerState: Partial<MultiShelfTimerModalComponent> = {
      existingMultiShelfTimers: this.multiShelfTimerControl
        .value as ShelfTimer[]
    }
    const multiShelfTimerModal = this.modalService.show(
      MultiShelfTimerModalComponent,
      {
        initialState: multiShelfTimerState
      }
    )
    multiShelfTimerModal.content.multiShelfTimer.subscribe(
      (multiShelfTimer: FormGroup[]) => {
        this.multiShelfTimerControl.clear()
        for (const formGroup of multiShelfTimer) {
          this.multiShelfTimerControl.push(formGroup)
        }
      }
    )
  }

  fanSpeedValidForTemp(fanSpeed: number) {
    return fanSpeedValidForTemperature(
      fanSpeed,
      this.cavitySetpointControl.value,
      this.temperatureTypePipe
    )
  }

  powerLevelValidForFanSpeed(powerLevel: PowerLevelEnum) {
    return powerLevelValidForFanSpeed(powerLevel, this.fanSpeedControl.value)
  }

  applyFanSpeedConstraints() {
    if (
      !fanSpeedValidForTemperature(
        this.fanSpeedControl.value,
        this.cavitySetpointControl.value,
        this.temperatureTypePipe
      )
    ) {
      this.fanSpeedControl.setValue(MIN_FAN_SPEED_THRESHOLD_PERCENT)
      this.applyPowerLevelConstraints()
    }
  }

  applyPowerLevelConstraints() {
    if (
      !powerLevelValidForFanSpeed(
        this.powerLevelControl.value,
        this.fanSpeedControl.value
      )
    ) {
      this.powerLevelControl.setValue(
        getMaxPowerLevelForFanSpeed(this.fanSpeedControl.value)
      )
    }
  }
}
