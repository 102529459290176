import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormGroup } from "@angular/forms"

@Component({
  selector: "app-steam-step",
  templateUrl: "./steam-step.component.html",
  styleUrls: ["./steam-step.component.scss"]
})
export class SteamStepComponent implements OnInit {
  @Input() formGroup: FormGroup
  @Input() unitType: string
  @Input() index: number
  @Input() editingEnabled = true
  @Output() edit = new EventEmitter<void>()
  @Output() delete = new EventEmitter<void>()

  constructor() {}

  ngOnInit() {}
}
