import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormArray, FormControl } from "@angular/forms"
import { TranslateService } from "@ngx-translate/core"
import { BsModalService } from "ngx-bootstrap/modal"
import { RecipeImage } from "src/app/api"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"
import { ImagesUploadModalComponent } from "../images-upload-modal/images-upload-modal.component"

@Component({
  selector: "app-image-upload-button",
  templateUrl: "./image-upload-button.component.html",
  styleUrls: ["./image-upload-button.component.scss"]
})
export class ImageUploadButtonComponent implements OnInit {
  constructor(
    private ts: TranslateService,
    private modalService: BsModalService
  ) {}
  // Whether to present and input form for one or multiple recipe images
  @Input() multiImage: boolean

  // The form group that a single image should be populated into
  @Input() imageForm: FormControl

  // The form group that multiple images should be populated into
  @Input() imageFormArray: FormArray

  // If using a multi-image form group, exclude this many images from the start of the list
  @Input() formArraySkipItems = 0

  // Image button title
  @Input() title: string

  // Image button subtitle
  @Input() subtitle: string

  // Title for modal
  @Input() modalTitle: string

  // Message for modal
  @Input() modalMessage: string

  // Whether the add image button is inside a modal - render image upload dialog differently if true
  @Input() inModal = false

  // Event triggered when an image is added via the form
  @Output() imageAdded: EventEmitter<
    RecipeImage | RecipeImage[]
  > = new EventEmitter()

  // Event triggered when an image is removed via the form
  @Output() imageRemoved: EventEmitter<
    RecipeImage | RecipeImage[]
  > = new EventEmitter()

  selectedImagesHandler = new SelectedItemsHandler<{ id: string }>()

  // Function to check whether an uploaded image can be modified via the form
  // Used to support preventing deletion of images associated with an action step
  @Input() canEditImage: (image: RecipeImage) => boolean = image => true

  ngOnInit() {}

  openAddImage() {
    const initialState: Partial<ImagesUploadModalComponent> = {
      title: this.modalTitle, // this.ts.instant("RECIPE_LIBRARY.EDITOR.IMAGES.ADD_COVER_PHOTO"),
      message: this.modalMessage, // this.ts.instant( "RECIPE_LIBRARY.EDITOR.IMAGES.ADD_COVER_PHOTO_DESC"),
      maxFiles: this.multiImage ? undefined : 1,
      layeredModal: this.inModal,
      sortOrder: 0
    }
    const modalRef = this.modalService.show(ImagesUploadModalComponent, {
      initialState,
      backdrop: "static"
    })
    const component = modalRef.content as ImagesUploadModalComponent
    component.uploadedIds.subscribe((recipeImages: RecipeImage[]) => {
      if (this.multiImage) {
        recipeImages.forEach(image =>
          this.imageFormArray.push(new FormControl(image))
        )
        this.imageFormArray.markAsDirty()
        this.imageAdded.emit(recipeImages)
      } else {
        this.imageForm.setValue(recipeImages[0])
        this.imageForm.markAsDirty()
        this.imageAdded.emit(recipeImages[0])
      }
    })
  }

  deleteImage() {
    // single-image only
    this.imageRemoved.emit(this.imageForm.value) // listener may need info about which image was deleted
    this.imageForm.setValue({})
    this.imageForm.markAsDirty()
  }

  deleteSelectedImages() {
    // multi-image only
    const toDelete = (this.imageFormArray.value as RecipeImage[]).filter(item =>
      this.selectedImagesHandler.items.some(i => i.id === item.id)
    )

    this.imageRemoved.emit(toDelete)

    toDelete.forEach(item => {
      const idx = this.imageFormArray.value.findIndex(
        (v: RecipeImage) => v.id === item.id
      )
      this.imageFormArray.removeAt(idx)
    })
    this.selectedImagesHandler.clear()
  }
}
