import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import {
  GenericModalComponent,
  modalCancelFromType,
  ModalCloseEvent,
  modalType
} from '../generic-modal/generic-modal.component';

export class DialogModalOptions {
  type?: modalType;
  icon?: string;
  title?: string;
  message?: string;
  detail?: string;
  acceptButtonLabel?: string;
  cancelButtonLabel?: string;
  beforeAccept$?: Observable<any>;
}

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss']
})
export class DialogModalComponent extends DialogModalOptions implements OnInit {
  close$ = new Subject<ModalCloseEvent>();
  accept$ = new Subject<void>();
  cancel$ = new Subject<modalCancelFromType>();

  @ViewChild(GenericModalComponent, { static: true })
  modal: GenericModalComponent;

  constructor(private bsModalRef: BsModalRef) {
    super();
  }

  ngOnInit() {
    this.modal.close.subscribe((ev: ModalCloseEvent) => {
      this.bsModalRef.hide();
      this.close$.next(ev);
      this.close$.complete();

      if (ev.type === 'accept') {
        this.accept$.next();
        this.accept$.complete();
      } else if (ev.type === 'cancel') {
        this.cancel$.next(ev.cancelFrom);
        this.cancel$.complete();
      }
    });
  }
}
