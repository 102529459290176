import { Component, Input, OnInit } from '@angular/core';
import { Counter } from './counter/Counter';

@Component({
  selector: 'app-counter-list',
  templateUrl: './counter-list.component.html',
  styleUrls: ['./counter-list.component.scss']
})
export class CounterListComponent implements OnInit {
  @Input()
  counters: Counter[];

  constructor() {}

  ngOnInit() {}
}
