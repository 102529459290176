import { Injectable, Optional } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import {
  AcknowledgeOn,
  ConsoleLogger,
  DefaultTag,
  ICommandContext,
  ICommandEventArgs,
  ICommandReceipt,
  newIntData,
  PartialMessageReceivedEventArgs,
  RemoteSupportClient,
  RemoteSupportVideoTrack,
  RSCommand,
  RSNotification,
  RSTaggedData
} from "@cygnus-reach/core"
import fixWebmDuration from "fix-webm-duration"
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs"
import { filter, first, takeUntil } from "rxjs/operators"
import {
  CommandType,
  ConnectionState,
  MimeType,
  NotificationType,
  ShareType,
  UserRole
} from "../consts/remote-support/enums"
import { SessionDefaults } from "../consts/remote-support/values"
import {
  ArchivedMediaFileMetadata,
  AssetWrapper,
  MediaFile,
  MediaFileCategory,
  MediaFileMetadata,
  PartialMediaFileMetadata
} from "../models/remote-support/media"
import {
  ActiveMessageMetadata,
  Message,
  PartialMessage
} from "../models/remote-support/message"
import {
  ActiveSession,
  ArchivedSession,
  DrawingSegment,
  Session,
  SessionDisconnectType,
  ShareContext,
  UIServiceStream
} from "../models/remote-support/session"
import { SessionDataInterface } from "../models/remote-support/session-history"
import { RemoteSupportService } from "./remote-support.service"

@Injectable()
export class RemoteSupportUIService<CustomData> {
  // Core system
  private rs: RemoteSupportClient | undefined
  get rsClient(): RemoteSupportClient | undefined {
    return this.rs
  }
  private logger = new ConsoleLogger()

  /**
   * The interval between updates to an active session in session history, in ms.
   * If updated, associated internal timers are updated in parallel (with the update schedule reset)
   */
  get logFrequency(): number {
    return this._logFrequency
  }
  set logFrequency(freq: number) {
    this._logFrequency = freq
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = setInterval(this.intervalHandler.bind(this), freq)
    }
  }
  private _logFrequency: number = SessionDefaults.logFrequency

  recordHistory = false // Default history to off
  /**
   * Interval used to update the session log on a regular schedule
   */
  private interval: ReturnType<typeof setInterval> | undefined

  // Sharing
  // Ideally I'd like to dynamically generate a map with keys from ShareType's values, like this:
  // new Map(Object.values(ShareType).filter(val => (typeof val) === "number").map(value => [value as number, undefined]))
  // For the moment, we'll directly use indices to access array
  streams: Array<UIServiceStream | undefined> = [undefined, undefined]

  // Session data
  activeSession: ActiveSession<CustomData> | undefined

  get viewSession() {
    return this._viewSession
  }
  set viewSession(session: ArchivedSession<CustomData> | undefined) {
    // Handle case of unchanged session (currently not re-fetching data)
    if (session === this._viewSession) {
      return
    }

    // Clean up any previous asset fetches
    if (this.assetSubscriptions.length > 0) {
      this.assetSubscriptions.forEach(sub => sub.unsubscribe())
      this.assetSubscriptions = new Array<Subscription>()
    }
    if (this._viewSession) {
      this._viewSession.assets.forEach(a => {
        if (
          a.asset instanceof ArchivedMediaFileMetadata &&
          a.asset.archived === "fetching"
        ) {
          a.asset.archived = "unfetched"
        }
      })
    }

    // Set and fetch assets as necessary
    this._viewSession = session
    if (this._viewSession) {
      this.fetchSessionAssets(this._viewSession)
    }
  }
  _viewSession: ArchivedSession<CustomData> | undefined
  private assetSubscriptions: Array<Subscription> = new Array<Subscription>()

  customData: CustomData
  pin: string | undefined
  deviceIdentifier: string
  userRole: UserRole
  peerRole: UserRole

  // Session state subjects
  connectionStateSubject: BehaviorSubject<ConnectionState>
  get connectionState(): ConnectionState {
    return this.connectionStateSubject.value
  }
  set connectionState(state: ConnectionState) {
    this.connectionStateSubject.next(state)
  }
  private closeDependencies: BehaviorSubject<number>
  closeInitiated: Subject<UserRole>

  // Event/data subjects
  captureSubjects: {
    captureReceived: Subject<AssetWrapper<MediaFileMetadata>>;
    screenshotRequested: Subject<ShareContext<ICommandContext>>;
    screenshotAcknowledged: Subject<ShareContext<ICommandReceipt | undefined>>;
    recordingReady: Subject<ShareContext<MediaFile>>;
  }
  startShareReceipt: Subject<ShareContext<ICommandContext>>
  // TODO: Determine how segments should be passed in a unitless fashion for interpretation by the UI elements
  // Could be an argument for using SVG drawing overlay, which should allow for unitless drawing via viewBox attribute
  drawSegment: Subject<ShareContext<DrawingSegment>>
  streamChange: Subject<ShareContext<UIServiceStream | undefined>>

  // Message change subject (testing concept)
  messageUpdate: Subject<void>

  // Event handlers
  private connectHandler = this.onConnect.bind(this)
  private connectionLostHandler = this.onConnectionLoss.bind(this)
  private commandHandler = this.onCommand.bind(this)
  private notificationHandler = this.onNotification.bind(this)
  private partialMessageHandler = this.onPartialMessage.bind(this)
  private closeHandler = this.onClose.bind(this)
  private videoStreamHandler = this.onVideoStreamAdded.bind(this)

  constructor(
    private sanitizer: DomSanitizer,
    @Optional() private rsService?: RemoteSupportService<CustomData>
  ) {
    // Default user role to tech
    this.userRole = UserRole.Tech
    this.peerRole = UserRole.Agent

    // Set up connection-related subjects
    this.connectionStateSubject = new BehaviorSubject<ConnectionState>(
      ConnectionState.closed
    )
    this.closeDependencies = new BehaviorSubject<number>(0)
    this.closeInitiated = new Subject<UserRole>()

    // Set up capture subjects
    this.captureSubjects = {
      captureReceived: new Subject<AssetWrapper<MediaFileMetadata>>(),
      screenshotAcknowledged: new Subject<
        ShareContext<ICommandReceipt | undefined>
      >(),
      screenshotRequested: new Subject<ShareContext<ICommandContext>>(),
      recordingReady: new Subject<ShareContext<MediaFile>>()
    }

    this.startShareReceipt = new Subject<ShareContext<ICommandContext>>()
    this.drawSegment = new Subject<ShareContext<DrawingSegment>>()
    this.streamChange = new Subject<
      ShareContext<UIServiceStream | undefined>
    >()

    this.messageUpdate = new Subject<void>()
  }

  /* Basic helper functions */

  // TODO: Determine if there's a more efficient way to do this, a pipe would handle change detections slightly better if the inputs can be formatted correctly
  stateIs(...options: ConnectionState[]): boolean {
    return !!options.find(s => s === this.connectionState)
  }

  /**
   * Adds or removes a UI dependency for closing a session.  Dependencies should be added
   * whenever actions need to be performed from the UI prior to final session wrap-up and log updates.
   * They should then be removed when those actions have been completed or are no longer necessary.
   * @param action Whether a dependency should be added or removed.
   */
  updateCloseDependency(action: "add" | "remove") {
    this.logger.debug(`updateCloseDependency: Entered with action ${action}`)
    this.closeDependencies.next(
      this.closeDependencies.value + (action === "add" ? 1 : -1)
    )
  }

  /* Session start */

  async generatePin(
    deviceIdentifier: string,
    reachEnvironment: { api: string; key: string }
  ): Promise<void> {
    this.userRole = UserRole.Agent
    this.peerRole = UserRole.Tech
    this.connectionState = ConnectionState.connecting
    await this.bindNewClient(reachEnvironment.api, reachEnvironment.key)

    try {
      this.pin = await this.rs!.initiateSupportSession()
      this.deviceIdentifier = deviceIdentifier
    } catch (error) {
      this.connectionState = ConnectionState.closed
      return
    }

    this.connectionState = ConnectionState.hasPin
  }

  private async bindNewClient(api: string, key: string): Promise<void> {
    await this.cleanUpClient(true)
    this.rs = new RemoteSupportClient(
      api,
      key,
      SessionDefaults.webRtcTimeout,
      this.logger
    )

    this.rs.onConnect.subscribe(this.connectHandler)
    this.rs.onDisconnect.subscribe(this.connectionLostHandler)
    this.rs.onCommand.subscribe(this.commandHandler)
    this.rs.onNotification.subscribe(this.notificationHandler)
    this.rs.onPartialMessage.subscribe(this.partialMessageHandler)
    this.rs.onExpectedDisconnect.subscribe(this.closeHandler)
    this.rs.onVideoStreamAdded.subscribe(this.videoStreamHandler)
  }

  async sendInviteEmail(email: string, message: string): Promise<void> {
    return this.rs!.sendEmail(message, email, "AKIAZUALYOJKKUTXLR3B", "2XOjJC2r/YETzqQZWjFA+fH6aFKZZ/vIOTgtOwxm").then(
      ret => {
        const code: number = ret.$response.httpResponse.statusCode
        if (code >= 200 && code < 300) {
          return
        } else {
          throw new Error(
            `Could not send invitation email, got HTTP response of ${code}`
          )
        }
      },
      error => {
        throw new Error(`Error processing email: ${error}`)
      }
    )
  }

  async sendInviteSMS(phone: string, message: string): Promise<void> {
    return this.rs!.sendSMS(message, phone, "AKIAZUALYOJKKUTXLR3B", "2XOjJC2r/YETzqQZWjFA+fH6aFKZZ/vIOTgtOwxm").then(
      ret => {
        const code: number = ret.$response.httpResponse.statusCode
        if (code >= 200 && code < 300) {
          return
        } else {
          throw new Error(
            `Could not send invitation message, got HTTP response of ${code}`
          )
        }
      },
      error => {
        throw new Error(`Error processing message: ${error}`)
      }
    )
  }

  async connect(pin: string, reachEnvironment: { api: string; key: string }) {
    this.userRole = UserRole.Tech
    this.peerRole = UserRole.Agent
    if (this.connectionState === ConnectionState.connected) {
      this.logger.error(`connect: Hit in connected state`)
      return
    }

    const requiresSetup = this.connectionState !== ConnectionState.reconnecting
    this.connectionState = ConnectionState.connecting
    await this.bindNewClient(reachEnvironment.api, reachEnvironment.key)
    try {
      await this.rs!.connectToSupportSession(pin)
      if (requiresSetup) {
        this.logger.debug(`connect: creating Session object`)
        this.activeSession = new ActiveSession({
          date: new Date(),
          pin: this.pin!,
          deviceID: this.deviceIdentifier,
          customData: this.customData
        })

        if (this.recordHistory && this.rsService) {
          this.rsService
            .uploadLogData(this.activeSession.toSessionLog())
            .subscribe(ret =>
              this.logger.debug(
                `onConnect: uploadLogData got status of ${ret.status}`
              )
            )
          this.interval = setInterval(
            this.intervalHandler.bind(this),
            this.logFrequency
          )
        } else {
          this.logger.debug(
            `connect: If recordHistory were set, would upload log to the history bucket:\n${JSON.stringify(
              this.activeSession.toSessionLog()
            )}`
          )
        }
      }
    } catch (error) {
      this.connectionState = ConnectionState.closed
      throw error
    }
  }

  private onConnect(): void {
    if (this.connectionState === ConnectionState.reconnecting) {
      this.logger.debug(`onConnect: Hit while in reconnecting state`)
    } else if (this.connectionState === ConnectionState.connected) {
      this.logger.debug(`onConnect: Hit while in connected state`)
      return
    } else {
      this.logger.debug(`onConnect: creating Session object`)
      this.activeSession = new ActiveSession({
        date: new Date(),
        pin: this.pin!,
        deviceID: this.deviceIdentifier,
        customData: this.customData
      })

      if (this.recordHistory && this.rsService) {
        this.rsService
          .uploadLogData(this.activeSession.toSessionLog())
          .subscribe(ret =>
            this.logger.debug(
              `onConnect: uploadLogData got status of ${ret.status}`
            )
          )
      } else {
        this.logger.debug(
          `onConnect: Should upload log to the history bucket:\n${JSON.stringify(
            this.activeSession.toSessionLog()
          )}`
        )
      }

      this.interval = setInterval(
        this.intervalHandler.bind(this),
        this.logFrequency
      )
    }
    this.connectionState = ConnectionState.connected
  }

  /* Session end */

  async disconnect(): Promise<void> {
    if (this.closeDependencies.value > 0) {
      this.closeDependencies
        .pipe(
          takeUntil(
            this.connectionStateSubject.pipe(
              filter(state => state === ConnectionState.closed)
            )
          )
        )
        .subscribe(remaining => {
          if (remaining === 0) {
            this.closeHelper(true)
          }
        })
      this.closeInitiated.next(this.userRole)
    } else {
      this.closeInitiated.next(this.userRole)
      this.closeHelper(true)
    }
  }

  private async onClose(): Promise<void> {
    if (this.connectionState === ConnectionState.closed) {
      return
    }

    if (this.closeDependencies.value > 0) {
      this.closeDependencies
        .pipe(
          takeUntil(
            this.connectionStateSubject.pipe(
              filter(state => state === ConnectionState.closed)
            )
          )
        )
        .subscribe(remaining => {
          if (remaining === 0) {
            this.closeHelper(false)
          }
        })
      this.closeInitiated.next(this.peerRole)
    } else {
      this.closeInitiated.next(this.peerRole)
      this.closeHelper(false)
    }
  }

  /**
   * Private helper function to take care of common session close functions.
   * @param initiated Whether the calling side initiated the session close, determining log updates and if a disconnect signal will be sent.
   */
  private closeHelper(initiated: boolean) {
    if (this.activeSession) {
      this.updateLog(initiated ? this.userRole : this.peerRole)
    }
    this.connectionState = ConnectionState.closed
    this.cleanUpClient(initiated)
  }

  /**
   * Clears data generated in the session and closes existing streams (semi-)gracefully.
   * This is called in {@link cleanUpClient}, and thus does not need to be manually called on session end.
   */
  private clearData() {
    // Asset blob cleanup
    if (this.activeSession && this.activeSession.assets) {
      this.activeSession.assets.forEach(wrapper => {
        wrapper.cleanupAsset()
      })
    }

    // Stream cleanup
    (this.streams.filter(s => s !== undefined) as Array<
      UIServiceStream
    >).forEach(stream => {
      if (stream.recording && stream.recording.activeData) {
        stream.recording.activeData.recorder.stop()
      }
      stream.recording = undefined
      stream.stream.getTracks().forEach(t => t.stop())
    })
    this.streams.forEach((v, index) => (this.streams[index] = undefined))

    // Data cleanup
    this.activeSession = undefined
    this.pin = undefined
  }

  /**
   * Ends core session functions and disconnects from the remote support session.
   * If actions need to be performed in the UI prior to disconnection, they are allowed to be performed prior to full disconnection.
   * @param sendDisconnect Whether a signal should be sent to the other side of the connection to disconnect.
   * This should align with whether the caller is initiating the disconnect.
   */
  private async cleanUpClient(sendDisconnect: boolean = false): Promise<void> {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = undefined
    }
    this.clearData()
    if (this.rs) {
      if (this.rs.isConnected) {
        await this.rs.disconnect(sendDisconnect)
      }
      if (!true) {
        this.rs.close()
        this.rs = undefined
      }
      this.rs = undefined
    }
  }

  /* Session connection loss */

  private onConnectionLoss(): void {
    this.connectionState = ConnectionState.reconnecting

    // TODO: End streams locally on connection loss, both sides will do this in this instance
    this.streams.forEach((stream, index) => {
      if (stream) {
        this.endShareHelper(index)
      }
    })
  }

  /* Received message and notification handling */

  /*
    Note that this implementation supports multiple oPM events per message.
    The onPartialMessage documentation implies that the system works like this,
    but drilling into the source code shows that the underlying event is only
    generated on the first chunk.
  */
  private async onPartialMessage(ev: PartialMessageReceivedEventArgs) {
    if (!this.activeSession) {
      return
    }

    const isRequestedMedia = [
      NotificationType.RequestedScreenshot,
      NotificationType.RequestedRecording
    ].includes(ev.category)

    const isMessage = [
      NotificationType.Chat,
      NotificationType.Image,
      NotificationType.Video
    ].includes(ev.category)

    if (isMessage) {
      this.messageHandler(ev)
    } else if (isRequestedMedia) {
      this.requestedMediaHandler(ev)
    }
  }

  private async onNotification(notification: RSNotification): Promise<void> {
    // Break up notification logic into general categories
    const mt = NotificationType
    const messageCategories = [mt.Image, mt.Video, mt.Chat]
    const agentMediaCategories = [
      mt.RequestedScreenshot,
      mt.RequestedRecording
    ]

    if (messageCategories.includes(notification.category)) {
      this.messageHandler(notification)
    } else if (agentMediaCategories.includes(notification.category)) {
      this.requestedMediaHandler(notification)
    } else if (notification.category === mt.Drawing) {
      this.drawHandler(notification)
    }
  }

  private onCommand(command: ICommandEventArgs) {
    const captureCategories = [
      CommandType.CaptureScreenshot,
      CommandType.RecordVideo,
      CommandType.RecordVideoEnd
    ]
    const shareCategories = [
      CommandType.StartShare,
      CommandType.EndShare,
      CommandType.SwapTool,
      CommandType.UndoPath
    ]

    if (captureCategories.includes(command.command.category)) {
      this.captureHandler(command)
    }

    if (shareCategories.includes(command.command.category)) {
      this.shareHandler(command)
    }
  }

  private messageHandler(
    notification: RSNotification | PartialMessageReceivedEventArgs
  ) {
    if (!this.activeSession) {
      return
    }

    // Determine if this is media
    const isMedia = notification.category !== NotificationType.Chat

    // Get partial message for reference, if it exists
    let i: number
    let partial: PartialMessage | undefined
    if (
      (i = this.activeSession.messages.findIndex(
        m => m instanceof PartialMessage && m.id === notification.msgid
      )) >= 0
    ) {
      partial = this.activeSession.messages[i] as PartialMessage
    }

    // Divide logic into partial and complete message handling
    if (notification instanceof PartialMessageReceivedEventArgs) {
      if (partial) {
        partial.update(notification)
        if (partial.message instanceof AssetWrapper) {
          this.updateAssetsPartial(partial.message, "update")
        }
      } else {
        const newPartial = new PartialMessage(notification)
        this.activeSession.messages.push(newPartial)
        if (newPartial.message instanceof AssetWrapper) {
          this.updateAssetsPartial(newPartial.message, "add")
        }
      }
    } else {
      let message: Message
      if (isMedia) {
        const refDate = partial ? partial.timestamp : new Date()
        const media = new MediaFile(
          this.sanitizer,
          notification.data.tag,
          notification.data.data,
          MediaFileCategory.received,
          refDate
        )
        let asset: AssetWrapper<MediaFile>
        if (partial && partial.message instanceof AssetWrapper) {
          partial.message.replaceAsset(media)
          asset = partial.message as AssetWrapper<MediaFile>
          this.updateAssetsPartial(asset, "transfer")
        } else {
          asset = new AssetWrapper<MediaFile>(media, true, true)
          this.updateAssets(asset, "add")
        }
        message = new Message(false, asset, false, refDate, notification.msgid)
      } else {
        const text = new TextDecoder("utf-8").decode(notification.data.data)
        message = new Message(
          false,
          text,
          false,
          partial ? partial.timestamp : new Date(),
          notification.msgid
        )
      }

      if (partial) {
        this.activeSession.messages.splice(i, 1, message)
      } else {
        this.activeSession.messages.push(message)
      }
      this.updateLog()
    }
    this.markMessagesChanged(this.activeSession)
  }

  private requestedMediaHandler(
    notification: RSNotification | PartialMessageReceivedEventArgs
  ) {
    if (!this.activeSession) {
      return
    }

    // Search for previous versions to update
    let i: number
    let partial: AssetWrapper<PartialMediaFileMetadata> | undefined
    if (
      this.activeSession.assets.length &&
      (i = this.activeSession.assets.findIndex(
        a =>
          a.asset instanceof PartialMediaFileMetadata &&
          a.asset.id === notification.msgid
      )) >= 0
    ) {
      partial = this.activeSession.assets[i] as AssetWrapper<
        PartialMediaFileMetadata
      >
    }

    const refDate = partial ? partial.asset.timestamp : new Date()

    if (notification instanceof PartialMessageReceivedEventArgs) {
      if (partial) {
        partial.asset.update(notification.received)
        this.updateAssetsPartial(partial, "update")
      } else {
        const partialFile = new PartialMediaFileMetadata(
          notification.category === NotificationType.RequestedScreenshot
            ? "image"
            : "video",
          MediaFileCategory.capture,
          refDate,
          notification.msgid,
          notification.received,
          notification.length
        )
        this.updateAssetsPartial(
          new AssetWrapper<PartialMediaFileMetadata>(partialFile, false, true),
          "add"
        )
      }
    } else {
      const media = new MediaFile(
        this.sanitizer,
        notification.data.tag,
        notification.data.data,
        MediaFileCategory.capture,
        refDate
      )
      if (partial) {
        // Remove additional type restrictions for update
        const assetWrapper = partial as AssetWrapper<MediaFileMetadata>
        assetWrapper.replaceAsset(media)
        this.updateAssetsPartial(
          assetWrapper as AssetWrapper<MediaFile>,
          "transfer"
        )
        this.captureSubjects.captureReceived.next(assetWrapper)
      } else {
        const newWrapper = new AssetWrapper<MediaFile>(media, false, true)
        this.updateAssets(newWrapper, "add")
        this.captureSubjects.captureReceived.next(newWrapper)
      }
    }
  }

  private captureHandler(command: ICommandEventArgs) {
    if (!this.activeSession) {
      return
    }

    const type = command.command.data.data[0] as ShareType
    const data = { shareType: type, data: command.context } as ShareContext<
      ICommandContext
    >

    switch (command.command.category) {
      case CommandType.RecordVideo:
        this.startRecording(type, command.context)
        break
      case CommandType.RecordVideoEnd:
        this.endRecording(type, command.context)
        break
      case CommandType.CaptureScreenshot:
        this.captureSubjects.screenshotRequested.next(data)
        break
      default:
        command.context.error(
          0,
          "captureHandler unexpectedly got this command."
        )
        return
    }
  }

  private drawHandler(event: RSNotification) {
    const arr = Array.from(event.data.data)
    const type = arr.shift()!
    const data = arr.map(coord => coord / 255.0)
    this.drawSegment.next({
      shareType: type,
      data: {
        start: { x: data[0], y: data[1] },
        end: { x: data[2], y: data[3] }
      }
    })
  }

  private shareHandler(event: ICommandEventArgs) {
    if (!this.activeSession) {
      return
    }

    const type = event.command.data.data[0] as ShareType
    const data = { shareType: type, data: event.context } as ShareContext<
      ICommandContext
    >
    switch (event.command.category) {
      case CommandType.StartShare:
        this.startShareReceipt.next(data)
        break
      case CommandType.EndShare:
        this.endShare(type, event)
        break
      case CommandType.SwapTool:
      case CommandType.UndoPath:
        this.logger.warn(
          `Got unhandled share command type: ${
            event.command.category === CommandType.SwapTool ? "Swap" : "Undo"
          }.`
        )
        event.context.error(0, "This command is not currently supported.")
        break
      default:
        this.logger.warn(
          `Got unexpected command type: ${event.command.category}`
        )
        event.context.error(0, "shareHandler unexpectedly got this command.")
        return
    }
  }

  private intervalHandler() {
    this.updateLog(undefined, true)
  }

  private async onVideoStreamAdded(track: RemoteSupportVideoTrack) {
    const stream = { stream: track.stream, provider: this.peerRole }
    let type: ShareType
    switch (track.kind) {
      case "camera":
        this.streams[ShareType.Video] = stream
        type = ShareType.Video
        break
      case "screen":
        this.streams[ShareType.Screen] = stream
        type = ShareType.Screen
        break
      default:
        // Based on the current implementation, assume that the agent side receives a video share, and tech a screen share
        if (this.userRole === UserRole.Agent) {
          this.streams[ShareType.Video] = stream
          type = ShareType.Video
        } else {
          this.streams[ShareType.Screen] = stream
          type = ShareType.Screen
        }
    }
    this.streamChange.next({ shareType: type, data: this.streams[type] })
  }

  /* User action handlers */

  /* Messaging */

  /**
   * Sends a message with the provided data to the peer.
   * Updates the active session's assets and messages appropriately.
   * @param data A string representing a text-based message to be sent, or for file attachments, a {@link Uint8Array} or {@link ArrayBuffer} containing the raw file data.
   * @param type The MIME type of the provided data, not necessary for text messages, defaults to 'text/plain'.
   * @param addToMedia Whether file attachments should be added to the media list, defaults to true.
   * @returns The {@link Message} that was sent.
   */
  async sendMessage(
    data: string | Uint8Array | ArrayBuffer,
    type: string = "text/plain",
    addToMedia: boolean = true
  ): Promise<Message | undefined> {
    if (!this.activeSession) {
      return
    }

    // Shared data for text and media messages
    let formattedData: string | AssetWrapper<MediaFile>
    let id: number
    let refDate: Date

    if (data instanceof Uint8Array || data instanceof ArrayBuffer) {
      id = await this.sendDataHelper(data, type)
      refDate = new Date()
      const media = new MediaFile(
        this.sanitizer,
        type,
        data,
        MediaFileCategory.sent,
        refDate
      )
      formattedData = new AssetWrapper<MediaFile>(media, true, addToMedia)
    } else {
      formattedData = data
      id = await this.rs!.sendChat(formattedData)
      refDate = new Date()
    }
    const message = new Message(true, formattedData, true, refDate, id)
    this.activeSession.messages.push(message)
    if (formattedData instanceof AssetWrapper) {
      this.updateAssets(formattedData, "add")
    }
    this.markMessagesChanged(this.activeSession)
    this.updateLog()
    return message
  }

  /**
   * Marks the provided list of messages as read/unread.
   * @param messages The messages to be updated.  If not provided, the active session's messages are used.
   * @param read Whether the messages should be marked as read or unread.  Defaults to true (read).
   */
  markRead(messages?: Array<ActiveMessageMetadata>, read: boolean = true) {
    if (messages) {
      messages.forEach(message => {
        message.isRead = read
      })
    } else {
      if (!this.activeSession) {
        return
      }
      this.activeSession.messages.forEach(message => (message.isRead = read))
    }
    // TODO: Mark messages updated?  Array itself unchanged, just the deeper properties, so depends on if we're in onPush
  }

  /**
   * Helper function for sending data as a notification
   * @param data The data to be sent
   * @param type The media type associated with the data
   * @param internal If the message is to be sent on the non-message data channel (e.g. requested screenshots), defaults to false
   * @returns A promise of the id associated with the sent notification
   */
  private sendDataHelper(
    data: Uint8Array | ArrayBuffer,
    type: string,
    internal: boolean = false
  ): Promise<number> {
    // Ensure type conversion to Uint8Array
    if (data instanceof ArrayBuffer) {
      data = new Uint8Array(data)
    }

    let category: NotificationType
    switch (type.split("/")[0]) {
      case "image":
        category = internal
          ? NotificationType.RequestedScreenshot
          : NotificationType.Image
        break
      case "video":
        category = internal
          ? NotificationType.RequestedRecording
          : NotificationType.Video
        break
      default:
        category = NotificationType.Bytes
    }
    const notifData = new RSTaggedData(type, data as Uint8Array)
    return this.rs!.sendNotification(
      RSNotification.create(category, notifData)
    )
  }

  /* Session resources (media, notes) */

  /**
   * Sends a provided media file as a file attachment to the peer.
   * The media file is cloned as a newly-sent file and added to the active session's assets, and is not marked as being in media.
   * @param media The media file to be sent.
   * @param retainOriginal Whether the original media file will be retained for further use, with the data URL being unassigned if not.  Defaults true.
   */
  async sendMedia(media: MediaFile, retainOriginal: boolean = true) {
    if (!this.activeSession) {
      return
    }
    // - Asset should really be cloned to ensure there aren't conflicts, especially because its category has changed
    const refDate = new Date()
    const newWrapper = new AssetWrapper<MediaFile>(
      media.cloneFile(this.sanitizer, {
        category: MediaFileCategory.sent,
        timestamp: refDate,
        edited: false
      }),
      true,
      false
    )

    if (!retainOriginal) {
      media.cleanup()
    }

    const id = await this.sendDataHelper(
      newWrapper.asset.data,
      newWrapper.asset.type
    )
    const message = new Message(true, newWrapper, true, refDate, id)
    this.activeSession.messages.push(message)
    this.updateAssets(newWrapper, "add", true)

    this.markMessagesChanged(this.activeSession)
  }
  /**
   * Appropriately updates the assets of a session when a file is edited.  This means already-edited files are overwritten,
   * and newly-edited files are removed from media with an edited copy added in their place.
   * @param wrapper The original asset to be modified, be it overwritten in place or cloned with new data.
   * @param data The new raw image data associated with the edited asset
   * @param useActive Whether the active session should be used, defaulting to true
   */
  saveMediaEdits(
    wrapper: AssetWrapper<MediaFile>,
    data: Uint8Array | ArrayBuffer,
    useActive: boolean = true
  ): AssetWrapper<MediaFile> {
    if (!wrapper.asset.edited) {
      const newWrapper = new AssetWrapper<MediaFile>(
        wrapper.asset.cloneFile(this.sanitizer, { data: data, edited: true }),
        wrapper.inMessage,
        wrapper.inMedia
      )
      wrapper.inMedia = false
      if (!wrapper.inMessage) {
        this.updateAssets(wrapper, "delete", useActive)
      }
      this.updateAssets(newWrapper, "add", useActive)
      return newWrapper
    } else {
      wrapper.asset.update(this.sanitizer, { data: data })
      this.updateAssets(wrapper, "update", useActive)
      return wrapper
    }
  }
  /**
   * Removes an asset from a session's media list, and deletes it if there are no surviving references.
   * @param wrapper An {@link AssetWrapper} referencing the media to be deleted.
   * @param useActive Whether the active session should be used.  Defaults to true.
   */
  deleteMedia(wrapper: AssetWrapper<MediaFile>, useActive: boolean = true) {
    // Probably should catch errors here to gracefully pass to UI
    this.updateAssets(wrapper, "delete", useActive)
  }

  // Save note
  // TODO: Confirm we want to handle note metadata logic here
  saveNote(note: string, useActive: boolean = true, editor?: string) {
    const session = useActive ? this.activeSession : this.viewSession
    if (!session) {
      return
    }

    session.note.setNote(note)
    if (editor) {
      session.note.setEditor(editor)
    }
    session.note.setDate(new Date())

    this.updateLog(undefined, useActive)
  }

  /* Sharing */

  // Send share request
  requestShare(type: ShareType): Promise<ICommandReceipt> {
    const data = newIntData(type)
    const command = RSCommand.create(
      CommandType.StartShare,
      data,
      AcknowledgeOn.Finished
    )
    return this.rs!.sendCommand(command)
  }
  // Start share
  async startShare(type: ShareType, stream: MediaStream) {
    let added = false
    if (type === ShareType.Video) {
      added = await this.rs!.addVideoStream(stream)
    } else {
      added = await this.rs!.addScreenStream(stream)
    }

    if (added) {
      if (this.streams[type]) {
        this.endShareHelper(type, false)
      }

      const streamObj: UIServiceStream = {
        stream: stream,
        provider: this.userRole
      }
      this.streams[type] = streamObj
      this.streamChange.next({ shareType: type, data: streamObj })
    } else {
      // TODO: Pass this failure to the UI in some way
    }
  }
  // End share
  async endShare(type: ShareType, incomingCommand?: ICommandEventArgs) {
    const stream = this.streams[type]
    if (!stream) {
      this.logger.error(`endShare: Stream already ended!`)
      return
    }
    const isProvider = stream.provider === this.userRole

    // End share requests
    if (incomingCommand) {
      if (isProvider) {
        if (incomingCommand.command.acknowledgeOn !== AcknowledgeOn.Finished) {
          this.logger.error(
            `endShare: Got acknowledgeOn other than finished when requested as stream provider`
          )
        }
        try {
          this.endShareHelper(type)
          await incomingCommand.context.acknowledge()
        } catch (err) {
          incomingCommand.context.error(100, `Failed to end stream: ${err}`)
        }
      } else {
        if (incomingCommand.command.acknowledgeOn !== AcknowledgeOn.Received) {
          this.logger.error(
            `endShare: Got acknowledgeOn other than received when requested as stream receiver`
          )
        }
        try {
          this.endShareHelper(type)
        } catch (err) {
          throw new Error(`Failed to end stream locally: ${err}`)
        }
      }
    } else {
      const typeData = newIntData(type)
      if (isProvider) {
        try {
          this.endShareHelper(type)
          const command = RSCommand.create(
            CommandType.EndShare,
            typeData,
            AcknowledgeOn.Received
          )
          await this.rs!.sendCommand(command)
        } catch (err) {
          throw new Error(`Failed to end stream or send command: ${err}`)
        }
      } else {
        try {
          const command = RSCommand.create(
            CommandType.EndShare,
            typeData,
            AcknowledgeOn.Finished
          )
          const receipt = await this.rs!.sendCommand(command)

          await receipt
            .wait()
            .catch(err => {
              throw err
            })
            .finally(() => {
              this.endShareHelper(type)
            })
        } catch (err) {
          throw new Error(`End stream command process failed: ${err}`)
        }
      }
    }
  }

  /**
   * Handles basic cleanup of streams.
   * @param type The stream to be ended.
   * @param markChange If {@link streamChange} should send out this update.  Defaults to true.
   *
   * @throws If stream is undefined.
   */
  private endShareHelper(type: ShareType, markChange: boolean = true) {
    const stream = this.streams[type]
    if (stream) {
      if (stream.recording) {
        this.endRecording(type)
      }
      stream.stream.getTracks().forEach(track => track.stop())
      this.streams[type] = undefined
      if (markChange) {
        this.streamChange.next({ shareType: type, data: undefined })
      }
    } else {
      throw new Error("Stream was undefined")
    }
  }

  // Send drawing point
  addDrawingSegment(segment: DrawingSegment, type: ShareType) {
    this.drawSegment.next({ shareType: type, data: segment })

    const data = new RSTaggedData(
      DefaultTag.Object,
      new Uint8Array(
        [type].concat(
          [segment.start.x, segment.start.y, segment.end.x, segment.end.y].map(
            val => Math.max(0, Math.min(255, val * 255))
          )
        )
      )
    )
    this.rs!.sendNotification(
      new RSNotification(0, NotificationType.Drawing, data)
    )
  }
  // TODO: Handle tool changes if we're continuing to support them

  // Take screenshot
  async requestScreenshot(type: ShareType) {
    if (!this.streams[type]) {
      return
    }
    // If stream is being provided by this side of the connection, pass directly back to UI to handle
    if (this.streams[type]!.provider === this.userRole) {
      this.captureSubjects.screenshotAcknowledged.next({
        shareType: type,
        data: undefined
      })
    } else {
      const command = RSCommand.create(
        CommandType.CaptureScreenshot,
        newIntData(type),
        AcknowledgeOn.Finished
      )
      const receipt = await this.rs!.sendCommand(command)
      this.captureSubjects.screenshotAcknowledged.next({
        shareType: type,
        data: receipt
      })
    }
  }

  async sendScreenshot(data: Uint8Array | ArrayBuffer, mimeType: string) {
    await this.sendDataHelper(data, mimeType, true)
  }
  // Start recording
  startRecording(type: ShareType, fromRequest?: ICommandContext) {
    const stream = this.streams[type]
    if (!stream) {
      return
    }
    // If stream is being provided by this side of the connection, begin recording
    if (stream.provider === this.userRole) {
      if (!stream.recording) {
        const mimeType = MediaRecorder.isTypeSupported(MimeType.mp4)
          ? MimeType.mp4
          : `${MimeType.webm}; codecs="vp8"`
        const options = {
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 2500000,
          mimeType: mimeType
        }
        const mediaRecorder = new MediaRecorder(stream.stream, options)

        mediaRecorder.start()
        const recordStart = performance.now()
        const timeout = setTimeout(() => this.endRecording(type), 30000)
        stream.recording = {
          requester: fromRequest ? this.peerRole : this.userRole,
          startTime: recordStart,
          activeData: {
            recorder: mediaRecorder,
            timeout: timeout
          }
        }

        if (fromRequest) {
          fromRequest.acknowledge()
        } else {
          // Send a "record video" command to signal UI to block recording and put up indicator, no acknowledgement necessary
          this.rs!.sendCommand(
            RSCommand.create(
              CommandType.RecordVideo,
              newIntData(type),
              AcknowledgeOn.Received
            )
          )
        }

        // dataavailable event will only be fired once, immediately after stop()
        stream.recording.activeData!.recorder.ondataavailable = e => {
          if (stream.recording && stream.recording.activeData) {
            const activeData = stream.recording.activeData
            activeData.data = e.data
            clearTimeout(activeData.timeout)
            activeData.refTime = performance.now() - stream.recording.startTime
          }
        }

        // stop event will be fired directly after single ondataavailable, handle conversions and notifications here
        stream.recording.activeData!.recorder.onstop = e => {
          if (stream.recording && stream.recording.activeData) {
            this.completeRecording(type)
          }
        }
      } else {
        this.logger.error(`startRecording: Hit while already recording`)
        return
      }
    } else {
      if (fromRequest) {
        // This request will not require acknowledgement, just telling the UI to update its display for this
        stream.recording = {
          requester: this.peerRole,
          startTime: performance.now()
        }
        return
      }
      this.rs!.sendCommand(
        RSCommand.create(
          CommandType.RecordVideo,
          newIntData(type),
          AcknowledgeOn.Finished
        )
      ).then(
        receipt => {
          receipt
            .wait()
            .then(() => {
              stream.recording = {
                requester: this.userRole,
                startTime: performance.now()
              }
            })
            .catch(err => {
              throw new Error(
                `Error while waiting for acknowledgement: ${err}`
              )
            })
        },
        err => {
          this.logger.error(`startRecording: Unable to start recording.`, err)
          throw new Error(`Error while sending command: ${err}`)
        }
      )
    }
  }

  private async completeRecording(type: ShareType) {
    const stream = this.streams[type]
    if (
      !stream ||
      !stream.recording ||
      !stream.recording.activeData ||
      !stream.recording.activeData.data
    ) {
      this.logger.error(`completeRecording: Hit without accessible data.`)
      return
    }

    let data = stream.recording.activeData.data
    this.logger.debug(
      `completeRecording: Data blob has MIME type of "${data.type}"`
    )

    if (data.type.includes("webm")) {
      if (!stream.recording.activeData.refTime) {
        this.logger.error(`completeRecording: Got undefined recording refTime`)
        return
      }
      // .webm recordings need additional metadata generated for reasonable display
      fixWebmDuration(data, stream.recording.activeData.refTime, {
        logger: false
      }).then((fixed: Blob) => {
        data = fixed
        // @ts-ignore: Blob generally allows arrayBuffer(), supported by all modern browsers since 2020 according to MDN docs
        data.arrayBuffer().then(ab => {
          if (!stream.recording || !stream.recording.activeData) {
            return
          }

          this.distributeRecording(ab, data.type, type)

          clearTimeout(stream.recording.activeData.timeout)
          stream.recording = undefined
        })
      })
    } else {
      // @ts-ignore: Blob generally allows arrayBuffer(), supported by all modern browsers since 2020 according to MDN docs
      data.arrayBuffer().then(ab => {
        if (!stream.recording || !stream.recording.activeData) {
          return
        }

        this.distributeRecording(ab, data.type, type)

        clearTimeout(stream.recording.activeData.timeout)
        stream.recording = undefined
      })
    }
  }

  /**
   * Helper function for distributing a processed recording properly.
   * This means updating the UI-facing subject or sending a video capture notification.
   * @param data The ArrayBuffer containing the recording data
   * @param mimeType The media type of data
   * @param shareType The type of share the recording is associated with
   */
  private async distributeRecording(
    data: ArrayBuffer,
    mimeType: string,
    shareType: ShareType
  ) {
    const stream = this.streams[shareType]
    if (!stream || !stream.recording || !stream.recording.activeData) {
      this.logger.error(`distributeRecording: Hit without accessible data.`)
      return
    }

    if (stream.recording.requester === this.userRole) {
      const media = new MediaFile(
        this.sanitizer,
        mimeType,
        data,
        MediaFileCategory.capture,
        new Date()
      )
      this.captureSubjects.recordingReady.next({
        shareType: shareType,
        data: media
      })
    } else {
      this.sendDataHelper(data, mimeType, true)
    }
  }

  // End recording
  endRecording(type: ShareType, commanded?: ICommandContext) {
    const stream = this.streams[type]
    if (!stream || !stream.recording) {
      return
    }

    // Provider case
    if (stream.recording.activeData) {
      // Stop recording in all cases
      stream.recording.activeData.recorder.stop()
      if (commanded) {
        commanded.acknowledge()
      } else {
        this.rs!.sendCommand(
          RSCommand.create(
            CommandType.RecordVideoEnd,
            newIntData(type),
            AcknowledgeOn.Received
          )
        )
      }
    } else {
      if (commanded) {
        // This command does not require acknowledgement, just for updating the UI
        stream.recording = undefined
      } else {
        this.rs!.sendCommand(
          RSCommand.create(
            CommandType.RecordVideoEnd,
            newIntData(type),
            AcknowledgeOn.Finished
          )
        ).then(
          receipt => {
            receipt
              .wait()
              .then(() => (stream.recording = undefined))
              .catch(err => {
                throw new Error(
                  `Error while waiting for acknowledgement: ${err}`
                )
              })
          },
          err => {
            this.logger.error(`endRecording: Unable to end recording.`, err)
            throw new Error(`Error while sending command: ${err}`)
          }
        )
      }
    }
  }

  /* Concurrency handlers */

  // TODO: Unclear why this function was never completed or used, presumably would help centralize partial message handling?
  private updateActiveMessages(
    target: ActiveMessageMetadata,
    change?: number | Message
  ) {
    if (!this.activeSession) {
      return
    }

    // Updating existing message
    if (change) {
      if (change instanceof Message) {
        const index = this.activeSession.messages.findIndex(
          m => m instanceof PartialMessage && change.id === m.id
        )
        if (index >= 0) {
          this.activeSession.messages.splice(index, 1, change)
          // Call asset change handler for addition of
        }
      } else {
      }
    } else {
      this.activeSession.messages.push(target)
      if (target instanceof Message && target.message instanceof AssetWrapper) {
        // Call similar handler for assets with target's asset
      }
    }
    this.markMessagesChanged(this.activeSession)
  }

  // The type handling here is messy, could potentially be improved as a generic function, or by splitting into two functions
  private updateAssetsPartial(
    newAsset: AssetWrapper<MediaFileMetadata>,
    action: "add" | "update" | "transfer",
    useActive: boolean = true
  ) {
    const session = useActive ? this.activeSession : this.viewSession
    const forbiddenPartialType =
      session instanceof ActiveSession
        ? ArchivedMediaFileMetadata
        : PartialMediaFileMetadata
    if (!session) {
      return
    }
    // Block adding full MediaFiles
    if (action === "add" && newAsset.asset instanceof MediaFile) {
      this.logger.error(
        `updateAssetsPartial: Attempted to add MediaFile asset`
      )
      return
    }
    // Block active/archived type mismatches
    if (newAsset.asset instanceof forbiddenPartialType) {
      this.logger.error(
        `updateAssetsPartial: Attempted to use wrong type for ${
          useActive ? "active" : "archived"
        } session`
      )
      return
    }

    if (session instanceof ActiveSession) {
      const typedNew = newAsset as AssetWrapper<
        MediaFile | PartialMediaFileMetadata
      >
      switch (action) {
        case "add":
          session.assets.push(typedNew)
          break
        case "update":
          // No changes to assets array itself
          break
        case "transfer":
          if (typedNew.asset instanceof PartialMediaFileMetadata) {
            this.logger.error(
              `updateAssetsPartial: Attempted to transfer PartialMediaFileMetadata asset`
            )
            return
          }
          this.updateAssets(
            typedNew as AssetWrapper<MediaFile>,
            "update",
            true
          )
          break
      }
    } else {
      const typedNew = newAsset as AssetWrapper<
        MediaFile | ArchivedMediaFileMetadata
      >
      switch (action) {
        case "add":
          session.assets.push(typedNew)
          break
        case "update":
          // No changes to assets array itself
          break
        case "transfer":
          if (typedNew.asset instanceof PartialMediaFileMetadata) {
            this.logger.error(
              `updateAssetsPartial: Attempted to transfer SessionMediaFileMetadata asset`
            )
            return
          }
          this.updateAssets(
            typedNew as AssetWrapper<MediaFile>,
            "update",
            false
          )
          break
      }
    }
    this.markAssetsChanged(session)
  }

  /* Session history systems */

  /**
   * When a complete asset is modified in a session, this function is called to ensure this change is reflected in the session object.
   * This is intended as a low-level function, so concepts like newly-edited files replacing their original version in media are not enforced here.
   * @param wrapper The {@link AssetWrapper} to be changed in the asset array, with data and flags updated as necessary
   * @param action The action to be performed on the file: add new file, update existing, or delete
   * @param useActive Default is true.  Whether the active session should be updated (otherwise using the session being viewed in session history)
   */
  private updateAssets(
    wrapper: AssetWrapper<MediaFile>,
    action: "add" | "update" | "delete",
    useActive: boolean = true
  ) {
    const session: Session<CustomData> = useActive
      ? this.activeSession!
      : this.viewSession!
    const path = `${session.path.join("~")}~${wrapper.asset.fileName}`

    // Case where session updates are only made upon successful changes in cloud
    if (this.recordHistory && this.rsService && !useActive) {
      switch (action) {
        case "delete":
          if (!wrapper.inMessage) {
            this.rsService
              .deleteMediaFile(path)
              .pipe(first())
              .subscribe(
                () => {
                  // For archived sessions, only remove file from the log upon successful deletion
                  const assets = session.assets
                  const removeIndex = assets.findIndex(
                    asset => asset === wrapper
                  )
                  if (removeIndex >= 0) {
                    assets.splice(removeIndex, 1)
                    wrapper.cleanupAsset()
                    this.updateLog(undefined, useActive)
                    this.markAssetsChanged(session)
                  }
                },
                error => {
                  console.error(error)
                  // TODO: Produce an event to allow UI to react to delete failure
                }
              )
          } else {
            wrapper.inMedia = false
            this.updateLog(undefined, useActive)
          }
          break
        case "update":
          // TODO: Mark asset array as changed (defer because array itself unchanged)
          const update = this.rsService
            .uploadMedia(path, wrapper.asset.data)
            .pipe(first())

          update.subscribe(
            response => {
              // Asset in the session log remains the same, no need to update
            },
            error => {
              this.logger.error(error)
              // TODO: Produce an event to allow UI to react to upload failure
            }
          )
          break
        case "add":
          const upload = this.rsService
            .uploadMedia(path, wrapper.asset.data)
            .pipe(first())

          upload.subscribe(
            response => {
              session.assets.push(wrapper)
              this.updateLog(undefined, useActive)
              this.markAssetsChanged(session)
            },
            error => {
              this.logger.error(error)
              // TODO: Produce an event to allow UI to react to upload failure
            }
          )
          break
      }
    } else {
      switch (action) {
        case "delete":
          if (wrapper.inMessage) {
            wrapper.inMedia = false
          } else {
            const index = session.assets.findIndex(a => a === wrapper)
            if (index >= 0) {
              session.assets.splice(index, 1)
              wrapper.cleanupAsset()
            } else {
              this.logger.error(
                `Failed to find asset (file name: ${
                  wrapper.asset.fileName
                }) for deletion in asset array.`
              )
              // TODO: Pass error to UI
            }
          }
          break
        case "update":
          // Asset array remains unchanged
          break
        case "add":
          session.assets.push(wrapper)
          break
      }
      this.markAssetsChanged(session)

      // Record in history or produce debug printout of intended log upload
      if (this.recordHistory && this.rsService) {
        switch (action) {
          case "delete":
            if (!wrapper.inMessage) {
              this.rsService
                .deleteMediaFile(path)
                .pipe(first())
                .subscribe(
                  () => {
                    this.updateLog(undefined, useActive)
                  },
                  error => {
                    console.error(error)
                    // TODO: Produce an event to allow UI to react to delete failure
                  }
                )
            }
            break
          case "update":
          case "add":
            const upload = this.rsService
              .uploadMedia(path, wrapper.asset.data)
              .pipe(first())

            upload.subscribe(
              response => {
                this.updateLog(undefined, useActive)
              },
              error => {
                this.logger.error(error)
                // TODO: Produce an event to allow UI to react to upload failure
              }
            )
            break
        }
      } else {
        this.logger.debug(
          `Should ${action} asset with file name ${wrapper.asset.fileName} ${
            action === "delete" ? "from" : "in"
          } ${useActive ? "active" : "viewed"} session's bucket.`
        )
      }
    }
  }

  /**
   * Marks the given session's assets array as changed so change detection runs appropriately.
   * Current implementation has some performance concerns with large asset arrays and generally feels hack-y,
   * but works as a proof of concept.
   * @param session The session whose assets should be marked as changed
   */
  private markAssetsChanged(session: Session<CustomData>) {
    session.assets.concat([])
  }

  /**
   * Marks the given session's messages array as changed so change detection runs appropriately.
   * Also marks messageUpdate if any messages are unread, as this function is primarily used when adding messages.
   * Current implementation has some performance concerns with large message arrays and generally feels hack-y,
   * but works as a proof of concept.
   * @param session The session whose messages should be marked as changed
   */
  private markMessagesChanged(session: Session<CustomData>) {
    if (session instanceof ActiveSession) {
      session.messages = session.messages.concat([])
      if (session.messages.some(m => !m.isRead)) {
        this.messageUpdate.next()
      }
    } else if (session instanceof ArchivedSession) {
      session.messages = session.messages.concat([])
    } else {
      // This shouldn't be hit, limiting to active or archived appeared to be necessary for TS to be happy
    }
  }

  // TODO: parameter order reversal may reduce cases of calls with explicit "undefined"
  private updateLog(userRole?: UserRole, useActive: boolean = true) {
    const refSession = useActive ? this.activeSession : this.viewSession
    if (refSession) {
      if (userRole) {
        if (userRole === UserRole.Agent) {
          refSession.disconnectType = SessionDisconnectType.agent
        } else {
          refSession.disconnectType = SessionDisconnectType.tech
        }
      }
      if (this.connectionState !== ConnectionState.closed) {
        refSession.duration = new Date().valueOf() - refSession.date.valueOf()
      }

      if (this.recordHistory && this.rsService) {
        this.rsService
          .uploadLogData(refSession.toSessionLog())
          .pipe(first())
          .subscribe(ret =>
            console.log(`updateLog: uploadLogData got status of ${ret.status}`)
          )
      } else {
        this.logger.debug(
          `updateLog: Should upload log to the history bucket:\n${JSON.stringify(
            refSession.toSessionLog()
          )}`
        )
      }
    } else {
      this.logger.warn("updateLog: Session not recognized")
    }
  }

  private fetchSessionAssets(session: ArchivedSession<CustomData>) {
    if (!this.rsService) {
      return
    }

    const toFetch = session.assets.filter(a => {
      if (a.asset instanceof MediaFile) {
        return false
      } else {
        return (
          a.asset.archived &&
          ["unfetched", "fetchError"].includes(a.asset.archived)
        )
      }
    }) as Array<AssetWrapper<ArchivedMediaFileMetadata>>
    if (toFetch.length === 0) {
      return
    }

    const fetchPath = session.path.join("~")
    const listObs = this.rsService
      .getMediaFileList({ filter: fetchPath, addedData: "size" })
      .pipe(first()) as Observable<SessionDataInterface[]>
    this.assetSubscriptions.push(
      listObs.subscribe(list =>
        toFetch.forEach(wrapper => {
          if (!this.rsService) {
            return
          }

          // Iterate through assets and check for associated file
          // In an ideal model, we would throw out a file once it's associated with an asset, but we'll not add that constraint yet
          const assocAsset = list.find(file =>
            file.Key.includes(wrapper.asset.fileName)
          )
          if (!assocAsset) {
            // This is an unarchived file
            // TODO: This system of forcing change detection is messy, understand how it worked in the past
            wrapper.replaceAsset(
              new ArchivedMediaFileMetadata(wrapper.asset.fileName, false)
            )
            return
          }
          wrapper.asset.archived = "fetching"
          const pathSections = assocAsset.Key.split(/\/|~/g)
          pathSections.shift()
          const assetPath = pathSections.join("~")
          const mediaObs = this.rsService
            .downloadMedia(assetPath, assocAsset.Size)
            .pipe(first())
          const sub = mediaObs.subscribe(
            data => {
              (wrapper as AssetWrapper<MediaFileMetadata>).replaceAsset(
                wrapper.asset.toMediaFile(this.sanitizer, data)
              )
            },
            err => {
              // TODO: Confirm this is necessary to get change detection (likely is)
              wrapper.replaceAsset(
                new ArchivedMediaFileMetadata(
                  wrapper.asset.fileName,
                  "fetchError"
                )
              )
            }
          )
          this.assetSubscriptions.push(sub)
        })
      )
    )
  }
}
