import { Component, Input, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"
import { contains, ODataFiltrableDataSource } from "filtrable-data-source"
import { debounceTime } from "rxjs/operators"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"

@Component({
  selector: "app-data-source-search-many-fields",
  templateUrl: "./data-source-search-many-fields.component.html",
  styleUrls: ["./data-source-search-many-fields.component.scss"]
})
export class DataSourceSearchManyFieldsComponent implements OnInit {
  @Input() dataSource: ODataFiltrableDataSource<any>
  @Input() selectedHandler?: SelectedItemsHandler<any>
  @Input() fields: string[]
  @Input() placeholder: string

  formControl = new FormControl("")

  constructor() {}

  ngOnInit() {
    this.formControl.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      if (val) {
        this.dataSource.setCustomFilter(this.buildFilterString(val))
      } else {
        this.dataSource.removeCustomFilter()
      }
      this.dataSource.applyFilters()
      if (this.selectedHandler) {
        this.selectedHandler.items = []
      }
    })
  }

  buildFilterString(searchParam: string): string {
    let filter = ""
    this.fields.forEach((field, idx) => {
      filter += idx === 0 ? "" : " or "
      filter += `contains(${field},  '${searchParam.toLowerCase()}')`
    })
    return filter
  }
}
