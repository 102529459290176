import { Component, Input, OnInit } from "@angular/core"
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms"
import { initial } from "lodash-es"
import { BsModalService } from "ngx-bootstrap/modal"
import { StageType, UnitType } from "src/app/api"
import {
  AuthenticationService,
  UserTempUnit
} from "src/app/core/authentication.service"
import { TemperatureTypePipe } from "../../temperature-type.pipe"
import { AddStepModalComponent } from "./add-step-modal/add-step-modal.component"
import { EditActionModalComponent } from "./edit-action-modal/edit-action-modal.component"
import { EditCombiModalComponent } from "./edit-combi-modal/edit-combi-modal.component"
import { EditConvergeStageModalComponent } from "./edit-converge-stage-modal/edit-converge-stage-modal.component"
import { EditCookStageModalComponent } from "./edit-cook-stage-modal/edit-cook-stage-modal.component"
import { EditHoldStageModalComponent } from "./edit-hold-stage-modal/edit-hold-stage-modal.component"
import { EditPreheatStepComponent } from "./edit-preheat-step/edit-preheat-step.component"
import { EditProdigiProStageModalComponent } from "./edit-prodigi-pro-stage-modal/edit-prodigi-pro-stage-modal.component"
import { EditSmokeStageModalComponent } from "./edit-smoke-stage-modal/edit-smoke-stage-modal.component"
import { EditStageModalComponent } from "./edit-stage-modal/edit-stage-modal.component"
import { EditSteamModalComponent } from "./edit-steam-modal/edit-steam-modal.component"
import {
  coldSmokeByUserTemp,
  createActionFormGroup,
  createColdSmokeStageFromGroup,
  createCombiStageFromGroup,
  createConvergeStageFormGroup,
  createCookStageFromGroup,
  createHoldStageFromGroup,
  createPreheatStageFromGroup,
  createProdigiProFormGroup,
  createStageFromGroup,
  createSteamStageFromGroup,
  isActionStep,
  isColdSmokeStep,
  isCombiStep,
  isCookStep,
  isHoldStep,
  isPreheatStep,
  isStageStep,
  isSteamStep,
  modeToStageType,
  patchAndAddValue
} from "./form-groups"
import { GenericEditStepModalComponent } from "./generic-edit-step-modal-component"
import { buildMultiShelfTimerFormArray } from "./multi-shelf-timer-modal/multi-shelf-timer-modal.component"

export function unitTypeHasProbeCooking(type: UnitType): boolean {
  return (
    type === UnitType.VectorF ||
    type === UnitType.VectorFG ||
    type === UnitType.CookAndHold ||
    type === UnitType.Converge ||
    type === UnitType.ProdigiPro
  )
}

@Component({
  selector: "app-recipe-steps-editor",
  templateUrl: "./recipe-steps-editor.component.html",
  styleUrls: ["./recipe-steps-editor.component.scss"]
})
export class RecipeStepsEditorComponent implements OnInit {
  @Input() stepsFormArray: FormArray
  @Input() imageFormArray: FormArray
  @Input() unitType: UnitType
  @Input() editingEnabled = true
  tempUnit: UserTempUnit
  modeToStageType = modeToStageType
  getColdSmokeTemp = coldSmokeByUserTemp
  UnitType = UnitType
  bodyElement: HTMLElement = document.body
  preheatStageExists = false
  stageLimit = 10

  constructor(
    private modalService: BsModalService,
    private authService: AuthenticationService,
    private temperatureTypePipe: TemperatureTypePipe
  ) {}

  ngOnInit() {
    this.tempUnit = this.authService.getUserTemp()
    if (this.unitType === UnitType.ProdigiPro) {
      this.stageLimit = 15
    }
  }

  addStep() {
    this.preheatStageExists = false
    for (const step of this.stepsFormArray.controls) {
      if (
        this.isPreheatStepGroup(step as FormGroup) ||
        (this.isCookStepGroup(step as FormGroup) &&
          step.value.smoker &&
          step.value.cavitySetpoint === 60 &&
          this.unitType === UnitType.CookAndHold)
      ) {
        this.preheatStageExists = true
        break
      }
    }
    // Create and show the Add Stage vs. Add Action dialog
    const initialState: Partial<AddStepModalComponent> = {
      unitType: this.unitType,
      steps: this.stepsFormArray.length,
      preheatStageExists: this.preheatStageExists
    }
    const modalRef = this.modalService.show(AddStepModalComponent, {
      initialState,
      class:
        ((this.unitType === UnitType.CookAndHold ||
          this.unitType === UnitType.CookAndHoldNoSmoker) &&
          (this.stepsFormArray.length === 0 || !this.preheatStageExists)) ||
        (this.unitType === UnitType.Converge ||
          this.unitType === UnitType.ProdigiPro)
          ? "modal-xl" // use an extra-wide modal instead of the normal sized one
          : ""
    })
    const component = modalRef.content as AddStepModalComponent

    // When one of those buttons is clicked, an event is emitted via the component's `stepSelected` output.
    // Create and show the appropriate dialog.
    component.stepSelected.subscribe((stageType: StageType) => {
      this.openAppropriateStageModal(stageType)
    })
  }

  openAppropriateStageModal(stageType: StageType) {
    if (stageType === StageType.action) {
      this.openEditActionModal()
    } else if (UnitType.Converge === this.unitType) {
      this.openEditConvergeStageModal(stageType)
    } else if (
      UnitType.ProdigiPro === this.unitType &&
      stageType !== StageType.preheat
    ) {
      this.openEditProdigiProStageModal(stageType)
    } else if (stageType === StageType.stage) {
      this.openEditStageModal()
    } else if (stageType === StageType.coldSmoke) {
      this.openEditColdSmokeStageModal()
    } else if (stageType === StageType.hold) {
      this.openEditHoldStageModal()
    } else if (stageType === StageType.cook) {
      this.openEditCookStageModal()
    } else if (stageType === StageType.preheat) {
      this.openEditPreheatStageModal()
    }
  }

  openEditActionModal(index?: number, oldFormGroup?: FormGroup) {
    const formGroup = createActionFormGroup(undefined, this.unitType)
    if (oldFormGroup) {
      patchAndAddValue(oldFormGroup, formGroup)
    } else if (this.unitType === UnitType.ProdigiPro) {
      const prodigiForm = createProdigiProFormGroup(
        this.tempUnit,
        StageType.action,
        this.temperatureTypePipe
      )
      patchAndAddValue(prodigiForm, formGroup)
    }
    const initialState: Partial<EditActionModalComponent> = {
      formGroup: formGroup,
      isEditing: oldFormGroup != null,
      unitType: this.unitType
    }
    const actionModal = this.openEditingStepModal(
      EditActionModalComponent,
      initialState,
      index
    )

    // action modals require the additional on-save step of conditionally updating the recipe image array
    actionModal.save.subscribe((res: FormGroup) => {
      if (
        res.value.visual &&
        res.value.visual.id &&
        !this.imageFormArray.value.some(
          (v: any) => v.id === res.value.visual.id
        )
      ) {
        this.imageFormArray.push(new FormControl(res.value.visual))
      }
    })
  }

  openEditStageModal(index?: number, oldFormGroup?: FormGroup) {
    const formGroup = createStageFromGroup(this.unitType, this.tempUnit)
    if (oldFormGroup) {
      formGroup.setValue(oldFormGroup.getRawValue())
    }
    const initialState: Partial<EditStageModalComponent> = {
      formGroup: formGroup,
      hasProbeCooking: unitTypeHasProbeCooking(this.unitType),
      isEditing: oldFormGroup != null
    }
    this.openEditingStepModal(EditStageModalComponent, initialState, index)
  }

  openEditCookStageModal(index?: number, oldFormGroup?: FormGroup) {
    const formGroup = createCookStageFromGroup(this.unitType, this.tempUnit)

    if (oldFormGroup) {
      formGroup.setValue(oldFormGroup.getRawValue())
    }

    const initialState: Partial<EditCookStageModalComponent> = {
      formGroup: formGroup,
      isEditing: oldFormGroup != null,
      unitType: this.unitType
    }
    this.openEditingStepModal(EditCookStageModalComponent, initialState, index)
  }

  openEditColdSmokeStageModal(index?: number, oldFormGroup?: FormGroup) {
    const formGroup = createColdSmokeStageFromGroup(
      this.unitType,
      this.tempUnit
    )
    if (oldFormGroup) {
      formGroup.setValue(oldFormGroup.getRawValue())
    }
    const initialState: Partial<EditSmokeStageModalComponent> = {
      formGroup: formGroup,
      isEditing: oldFormGroup != null,
      unitType: this.unitType
    }
    this.openEditingStepModal(
      EditSmokeStageModalComponent,
      initialState,
      index
    )
  }

  openEditHoldStageModal(index?: number, oldFormGroup?: FormGroup) {
    const formGroup = createHoldStageFromGroup(this.unitType, this.tempUnit)
    if (oldFormGroup) {
      formGroup.setValue(oldFormGroup.getRawValue())
    }
    const initialState: Partial<EditHoldStageModalComponent> = {
      formGroup: formGroup,
      isEditing: oldFormGroup != null,
      unitType: this.unitType
    }
    this.openEditingStepModal(EditHoldStageModalComponent, initialState, index)
  }

  openEditPreheatStageModal(index?: number, oldFormGroup?: FormGroup) {
    const formGroup = createPreheatStageFromGroup(this.unitType, this.tempUnit)
    if (oldFormGroup) {
      patchAndAddValue(oldFormGroup, formGroup)
    } else if (this.unitType === UnitType.ProdigiPro) {
      const prodigiForm = createProdigiProFormGroup(
        this.tempUnit,
        StageType.preheat,
        this.temperatureTypePipe
      )
      patchAndAddValue(prodigiForm, formGroup)
    }
    const initialState: Partial<EditPreheatStepComponent> = {
      formGroup: formGroup,
      isEditing: oldFormGroup != null,
      unitType: this.unitType,
      stageType: StageType.preheat
    }
    this.openEditingStepModal(EditPreheatStepComponent, initialState, index)
  }

  openEditConvergeStageModal(
    stageType: StageType,
    index?: number,
    oldFormGroup?: FormGroup
  ) {
    const formGroup = createConvergeStageFormGroup(this.tempUnit, stageType)
    if (oldFormGroup) {
      // Converge recipes originally did not have or need a humidity setting for all step types.
      // Now that all step types require the same inputs, we have to add the humidity placeholder
      // for existing recipes.
      const group = oldFormGroup.getRawValue()
      if (!group["humidity"]) {
        group.humidity = 0
      }
      formGroup.setValue(group)
    }
    const initialState: Partial<EditConvergeStageModalComponent> = {
      formGroup: formGroup,
      isEditing: oldFormGroup != null,
      unitType: this.unitType,
      stageType: stageType
    }
    this.openEditingStepModal(
      EditConvergeStageModalComponent,
      initialState,
      index
    )
  }

  openEditProdigiProStageModal(
    stageType: StageType,
    index?: number,
    oldFormGroup?: FormGroup
  ) {
    const formGroup = createProdigiProFormGroup(
      this.tempUnit,
      stageType,
      this.temperatureTypePipe
    )
    if (oldFormGroup) {
      if (
        oldFormGroup.controls.multiShelfTimer &&
        oldFormGroup.controls.multiShelfTimer.value.length > 0
      ) {
        for (const shelfTimer of oldFormGroup.controls.multiShelfTimer.value) {
          buildMultiShelfTimerFormArray(
            formGroup.controls.multiShelfTimer as FormArray,
            shelfTimer
          )
        }
      }
      formGroup.setValue(oldFormGroup.getRawValue())
    }
    const initialState: Partial<EditProdigiProStageModalComponent> = {
      formGroup: formGroup,
      isEditing: oldFormGroup != null,
      unitType: this.unitType,
      stageType: stageType
    }
    this.openEditingStepModal(
      EditProdigiProStageModalComponent,
      initialState,
      index
    )
  }

  deleteStep(index: number) {
    this.stepsFormArray.removeAt(index)
    this.stepsFormArray.markAsDirty()
    this.updateStepIndex()
  }

  isColdSmokeStepGroup(formGroup: FormGroup): boolean {
    return isColdSmokeStep(formGroup.value, this.tempUnit)
  }

  isActionStepGroup(formGroup: FormGroup): boolean {
    return isActionStep(formGroup.value, this.tempUnit)
  }

  isStageStepGroup(formGroup: FormGroup): boolean {
    return isStageStep(formGroup.value, this.tempUnit)
  }

  isCookStepGroup(formGroup: FormGroup): boolean {
    return isCookStep(formGroup.value, this.tempUnit)
  }

  isHoldStepGroup(formGroup: FormGroup): boolean {
    return isHoldStep(formGroup.value, this.tempUnit)
  }

  isPreheatStepGroup(formGroup: FormGroup): boolean {
    return isPreheatStep(formGroup.value, this.tempUnit)
  }

  isSteamStepGroup(formGroup: FormGroup): boolean {
    return isSteamStep(formGroup.value, this.tempUnit)
  }

  isCombiStepGroup(formGroup: FormGroup): boolean {
    return isCombiStep(formGroup.value, this.tempUnit)
  }

  isLastStepHold() {
    return isHoldStep(
      this.stepsFormArray.value[this.stepsFormArray.value.length - 1],
      this.tempUnit
    )
  }

  private openEditingStepModal(
    componentClass:
      | typeof EditStageModalComponent
      | typeof EditActionModalComponent
      | typeof EditSmokeStageModalComponent
      | typeof EditHoldStageModalComponent
      | typeof EditCookStageModalComponent
      | typeof EditPreheatStepComponent
      | typeof EditSteamModalComponent
      | typeof EditCombiModalComponent
      | typeof EditConvergeStageModalComponent
      | typeof EditProdigiProStageModalComponent,
    initialState:
      | Partial<EditStageModalComponent>
      | Partial<EditActionModalComponent>
      | Partial<EditSmokeStageModalComponent>
      | Partial<EditHoldStageModalComponent>
      | Partial<EditCookStageModalComponent>
      | Partial<EditPreheatStepComponent>
      | Partial<EditSteamModalComponent>
      | Partial<EditCombiModalComponent>
      | Partial<EditConvergeStageModalComponent>
      | Partial<EditProdigiProStageModalComponent>,
    index?: number
  ) {
    const modalRef = this.modalService.show(componentClass, {
      initialState: initialState
    })

    const component = modalRef.content as GenericEditStepModalComponent
    component.save.subscribe((fg: FormGroup) => {
      this.stepsFormArray.markAsDirty()
      if (index != null) {
        this.stepsFormArray.setControl(index, fg)
      } else {
        fg.controls["step"].setValue(this.stepsFormArray.length)
        fg.controls["name"].setValue(
          "Step " + (this.stepsFormArray.length + 1)
        )
        if (initialState.stageType && initialState.stageType === "preheat") {
          this.stepsFormArray.insert(0, fg)
        } else {
          this.stepsFormArray.push(fg)
        }
      }
      this.updateStepIndex()
    })
    return component
  }

  updateStepIndex(): void {
    while (this.stepsFormArray.length > this.stageLimit) {
      this.stepsFormArray.controls.pop()
    }
    for (const index in this.stepsFormArray.controls) {
      if (
        this.stepsFormArray.value.hasOwnProperty(index) &&
        this.stepsFormArray.controls.hasOwnProperty(index)
      ) {
        this.stepsFormArray.value[index].step = parseInt(index, 10)
        this.stepsFormArray.value[index].name =
          "Step " + (parseInt(index, 10) + 1)
        this.stepsFormArray.controls[index].setValue(
          this.stepsFormArray.value[index]
        )
      }
    }
  }

  updatePreheatIndex(): void {
    for (const index in this.stepsFormArray.controls) {
      if (
        this.stepsFormArray.value.hasOwnProperty(index) &&
        this.stepsFormArray.controls.hasOwnProperty(index)
      ) {
        if (
          modeToStageType(this.stepsFormArray.value[index].mode) ===
          StageType.preheat
        ) {
          const preheatControl = this.stepsFormArray.controls[index]
          const preheatValue = this.stepsFormArray.value[index]
          this.stepsFormArray.controls.splice(+index, 1)
          this.stepsFormArray.controls.splice(0, 0, preheatControl)
          this.stepsFormArray.value.splice(+index, 1)
          this.stepsFormArray.value.splice(0, 0, preheatValue)
          return
        }
      }
    }
  }

  drop(event: any) {
    this.bodyElement.classList.remove("inheritCursors")
    this.bodyElement.style.cursor = "unset"
    const movedItemControl = this.stepsFormArray.controls[event.previousIndex]
    const movedItemValue = this.stepsFormArray.value[event.previousIndex]
    this.stepsFormArray.controls.splice(event.previousIndex, 1)
    this.stepsFormArray.controls.splice(
      event.currentIndex,
      0,
      movedItemControl
    )
    this.stepsFormArray.value.splice(event.previousIndex, 1)
    this.stepsFormArray.value.splice(event.currentIndex, 0, movedItemValue)
    this.updatePreheatIndex()
    this.updateStepIndex()
  }

  dragStart(event: any) {
    this.bodyElement.classList.add("inheritCursors")
    this.bodyElement.style.cursor = "grabbing"
  }
}
