/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type UnitRemoteControlCommand =
  | 'START_PREHEAT'
  | 'STOP_PREHEAT'
  | 'STOP_RUNNING'
  | 'TURN_ON'
  | 'TURN_OFF';

export const UnitRemoteControlCommand = {
  STARTPREHEAT: 'START_PREHEAT' as UnitRemoteControlCommand,
  STOPPREHEAT: 'STOP_PREHEAT' as UnitRemoteControlCommand,
  STOPRUNNING: 'STOP_RUNNING' as UnitRemoteControlCommand,
  TURNON: 'TURN_ON' as UnitRemoteControlCommand,
  TURNOFF: 'TURN_OFF' as UnitRemoteControlCommand
};
