import { Subject } from "rxjs"

export class SelectedItemsHandler<T> {
  get items(): T[] {
    return this._items
  }

  set items(value: T[]) {
    this._items = value
    this.itemsChanged$.next()
  }

  idField: string[]
  itemsChanged$ = new Subject<void>()

  private _items: T[]

  /**
   * Constructs a handler for selected items based on the provided items and identifying field
   * @param idField A string representing the property to be compared.  Properties of properties are specified using dot notation.
   * @param items The items to be compared
   */
  constructor(idField = "id", items: T[] = []) {
    this.idField = idField.split(".")
    this.items = items
  }

  findIndex(item: T): number {
    return this.items.findIndex(i => {
      // Set initial values for comparison differently so no provided id field fails
      let layer: { targetItem: any; iterItem: any } = {
        targetItem: true,
        iterItem: false
      }
      this.idField.forEach((key, index) => {
        layer = { targetItem: (i as any)[key], iterItem: (item as any)[key] }
        if (
          index !== this.idField.length - 1 &&
          (layer.targetItem === undefined || layer.iterItem === undefined)
        ) {
          return false
        }
      })
      return layer.targetItem === layer.iterItem
    })
  }

  toggleItemSelected(item: T, selected: boolean) {
    if (selected) {
      if (!this.isSelected(item)) {
        this.items = this.items.concat(item)
      }
    } else {
      const index = this.findIndex(item)
      if (index !== -1) {
        this.items.splice(index, 1)
        this.items = [...this.items]
      }
    }
    this.itemsChanged$.next()
  }

  selectSingleItem(item: T) {
    this.items = [item]
    this.itemsChanged$.next()
  }

  isSelected(item: T) {
    return this.findIndex(item) !== -1
  }

  clear() {
    this.items = []
  }
}
