import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject, combineLatest, from } from "rxjs"
import { Observable } from "rxjs/internal/Observable"
import { catchError, mergeAll, tap, timeout } from "rxjs/operators"
import { map } from "rxjs/operators"
import { environment } from "src/environments/environment"
import {
  ArchivedMediaFileMetadata,
  AssetWrapper,
  MediaFile,
  MediaFileMetadata
} from "../models/remote-support/media"
import {
  ArchivedMessage,
  ArchivedMessageMetadata
} from "../models/remote-support/message"
import {
  ArchivedSession,
  SessionLog,
  SessionLogInterface,
  SessionMetadataInterface,
  SessionNote
} from "../models/remote-support/session"
import { SessionDataInterface } from "../models/remote-support/session-history"
import { FileUtil, HTTPUtil, RemoteSupportUtil, SystemUtil } from "../utils"
import { handleError } from "./apiErrorHandler"
@Injectable()
export class RemoteSupportService<CustomData> {
  headers: HttpHeaders

  // Shared
  URL_BASE = environment.reach.api + RemoteSupportUtil.PATH_SESSION_BASE
  // Media
  URL_MEDIA_LIST = this.URL_BASE + RemoteSupportUtil.PATH_MEDIA_LIST
  URL_MEDIA = this.URL_BASE + RemoteSupportUtil.PATH_MEDIA
  URL_MEDIA_UPLOAD_URL =
    this.URL_BASE + RemoteSupportUtil.PATH_MEDIA_UPLOAD_URL
  URL_MEDIA_DOWNLOAD_URL =
    this.URL_BASE + RemoteSupportUtil.PATH_MEDIA_DOWNLOAD_URL
  // History
  URL_HISTORY_LIST = this.URL_BASE + RemoteSupportUtil.PATH_HISTORY_LIST
  URL_HISTORY = this.URL_BASE + RemoteSupportUtil.PATH_HISTORY

  private uploadLimit = 4500000 // Max bytelength of file uploads, experimentally found

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(
      HTTPUtil.HEADER_X_API_KEY,
      environment.reach.key
    )
  }

  getParameterDefinitions(): Observable<string> {
    return this.http.get<string>(environment.mockApiBasePath + "/definitions")
  }

  getParameterGroups(): Observable<string> {
    return this.http.get<string>(environment.mockApiBasePath + "/groups")
  }

  private getBucketFileList(
    bucket: "media" | "history",
    options?: { filter?: string; addedData?: "size" | "size-modified" }
  ): Observable<Array<string> | Array<SessionDataInterface>> {
    let url: string
    switch (bucket) {
      case "media":
        url = this.URL_MEDIA_LIST
        break
      case "history":
        url = this.URL_HISTORY_LIST
        break
      default:
        url = this.URL_MEDIA_LIST
    }
    if (!options) {
      return this.httpGet<{ files: Array<string> }>(url).pipe(
        map(obj => obj.files)
      )
    }
    const suffix = options.filter ? `/${options.filter}` : ""
    const opts = options.addedData
      ? options.addedData === "size"
        ? RemoteSupportUtil.SUFFIX_SIZE
        : RemoteSupportUtil.SUFFIX_SIZE_MODIFIED
      : ""
    return this.httpGet<{ files: Array<string> | Array<SessionDataInterface> }>(
      `${url}${opts}${suffix}`
    ).pipe(map(obj => obj.files))
  }

  getMediaFileList(options?: {
    filter?: string;
    addedData?: "size" | "size-modified";
  }): Observable<Array<string> | Array<SessionDataInterface>> {
    return this.getBucketFileList("media", options)
  }

  getHistoryList(options?: {
    filter?: string;
    addedData?: "size" | "size-modified";
  }): Observable<Array<string | SessionDataInterface>> {
    return this.getBucketFileList("history", options)
  }

  httpGet<T>(url: string): Observable<T> {
    return this.http
      .get<T>(url, {
        headers: this.headers
      })
      .pipe(
        timeout(environment.timeoutMs),
        catchError(handleError)
      )
  }

  /**
   * Generalized function for uploading media, regardless of data size.
   * @param path The path to which the file should be uploaded
   * @param data The data to be uploaded
   * @returns An {@link HttpResponse} or {@link Response} from the upload, with type depending on the upload method selected
   */
  uploadMedia(
    path: string,
    data: ArrayBuffer | ArrayBufferView
  ): Observable<HttpResponse<string> | Response> {
    if (data.byteLength > this.uploadLimit) {
      return from(this.uploadMediaDataAsync(path, data))
    } else {
      return this.uploadMediaData(path, data)
    }
  }

  /**
   * Generalized function for downloading media, regardless of data size.
   * @param fileName The path to the file being fetched
   * @param byteLength The size of the file, in bytes
   * @returns A {@link Uint8Array} containing the raw data of the file
   */
  downloadMedia(fileName: string, byteLength: number): Observable<Uint8Array> {
    const formattedKey = fileName.replace(/\//g, "~")
    if (byteLength > this.uploadLimit) {
      return from(this.downloadMediaDataAsync(formattedKey))
    } else {
      return this.downloadMediaData(formattedKey)
    }
  }

  // Function to make the HTTP GET request and decode the base64 payload
  protected downloadMediaData(fileName: string): Observable<Uint8Array> {
    const url = this.URL_MEDIA + FileUtil.PATH_SEPARATOR + fileName

    return this.http
      .get(url, { headers: this.headers, responseType: "text" })
      .pipe(map(response => SystemUtil.decodeBase64ToBinary(response)))
  }

  protected uploadMediaData(
    fileName: string,
    data: ArrayBuffer | ArrayBufferView | DataView | Blob | string
  ): Observable<HttpResponse<string>> {
    const url = this.URL_MEDIA + FileUtil.PATH_SEPARATOR + fileName
    const mimeType = FileUtil.getMimeType(fileName)

    let headers = this.headers
    headers = headers.set("Content-Type", mimeType)
    const body = new Blob([data], { type: mimeType })
    return this.http
      .post<string>(url, body, {
        headers: headers,
        observe: "response"
      })
      .pipe(
        timeout(environment.timeoutMs),
        catchError(handleError)
      )
  }

  deleteMediaFile(fileName: string): Observable<HttpResponse<string>> {
    const url = this.URL_MEDIA + FileUtil.PATH_SEPARATOR + fileName
    return this.http
      .delete<string>(url, { headers: this.headers, observe: "response" })
      .pipe(
        timeout(environment.timeoutMs),
        catchError(handleError)
      )
  }

  /**
   * Generalized function for uploading data to the history bucket.  This was previously used for messages, but is now effectively deprecated.
   * @param path The path to which the file should be uploaded
   * @param data The data to be uploaded (limited to string format)
   * @returns An {@link HttpResponse} from the upload
   */
  uploadHistoryData(
    fileName: string,
    data: string
  ): Observable<HttpResponse<string>> {
    const url = this.URL_HISTORY + FileUtil.PATH_SEPARATOR + fileName
    const mimeType = FileUtil.getMimeType(fileName)

    let headers = this.headers
    headers = headers.set("Content-Type", mimeType)
    return this.http
      .post<string>(url, new Blob([data], { type: mimeType }), {
        headers: headers,
        observe: "response"
      })
      .pipe(
        timeout(environment.timeoutMs),
        catchError(handleError)
      )
  }

  /**
   * Uploads a provided log to the appropriate location in the history bucket.
   * @param data The {@link SessionLog} to be uploaded
   * @returns An {@link HttpResponse} from the upload
   */
  uploadLogData(
    data: SessionLog<CustomData>
  ): Observable<HttpResponse<string>> {
    const fileName = "logV2.json"
    const url =
      this.URL_HISTORY + FileUtil.PATH_SEPARATOR + data.getPath() + fileName
    let headers = this.headers
    headers = headers.set("Content-Type", "application/json")
    return this.http
      .post<string>(url, JSON.stringify(data), {
        headers: headers,
        observe: "response"
      })
      .pipe(
        timeout(environment.timeoutMs),
        catchError(handleError)
      )
  }

  /**
   * Fetches and parses a session log for further processing.
   * @param fileName The location of the log file to be fetched
   * @returns A parsed {@link SessionLogInterface}
   */
  downloadLogData(
    fileName: string
  ): Observable<SessionLogInterface<CustomData>> {
    const url = this.URL_HISTORY + FileUtil.PATH_SEPARATOR + fileName

    return this.http
      .get(url, { headers: this.headers, responseType: "text" })
      .pipe(
        map(
          response =>
            JSON.parse(response, this.logDataReviver) as SessionLogInterface<
              CustomData
            >
        )
      )
  }

  private logDataReviver(key: string, value: any): Date | SessionNote | string {
    // console.log(`logDataReviver: hit with key: value = ${key}:${value}`)
    switch (key) {
      case "date":
      case "timestamp":
        return new Date(value)
      case "note":
        if (value) {
          return new SessionNote(value)
        } else {
          return new SessionNote({
            editDate: new Date(),
            editor: "",
            text: ""
          })
        }
      default:
        return value
    }
  }

  // Function to make the HTTP GET request and decode the base64 payload
  private downloadHistoryData(fileName: string): Observable<string> {
    const url = this.URL_HISTORY + FileUtil.PATH_SEPARATOR + fileName

    return this.http.get(url, { headers: this.headers, responseType: "text" })
  }

  protected getUploadMediaDataURLAsync(
    fileName: string
  ): Observable<HttpResponse<{ url: string }>> {
    const url = this.URL_MEDIA_UPLOAD_URL + FileUtil.PATH_SEPARATOR + fileName
    const mimeType = FileUtil.getMimeType(fileName)

    let headers = this.headers
    headers = headers.set("Content-Type", mimeType)
    return this.http
      .get<{ url: string }>(url, {
        headers: headers,
        observe: "response"
      })
      .pipe(
        timeout(environment.timeoutMs),
        catchError(handleError)
      )
  }

  protected getDownloadMediaDataURLAsync(
    fileName: string
  ): Observable<HttpResponse<{ url: string }>> {
    const url =
      this.URL_MEDIA_DOWNLOAD_URL + FileUtil.PATH_SEPARATOR + fileName
    const mimeType = FileUtil.getMimeType(fileName)

    const headers = this.headers
    return this.http
      .get<{ url: string }>(url, {
        headers: headers,
        observe: "response"
      })
      .pipe(
        timeout(environment.timeoutMs),
        catchError(handleError)
      )
  }

  protected async uploadMediaDataAsync(
    fileName: string,
    data: ArrayBuffer | ArrayBufferView | DataView | Blob | string
  ): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
      this.getUploadMediaDataURLAsync(fileName).subscribe(response => {
        if (response.status === 200 && response.body != null) {
          const url = response.body.url
          console.log("uploadMediaDataAsync: " + url)
          const mimeType = FileUtil.getMimeType(fileName)
          const imageBlob = new Blob([data], { type: mimeType })

          fetch(url, {
            method: "PUT",
            body: imageBlob
          })
            .then(fetchResponse => {
              if (fetchResponse.ok) {
                console.log("Upload successful!")
                resolve(fetchResponse) // Resolve the Promise when the upload is successful
              } else {
                console.error("Upload failed!")
                reject(new Error("Upload failed")) // Reject the Promise if the upload fails
              }
            })
            .catch(error => {
              console.error("Error uploading:", error)
              reject(error) // Reject the Promise if there is an error during the upload
            })
        }
      })
    })
  }

  protected async downloadMediaDataAsync(
    fileName: string
  ): Promise<Uint8Array> {
    return new Promise<Uint8Array>((resolve, reject) => {
      this.getDownloadMediaDataURLAsync(fileName).subscribe(response => {
        if (response.status === 200 && response.body != null) {
          const url = response.body.url
          console.log("downloadMediaDataAsync: " + url)
          fetch(url)
            .then(fetchResponse => {
              if (fetchResponse.ok) {
                return fetchResponse.blob() // Extract the response body as a Blob
              } else {
                throw new Error("Download failed!")
              }
            })
            .then(blob => {
              const reader = new FileReader()
              reader.onloadend = () => {
                const arrayBuffer = reader.result as ArrayBuffer
                const uint8Array = new Uint8Array(arrayBuffer)
                resolve(uint8Array)
              }
              reader.onerror = reject
              reader.readAsArrayBuffer(blob)
            })
            .catch(error => {
              console.error("Error downloading:", error)
              reject(error)
            })
        }
      })
    })
  }

  /**
   * Returns a base64 encoded string with the parameter config json
   */
  getParameterConfig(): Observable<string> {
    return this.http
      .get<string>(environment.apiBasePath + "/cygnus/config/global")
      .pipe(
        timeout(environment.timeoutMs),
        catchError(handleError)
      )
  }

  /**
   * Fetches session log data and processes it into an array of {@link ArchivedSession} objects
   * @param path Optional filter for the subdirectory to search in, most likely simply a device identifier string
   * @returns An array of {@link ArchivedSession} objects, where all self-contained data is fully processed.
   * Assets are generated based on file names as {@link ArchivedMediaFileMetadata} objects with the archived field set to "unfetched".
   */
  getSessionList(path?: string): Observable<ArchivedSession<CustomData>[]> {
    const logList = (this.getHistoryList({
      filter: path,
      addedData: "size"
    }) as Observable<SessionDataInterface[]>).pipe(
      map(idFiltered => idFiltered.filter(f => /.*logV2\.json$/.test(f.Key)))
    )
    return this.handleLogs(logList)
  }

  private handleLogs(
    logs: Observable<SessionDataInterface[]>
  ): Observable<ArchivedSession<CustomData>[]> {
    const candidate = logs.pipe(
      map(paths => {
        if (!paths || paths.length === 0) {
          return new Array<Observable<SessionLogInterface<CustomData>>>()
        }

        const observables = new Array<
          Observable<SessionLogInterface<CustomData>>
        >()
        for (const log of paths) {
          const sections = log.Key.split(/\/|~/)
          sections.shift()
          const fullPath = `${sections[0]}~${sections[1]}~${sections[2]}`
          if (log.Size > RemoteSupportUtil.DOWNLOAD_LIMIT) {
            console.error(
              `Log file for session too large to retrieve (${log.Key})`
            )
          } else {
            observables.push(this.downloadLogData(fullPath))
          }
        }

        return observables
      }),
      map(obsList => {
        if (!obsList) {
          return new Array<ArchivedSession<CustomData>>()
        }
        return combineLatest(obsList).pipe(
          map(list => {
            const sessions = new Array<ArchivedSession<CustomData>>()
            for (const log of list) {
              const assets = log.assets
                ? log.assets.map(
                    a =>
                      new AssetWrapper<ArchivedMediaFileMetadata>(
                        new ArchivedMediaFileMetadata(a.asset, "unfetched"),
                        a.inMessage,
                        a.inMedia
                      )
                  )
                : log.assets
              const messages = log.messages
                ? log.messages.map(m => {
                    let message:
                      | string
                      | AssetWrapper<ArchivedMediaFileMetadata>
                    if (m.type === "text") {
                      message = m.message
                    } else if (!assets) {
                      message = new AssetWrapper(
                        new ArchivedMediaFileMetadata(m.message, false),
                        true
                      )
                    } else {
                      const matchingAsset = assets.find(
                        a => a.asset.fileName === m.message
                      )
                      message = matchingAsset
                        ? matchingAsset
                        : new AssetWrapper(
                            new ArchivedMediaFileMetadata(m.message, false),
                            true
                          )
                    }
                    return new ArchivedMessage(m.sent, m.timestamp, message)
                  })
                : log.messages

              const metadata: SessionMetadataInterface<
                CustomData,
                ArchivedMediaFileMetadata
              > = {
                date: log.date,
                duration: log.duration,
                deviceID: log.deviceIdentifier,
                pin: log.pin,
                disconnectType: log.disconnectType,
                note: log.note,
                assets: assets,
                messages: messages,
                customData: log.customData
              }
              sessions.push(new ArchivedSession<CustomData>(metadata))
            }
            return sessions
          })
        )
      })
    )

    if (!candidate) {
      return candidate
    }
    const higherOrder = candidate as Observable<
      Observable<ArchivedSession<CustomData>[]>
    >
    return higherOrder.pipe(mergeAll())
  }
}
