import { ChangeDetectorRef, Component, Inject } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"

export interface DialogData {
  title: string
  message: string
  confirmButtonText: string
  cancelButtonText: string
  hideCancelButton: boolean
  infoDialog: boolean
  icon: boolean
  isDelete: boolean
  canClose: boolean
  messageBullets: string[]
}

/**
 * Generic dialog. Invokes onClose with `true` if user clicked the confirmation button and `false` if the user canceled
 */

@Component({
  selector: "app-generic-dialog",
  templateUrl: "./generic-dialog.component.html",
  styleUrls: ["./generic-dialog.component.scss"]
})
export class GenericDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public config: DialogData,
    private dialogRef: MatDialogRef<GenericDialogComponent>
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true)
  }

  onCancel(): void {
    this.dialogRef.close(false)
  }

  onClose(): void {
    this.dialogRef.close(undefined)
  }
}
