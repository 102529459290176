import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogModalService } from 'src/app/core/dialog-modal.service';

@Component({
  selector: 'app-image-uploader-control',
  templateUrl: './image-uploader-control.component.html',
  styleUrls: ['./image-uploader-control.component.scss']
})
export class ImageUploaderControlComponent implements OnInit {
  @Input()
  maxSize = 780 * 1024; // 780 KB
  @Input()
  acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
  @Input() contentText: string;

  @Output() fileSelected = new EventEmitter<File>();

  @ViewChild('fileInput', { static: true })
  fileInput: ElementRef<HTMLInputElement>;

  isDragging = false;

  constructor(
    private dialogService: DialogModalService,
    private ts: TranslateService
  ) {}

  ngOnInit() {}

  uploaderClick() {
    this.fileInput.nativeElement.click();
  }

  fileChanged(event: Event) {
    const files = this.fileInput.nativeElement.files;
    if (files) {
      this.parseFile(files[0]);
    }
  }

  fileDropped(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;

    if (event.dataTransfer && event.dataTransfer.files) {
      this.parseFile(event.dataTransfer.files[0]);
    }
  }

  onDragOver(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragging = true;
  }

  parseFile(file: File) {
    const type = file.type;

    if (this.acceptedTypes.indexOf(type) === -1) {
      return;
    }

    if (file.size > this.maxSize) {
      const message = this.ts.instant('GLOBAL.UPLOAD_IMAGE_SIZE_EXCEED', {
        size: this.maxSize / 1024
      });
      this.dialogService.danger({
        title: this.ts.instant('GLOBAL.ERROR'),
        message
      });
      return;
    }

    this.fileSelected.emit(file);
  }
}
