import { Observable } from "rxjs"
import { MimeType } from "../consts/remote-support/enums"

export namespace SystemUtil {
  export async function getFirstValue<T>(
    observable: Observable<T>
  ): Promise<T> {
    return await observable.toPromise()
  }

  export function decodeBase64ToBinary(base64String: string): Uint8Array {
    const binaryString = atob(base64String)
    const length = binaryString.length
    const bytes = new Uint8Array(length)

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return bytes
  }
}
