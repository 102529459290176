import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { PageChangedEvent } from "ngx-bootstrap/pagination"

@Component({
  selector: "app-list-pagination",
  templateUrl: "./list-pagination.component.html",
  styleUrls: [
    "../data-source-pagination/data-source-pagination.component.scss",
    "./list-pagination.component.scss"
  ]
})
export class ListPaginationComponent implements OnInit {
  @Input()
  list: Array<any> | undefined
  @Input()
  limit: number
  @Input()
  currentPage: number
  @Output()
  currentPageChange = new EventEmitter<number>()

  constructor() {}

  ngOnInit() {}

  handlePageChange(event: PageChangedEvent) {
    this.currentPageChange.emit(event.page)
  }
}
