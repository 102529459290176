import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { ToastrService } from "ngx-toastr"
import { RecipeBase, UnitType } from "src/app/api"
import { GlobalLibraryService } from "src/app/api/api/global-library.service"
import { UnitService } from "src/app/api/api/unit.service"
import { LayoutService } from "src/app/core/layout.service"
import { SelectedItemsHandler } from "src/app/core/selected-items-handler"
import { Recipe } from "src/app/models/Recipe"

@Component({
  selector: "app-recipe-selection",
  templateUrl: "./recipe-selection.component.html",
  styleUrls: ["./recipe-selection.component.scss"]
})
export class RecipeSelectionComponent implements OnInit {
  private _isCollapsed: boolean
  manuallyCollapsed = false
  userUnits: UnitType[]
  invalidRecipeUnitType = false

  @Input()
  public set isCollapsed(value: boolean) {
    this._isCollapsed = value
    this.isCollapsedChange.emit(this.isCollapsed)
    setTimeout(() => {
      this.layoutService.sidebarToggled.next()
    }, 500)
  }
  @Output() isCollapsedChange = new EventEmitter<boolean>()

  public get isCollapsed() {
    return this._isCollapsed
  }

  @Input() selectedHandler: SelectedItemsHandler<any>

  constructor(
    private layoutService: LayoutService,
    private globalLibraryService: GlobalLibraryService,
    private ts: TranslateService,
    private toastService: ToastrService,
    private router: Router,
    private unitService: UnitService
  ) {}

  ngOnInit() {
    this.unitService.getTypes().subscribe(response => {
      this.userUnits = response
      if (this.selectedHandler.items.length > 0) {
        this.checkMatchesUserOven()
      }
      this.selectedHandler.itemsChanged$.subscribe(() =>
        this.automaticToggling()
      )
    })
  }

  toggleCollapse(manualSet = false) {
    if (!this.manuallyCollapsed) {
      this.manuallyCollapsed = manualSet
    }
    this.isCollapsed = !this.isCollapsed
  }

  removeItem(item: any) {
    this.selectedHandler.toggleItemSelected(item, false)
  }

  addToCompanyLibrary() {
    this.globalLibraryService
      .copyToCompanyLibrary(this.selectedHandler.items.map(i => i.recipeId))
      .subscribe(_ => {
        this.unselectItems()
        this.toastService.success(
          this.ts.instant("RECIPE_LIBRARY.GLOBAL.ADD_TO_CUSTOMER_SUCCESS")
        )
      })
  }

  unselectItems() {
    this.selectedHandler.clear()
    this.automaticToggling()
  }

  /**
   * Collapse recipe selection when no items selected;
   * Expand when first item selected
   */
  private automaticToggling() {
    if (this.selectedHandler.items.length === 0) {
      this.isCollapsed = true
      this.manuallyCollapsed = false
    } else if (!this.manuallyCollapsed) {
      this.isCollapsed = false
    }
    this.storeSelectedRecipes()
    this.checkMatchesUserOven()
  }

  storeSelectedRecipes() {
    this.globalLibraryService.selectedRecipes = this.selectedHandler.items
  }

  checkMatchesUserOven() {
    this.invalidRecipeUnitType = false
    this.selectedHandler.items.forEach(i => {
      // items have changed re-evaluate
      this.hasInvalidUnitType(i.unitType)
    })
  }

  hasInvalidUnitType(unitType: string): boolean {
    if (!this.userUnits) {
      return true
    }
    const hasInvalidUnit =
      this.userUnits.find(x => x === unitType) === undefined
    if (!this.invalidRecipeUnitType && hasInvalidUnit) {
      this.invalidRecipeUnitType = true
    }
    return hasInvalidUnit
  }

  recipeClicked(recipe: RecipeBase) {
    this.router.navigate(["global-library", "recipes", recipe.recipeId])
  }
}
