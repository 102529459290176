import { Pipe, PipeTransform } from '@angular/core';

/**
 * Truncates a string to the provided length limit and adds ellipsis to the end. Accepts a string and a number.
 * 
 * Usage:
 * {{ dataString | truncate: 15 }} 
 */
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit:number): string {
    if (value.length < limit) return value;
    let retVal = value.slice(0, limit).trim();
    return value.length > limit ? retVal+'...' : retVal;
  }

}
