import { Injectable } from "@angular/core"
import { UserRole } from "../api"
import { LocalStorageKey } from "../models/enums/local-storage"

export interface IUserPermissions {
  system_view: boolean
  group_view: boolean
  locations_view: boolean
  admin_view: boolean
  group_user_view: boolean
  location_admin_only_view: boolean
  technician_view: boolean
  culinary_edit_view: boolean
}

export class AuthRoles {
  [key: string]: boolean;
  system_admin = false
  culinary_admin = false
  company_admin = false
  company_user = false
  group_admin = false
  group_user = false
  location_admin = false
  location_user = false
}

@Injectable({
  providedIn: "root"
})
export class PermissionService {
  permissions: IUserPermissions
  roles: AuthRoles = this.getRoles()

  constructor() {}

  public getRoles(): AuthRoles {
    const userRole = localStorage.getItem(LocalStorageKey.userRole) as UserRole
    const roles: AuthRoles = {
      system_admin: userRole === UserRole.SystemAdmin,
      company_admin: userRole === UserRole.CompanyAdmin,
      culinary_admin: userRole === UserRole.CulinaryAdmin,
      company_user: userRole === UserRole.CompanyUser,
      group_admin: userRole === UserRole.GroupAdmin,
      group_user: userRole === UserRole.GroupUser,
      location_admin: userRole === UserRole.LocationAdmin,
      location_user: userRole === UserRole.LocationUser
    }
    return roles
  }

  public evaluate() {
    const roles: AuthRoles = this.getRoles()

    this.permissions = {
      system_view: roles.system_admin,
      culinary_edit_view: roles.system_admin || roles.culinary_admin,
      group_view:
        roles.company_admin || roles.culinary_admin || roles.system_admin,
      locations_view:
        roles.company_admin ||
        roles.culinary_admin ||
        roles.system_admin ||
        roles.group_admin,
      admin_view:
        roles.company_admin ||
        roles.culinary_admin ||
        roles.system_admin ||
        roles.group_admin ||
        roles.location_admin,
      group_user_view:
        roles.company_admin ||
        roles.culinary_admin ||
        roles.system_admin ||
        roles.group_admin ||
        roles.group_user,
      location_admin_only_view: roles.location_admin,
      technician_view: false
    }
  }
}
