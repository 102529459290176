import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ITreeOptions, ITreeState } from 'angular-tree-component';
import { debounceTime } from 'rxjs/operators';
import { LocationsTreeItem } from 'src/app/api';
import { IdAndLabel } from 'src/app/core/idAndLabel';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import { ITreeEvent } from '../tree/tree.component';

export type LocationsTree = LocationsTreeItem[];

interface MyTreeNode {
  id: string;
  name: string;
}

interface UnitTreeNode extends MyTreeNode {
  unitId: string;
}

interface LocationTreeNode extends MyTreeNode {
  groupIds: string[];
  children: UnitTreeNode[];
}

function isLocationTreeNode(node: any): node is LocationTreeNode {
  return node.groupIds != null;
}

function getUnitTreeNodeId(unitId: string): string {
  return `u_${unitId}`;
}

function convertUnitNodeToIdAndLabel(node: UnitTreeNode): IdAndLabel {
  return {
    id: node.unitId,
    label: node.name
  };
}

function createNodesFromLocationsTree(tree: LocationsTree): LocationTreeNode[] {
  return tree
    .filter(loc => loc.units.length > 0)
    .map(loc => ({
      id: `l_${loc.locationId}`,
      name: loc.locationName,
      groupIds: loc.groupIds!,
      children: loc.units.map(u => ({
        id: getUnitTreeNodeId(u.unitId),
        unitId: u.unitId,
        name: u.unitName
      }))
    }));
}

function filterTreeByGroupIds(
  tree: LocationsTree,
  groupIds: string[]
): LocationsTree {
  return tree.filter(
    loc => groupIds.filter(g => loc.groupIds.includes(g)).length > 0
  );
}

function filterTreeByName(tree: LocationsTree, name: string): LocationsTree {
  const locationIds = tree
    .filter(loc =>
      loc.locationName.toLocaleLowerCase().includes(name.toLocaleLowerCase())
    )
    .map(l => l.locationId);
  return tree.map(loc => {
    if (locationIds.includes(loc.locationId)) {
      return loc;
    } else {
      return {
        ...loc,
        units: loc.units.filter(u =>
          u.unitName.toLocaleLowerCase().includes(name.toLocaleLowerCase())
        )
      };
    }
  });
}

@Component({
  selector: 'app-locations-tree',
  templateUrl: './locations-tree.component.html',
  styleUrls: ['./locations-tree.component.scss']
})
export class LocationsTreeComponent implements OnInit {
  @Input()
  set locationsTree(val: LocationsTree) {
    this._tree = val;
    this.filterTree();
  }
  @Input() selectedUnits: SelectedItemsHandler<IdAndLabel>;
  @Input() filterGroupIds?: SelectedItemsHandler<IdAndLabel>;

  allUnits: IdAndLabel[] = [];
  treeState: ITreeState;
  nodes: LocationTreeNode[];
  options: ITreeOptions = {
    useCheckbox: true
  };
  searchFc = new FormControl(undefined);

  private _tree: LocationsTree;

  constructor() {}

  ngOnInit() {
    this.selectedUnits.itemsChanged$.pipe(debounceTime(0)).subscribe(() => {
      const selectedIds: Record<string, boolean> = {};
      for (const unit of this.selectedUnits.items) {
        selectedIds[getUnitTreeNodeId(unit.id)] = true;
      }
      this.treeState = {
        ...this.treeState,
        selectedLeafNodeIds: selectedIds
      };
    });
    this.searchFc.valueChanges.subscribe(() => {
      this.filterTree();
    });
    if (this.filterGroupIds) {
      this.filterGroupIds.itemsChanged$.subscribe(() => {
        this.filterTree();
      });
    }
  }

  onTreeEvent(event: ITreeEvent) {
    if (event.eventName === 'select' || event.eventName === 'deselect') {
      const nodeModel = event.node.data as UnitTreeNode;
      this.selectedUnits.toggleItemSelected(
        convertUnitNodeToIdAndLabel(nodeModel),
        event.eventName === 'select'
      );
    }
  }

  filterTree() {
    const groupIds = this.filterGroupIds
      ? this.filterGroupIds.items.map(i => i.id)
      : [];
    const nameFilter = this.searchFc.value as string | undefined;
    let tree = [...this._tree];

    if (groupIds.length > 0) {
      tree = filterTreeByGroupIds(tree, groupIds);
    }

    if (nameFilter) {
      tree = filterTreeByName(tree, nameFilter);
    }

    this.nodes = createNodesFromLocationsTree(tree);
    this.allUnits = this.nodes.reduce((acc: IdAndLabel[], curr) => {
      return acc.concat(curr.children.map(convertUnitNodeToIdAndLabel));
    }, []);
  }
}
