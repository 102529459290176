/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type UserRole =
  | "system_admin"
  | "culinary_admin"
  | "company_admin"
  | "company_user"
  | "group_admin"
  | "group_user"
  | "location_admin"
  | "location_user";

export const UserRole = {
  SystemAdmin: "system_admin" as UserRole,
  CulinaryAdmin: "culinary_admin" as UserRole,
  CompanyAdmin: "company_admin" as UserRole,
  CompanyUser: "company_user" as UserRole,
  GroupAdmin: "group_admin" as UserRole,
  GroupUser: "group_user" as UserRole,
  LocationAdmin: "location_admin" as UserRole,
  LocationUser: "location_user" as UserRole
};
