import { Pipe, PipeTransform } from '@angular/core';
import { UserBase } from '../api';

@Pipe({
  name: 'usersList'
})
export class UsersListPipe implements PipeTransform {
  transform(value: UserBase[], args?: any): any {
    return value && value.map(v => v.email).join(', ')
  }
}
