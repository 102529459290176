import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "decamelize"
})
export class DecamelizePipe implements PipeTransform {
  transform(value: string): string {
    value = value.replace(/[A-Z]/g, match => " " + match).trim()
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}
