import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router"
import { Observable } from "rxjs"
import { PermissionService } from "./core/permission.service"
import { LocalStorageKey } from "./models/enums/local-storage"

@Injectable({
  providedIn: "root"
})
export class DashboardGuard implements CanActivate {
  constructor(private permission: PermissionService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(async (resolve, reject) => {
      const locationId = localStorage.getItem(LocalStorageKey.locationId)
      if (state.url === "/") {
        const roles = this.permission.getRoles()
        if (roles) {
          if ((roles.location_admin || roles.location_user) && locationId) {
            this.router.navigate(["/locations", locationId])
          } else {
            this.router.navigate(["/overview"])
          }
        }
      }
      resolve(true)
    })
  }
}
