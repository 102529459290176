import { DatePipe } from "@angular/common"
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  PipeTransform,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { TableColumn } from "@swimlane/ngx-datatable"
import { FiltrableDataSource } from "filtrable-data-source"
import { ChartConfiguration } from "src/app/api"
import { getDateFormatByResolution } from "src/app/core/chart-utils"
import { DbUnitPipe } from "../db-unit.pipe"
import { PercentageUnitPipe } from "../percentage-unit.pipe"
import { RpmUnitPipe } from "../rpm-unit.pipe"
import { TemperaturePipe } from "../temperature/temperature.pipe"

@Component({
  selector: "app-chart-table",
  templateUrl: "./chart-table.component.html",
  styleUrls: ["./chart-table.component.scss"],
  providers: [
    TemperaturePipe,
    PercentageUnitPipe,
    RpmUnitPipe,
    DbUnitPipe,
    DatePipe
  ]
})
export class ChartTableComponent implements OnInit, OnChanges {
  @Input() configuration: ChartConfiguration
  @Input() dataSource: FiltrableDataSource<unknown>
  columns: TableColumn[] = []
  @Input() resolution: string
  getDateFormat = getDateFormatByResolution

  @ViewChild("singleCellTpl", { static: true }) singleCellTpl: TemplateRef<any>

  constructor(
    private ts: TranslateService,
    private temperaturePipe: TemperaturePipe,
    private percentageType: PercentageUnitPipe,
    private rpmType: RpmUnitPipe,
    private dbType: DbUnitPipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.configuration &&
      changes.configuration.previousValue !== changes.configuration.currentValue
    ) {
      this.updateColumns()
    }
  }

  ngOnInit() {
    this.updateColumns()
  }

  updateColumns() {
    this.columns = []
    this.columns.push({
      prop: "timestamp",
      cellTemplate: this.singleCellTpl,
      name: this.ts.instant("GLOBAL.DATE_TIME"),
      minWidth: 200,
      sortable: true
    })

    this.configuration.table.forEach((element, index: number) => {
      this.columns.push({
        prop: this.configuration.tableMap[element],
        name: element,
        canAutoResize: true,
        sortable: false,
        minWidth: 175,
        pipe: this.getVariablePipe(this.configuration.variableTypes[element])
      })
    })
  }

  getVariablePipe(type: string): PipeTransform | undefined {
    switch (type) {
      case "temperature":
        return this.temperaturePipe
      case "percentage":
        return this.percentageType
      case "RPM":
        return this.rpmType
      case "dB":
        return this.dbType
    }
    return undefined
  }
}
