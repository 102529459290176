import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UnitsService } from 'src/app/api';
import { DialogModalService } from 'src/app/core/dialog-modal.service';
import { ModalCloseEvent } from '../shared/generic-modal/generic-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AdminUnitsService {
  constructor(
    private unitsService: UnitsService,
    private dialogService: DialogModalService,
    private ts: TranslateService
  ) {}

  deleteUnits(unitIds: string[]): Observable<ModalCloseEvent> {
    return this.openConfrimModal(unitIds).pipe(
      mergeMap(() => this.openSuccessModal())
    );
  }

  private openConfrimModal(unitIds: string[]): Observable<void> {
    return this.dialogService.dangerConfirm({
      title: this.ts.instant('ADMINISTRATION.UNITS.DELETE_UNITS'),
      detail: this.ts.instant('ADMINISTRATION.UNITS.DELETE_UNITS_CONFIRM'),
      acceptButtonLabel: this.ts.instant('GLOBAL.DELETE'),
      beforeAccept$: this.unitsService.deleteUnits(unitIds)
    }).accept$;
  }

  private openSuccessModal(): Observable<ModalCloseEvent> {
    return this.dialogService.success({
      icon: 'oven-fill-white.svg',
      message: this.ts.instant('ADMINISTRATION.UNITS.DELETE_UNITS_SUCCESS'),
      detail: this.ts.instant('GLOBAL.OK_CLICK')
    }).close$;
  }
}
