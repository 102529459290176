/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ChartConfiguration {
  graph: Array<string>;
  graphMap: { [key: string]: string };
  table: Array<string>;
  tableMap: { [key: string]: string };
  variableTypes: { [key: string]: string };
  chamberCount: number;
  // Line color per series
  colors?: Array<string>;
}
export namespace ChartConfiguration {
  export type VariableTypesEnum =
    | "temperature"
    | "percentage"
    | "RPM"
    | "dB"
    | "string";
  export const VariableTypesEnum = {
    Temperature: "temperature" as VariableTypesEnum,
    Percentage: "percentage" as VariableTypesEnum,
    RPM: "RPM" as VariableTypesEnum,
    DB: "dB" as VariableTypesEnum,
    String: "string" as VariableTypesEnum
  };
}
