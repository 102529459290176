import { NotificationType } from "@app/shared/consts/remote-support/enums"
import {
  AssetWrapper,
  MediaFile,
  MediaFileCategory,
  MediaFileMetadata,
  PartialMediaFileMetadata
} from "@app/shared/models/remote-support/media"
import { PartialMessageReceivedEventArgs } from "@cygnus-reach/core"

export abstract class MessageMetadata<T> {
  constructor(sent: boolean, ts: Date, message: T) {
    this.sent = sent
    this.timestamp = ts
    this.message = message
  }

  sent: boolean
  timestamp: Date
  message: T
}

export abstract class ArchivedMessageMetadata extends MessageMetadata<
  string | AssetWrapper<MediaFileMetadata>
> {
  constructor(
    sent: boolean,
    ts: Date,
    message: string | AssetWrapper<MediaFileMetadata>
  ) {
    super(sent, ts, message)
  }
  // This may eventually get sender metadata, if the zero-install gets nicknames
}

export class LoggedMessageMetadata extends ArchivedMessageMetadata {
  constructor(
    sent: boolean,
    ts: Date,
    message: string,
    type: "text" | "media"
  ) {
    super(sent, ts, message)
    this.type = type
  }
  // Note: update to >= ES2022 will require this gets a declare tag
  message: string
  type: "text" | "media"
}

export class ArchivedMessage extends ArchivedMessageMetadata {
  constructor(
    sent: boolean,
    ts: Date,
    message: string | AssetWrapper<MediaFileMetadata>
  ) {
    super(sent, ts, message)
  }
}

export abstract class ActiveMessageMetadata extends MessageMetadata<
  undefined | string | AssetWrapper<PartialMediaFileMetadata | MediaFile>
> {
  constructor(
    sent: boolean,
    message:
      | undefined
      | string
      | AssetWrapper<PartialMediaFileMetadata | MediaFile>,
    isRead: boolean,
    timestamp: Date,
    id: number
  ) {
    super(sent, timestamp, message)
    this.isRead = isRead
    this.id = id
  }
  isRead: boolean
  /**
   * Reach's msgid field, should be unique but seems to currently have a risk of collisions on reconnection
   */
  id: number
}

export class PartialMessage extends ActiveMessageMetadata {
  constructor(event: PartialMessageReceivedEventArgs) {
    let assetInfo:
      | false
      | { type: "image" | "video"; category: MediaFileCategory } = false
    switch (event.category) {
      case NotificationType.RequestedScreenshot:
        assetInfo = { type: "image", category: MediaFileCategory.capture }
        break
      case NotificationType.Image:
        assetInfo = { type: "image", category: MediaFileCategory.received }
        break
      case NotificationType.RequestedRecording:
        assetInfo = { type: "video", category: MediaFileCategory.capture }
        break
      case NotificationType.Video:
        assetInfo = { type: "video", category: MediaFileCategory.received }
        break
    }

    const refDate = new Date()

    super(
      false,
      assetInfo
        ? new AssetWrapper<PartialMediaFileMetadata>(
            new PartialMediaFileMetadata(
              assetInfo.type,
              assetInfo.category,
              refDate,
              event.msgid,
              event.received,
              event.length,
              false
            ),
            assetInfo.category !== MediaFileCategory.capture,
            true
          )
        : undefined,
      false,
      refDate,
      event.msgid
    )
    this.totalChunks = event.length
    this.receivedChunks = event.received
  }
  readonly totalChunks: number
  /**
   * The total number of message chunks received.
   * This should not be set directly outside of the class, but updated with {@link update}
   */
  receivedChunks: number

  update(event: PartialMessageReceivedEventArgs) {
    this.receivedChunks = event.received
    if (
      this.message instanceof AssetWrapper &&
      this.message.asset instanceof PartialMediaFileMetadata
    ) {
      this.message.asset.update(event.received)
    }
  }
}

export class Message extends ActiveMessageMetadata {
  constructor(
    sent: boolean,
    message: string | AssetWrapper<MediaFile>,
    isRead: boolean,
    timestamp: Date,
    id: number
  ) {
    super(sent, message, isRead, timestamp, id)
  }
  // Note: update to >= ES2022 will require this gets a declare tag
  message: string | AssetWrapper<PartialMediaFileMetadata | MediaFile>
}
