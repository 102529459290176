import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { BsModalRef } from "ngx-bootstrap/modal"
import { StageType, UnitType } from "src/app/api"

@Component({
  selector: "app-add-step-modal",
  templateUrl: "./add-step-modal.component.html",
  styleUrls: ["./add-step-modal.component.scss"]
})
export class AddStepModalComponent implements OnInit {
  unitType: UnitType
  steps: number
  preheatStageExists: boolean
  isCookAndHold = false

  @Output() stepSelected = new EventEmitter<StageType>()

  constructor(private modalRef: BsModalRef) {}

  ngOnInit() {
    this.isCookAndHold =
      this.unitType === UnitType.CookAndHold ||
      this.unitType === UnitType.CookAndHoldNoSmoker
  }

  addStage() {
    this.ok(StageType.stage)
  }

  addAction() {
    this.ok(StageType.action)
  }

  addColdSmokeStage() {
    this.ok(StageType.coldSmoke)
  }

  addHoldStage() {
    this.ok(StageType.hold)
  }

  addCookStage() {
    this.ok(StageType.cook)
  }

  addPreheatStage() {
    this.ok(StageType.preheat)
  }

  addSteamStage() {
    this.ok(StageType.steam)
  }

  addCombiStage() {
    this.ok(StageType.combi)
  }

  cancel() {
    this.modalRef.hide()
  }

  private ok(stageType: StageType) {
    this.modalRef.hide()
    this.stepSelected.emit(stageType)
  }
}
