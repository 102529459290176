import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { FiltrableDataSource } from 'filtrable-data-source';
import { Unit } from 'src/app/api';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';
import { getColorHexByUnit } from '../../core/utils';

@Component({
  selector: 'app-units-grid',
  templateUrl: './units-grid.component.html',
  styleUrls: ['./units-grid.component.scss']
})
export class UnitsGridComponent implements OnInit {
  @Input() dataSource: FiltrableDataSource<Unit>;
  @Input() selectedHandler: SelectedItemsHandler<Unit>;
  @Input() menuTpl: TemplateRef<any>;
  @Input() admin: boolean;

  @Output() unitLinkClicked = new EventEmitter<string>();

  getColorHex = getColorHexByUnit;

  constructor() {}

  ngOnInit() {}
}
