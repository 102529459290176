import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { PagingParams } from "../model/paging/PagingParams";
import { CancelledCooksReport } from "src/app/models/CancelledCooksReport";
import { CompletedCooksReportEntry } from "src/app/models/CompletedCooksReportEntry";
import { UnitRecipeReportEntry } from "src/app/models/UnitRecipeReportEntry";
import { PowerCycleReportEntry } from "src/app/models/PowerCycleReportEntry";
import { WifiSignalStrengthReportEntry } from "../../models/WifiSignalStrengthReportEntry";
import { ResolutionType } from "../model/resolutionType";
import { UnitStatusReportEntry } from "src/app/models/UnitStatusReportEntry";
import { ControlBoardTempReportEntry } from "../../models/ControlBoardTempReportEntry";
import { DoorOpenReportEntry } from "src/app/models/DoorOpenReportEntry";
import { AlertHistoryReportEntry } from "../../models/AlertHistoryReportEntry";
import { CleanIntervalsReport } from "src/app/models/CleanIntervalsReport";

@Injectable({
  providedIn: "root"
})
export class ReportService {
  constructor(protected httpClient: HttpClient) {}

  public getUnitStatusReport(
    locationIds: string[],
    unitIds: string[],
    timezone: string,
    pagingParams?: PagingParams
  ): Observable<UnitStatusReportEntry[]> {
    const params = new HttpParams();
    return this.httpClient.post<UnitStatusReportEntry[]>(
      "/report/unit-status",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        timezone: timezone,
        offset: pagingParams ? pagingParams.offset : undefined,
        count: pagingParams ? pagingParams.count : undefined
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getUnitStatusReportExport(
    locationIds: string[],
    unitIds: string[],
    timezone: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams().set("export", true.toString());
    return this.httpClient.post(
      "/report/unit-status",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        timezone: timezone
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getUnitRecipeReport(
    locationIds: string[],
    unitIds: string[],
    timezone: string,
    pagingParams?: PagingParams
  ): Observable<UnitRecipeReportEntry[]> {
    const params = new HttpParams();
    return this.httpClient.post<UnitRecipeReportEntry[]>(
      "/report/unit-recipes",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        timezone: timezone,
        offset: pagingParams ? pagingParams.offset : undefined,
        count: pagingParams ? pagingParams.count : undefined
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getUnitRecipeReportExport(
    locationIds: string[],
    unitIds: string[],
    timezone: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    let params = undefined;
    params = new HttpParams().set("export", true.toString());
    return this.httpClient.post(
      "/report/unit-recipes",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        timezone: timezone
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getAlertHistoryReport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<AlertHistoryReportEntry[]> {
    const params = new HttpParams();
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post<AlertHistoryReportEntry[]>(
      "/report/alert-history",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getAlertHistoryReportExport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams().set("export", "true");
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post(
      "/report/alert-history",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getCleaningAlertHistoryReport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<AlertHistoryReportEntry[]> {
    const params = new HttpParams();
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post<AlertHistoryReportEntry[]>(
      "/report/cleaning-alert-history",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getCleaningAlertHistoryReportExport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams().set("export", "true");
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post(
      "/report/cleaning-alert-history",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getCleaningIntervalsReport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<CleanIntervalsReport[]> {
    const params = new HttpParams();
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post<CleanIntervalsReport[]>(
      "/report/cleaning-intervals",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getCleaningIntervalsReportExport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams().set("export", "true");
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post(
      "/report/cleaning-intervals",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getCancelledCooksReport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<CancelledCooksReport[]> {
    const params = new HttpParams().set("reportType", "CANCELLED_COOKS");
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post<CancelledCooksReport[]>(
      "/report/cooks",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getCancelledCooksReportExport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams()
      .set("reportType", "CANCELLED_COOKS")
      .set("export", true.toString());
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post(
      "/report/cooks",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getCompletedCooksReport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<CompletedCooksReportEntry[]> {
    const params = new HttpParams().set("reportType", "COMPLETED_COOKS");
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post<CompletedCooksReportEntry[]>(
      "/report/cooks",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getCompletedCooksReportExport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams()
      .set("reportType", "COMPLETED_COOKS")
      .set("export", true.toString());
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post(
      "/report/cooks",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getPowerCycleReport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<PowerCycleReportEntry[]> {
    const params = new HttpParams();
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post<PowerCycleReportEntry[]>(
      "/report/power-cycle",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getPowerCycleReportExport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams().set("export", true.toString());
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post(
      "/report/power-cycle",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getDoorOpenReport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<DoorOpenReportEntry[]> {
    const params = new HttpParams();
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post<DoorOpenReportEntry[]>(
      "/report/door-open",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getDoorOpenReportExport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams().set("export", true.toString());
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post(
      "/report/door-open",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getWifiSignalStrengthReport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string,
    resolution: ResolutionType
  ): Observable<WifiSignalStrengthReportEntry[]> {
    const params = new HttpParams();
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post<WifiSignalStrengthReportEntry[]>(
      "/report/wifi-signal",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone,
        resolution: resolution
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getWifiSignalStrengthReportExport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string,
    resolution: ResolutionType
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams().set("export", "true");
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post(
      "/report/wifi-signal",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone,
        resolution: resolution
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }

  public getControlBoardTempReport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string,
    resolution: ResolutionType
  ): Observable<ControlBoardTempReportEntry[]> {
    const params = new HttpParams();
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post<ControlBoardTempReportEntry[]>(
      "/report/control-board-temp",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone,
        resolution: resolution
      },
      {
        params: params,
        responseType: "json"
      }
    );
  }

  public getControlBoardTempExport(
    locationIds: string[],
    unitIds: string[],
    dateRange: [Date, Date],
    timezone: string,
    resolution: ResolutionType
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("Accept", "application/octet-stream");
    const params = new HttpParams().set("export", "true");
    const start = dateRange[0].toISOString();
    const end = dateRange[1].toISOString();
    return this.httpClient.post(
      "/report/control-board-temp",
      {
        locationIds: locationIds,
        unitIds: unitIds,
        start: start,
        end: end,
        timezone: timezone,
        resolution: resolution
      },
      {
        headers: headers,
        params: params,
        responseType: "blob" as "blob"
      }
    );
  }
}
