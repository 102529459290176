/**
 * Alto Shaam Dashboard API
 * APIs for the Alto Shaam Dasbhoard
 *
 * OpenAPI spec version: 1.8.1
 * Contact: gianluca.frediani@kalpa.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type UnitType =
  | "vector"
  | "vectorF"
  | "vectorFG"
  | "cookAndHold"
  | "cookAndHoldNoSmoker"
  | "converge"
  | "prodigiPro";

export const UnitType = {
  Vector: "vector" as UnitType,
  VectorF: "vectorF" as UnitType,
  VectorFG: "vectorFG" as UnitType,
  CookAndHold: "cookAndHold" as UnitType,
  CookAndHoldNoSmoker: "cookAndHoldNoSmoker" as UnitType,
  Converge: "converge" as UnitType,
  ProdigiPro: "prodigiPro" as UnitType
};
