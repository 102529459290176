// Action and Stage should not be in this enum, but adding them for now until we can refactor.
export enum StageType {
  action = "action",
  stage = "stage", // All cook stages EXCEPT Cook & Hold's
  coldSmoke = "cold_smoke", // this has the wrong case, but not sure if we can change it for backwards compatibility
  hold = "hold",
  cook = "cook", // ONLY Cook & Hold's version of convection (haloheat)
  preheat = "preheat",
  steam = "steam",
  combi = "combi"
}
