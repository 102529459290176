import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable, Subject } from "rxjs"

interface ITokenResponse {
  access_token: string
  expires_in: number
  id_token: string
  "not-before-policy": number
  refresh_expires_in: number
  refresh_token: string
  scope: string
  session_state: string
  token_type: string
}

export type impersonateEventType = "start" | "stop"

async function initTokens(
  instance: any,
  accessToken: string,
  refreshToken: string,
  idToken: string
) {
  return instance.init({
    token: accessToken,
    refreshToken: refreshToken,
    idToken: idToken
  })
}

@Injectable({
  providedIn: "root"
})
export class KeycloakImpersonateService {
  private _impersonateSubject = new Subject<impersonateEventType>()
  private _originalKcInstance?: any

  timer?: number
  tokenRefreshTime = 300

  get impersonateEvent$(): Observable<impersonateEventType> {
    return this._impersonateSubject.asObservable()
  }

  constructor() {}

  async startImpersonation(userId: string) {}

  async stopImpersonation() {}

  private refreshOriginalToken = () => {
    if (this._originalKcInstance) {
      this._originalKcInstance.updateToken(this.tokenRefreshTime + 60)
    }
  };
}
