import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Duration } from 'luxon';

const durationRegex = /^(\d{1,}):([0-5]\d):([0-5]\d)$/;

export function durationValidator(
  control: FormControl
): { duration: boolean } | null {
  if (!durationRegex.test(control.value as string)) {
    return {
      duration: false
    };
  }
  return null;
}

export function minTimeValidator(minValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const minValidator = Validators.min(+minValue);
    const error = minValidator(control);
    if (error && error.hasOwnProperty('min')) {
      return {
        min: {
          min: fromSecondsToTimeString(minValue.toString()),
          actual: fromSecondsToTimeString(control.value)
        }
      };
    }
    return null;
  };
}

export function maxTimeValidator(maxValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const maxValidator = Validators.max(+maxValue);
    const error = maxValidator(control);
    if (error && error.hasOwnProperty('max')) {
      return {
        max: {
          max: fromSecondsToTimeString(maxValue.toString()),
          actual: fromSecondsToTimeString(control.value)
        }
      };
    }
    return null;
  };
}

export function fromSecondsToTimeString(seconds: string): string {
  return Duration.fromMillis(+seconds * 1000).toFormat('hh:mm:ss');
}

export function fromTimeStringToSeconds(
  timeString: string
): string | undefined {
  const parts = timeString.match(durationRegex);
  if (parts == null || parts.length !== 4) {
    return undefined;
  }
  return (+parts[1] * 3600 + +parts[2] * 60 + +parts[3]).toString();
}
