import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-data-spinner',
  templateUrl: './custom-data-spinner.component.html',
  styleUrls: ['./custom-data-spinner.component.scss']
})
export class CustomDataSpinnerComponent implements OnInit {
  @Input() customDataSource: { isLoading: boolean; data: any; errors: Error[] };
  @Input() firstMessage = '';
  @Input() secondMessage = '';

  history = history;

  constructor() {}

  ngOnInit() {}
}
