import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { AppMainService } from './app.main.service';
import { Observable } from 'rxjs/index';
import { catchError, finalize, map, tap } from 'rxjs/internal/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpErrorEmitter implements HttpInterceptor {
  constructor(private appMainService: AppMainService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        event => event,
        error => {
          this.appMainService.emit(error);
        }
      )
    );
  }

  subscribe(callback: any) {
    this.appMainService.subscribe(callback);
  }
}
