import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-cancellable-search-input',
  templateUrl: './cancellable-search-input.component.html',
  styleUrls: ['./cancellable-search-input.component.scss']
})
export class CancellableSearchInputComponent implements OnInit {
  @Input() searchFc: FormControl;
  @Input() placeholder: string;

  cancelBtnHidden = true;

  constructor() {}

  ngOnInit() {
    this.searchFc.valueChanges.subscribe((v?: string) => {
      this.cancelBtnHidden = !v;
    });
  }

  onCancelBtnClick(ev: MouseEvent) {
    this.searchFc.setValue(undefined);
    ev.stopPropagation();
  }
}
