import { Pipe, PipeTransform } from "@angular/core"
import { RecipeImage } from "../api"

@Pipe({
  name: "recipeImage"
})
export class RecipeImagePipe implements PipeTransform {
  transform(image?: RecipeImage): string | undefined {
    // render the presigned S3 url for a cloud-originated image, or the base64 encoded image data of an upload-pending image
    return (
      image &&
      (image.presignedUrl || `data:${image.mimeType};base64,${image.data}`)
    )
  }
}
