import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DatatableComponent as NgxDatatableComponent,
  SelectionType,
  TableColumn
} from '@swimlane/ngx-datatable';
import { FiltrableDataSource, ISortColumn } from 'filtrable-data-source';
import { LayoutService } from 'src/app/core/layout.service';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {
  @Input()
  dataSource: FiltrableDataSource<any>;
  @Input() selectionHandler?: SelectedItemsHandler<any>;
  @Input()
  set columns(val: TableColumn[]) {
    this._columns = val;
    this.parseColumns();
  }
  @Input() selected: any[] = [];
  @Input() selectionType?: SelectionType = undefined;
  @Output() select = new EventEmitter<any>();

  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<any>;
  @ViewChild(NgxDatatableComponent, { static: true })
  ngxDatatable: NgxDatatableComponent;

  messages = {};
  _columns: TableColumn[] = [];
  sort?: ISortColumn[];

  constructor(
    private ts: TranslateService,
    private layoutService: LayoutService
  ) {}

  async ngOnInit() {
    const emptyMessageString = await this.ts.get('GLOBAL.NO_DATA').toPromise();
    this.messages = {
      emptyMessage: emptyMessageString
    };
    if (this.dataSource.sortBy) {
      this.sort = [this.dataSource.sortBy];
    }
    if (this.selectionHandler) {
      this.selectionHandler.itemsChanged$.subscribe(() => {
        // force ngx-datatable to execute change detection to reflect selection state
        this.selected = [...this.selected];
      });
    }
    this.layoutService.sidebarToggled.subscribe(() => {
      this.ngxDatatable.recalculate();
      this.selected = [...this.selected];
    });
  }

  getRowClass = (row: any) => {
    if (this.selectionHandler) {
      return {
        active: this.selectionHandler.isSelected(row)
      };
    }
    return {};
  };

  private parseColumns() {
    if (this.selectionHandler) {
      this._columns = [
        {
          width: 70,
          sortable: false,
          canAutoResize: false,
          draggable: false,
          resizeable: false,
          cellTemplate: this.checkboxTpl
        },
        ...this._columns
      ];
    }
  }
}
