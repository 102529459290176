import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FormGroup } from "@angular/forms"
import {
  AuthenticationService,
  UserTempUnit
} from "src/app/core/authentication.service"
import { coldSmokeByUserTemp } from "../form-groups"

@Component({
  selector: "app-smoke-step",
  templateUrl: "./smoke-step.component.html",
  styleUrls: ["./smoke-step.component.scss"]
})
export class SmokeStepComponent implements OnInit {
  @Input() formGroup: FormGroup
  @Input() index: number
  @Input() editingEnabled = true
  @Output() edit = new EventEmitter<void>()
  @Output() delete = new EventEmitter<void>()
  tempUnit: UserTempUnit
  getColdSmokeTemp = coldSmokeByUserTemp
  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    this.tempUnit = this.authService.getUserTemp()
  }
}
