import { Component, Input, OnInit } from '@angular/core';
import { Counter } from './Counter';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  @Input()
  counter: Counter;

  constructor() {}

  ngOnInit() {}
}
